import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectLoginData } from '../../redux/user/user.selectors'
import AuthService from '../../services/auth/auth-service'

const Authenticator = ({ children, loginData }) => {
  const { isLoggedIn, role, expiresOn } = loginData
  const location = useLocation()
  const dateNow = new Date(Date.now())
  const formattedExpiresOn = new Date(expiresOn)
  if (!isLoggedIn || !AuthService.canUserViewPage(location.pathname, role)) return <Redirect to="/" />

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

const mapStateToProps = createStructuredSelector({
  loginData: selectLoginData,
})

export default connect(mapStateToProps, null)(Authenticator)
