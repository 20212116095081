import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled, alpha } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import Icon from '@material-ui/core/Icon'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
    width: 'auto',
  },
  border: '1px solid #e0e0e0',
  display: 'flex',
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#666',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
    marginLeft: '55px',
    color: '#666',
  },
}))

const SearchBar = React.memo(({ ...props }) => {
  const { t } = useTranslation()
  return (
    <Search>
      <SearchIconWrapper>
        <Icon>search</Icon>
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={`${t('search')}…`}
        inputProps={{ 'aria-label': 'search' }}
        onChange={props.onChange}
      />
    </Search>
  )
})

export default SearchBar
