import React from 'react'
import Joyride from 'react-joyride'

const JoyrideContainerComponent = ({ show, steps }) => {
  if (!show) {
    return null
  }

  return (
    <Joyride
      steps={steps}
      styles={{
        options: {
          zIndex: 1201,
          beaconSize: 50,
          primaryColor: '#E84C3D',
        },
        buttonNext: {
          backgroundColor: '#2a3858',
          color: '#ffffff',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          letterSpacing: '1.5px',
          padding: '6px 20px',
        },
        tooltipContent: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
      }}
    />
  )
}

export default JoyrideContainerComponent
