import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    width: '90vh',
    padding: 20,
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  closeBtn: {
    maxWidth: '24px',
    maxHeight: '26px',
    minWidth: '24px',
    minHeight: '26px',
  },
  currentLabel: {
    marginTop: 30,
  },
  lotLabel: {
    marginTop: 10,
  },
  form: {
    width: 330,
  },
  btnContainer: { marginTop: 10, display: 'flex', justifyContent: 'flex-start', marginBottom: 10 },
  autoCompleteContainer: {
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.13)',
    },
  },
  autoComplete: {
    '& > div > div > input': {
      paddingLeft: '10px !important',
    },
    '& > div > div > div': {
      top: 'calc(50% - 20px)',
      color: 'rgba(0, 0, 0, 0.54)',
      right: '8px',
      position: 'absolute',
    },
    '& > div > label': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontStyle: 'italic',
      paddingLeft: '10px',
    },
  },
  startButton: {
    textTransform: 'none',
    marginRight: 10,
  },
  saveButton: {
    textTransform: 'none',
  },
  searchBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

export default useStyles
