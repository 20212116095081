import { useQuery } from 'react-query'
import RequestService from '../../services/request/request-service'
import { RECEIVING_SUMMARY_QUERY } from '../constants/queries'

const useRecevingReportQuery = ({
  start_date = '1970-01-01T00:00:00.000',
  end_date = '1970-01-01T23:59:59.999',
  filter = { id_facility: 0 },
} = {}) => {
  return useQuery({
    queryKey: [RECEIVING_SUMMARY_QUERY, filter, start_date, end_date],
    queryFn: async ({ queryKey }) => {
      const url = `${queryKey[0]}?${filter?.id_facility !== 0 ? `id_facility=${filter?.id_facility}&` : ''}start_date=${
        queryKey[2]
      }Z&end_date=${queryKey[3]}Z`

      const { data } = await RequestService.Get(url)
      return data
    },
    initialData: [],
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
export default useRecevingReportQuery
