import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
  module: {
    height: 36,
    marginBottom: 10,
    paddingLeft: 10,
    borderWidth: 5,
    borderStyle: 'none none none solid',
    borderImage: 'linear-gradient( to bottom, #2a3858, #5a7a98  ) 1 100%',
  },
  title: {
    fontSize: 12,
  },
  value: {
    marginTop: -7,
    fontWeight: 'bold',
  },
}))

export default styles
