import { DEFAULT_ERROR_MESSAGE } from './messages'

export const REQUEST_HEADERS = {
  'Content-Type': 'application/json',
  Accept: '*/*',
}
export const POST_METHOD = 'POST'
export const PUT_METHOD = 'PUT'
export const GET_METHOD = 'GET'
export const DELETE_METHOD = 'DELETE'
export const ERROR_RESPONSE_BODY = { hasError: true, errorMessage: DEFAULT_ERROR_MESSAGE }
