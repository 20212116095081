import { forwardRef } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Transition from '../transition/transition.component'
import PrimaryButton from '../button/button.component'
import useStyles from './form-dialog.styles'

const getTitleTranslation = (title) => {
  switch (title) {
    case 'Add new lot':
      return 'add_new_lot'
    case 'Add new facility':
      return 'add_new_facility'
    case 'Locations':
      return 'locations'
    case 'Add new region':
      return 'add_new_region'
    case 'Add new location':
      return 'add_new_location'
    case 'Add new outlet':
      return 'add_new_outlet'
    case 'Add new grower':
      return 'add_new_grower'
    case 'Add new size':
      return 'add_new_size'
    case 'Add new defect':
      return 'add_new_defect'
    case 'Add new variety':
      return 'add_new_variety'
    case 'Add new color':
      return 'add_new_color'
    case 'Add new stem condition':
      return 'add_new_stem_condition'
    case 'Add new sample type':
      return 'add_new_sample_type'
    case 'Add new pack-type':
      return 'add_new_pack_type'
    case 'Add new shift':
      return 'add_new_shift'
    case 'Add new category':
      return 'add_new_category'
    case 'Add new unit type':
      return 'add_new_unit_type'
    case 'Add new unit':
      return 'add_new_unit'
    case 'Add new downtime':
      return 'add_new_downtime'
    case 'Edit lot':
      return 'edit_lot'
    case 'Edit category':
      return 'edit_category'
    case 'Edit unit type':
      return 'edit_unit_type'
    case 'Edit unit':
      return 'edit_unit'
    case 'Edit region':
      return 'edit_region'
    case 'Edit facility':
      return 'edit_facility'
    case 'Edit location':
      return 'edit_location'
    case 'Edit outlet':
      return 'edit_outlet'
    case 'Edit grower':
      return 'edit_grower'
    case 'Edit size':
      return 'edit_size'
    case 'Edit defect':
      return 'edit_defect'
    case 'Edit variety':
      return 'edit_variety'
    case 'Edit color':
      return 'edit_color'
    case 'Edit stem condition':
      return 'edit_stem_condition'
    case 'Edit sample type':
      return 'edit_sample_type'
    case 'Edit pack-type':
      return 'edit_pack_type'
    case 'Edit machine':
      return 'edit_machine'
    case 'Edit user':
      return 'edit_user'
    case 'Edit shift':
      return 'edit_shift'
    default:
      return title
  }
}

const FormDialog = ({ children, ...props }, ref) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Dialog
      ref={ref}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return
        props.onClose()
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t(getTitleTranslation(props.title))}</DialogTitle>
      <form onSubmit={props.handleSubmit}>
        <DialogContent>
          <DialogContentText style={{ margin: 0 }}>
            <Trans i18nKey="please_fill_in_the_information_below">Please fill in the information below</Trans>
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button style={{ textTransform: 'none' }} onClick={props.onClose} color="primary">
            {t('cancel')}
          </Button>
          <div style={{ position: 'relative' }}>
            <PrimaryButton variant="contained" disabled={props.loading} type="submit">
              {t('save')}
            </PrimaryButton>
            {props.loading && <CircularProgress size={24} className={classes.circularProgress} />}
          </div>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default forwardRef(FormDialog)
