import React from 'react'

import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'

import useStyles from './many-many-input.styles'

const ManyToManyInput = ({ ...props }) => {
  const { inputLabel, name, selectedValues, setSelectedValues, allValues, required } = props

  const isAllSelected = allValues.length > 0 && selectedValues.length === allValues.length
  const classes = useStyles()

  const handleChange = (event) => {
    const { value } = event.target
    if (value[value.length - 1] === 'all') {
      setSelectedValues(selectedValues.length === allValues.length ? [] : allValues)
      return
    }
    setSelectedValues(value)
  }

  return (
    <FormControl margin="normal" variant="outlined" fullWidth>
      <InputLabel id={`multiple-${inputLabel}-checkbox-label`}>
        {inputLabel} {required ? '*' : ''}
      </InputLabel>
      <Select
        id={`multiple-${inputLabel}-checkbox-label`}
        label="inputLabel"
        name={name}
        onChange={handleChange}
        value={selectedValues}
        multiple
        required={required}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((item, index) => (
              <Chip
                key={`chip-${inputLabel}-${index}`}
                label={item.description ? item.description : item.name ? item.name : ''}
                className={classes.chip}
              />
            ))}
          </div>
        )}
        MenuProps={{
          getContentAnchorEl: () => null,
        }}
      >
        <MenuItem key={`check-${props.title}-0`} value="all">
          <Checkbox checked={isAllSelected} />
          <ListItemText primary="Select all" />
        </MenuItem>
        {allValues.map((item, index) => (
          <MenuItem key={`check-${inputLabel}-${index}`} value={item}>
            <Checkbox checked={selectedValues.some((el) => el.id === item.id)} />
            <ListItemText primary={item.description ? item.description : item.name ? item.name : ''} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ManyToManyInput
