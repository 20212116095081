export const TEMPLATE_LOC_REC = 1
export const TEMPLATE_LOC_UPS = 2
export const TEMPLATE_LOC_INL = 3
export const TEMPLATE_LOC_FIN = 4
export const TEMPLATE_LOC_CUL = 5
export const TEMPLATE_LOC_PED = 6

export const TEMPLATES_LOC = {
  TEMPLATE_LOC_REC: 1,
  TEMPLATE_LOC_UPS: 2,
  TEMPLATE_LOC_INL: 3,
  TEMPLATE_LOC_FIN: 4,
  TEMPLATE_LOC_CUL: 5,
  TEMPLATE_LOC_PED: 6,
}
