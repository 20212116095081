import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import useStyles from './simple-backdrop.styles'

const SimpleBackdrop = React.memo(({ ...props }) => {
  const classes = useStyles()

  return (
    <div>
      <Backdrop className={classes.backdrop} open={props.open} onClick={props.handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
})

export default SimpleBackdrop
