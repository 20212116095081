import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    height: 26,
  },
}))

export default useStyles
