import { createContext } from 'react'

const DashboardContext = createContext({
  dashboardClient: null,
  setDashboardClient: () => {},
  alertClient: null,
  setAlertClient: () => {},
  dashboardSubscription: null,
  setDashboardSubscription: () => {},
  dashboardReceiver: null,
  setDashboardReceiver: () => {},
  alertSubscription: null,
  setAlertSubscription: () => {},
  alertReceiver: null,
  setAlertReceiver: () => {},
  deleteSubscription: () => {},
  cleanUpContext: () => {},
  notificationClient: null,
  setNotificationClient: () => {},
  notificationReceiver: null,
  setNotificationReceiver: () => {},
  setNotificationSubscription: () => {},
  cleanUptNotificationContext: () => {},
})

export default DashboardContext
