import {
  DELETE_METHOD,
  ERROR_RESPONSE_BODY,
  GET_METHOD,
  POST_METHOD,
  PUT_METHOD,
  REQUEST_HEADERS,
} from '../../shared/constants/requests'
import { appInsights } from '../../config/appInsights'
import { LOG_API_ACTIONS } from '../../shared/constants/logging'
import { ACCOUNT_REFRESH_TOKEN_QUERY } from '../../shared/constants/queries'
import { getHttpInstance } from './http-instance'

const getAuthToken = () => {
  const expiresOn = localStorage.getItem('expiresOn')
  const refreshToken = localStorage.getItem('refreshToken')
  const accessToken = localStorage.getItem('accessToken')
  return { accessToken, refreshToken, expiresOn }
}

const removeAuthToken = () => {
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('expiresOn')
}
const updateAuthToken = ({ expiresOn, accessToken }) => {
  localStorage.setItem('expiresOn', expiresOn)
  localStorage.setItem('accessToken', accessToken)
}
class RequestService {
  static async getAccessToken() {
    const { accessToken, expiresOn, refreshToken } = getAuthToken()
    const dateNow = new Date()
    const formattedExpiresOn = new Date(expiresOn)
    if (refreshToken && accessToken && expiresOn && new Date(formattedExpiresOn.getTime() - 1000 * 60 * 5) < dateNow) {
      try {
        const { data } = await RequestService.Post(ACCOUNT_REFRESH_TOKEN_QUERY, () => {}, {
          refreshToken: `${refreshToken}`,
        })
        updateAuthToken(data)
        return data?.accessToken
      } catch (err) {
        removeAuthToken()
        return accessToken
      }
    }

    return accessToken
  }

  static ErrorHandler(error) {
    if (error.response) {
      const { status, data } = error.response
      if (status === 401 || status === 403) {
        return { status, redirect: true, ...ERROR_RESPONSE_BODY }
      }
      if (status === 400) {
        return { status, hasError: true, errorMessage: data?.message }
      }
      return { status, ...ERROR_RESPONSE_BODY }
    }
    if (error.request) {
      const { request } = error
      return { request, ...ERROR_RESPONSE_BODY }
    }
    if (error.message) {
      const { message } = error
      return { message, ...ERROR_RESPONSE_BODY }
    }
    return ERROR_RESPONSE_BODY
  }

  static async Get(url, history, returnHeaders = false, config = { isBlankInstance: false }) {
    try {
      const httpInstance = getHttpInstance(config?.isBlankInstance)
      const response = await httpInstance({
        headers: {
          ...REQUEST_HEADERS,
          Authorization: `Bearer ${await this.getAccessToken()}`,
        },
        method: GET_METHOD,
        url,
      })
      appInsights.trackEvent({ name: LOG_API_ACTIONS.CALLED_GET_METHOD }, { url })
      if (returnHeaders) return { ...response.data, headers: response.headers }
      return response.data
    } catch (error) {
      const err = RequestService.ErrorHandler(error)
      appInsights.trackEvent({ name: LOG_API_ACTIONS.API_ERROR }, { url, error: err })
      if (err.redirect) {
        history.push('/')
      }
      throw err
    }
  }

  static async Post(url, history, payload, config = { isBlankInstance: false }) {
    try {
      const httpInstance = getHttpInstance(config?.isBlankInstance)
      if (url === ACCOUNT_REFRESH_TOKEN_QUERY) {
        const response = await httpInstance({
          headers: {
            ...REQUEST_HEADERS,
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          method: POST_METHOD,
          data: payload,
          url,
        })
        appInsights.trackEvent({ name: LOG_API_ACTIONS.CALLED_POST_METHOD }, { url, payload })
        return response.data
      }

      const response = await httpInstance({
        headers: {
          ...REQUEST_HEADERS,
          Authorization: `Bearer ${await this.getAccessToken()}`,
        },
        method: POST_METHOD,
        data: payload,
        url,
      })
      appInsights.trackEvent({ name: LOG_API_ACTIONS.CALLED_POST_METHOD }, { url, payload })
      return response.data
    } catch (error) {
      const err = RequestService.ErrorHandler(error)
      appInsights.trackEvent({ name: LOG_API_ACTIONS.API_ERROR }, { url, error: err })
      if (err.redirect) {
        history.push('/')
      }
      throw err
    }
  }

  static async Put(url, history, payload, config = { isBlankInstance: false }) {
    const httpInstance = getHttpInstance(config?.isBlankInstance)
    try {
      const response = await httpInstance({
        headers: {
          ...REQUEST_HEADERS,
          Authorization: `Bearer ${await this.getAccessToken()}`,
        },
        method: PUT_METHOD,
        data: payload,
        url,
      })
      appInsights.trackEvent({ name: LOG_API_ACTIONS.CALLED_PUT_METHOD }, { url, payload })
      return response.data
    } catch (error) {
      const err = RequestService.ErrorHandler(error)
      appInsights.trackEvent({ name: LOG_API_ACTIONS.API_ERROR }, { url, error: err })
      if (err.redirect) {
        history.push('/')
      }
      throw err
    }
  }

  static async Delete(url, history, config = { isBlankInstance: false }) {
    const httpInstance = getHttpInstance(config?.isBlankInstance)
    try {
      const response = await httpInstance({
        headers: {
          ...REQUEST_HEADERS,
          Authorization: `Bearer ${await this.getAccessToken()}`,
        },
        method: DELETE_METHOD,
        url,
      })
      appInsights.trackEvent({ name: LOG_API_ACTIONS.CALLED_DELETE_METHOD }, { url })
      return response.data
    } catch (error) {
      const err = RequestService.ErrorHandler(error)
      appInsights.trackEvent({ name: LOG_API_ACTIONS.API_ERROR }, { url, error: err })
      if (err.redirect) {
        history.push('/')
      }
      throw err
    }
  }
}

export default RequestService
