import { store } from '../../redux/store'

class UserService {
  static getUserToken() {
    const state = store.getState()
    return state?.user?.token
  }

  static getUserRefreshToken() {
    const state = store.getState()
    return state?.user?.refreshToken
  }

  static getUserExpiresOn() {
    const state = store.getState()
    return state?.user?.expiresOn
  }

  static getUserRole() {
    const state = store.getState()
    return state?.user?.role
  }
}

export default UserService
