import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'

import { LOG_PAGE_LOADS } from '../../shared/constants/logging'

import useStyles from './not-found.styles'
import logo from '../../logo.png'

const NotFound = () => {
  const classes = useStyles()
  const history = useHistory()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_NOT_FOUND_PAGE)
  }, [])

  return (
    <Grid container component="main" alignItems="center" direction="column">
      <Grid item xs={12}>
        <img src={logo} className={classes.logo} alt="Logo" />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title} variant="h4" color="primary">
          Page not found!
        </Typography>
      </Grid>
      <br />
      <Grid item xs={12}>
        <Typography className={classes.message} variant="h5" color="primary">
          The requested URL does not exist.
          <br />
          Please try again.
        </Typography>
      </Grid>
      <Button onClick={() => history.push('/')}>Go to login</Button>
    </Grid>
  )
}

export default NotFound
