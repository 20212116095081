import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import html2canvas from 'html2canvas'
import { DateTime } from 'luxon'
import * as XLSXStyle from 'xlsx-js-style'

import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'

import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { Box, ImageList, ImageListItem } from '@material-ui/core'
import SimpleBackdrop from '../../../components/simple-backdrop/simple-backdrop.component'
import ReportsRowSizeHeaderRow from '../../../components/reports-row-size-header-row/reports-row-size-header-row.component'
import PrintButton from '../../../components/print-button/print-button.component'
import ReportInputs from '../../../components/report-inputs/report-inputs.component'

import PdfMakerService from '../../../services/pdf-maker/pdf-maker-service'
import RequestService from '../../../services/request/request-service'

import { numberToPercentage } from '../../../shared/utils/utils'
import { BLUE, GREEN, ORANGE, RED } from '../../../shared/constants/pdf'
import {
  SAMPLE_SIZE_DISTRIBUTION_INLINE_FINISHED_BOX_QUERY,
  SAMPLE_SIZE_DISTRIBUTION_RECEIVING_UPSTREAM_QUERY,
} from '../../../shared/constants/queries'

import makeStyles from '../reports.styles'

const SizingSummary = ({
  currentFacility,
  selectedLot,
  selectedLotName,
  selectedVariety,
  selectedVarietyName,
  selectedGrowerName,
  selectedStartDate,
  selectedEndDate,
  selectedMachine,
  desTemplates,
}) => {
  const classes = makeStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const [sizeDistributionChartData, setSizeDistributionChartData] = useState([])
  const [sizeDistributionChartReceivingData, setSizeDistributionChartReceivingData] = useState([])
  const [sizeDistributionChartUpstreamData, setSizeDistributionChartUpstreamData] = useState([])
  const [pickDate, setPickDate] = useState('-')
  const [peakSizeOverall, setPeakSizeOverall] = useState('-')
  const [sampleSize, setSampleSize] = useState(0)
  const [receivingSampleSize, setReceivingSampleSize] = useState(0)
  const [upstreamSampleSize, setUpstreamSampleSize] = useState(0)
  const [receivingSamples, setReceivingSamples] = useState(0)
  const [upstreamSamples, setUpstreamSamples] = useState(0)
  const [inlineSampleSize, setInlineSampleSize] = useState(0)
  const [finishedBoxSampleSize, setFinishedBoxSampleSize] = useState(0)
  const [inlineSamples, setInlineSamples] = useState(0)
  const [finishedBoxSamples, setFinishedBoxSamples] = useState(0)
  const [finishedboxAttachmentSamples, setFinishedboxAttachmentSamples] = useState([])
  const [reportDate] = useState(DateTime.now().toFormat('LL-dd-yy'))

  const [reportDataGeneral, setReportDataGeneral] = useState({})
  const [reportDataSizeDistribution, setReportDataSizeDistribution] = useState({})
  const [reportDataReceiving, setReportDataReceiving] = useState({})
  const [reportDataUpstream, setReportDataUpstream] = useState({})
  const [reportDataInline, setReportDataInline] = useState({})
  const [reportDataFinishedBox, setReportDataFinishedBox] = useState({})
  const [reportDataInlineGraph, setReportDataInlineGraph] = useState([])
  const [reportDataFinishedBoxGraph, setReportDataFinishedBoxGraph] = useState([])

  const tableCellHeaderClassNames = `${classes.tableCell} ${classes.tableCellLabel}`
  const tableCellClassNames = `${classes.tableCell}`

  useEffect(() => {
    return () => {
      // Clear the array when the component unmounts
      setReportDataGeneral({})
      setReportDataSizeDistribution({})
      setReportDataReceiving({})
      setReportDataUpstream({})
      setReportDataInline({})
      setReportDataFinishedBox({})
      setReportDataInlineGraph([])
      setReportDataFinishedBoxGraph([])
      setSizeDistributionChartData([])
      setSizeDistributionChartReceivingData([])
      setSizeDistributionChartUpstreamData([])
      setFinishedboxAttachmentSamples([])
    }
  }, [])

  useEffect(() => {
    if (selectedVariety === 0 || selectedLot === 0) {
      setShowReport(false)
    }
  }, [selectedLot, selectedVariety])

  const buildSizeDistributionChartData = (rawData) => {
    let maxValue = 0
    let maxObj = null
    const chartData = []

    if (rawData.length === 0) return { peakSize: '-', returnData: [] }

    const totalSum = rawData.reduce((total, item) => {
      return total + item.total
    }, 0)

    rawData.forEach((item) => {
      if (item.total > maxValue) {
        maxObj = item
        maxValue = item.total
      }
      if (item.size !== 'Oversize') {
        chartData.push({
          name: item.size,
          pieces: item.total,
          percentage: Number(((item.total / totalSum) * 100).toFixed(2)),
          numberName: item.size !== 'Undersize' ? +item.size.split(' ')[0] : 13,
          underSize: item.underSize,
          overSize: item.overSize,
        })
      }
    })

    let peakSize = ''
    const returnData = chartData
      .map((item) => {
        const isPeakSize = item.name === maxObj.size
        if (isPeakSize) {
          peakSize = item.name
        }
        return {
          ...item,
          isPeakSize,
        }
      })
      .sort((a, b) => b.numberName - a.numberName)

    return { peakSize, returnData }
  }

  const createSizingDistributionData = (receivingData, upstreamData) => {
    let data
    if (receivingData.length === 0 && upstreamData.length === 0) return []
    if (receivingData.length > 0) {
      data = receivingData
    } else if (upstreamData.length > 0) {
      data = upstreamData
    }

    const builtData = []
    if (data) {
      const keys = data.map((item) => item.size)
      keys.forEach((key) => {
        const recData = receivingData.find((item) => item.size === key)
        const upData = upstreamData.find((item) => item.size === key)
        let recOverSize = 0
        let recUnderSize = 0
        let upOverSize = 0
        let upUnderSize = 0
        let recTotal = 0
        let upTotal = 0
        if (recData) {
          recOverSize = recData.overSize
          recUnderSize = recData.underSize
          recTotal = recData.total
        }
        if (upData) {
          upOverSize = upData.overSize
          upUnderSize = upData.underSize
          upTotal = upData.total
        }
        builtData.push({
          size: key,
          overSize: recOverSize + upOverSize,
          underSize: recUnderSize + upUnderSize,
          total: recTotal + upTotal,
        })
      })
    }
    return builtData
  }

  const buildSizingAccuraciesReportData = (sizingData, type) => {
    const reportDataObject = {}
    const reportDataArray = []
    const reportDataGraphArray = []
    let overallUnders = 0
    let overallCorrect = 0
    let overallOvers = 0
    let totalCountOfSizesUnders = 0
    let totalCountOfSizesCorrect = 0
    let totalCountOfSizesOvers = 0

    const filteredSortedData = sizingData
      .filter((dataItem) => dataItem.size !== 'Undersize' && dataItem.size !== 'Oversize')
      .sort((a, b) => +b.size.split(' ')[0] - +a.size.split(' ')[0])

    filteredSortedData.forEach((item) => {
      if (item.total !== 0) totalCountOfSizesCorrect += 1
      if (item.underSize !== 0) totalCountOfSizesUnders += 1
      if (item.overSize !== 0) totalCountOfSizesOvers += 1
      const totalCount =
        item.underSize + item.total + item.overSize > 0 ? item.underSize + item.total + item.overSize : 1
      const unders = (item.underSize / totalCount) * 100 ?? 0
      const correct = (item.total / totalCount) * 100 ?? 0
      const overs = (item.overSize / totalCount) * 100 ?? 0
      reportDataArray.push({
        id: item.size.split(' ')[0],
        unders: unders.toFixed(2),
        correct: correct.toFixed(2),
        overs: overs.toFixed(2),
        pieces: item.total,
      })
      reportDataObject[item.size.split(' ')[0]] = {
        unders,
        correct,
        overs,
      }
      overallCorrect += correct
      overallOvers += overs
      overallUnders += unders

      const graphDataObject = {
        name: item.size,
        pieces: item.total,
        underSize: item.underSize,
        unders: unders.toFixed(2),
        overSize: item.overSize,
        overs: overs.toFixed(2),
        numberName: item.size.split(' ')[0],
        correct: correct.toFixed(2),
      }

      reportDataGraphArray.push(graphDataObject)
    })

    if (type === 'inline') {
      setReportDataInlineGraph(reportDataGraphArray.sort((a, b) => b.numberName - a.numberName))
    }
    if (type === 'finishedbox') {
      setReportDataFinishedBoxGraph(reportDataGraphArray.sort((a, b) => b.numberName - a.numberName))
    }

    const unders = (overallUnders / (totalCountOfSizesUnders > 0 ? totalCountOfSizesUnders : 1)).toFixed(2)
    const correct = (overallCorrect / (totalCountOfSizesCorrect > 0 ? totalCountOfSizesCorrect : 1)).toFixed(2)
    const overs = (overallOvers / (totalCountOfSizesOvers > 0 ? totalCountOfSizesOvers : 1)).toFixed(2)
    reportDataArray.push({
      id: 'Overall',
      unders,
      correct,
      overs,
    })
    reportDataObject.overall = {
      unders,
      correct,
      overs,
    }

    return reportDataArray
  }

  const loadSizedDistribution = async () => {
    try {
      setShowBackdrop(true)
      setPeakSizeOverall('-')
      // eslint-disable-next-line max-len
      let url = `${SAMPLE_SIZE_DISTRIBUTION_RECEIVING_UPSTREAM_QUERY}?id_lot=${selectedLot}&id_variety=${selectedVariety}&id_facility=${currentFacility.id}&start_date=${selectedStartDate}Z&end_date=${selectedEndDate}Z&id_machine=${selectedMachine.id}`
      const tskSizeSummary = RequestService.Get(url, history)

      // eslint-disable-next-line max-len
      url = `${SAMPLE_SIZE_DISTRIBUTION_INLINE_FINISHED_BOX_QUERY}?id_lot=${selectedLot}&id_variety=${selectedVariety}&id_facility=${currentFacility.id}&start_date=${selectedStartDate}Z&end_date=${selectedEndDate}Z&id_machine=${selectedMachine.id}`
      const tskGradeSummary = RequestService.Get(url, history)

      const [resSizeSummary, resGradeSummary] = await Promise.all([tskSizeSummary, tskGradeSummary])

      const { receiving, upstream, pick_date, receiving_samples, upstream_samples } = resSizeSummary.data
      const recData = receiving?.result || []
      const upData = upstream?.result || []
      const recTotalCount = receiving?.totalCount || 0
      const upTotalCount = upstream?.totalCount || 0
      const builtData = buildSizeDistributionChartData(createSizingDistributionData(recData, upData))
      const builtRecData = buildSizeDistributionChartData(recData)
      const builtUpData = buildSizeDistributionChartData(upData)
      const pickDateFormatted =
        pick_date !== '0001-01-01T00:00:00' ? DateTime.fromISO(pick_date).toFormat('LL-dd-yy') : '-'
      setSampleSize(recTotalCount + upTotalCount)
      setUpstreamSampleSize(upTotalCount)
      setReceivingSampleSize(recTotalCount)
      setSizeDistributionChartReceivingData(builtRecData.returnData || [])
      setSizeDistributionChartUpstreamData(builtUpData.returnData || [])
      setSizeDistributionChartData(builtData.returnData || [])
      setPickDate(pickDateFormatted)
      setReceivingSamples(receiving_samples)
      setUpstreamSamples(upstream_samples)
      setPeakSizeOverall(builtData.peakSize)

      const { inline_samples, finishedbox_samples, inline, finishedbox, finishedbox_attachment_samples } =
        resGradeSummary.data
      const inlineData = inline?.result || []
      const finishedBoxData = finishedbox?.result || []
      const inlineTotalCount = inline?.totalCount || 0
      const finishedBoxTotalCount = finishedbox?.totalCount || 0
      setInlineSamples(inline_samples)
      setInlineSampleSize(inlineTotalCount)
      setFinishedBoxSamples(finishedbox_samples)
      setFinishedBoxSampleSize(finishedBoxTotalCount)
      setFinishedboxAttachmentSamples(finishedbox_attachment_samples)

      // REPORT
      setReportDataGeneral({
        general: {
          facility: currentFacility?.description,
          lotNumber: selectedLotName,
          grower: selectedGrowerName,
          variety: selectedVarietyName,
          reportDate,
          pickDate: pickDateFormatted,
          peakSize: builtData.peakSize,
          numberSamples: receiving_samples + upstream_samples,
          sampleSize: recTotalCount + upTotalCount,
          machine: selectedMachine?.name
            ? selectedMachine?.name
            : selectedMachine?.description
            ? selectedMachine?.description
            : '',
        },
      })
      setReportDataSizeDistribution({
        sizeDistribution: {
          data: builtData.returnData,
        },
      })
      setReportDataReceiving({
        receiving: {
          data: builtRecData.returnData,
          location: 'Receiving',
          numberSamples: receiving_samples,
          sampleSize: recTotalCount,
          peakSize: builtRecData.peakSize,
        },
      })
      setReportDataUpstream({
        upstream: {
          data: builtUpData.returnData,
          location: 'Upstream',
          numberSamples: upstream_samples,
          sampleSize: upTotalCount,
          peakSize: builtUpData.peakSize,
        },
      })
      setReportDataInline({
        inline: {
          location: 'In-Line',
          numberSamples: inline_samples,
          sampleSize: inlineTotalCount,
          rowSize: buildSizingAccuraciesReportData(inlineData, 'inline'),
        },
      })
      setReportDataFinishedBox({
        finishedBox: {
          location: 'Finished Box',
          numberSamples: finishedbox_samples,
          sampleSize: finishedBoxTotalCount,
          rowSize: buildSizingAccuraciesReportData(finishedBoxData, 'finishedbox'),
        },
      })
      setShowReport(true)
      setShowBackdrop(false)
    } catch (error) {
      setShowBackdrop(false)
      console.error(error)
    }
  }

  const printDocument = async () => {
    if (!reportDataGeneral?.general) return

    setShowBackdrop(true)
    const sizeDistributionBarChart = document.getElementById('sizeDistributionBarChart2')
    const sizingAccuraciesInlineChart = document.getElementById('sizingAccuraciesInlineChart2')
    const sizingAccuraciesFinishedBoxChart = document.getElementById('sizingAccuraciesFinishedBoxChart2')
    const sizeDistributionBarChartImage = (await html2canvas(sizeDistributionBarChart)).toDataURL('image/png')
    const sizingAccuraciesInlineChartImage = (await html2canvas(sizingAccuraciesInlineChart)).toDataURL('image/png')
    const sizingAccuraciesFinishedBoxChartImage = (await html2canvas(sizingAccuraciesFinishedBoxChart)).toDataURL(
      'image/png'
    )

    PdfMakerService.generateReport(
      `SizingSummary_${reportDataGeneral?.general?.lotNumber?.trim() ?? ''}_${
        reportDataGeneral?.general?.variety?.trim() ?? ''
      }`,
      'SizingSummary',
      {
        ...reportDataGeneral,
        ...reportDataSizeDistribution,
        ...reportDataReceiving,
        ...reportDataUpstream,
        sizingAccuracies: {
          ...reportDataInline,
          ...reportDataFinishedBox,
        },
        desTemplates,
      },
      [sizeDistributionBarChartImage, sizingAccuraciesInlineChartImage, sizingAccuraciesFinishedBoxChartImage]
    )
    setShowBackdrop(false)
  }

  const printfReportExcel = () => {
    const sizeDistributionChartReceivingDataExcel = sizeDistributionChartReceivingData.map((item) => {
      if (item.isPeakSize) {
        return {
          t: 's',
          s: { font: { bold: true, sz: 11 }, fill: { fgColor: { rgb: 'ffff00' } } },
          v: item.name,
        }
      }
      return {
        t: 's',
        s: { font: { bold: true, sz: 11 } },
        v: item.name,
      }
    })
    const sizeDistributionChartDataExcel = sizeDistributionChartData.map((item) => {
      return {
        t: 's',
        s: { font: { sz: 11 }, fill: { fgColor: { rgb: '00b0f0' } } },
        v: item.pieces,
      }
    })
    const sizeDistributionChartDataPercentExcel = sizeDistributionChartData.map((item) => {
      return {
        t: 's',
        s: { font: { sz: 11 }, fill: { fgColor: { rgb: '008000' } } },
        v: numberToPercentage(item.percentage),
      }
    })

    const sizeDistributionChartReceivingDataPiecesExcel = sizeDistributionChartReceivingData.map((item) => {
      return {
        t: 's',
        s: { font: { sz: 11 }, fill: { fgColor: { rgb: '00b0f0' } } },
        v: item.pieces,
      }
    })
    const sizeDistributionChartReceivingDataPercentExcel = sizeDistributionChartReceivingData.map((item) => {
      return {
        t: 's',
        s: { font: { sz: 11 }, fill: { fgColor: { rgb: '008000' } } },
        v: numberToPercentage(item.percentage),
      }
    })
    const sizeDistributionChartUpstreamDataPiecesExcel = sizeDistributionChartUpstreamData.map((item) => {
      return {
        t: 's',
        s: { font: { sz: 11 }, fill: { fgColor: { rgb: '00b0f0' } } },
        v: item.pieces,
      }
    })
    const sizeDistributionChartUpstreamDataPercentExcel = sizeDistributionChartUpstreamData.map((item) => {
      return {
        t: 's',
        s: { font: { sz: 11 }, fill: { fgColor: { rgb: '008000' } } },
        v: numberToPercentage(item.percentage),
      }
    })

    const sizeDistributionChartInlineDataPercentExcel = reportDataInline?.inline?.rowSize.map((row) => {
      return [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: row.id === 'Overall' ? row.id : `${row.id} Row`,
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(row.unders || 0),
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(row.correct || 0),
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(row.overs || 0),
        },
      ]
    })
    const sizeDistributionChartFinishedDataPercentExcel = reportDataFinishedBox?.finishedBox?.rowSize.map((row) => {
      return [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: row.id === 'Overall' ? row.id : `${row.id} Row`,
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(row.unders || 0),
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(row.correct || 0),
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: numberToPercentage(row.overs || 0),
        },
      ]
    })

    const sizeDistributionData = [
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Facility',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: reportDataGeneral?.general?.facility ?? '',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Machine',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: reportDataGeneral?.general?.machine ?? '-',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Lot #',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: reportDataGeneral?.general?.lotNumber ?? '',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Grower',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: reportDataGeneral?.general?.grower ?? '',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Variety',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: reportDataGeneral?.general?.variety ?? '',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Report Date',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: reportDate,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Pick Date',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: pickDate,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Peak Size',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: peakSizeOverall,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '# of Samples',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: receivingSamples + upstreamSamples,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Sample Size',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: sampleSize,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        ...sizeDistributionChartReceivingDataExcel,
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 }, fill: { fgColor: { rgb: '00b0f0' } } },
          v: 'Total (pieces)',
        },
        ...sizeDistributionChartDataExcel,
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 }, fill: { fgColor: { rgb: '008000' } } },
          v: 'Percentage',
        },
        ...sizeDistributionChartDataPercentExcel,
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Location',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: desTemplates.TEMPLATE_LOC_REC || 'Receiving',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '# of Samples',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: receivingSamples,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Sample Size',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: receivingSampleSize,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        ...sizeDistributionChartReceivingDataExcel,
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 }, fill: { fgColor: { rgb: '00b0f0' } } },
          v: 'Total (pieces)',
        },
        ...sizeDistributionChartReceivingDataPiecesExcel,
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 }, fill: { fgColor: { rgb: '008000' } } },
          v: 'Percentage',
        },
        ...sizeDistributionChartReceivingDataPercentExcel,
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Location',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: desTemplates.TEMPLATE_LOC_UPS || 'Upstream',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '# of Samples',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: upstreamSamples,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Sample Size',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: upstreamSampleSize,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        ...sizeDistributionChartReceivingDataExcel,
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 }, fill: { fgColor: { rgb: '00b0f0' } } },
          v: 'Total (pieces)',
        },
        ...sizeDistributionChartUpstreamDataPiecesExcel,
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 }, fill: { fgColor: { rgb: '008000' } } },
          v: 'Percentage',
        },
        ...sizeDistributionChartUpstreamDataPercentExcel,
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Location',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: desTemplates.TEMPLATE_LOC_INL || 'In-Line',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '# of Samples',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: inlineSamples,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Sample Size',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: inlineSampleSize,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Row Size',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 }, fill: { fgColor: { rgb: 'ff0000' } } },
          v: 'Unders',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 }, fill: { fgColor: { rgb: '008000' } } },
          v: 'Correct',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 }, fill: { fgColor: { rgb: 'ffa500' } } },
          v: 'Overs',
        },
      ],
      ...sizeDistributionChartInlineDataPercentExcel,
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Location',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: desTemplates.TEMPLATE_LOC_FIN || 'Finished Box',
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '# of Samples',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: finishedBoxSamples,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Sample Size',
        },
        {
          t: 's',
          s: { font: { sz: 11 } },
          v: finishedBoxSampleSize,
        },
      ],
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: '',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Row Size',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 }, fill: { fgColor: { rgb: 'ff0000' } } },
          v: 'Unders',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 }, fill: { fgColor: { rgb: '008000' } } },
          v: 'Correct',
        },
        {
          t: 's',
          s: { font: { bold: true, sz: 11 }, fill: { fgColor: { rgb: 'ffa500' } } },
          v: 'Overs',
        },
      ],
      ...sizeDistributionChartFinishedDataPercentExcel,
    ]

    const sizeDistribution = XLSXStyle.utils.aoa_to_sheet(sizeDistributionData)
    const sizeDistributionCols = [{ wch: 18 }, { wch: 18 }, { wch: 18 }]
    sizeDistribution['!cols'] = sizeDistributionCols
    const excelFile = XLSXStyle.utils.book_new()
    XLSXStyle.utils.book_append_sheet(excelFile, sizeDistribution, 'Size Distribution')
    XLSXStyle.writeFile(
      excelFile,
      `SizingSummary_${reportDataGeneral?.general?.lotNumber?.trim() ?? ''}_${
        reportDataGeneral?.general?.variety?.trim() ?? ''
      }.xlsx`
    )
  }

  return (
    <Grid container>
      {!showReport ? null : <PrintButton printDocument={printDocument} printfReportExcel={printfReportExcel} />}
      <ReportInputs loadData={loadSizedDistribution} isLoading={showBackdrop} />
      {!showReport ? null : (
        <>
          <Grid xs={12} item>
            <Typography align="center" variant="h6">
              {t('sizing_summary')}
            </Typography>
          </Grid>
          <Grid xs={12} md={6} item className={classes.grid}>
            <Typography variant="h5">Size Distribution</Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="facility">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('facility')}</TableCell>
                    <TableCell className={classes.tableCell}>{reportDataGeneral?.general?.facility ?? ''}</TableCell>
                  </TableRow>
                  <TableRow key="MachineName">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('machine')}</TableCell>
                    <TableCell className={classes.tableCell}>{reportDataGeneral?.general?.machine ?? '-'}</TableCell>
                  </TableRow>
                  <TableRow key="lotNumber">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('lot')} #</TableCell>
                    <TableCell className={classes.tableCell}>{reportDataGeneral?.general?.lotNumber ?? ''}</TableCell>
                  </TableRow>
                  <TableRow key="grower">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('grower')}</TableCell>
                    <TableCell className={classes.tableCell}>{reportDataGeneral?.general?.grower ?? ''}</TableCell>
                  </TableRow>
                  <TableRow key="variety">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('variety')}</TableCell>
                    <TableCell className={classes.tableCell}>{reportDataGeneral?.general?.variety ?? ''}</TableCell>
                  </TableRow>
                  <TableRow key="reportDate">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('report_date')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{reportDate}</TableCell>
                  </TableRow>
                  <TableRow key="pickDate">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('pick_date')}</TableCell>
                    <TableCell className={classes.tableCell}>{pickDate}</TableCell>
                  </TableRow>
                  <TableRow key="peakSize">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('peak_size')}</TableCell>
                    <TableCell className={classes.tableCell}>{peakSizeOverall}</TableCell>
                  </TableRow>
                  <TableRow key="numberSamples">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      # {t('of_samples')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{receivingSamples + upstreamSamples}</TableCell>
                  </TableRow>
                  <TableRow key="sampleSize">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('sample_size')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{sampleSize}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid xs={12} md={6} item className={classes.grid}>
            <div className={classes.chartContainer}>
              <ResponsiveContainer id="sizeDistributionBarChart" minWidth={250.5} minHeight={150}>
                <BarChart width={300} height={200} data={sizeDistributionChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" style={{ fill: 'black' }} />
                  <YAxis
                    type="number"
                    style={{ fill: 'black' }}
                    domain={[0, 'dataMax']}
                    tickSize={5}
                    tickFormatter={(value) => `${value}%`}
                  />
                  <Tooltip formatter={(value) => `${value}%`} />
                  <Bar dataKey="percentage" fill={BLUE} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Grid>
          <Grid xs={12} md={6} item className={classes.hideChart}>
            <div className={classes.chartContainer}>
              <ResponsiveContainer id="sizeDistributionBarChart2" minWidth={260.5} minHeight={150} height={400}>
                <BarChart width={500} height={320} data={sizeDistributionChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" style={{ fill: 'black' }} />
                  <YAxis
                    type="number"
                    style={{ fill: 'black' }}
                    domain={[0, 'dataMax']}
                    tickSize={5}
                    tickFormatter={(value) => `${value}%`}
                  />
                  <Tooltip formatter={(value) => `${value}%`} />
                  <Bar dataKey="percentage" fill={BLUE} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Grid>
          <Grid
            xs={12}
            item
            className={`${classes.grid} ${sizeDistributionChartUpstreamData.length > 0 ? classes.positionTable : ''}`}
          >
            <TableContainer>
              {sizeDistributionChartData.length > 0 ? (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${classes.tableCell}`} />
                      {sizeDistributionChartData.map((item, index) => {
                        let classNames = tableCellHeaderClassNames
                        if (item.isPeakSize) classNames += ` ${classes.backgroundYellow}`
                        return (
                          <TableCell key={`${index}-${item.name}`} className={classNames} align="center">
                            {item.name}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ backgroundColor: BLUE }}>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                        {t('total_pieces')}
                      </TableCell>
                      {sizeDistributionChartData.map((item, index) => {
                        return (
                          <TableCell key={`${index}-${item.pieces}`} className={tableCellClassNames} align="center">
                            {item.pieces}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                    <TableRow style={{ backgroundColor: GREEN }}>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                        {t('percentage')}
                      </TableCell>
                      {sizeDistributionChartData.map((item, index) => {
                        return (
                          <TableCell key={`${index}-${item.percentage}`} className={tableCellClassNames} align="center">
                            {numberToPercentage(item.percentage)}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <div className={classes.noDataContainer}>
                  <Typography align="center" variant="h6">
                    {t('no_data')}.
                  </Typography>
                </div>
              )}
            </TableContainer>
          </Grid>
          <Grid xs={12} md={4} item className={classes.grid}>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="location">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('location')}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {desTemplates.TEMPLATE_LOC_REC || t('receiving')}
                    </TableCell>
                  </TableRow>
                  <TableRow key="numberSamples">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      # {t('of_samples')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{receivingSamples}</TableCell>
                  </TableRow>
                  <TableRow key="sampleSize">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('sample_size')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{receivingSampleSize}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            xs={12}
            item
            className={`${classes.grid} ${sizeDistributionChartUpstreamData.length > 0 ? classes.positionTable : ''}`}
            style={{ marginTop: 0 }}
          >
            <TableContainer>
              {sizeDistributionChartReceivingData.length > 0 ? (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${classes.tableCell}`} />
                      {sizeDistributionChartReceivingData.map((item, index) => {
                        let classNames = tableCellHeaderClassNames
                        if (item.isPeakSize) classNames += ` ${classes.backgroundYellow}`
                        return (
                          <TableCell key={`${index}-${item.name}`} className={classNames} align="center">
                            {item.name}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ backgroundColor: BLUE }}>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                        {t('total_pieces')}
                      </TableCell>
                      {sizeDistributionChartReceivingData.map((item, index) => {
                        return (
                          <TableCell key={`${index}-${item.pieces}`} className={tableCellClassNames} align="center">
                            {item.pieces}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                    <TableRow style={{ backgroundColor: GREEN }}>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                        {t('percentage')}
                      </TableCell>
                      {sizeDistributionChartReceivingData.map((item, index) => {
                        return (
                          <TableCell key={`${index}-${item.percentage}`} className={tableCellClassNames} align="center">
                            {numberToPercentage(item.percentage)}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <div className={classes.noDataContainer}>
                  <Typography align="center" variant="h6">
                    {t('no_data')}.
                  </Typography>
                </div>
              )}
            </TableContainer>
          </Grid>
          <Grid xs={12} md={4} item className={classes.grid}>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="location">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('location')}</TableCell>
                    <TableCell className={classes.tableCell}>{desTemplates.TEMPLATE_LOC_UPS || 'Upstream'}</TableCell>
                  </TableRow>
                  <TableRow key="numberSamples">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      # {t('of_samples')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{upstreamSamples}</TableCell>
                  </TableRow>
                  <TableRow key="sampleSize">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('sample_size')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{upstreamSampleSize}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            xs={12}
            item
            className={`${classes.grid} ${sizeDistributionChartUpstreamData.length > 0 ? classes.positionTable : ''}`}
            style={{ marginTop: 0 }}
          >
            <TableContainer>
              {sizeDistributionChartUpstreamData.length > 0 ? (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${classes.tableCell}`} />
                      {sizeDistributionChartUpstreamData.map((item, index) => {
                        let classNames = tableCellHeaderClassNames
                        if (item.isPeakSize) classNames += ` ${classes.backgroundYellow}`
                        return (
                          <TableCell key={`${index}-${item.name}`} className={classNames} align="center">
                            {item.name}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ backgroundColor: BLUE }}>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                        {t('total_pieces')}
                      </TableCell>
                      {sizeDistributionChartUpstreamData.map((item, index) => {
                        return (
                          <TableCell key={`${index}-${item.pieces}`} className={tableCellClassNames} align="center">
                            {item.pieces}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                    <TableRow style={{ backgroundColor: GREEN }}>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                        {t('percentage')}
                      </TableCell>
                      {sizeDistributionChartUpstreamData.map((item, index) => {
                        return (
                          <TableCell key={`${index}-${item.percentage}`} className={tableCellClassNames} align="center">
                            {numberToPercentage(item.percentage)}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <div className={classes.noDataContainer}>
                  <Typography align="center" variant="h6">
                    {t('no_data')}.
                  </Typography>
                </div>
              )}
            </TableContainer>
          </Grid>
          <Grid xs={12} md={4} item className={classes.grid}>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="location">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('location')}</TableCell>
                    <TableCell className={classes.tableCell}>{desTemplates.TEMPLATE_LOC_INL || 'In-Line'}</TableCell>
                  </TableRow>
                  <TableRow key="numberSamples">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      # {t('of_samples')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{inlineSamples}</TableCell>
                  </TableRow>
                  <TableRow key="sampleSize">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('sample_size')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{inlineSampleSize}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid xs={12} md={9} item>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <ReportsRowSizeHeaderRow classes={classes} />
                </TableHead>
                <TableBody>
                  {reportDataInline?.inline?.rowSize.map((row, index) => {
                    return (
                      <TableRow key={`${row.id}-${row.unders || 0}`}>
                        <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="right">
                          {row.id === 'Overall' ? row.id : `${row.id} Row`}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{numberToPercentage(row.unders || 0)}</TableCell>
                        <TableCell className={classes.tableCell}>{numberToPercentage(row.correct || 0)}</TableCell>
                        <TableCell className={classes.tableCell}>{numberToPercentage(row.overs || 0)}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid xs={12} item className={classes.grid}>
            <div className={classes.chartContainer}>
              <ResponsiveContainer id="sizingAccuraciesInlineChart" minWidth={250.5} minHeight={250} height="100%">
                <BarChart width={250} height={250} data={reportDataInlineGraph}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis domain={[0, 100]} tickSize={5} tickFormatter={(value) => `${value}%`} />
                  <Tooltip formatter={(value) => `${value}%`} />
                  <Bar dataKey="correct" fill={BLUE} />
                  <Bar dataKey="unders" fill={RED} />
                  <Bar dataKey="overs" fill={ORANGE} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Grid>
          <Grid xs={12} md={6} item className={classes.hideChart}>
            <div className={classes.chartContainer}>
              <ResponsiveContainer id="sizingAccuraciesInlineChart2" minWidth={250.5} minHeight={250} height={450}>
                <BarChart width={250} height={450} data={reportDataInlineGraph}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" style={{ fill: 'black', fontSize: '150%' }} />
                  <YAxis
                    domain={[0, 100]}
                    style={{ fill: 'black', fontSize: '150%' }}
                    tickSize={5}
                    tickFormatter={(value) => `${value}%`}
                  />
                  <Tooltip formatter={(value) => `${value}%`} />
                  <Bar dataKey="correct" fill={BLUE} />
                  <Bar dataKey="unders" fill={RED} />
                  <Bar dataKey="overs" fill={ORANGE} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Grid>
          <Grid xs={12} md={4} item className={classes.grid}>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="location">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('location')}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {desTemplates.TEMPLATE_LOC_FIN || t('finished_box')}
                    </TableCell>
                  </TableRow>
                  <TableRow key="numberSamples">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      # {t('of_samples')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{finishedBoxSamples}</TableCell>
                  </TableRow>
                  <TableRow key="sampleSize">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                      {t('sample_size')}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{finishedBoxSampleSize}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid xs={12} md={9} item>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <ReportsRowSizeHeaderRow classes={classes} />
                </TableHead>
                <TableBody>
                  {reportDataFinishedBox?.finishedBox?.rowSize.map((row) => {
                    return (
                      <TableRow key={`${row.id}-${row.unders || 0}`}>
                        <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="right">
                          {row.id === 'Overall' ? row.id : `${row.id} Row`}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{numberToPercentage(row.unders || 0)}</TableCell>
                        <TableCell className={classes.tableCell}>{numberToPercentage(row.correct || 0)}</TableCell>
                        <TableCell className={classes.tableCell}>{numberToPercentage(row.overs || 0)}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid xs={12} item className={classes.grid}>
            <div className={classes.chartContainer}>
              <ResponsiveContainer id="sizingAccuraciesFinishedBoxChart" minWidth={250.5} minHeight={250} height="100%">
                <BarChart width={250} height={250} data={reportDataFinishedBoxGraph}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis domain={[0, 100]} tickSize={5} tickFormatter={(value) => `${value}%`} />
                  <Tooltip formatter={(value) => `${value}%`} />
                  <Bar dataKey="correct" fill={BLUE} />
                  <Bar dataKey="unders" fill={RED} />
                  <Bar dataKey="overs" fill={ORANGE} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Grid>
          <Grid xs={12} md={6} item className={classes.hideChart}>
            <div className={classes.chartContainer}>
              <ResponsiveContainer id="sizingAccuraciesFinishedBoxChart2" minWidth={250.5} minHeight={250} height={450}>
                <BarChart width={250} height={450} data={reportDataFinishedBoxGraph}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" style={{ fill: 'black', fontSize: '150%' }} />
                  <YAxis
                    domain={[0, 100]}
                    style={{ fill: 'black', fontSize: '150%' }}
                    tickSize={5}
                    tickFormatter={(value) => `${value}%`}
                  />
                  <Tooltip formatter={(value) => `${value}%`} />
                  <Bar dataKey="correct" fill={BLUE} />
                  <Bar dataKey="unders" fill={RED} />
                  <Bar dataKey="overs" fill={ORANGE} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Grid>
          <Grid xs={12} item className={classes.grid}>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow key="location">
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{t('users')}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {desTemplates.TEMPLATE_LOC_FIN || t('finished_box')} {t('attachments')}
                    </TableCell>
                  </TableRow>
                  {finishedboxAttachmentSamples?.map((sp) => (
                    <TableRow key="images">
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} title={sp?.user?.email}>
                        {sp?.user?.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <ImageList cols={6} rowHeight={164}>
                          {sp?.attachments?.map((att) => (
                            <ImageListItem
                              key={att}
                              onClick={() => window.open(att, '_blank')}
                              style={{ cursor: 'pointer' }}
                            >
                              <img src={att} alt={att} loading="lazy" />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
      <SimpleBackdrop open={showBackdrop} />
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  selectedLot: state.reports.selectedLot,
  selectedLotName: state.reports.selectedLotName,
  selectedVariety: state.reports.selectedVariety,
  selectedVarietyName: state.reports.selectedVarietyName,
  selectedGrowerName: state.reports.selectedGrowerName,
  selectedStartDate: state.reports.selectedStartDate,
  selectedEndDate: state.reports.selectedEndDate,
  currentFacility: state.facility.currentFacility,
  selectedMachine: state.reports.selectedMachine,
})

export default connect(mapStateToProps)(SizingSummary)
