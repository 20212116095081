import React, { useContext, useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import { Mail, Language } from '@material-ui/icons/'
import Chip from '@material-ui/core/Chip'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'

import TableContainer from '../../components/table-container/table-container'
import Transition from '../../components/transition/transition.component'
import PrimaryButton from '../../components/button/button.component'

import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import CatalogGrid from '../../components/catalog-grid/catalog-grid.component'
import useStyles from '../../components/form-dialog/form-dialog.styles'

import RequestService from '../../services/request/request-service'

import { DEFAULT_DEPENDENCIES_ERROR_MESSAGE } from '../../shared/constants/messages'
import { DELETE_METHOD, POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import { ALERT_TYPES, INCLUDE_INACTIVE_FLAG } from '../../shared/constants/general'
import { ACTIONS_COLUMN, DEFAULT_TABLE_CONFIGURATION } from '../../shared/constants/table'
import { ALERT_CONFIG_QUERY, LOCATION_QUERY } from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import StepForm from '../../components/stepper/stepper.component'

const formInitialValues = {
  actions: [],
  active: true,
  condition: 1,
  description: '',
  name: '',
  rules: [],
  scope: {},
}

const Shift = ({ userEmail, userId }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(formInitialValues)
  const [rows, setRows] = useState([])
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [deletable, setDeletable] = useState(true)
  const [showActive, setShowActive] = useState(true)
  const [rulesData, setRulesData] = useState([])
  const [rulesOpen, setRulesOpen] = useState(false)
  const [actionsData, setActionsData] = useState([])
  const [actionsOpen, setActionsOpen] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(true)
  const [locations, setLocations] = useState([])
  // columns definition for table
  const columns = [
    { field: 'name', headerName: t('name'), minWidth: 115, flex: 1 },
    {
      field: 'condition',
      headerName: t('condition'),
      renderCell: (params) => (params.value === 1 ? 'And' : 'Or'),
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'rules',
      headerName: t('rules'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            const result = params.value.map((row) => ({
              ...row,
              id: `${row.field}${row.id_sample_type}${row.id_location}${row.operator}${row.profile}${row.value}`,
            }))

            setRulesData(result || [])
            setRulesOpen(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_rules')}
        </Button>
      ),
    },
    {
      field: 'actions',
      headerName: t('rule_actions'),
      flex: 1,
      minWidth: 140,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            const result = params.value.map((row) => ({
              ...row,
              id: `${row.value}${row.type}${row.disabled}`,
            }))
            setActionsData(result || [])
            setActionsOpen(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_rule_actions')}
        </Button>
      ),
    },
    { field: 'description', headerName: t('description'), minWidth: 150, flex: 1 },
    {
      field: 'scope',
      headerName: t('scope'),
      minWidth: 120,
      renderCell: (params) => (params.value.type === 1 ? t('global') : t('facility')),
      flex: 1,
    },
    {
      field: 'active',
      headerName: t('status'),
      minWidth: 120,
      renderCell: (params) => (params.value ? t('active') : t('inactive')),
      flex: 1,
    },
    {
      ...ACTIONS_COLUMN,
      headerName: t(ACTIONS_COLUMN.headerName),
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  const ruleColumns = [
    {
      field: 'id_location',
      headerName: t('location'),
      minWidth: 115,
      flex: 1,
      renderCell: (params) => getLocation(params.value),
    },
    {
      field: 'id_sample_type',
      headerName: t('sample_type'),
      minWidth: 115,
      flex: 1,
      renderCell: (params) => (params.value === 1 ? t('size') : t('grade')),
    },
    { field: 'field', headerName: t('field'), minWidth: 115, flex: 1 },
    {
      field: 'operator',
      headerName: t('operator'),
      minWidth: 115,
      flex: 1,
      renderCell: (params) => getOperator(params.value),
    },
    {
      field: 'profile',
      headerName: t('profile'),
      minWidth: 115,
      flex: 1,
      renderCell: (params) => getProfile(params.value),
    },
    {
      field: 'value',
      headerName: t('value'),
      minWidth: 115,
      flex: 1,
      renderCell: (params) => params.value + (params.row.field === t('firmness') ? '' : ' %'),
    },
  ]

  const getOperator = (value) => {
    switch (value) {
      case 1:
        return t('less_than')
      case 2:
        return t('greater_than')
      case 3:
        return t('less_than_or_equal')
      case 4:
        return t('greater_than_or_equal')
      default:
        return t('equal')
    }
  }

  const getProfile = (value) => {
    console.log('profile', value)
    switch (value) {
      case 1:
        return t('undersize')
      case 2:
        return t('oversize')
      default:
        return t('undersize')
    }
  }

  const getLocation = (value) => {
    let locationText = ''
    locations.forEach((x) => {
      if (x.id === value) locationText = x.description
    })
    return locationText
  }

  const actionColumns = [
    { field: 'value', headerName: t('message'), minWidth: 115, flex: 1 },
    {
      field: 'type',
      headerName: t('type'),
      minWidth: 115,
      flex: 1,
      renderCell: (params) => (params.value === 1 ? t('send_web_app_notify') : t('send_email_notify_in_future')),
    },
    {
      field: 'disabled',
      headerName: t('active'),
      minWidth: 115,
      flex: 1,
      renderCell: (params) => (params.value ? t('off') : t('on')),
    },
  ]

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_LOCATION_PAGE, { email: userEmail })
  }, [])

  // load data
  useEffect(() => {
    loadData()
  }, [tableConf, showActive])

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setLoading(false)
  }

  const loadData = async () => {
    setLoading(true)
    setRows([])
    try {
      const loadLocation = new Promise(() => {
        const locationURL = `${LOCATION_QUERY}?&startRowIndex=0&pageSize=10000`
        RequestService.Get(locationURL, history).then((locationReponse) => {
          setLocations(locationReponse.data)
        })
      })
      const loadAlertData = new Promise(() => {
        const alertUrl = `${ALERT_CONFIG_QUERY}?${!showActive ? INCLUDE_INACTIVE_FLAG : ''}&startRowIndex=${
          tableConf.page - 1
        }&pageSize=10000`
        RequestService.Get(alertUrl, history).then((alertReponse) => {
          setRows(alertReponse.data)
        })
      })

      Promise.all([loadLocation, loadAlertData])
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  // insert and edit
  const insertEditData = async (method, data) => {
    setLoading(true)

    let url = `${ALERT_CONFIG_QUERY}`
    let response
    if (method === DELETE_METHOD) {
      url = `${url}?id=${data.id}`
      response = await RequestService.Delete(url, history)
      if (response?.hasError) {
        onError(response.errorMessage)
        return
      }
    } else {
      if (method === POST_METHOD) {
        response = await RequestService.Post(url, history, data)
      }
      if (method === PUT_METHOD) {
        response = await RequestService.Put(url, history, data)
      }

      if (response?.hasError) {
        onError(response.errorMessage)
        return
      }
    }

    setTableConf({ ...tableConf })
    setLoading(false)
    setOpen(false)
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    setLoading(true)
    if (type === 'Edit') {
      trackEvent('EDIT_CLICK', { email: userEmail })
      params.actions.forEach((action) => {
        action.id = `${action.type}`
      })
      params.rules.forEach((rules) => {
        rules.id = `${rules.value}
                    ${rules.field}
                    ${rules.id_location}
                    ${rules.id_sample_type}
                    ${rules.operator}
                    ${rules.profile}`
      })
      setRowData({
        id: params.id,
        name: params.name,
        description: params.description,
        scope: params.scope,
        actions: params.actions,
        rules: params.rules,
        condition: params.condition,
        active: params.active,
      })
    } else {
      setRowData(formInitialValues)
    }
    setModalType(type)
    setOpen(true)
    setIsSubmiting(true)
    setLoading(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType !== 'Edit' ? POST_METHOD : PUT_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      active: !params.active,
    })
    setDelOpen(true)
  }

  const onClickDelConfirmation = async () => {
    const data = { ...rowData }
    insertEditData(DELETE_METHOD, data)
    setDelOpen(false)
  }

  const getActionsIcon = (value) => {
    switch (value) {
      case 1:
        return <Language />
      case 2:
        return <Mail />
      default:
        return <Language />
    }
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('alert_config')}
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={rows}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={loading}
      />
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        maxWidth="lg"
        open={openDialog}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') return
          setOpen(false)
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"> {`${modalType} alert config`}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ margin: 0 }}>
            <Trans i18nKey="please_fill_in_the_information_below">Please fill in the information below</Trans>
          </DialogContentText>
          <StepForm
            rowSelected={rowData}
            userId={userId}
            setRowData={setRowData}
            setIsSubmiting={setIsSubmiting}
            openDialog={openDialog}
          />
          {modalType === 'Edit' && (
            <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
          )}
        </DialogContent>
        <DialogActions>
          <Button style={{ textTransform: 'none' }} onClick={() => setOpen(false)} color="primary">
            {t('cancel')}
          </Button>
          <div style={{ position: 'relative' }}>
            <PrimaryButton variant="contained" onClick={handleSubmit} disabled={loading || isSubmiting} type="submit">
              {t('save')}
            </PrimaryButton>
            {loading && <CircularProgress size={24} className={classes.circularProgress} />}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        maxWidth="lg"
        open={rulesOpen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t('rule_list')}</DialogTitle>
        <DialogContent>
          <CatalogGrid columns={ruleColumns} rows={rulesData} disableNew />
        </DialogContent>
        <DialogActions>
          <Button style={{ textTransform: 'none' }} onClick={() => setRulesOpen(false)} color="primary">
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        maxWidth="lg"
        open={actionsOpen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t('rule_actions_list')}</DialogTitle>
        <DialogContent>
          <CatalogGrid columns={actionColumns} rows={actionsData} disableNew />
        </DialogContent>
        <DialogActions>
          <Button style={{ textTransform: 'none' }} onClick={() => setActionsOpen(false)} color="primary">
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        title={t('deactivate_alert_config')}
        okButton="Deactivate"
        open={openDelDialog}
        onClose={() => {
          setDelOpen(false)
          setDeletable(true)
        }}
        onOk={onClickDelConfirmation}
      >
        {t('do_you_really_want_to_deactivate_alert')} {rowData.name}?
        {!deletable && (
          <Alert style={{ marginTop: 10 }} severity="error">
            {t(DEFAULT_DEPENDENCIES_ERROR_MESSAGE)}
          </Alert>
        )}
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
  userId: state.user.id,
})

export default connect(mapStateToProps, null)(Shift)
