import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import * as XLSXStyle from 'xlsx-js-style'

import { Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import TableCell from '@material-ui/core/TableCell'
import StorageIcon from '@material-ui/icons/Storage'

import reportsSlice from '../../../redux/reports/reports.slice'
import SearchableInput from '../../../components/searchable-input/searchable-input.component'
import SingleDateTimePicker from '../../../components/single-datetimepicker/simple-datetimepicker.component'
import PrimaryButton from '../../../components/button/button.component'

import { GROWER_SUMMARY } from '../../../shared/constants/queries'
import RequestService from '../../../services/request/request-service'
import useMachineQuery from '../../../shared/hooks/useMachineQuery'
import useGrowerQuery from '../../../shared/hooks/useGrowerQuery'
import SimpleBackdrop from '../../../components/simple-backdrop/simple-backdrop.component'
import useVerietyQuery from '../../../shared/hooks/useVerietyQuery'
import makeStyles from '../reports.styles'
import useLotsFacilityQuery from '../../../shared/hooks/useLotsFacilityQuery'
import PrintButton from '../../../components/print-button/print-button.component'
import PdfMakerService from '../../../services/pdf-maker/pdf-maker-service'
import { isEmpty, map, meanBy, sumBy, uniq, upperFirst } from '../../../shared/utils/array-utils'

const SUMMARY_TYPE = {
  Quality: 'Quality',
  Sizing: 'Sizing',
}

const qualityReceivingHeader = ['Variety', 'Estimated P.O.', 'Minor Defect', 'Major Defect', 'Firmness', 'Total Bins']
const qualityUpStreamHeader = ['Variety', 'Estimated P.O.', 'Minor Defect', 'Major Defect', 'Firmness']
const qualityInLineHeader = ['Variety', 'B.I.G.', 'Minor Defect', 'Major Defect', 'Firmness']
const qualityFinishedBoxHeader = ['Variety', 'B.I.G.', 'Minor Defect', 'Major Defect', 'Firmness']
const qualityCullsHeader = ['Variety', 'G.I.B.']
const qualityPeddlerHeader = ['Variety', 'G.I.P.']

const GrowerSummary = ({ currentFacility }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = makeStyles()

  const [showPrint, setShowPrint] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(DateTime.now().startOf('day').toISO({ includeOffset: false }))
  const [endDate, setEndDate] = useState(DateTime.now().endOf('day').toISO({ includeOffset: false }))
  const [startDateISODate, setStartDateISODate] = useState(DateTime.now().startOf('day').toISODate())
  const [endDateISODate, setEndDateISODate] = useState(DateTime.now().endOf('day').toISODate())
  const [summaryType, setSummaryType] = useState(SUMMARY_TYPE.Quality)
  const [selectedMachine, setSelectedMachine] = useState({})
  const [selectedGrower, setSelectedGrower] = useState({})
  const [selectedLot, setSelectedLot] = useState({})
  const [growerSummaryData, setGrowerSummaryData] = useState([])
  const [dateReport, setDateReport] = useState('')

  const machineQuery = useMachineQuery({
    byFacility: true,
    filter: { id_facility: currentFacility.id },
  })
  const growerQuery = useGrowerQuery({ showActive: true })
  const varietyQuery = useVerietyQuery({ showActive: true })
  const lotQuery = useLotsFacilityQuery({
    filter: { id: currentFacility.id },
    optional: '&filterByFirst=false&startRowIndex=0&pageSize=100000&includeInactive=false',
  })
  const lots = [
    { description: 'All', name: 'All', id: 0 },
    ...lotQuery.data.map((x) => x.first).filter((y) => y.active),
  ]

  const loadData = async () => {
    if (endDateISODate >= startDateISODate) {
      try {
        setIsLoading(true)
        setShowPrint(false)
        setDateReport('')
        setGrowerSummaryData([])

        const params = [
          `id_lot=${selectedLot?.id || 0}`,
          `id_facility=${currentFacility.id}`,
          `id_machine=${selectedMachine?.id || 0}`,
          `id_grower=${selectedGrower?.id || 0}`,
          `start_date=${startDate}`,
          `end_date=${endDate}`,
        ]

        const url = `${GROWER_SUMMARY}?${params.join('&')}`
        const response = await RequestService.Get(url, history)
        setGrowerSummaryData(response?.data || [])

        if (DateTime.fromISO(startDate).toFormat('MMM-dd-yy') === DateTime.fromISO(endDate).toFormat('MMM-dd-yy')) {
          setDateReport(DateTime.fromISO(startDate).toFormat('MMM-dd-yy'))
        } else {
          const startDateReport = DateTime.fromISO(startDate).toFormat('MMM-dd-yy')
          const endDateReport = DateTime.fromISO(endDate).toFormat('MMM-dd-yy')
          setDateReport(`${startDateReport} to ${endDateReport}`)
        }

        if (response.data.length) {
          setShowPrint(true)
        } else {
          setShowPrint(false)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const totalQuality = (type) => {
    if (growerSummaryData?.length === 0) return 0
    return sumBy(growerSummaryData, (x) => x?.growerQualitySummary?.[type]?.grade_sample_total)
  }

  const totalSizing = (type) => {
    if (growerSummaryData?.length === 0) return 0

    switch (type) {
      case 'receivingSizing':
      case 'upstreamSizing': {
        return sumBy(growerSummaryData, (x) => x?.growerSizingSummary?.[type]?.[0]?.size_sample_total || 0)
      }
      case 'inLineSizing':
      case 'finishBoxSizing': {
        return sumBy(growerSummaryData, (x) => {
          const location = x?.growerSizingSummary?.[type]
          return isEmpty(location?.correct) ? 0 : sumBy(location.correct, 'size_sample_total')
        }).toFixed(0)
      }
      default: {
        return 0
      }
    }
  }

  const renderQualityReceiving = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const avgEstimatedPO = meanBy(data, (x) => x?.growerQualitySummary?.receivingQuality?.estimatedPackOut || 0) * 100
    const avgMinorDefect = meanBy(data, (x) => x?.growerQualitySummary?.receivingQuality?.minorDefect || 0) * 100
    const avgMajorDefect = meanBy(data, (x) => x?.growerQualitySummary?.receivingQuality?.majorDefect || 0) * 100
    const avgFirmness = meanBy(data, (x) => x?.growerQualitySummary?.receivingQuality?.firmness || 0)
    const totalBins = sumBy(data, (x) => x?.growerQualitySummary?.receivingQuality?.totalBin || 0)

    return (
      <>
        {data.map((item) => {
          const { growerQualitySummary, id_variety } = item
          const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
          const estimatedPO = (growerQualitySummary?.receivingQuality?.estimatedPackOut || 0) * 100
          const minorDefect = (growerQualitySummary?.receivingQuality?.minorDefect || 0) * 100
          const majorDefect = (growerQualitySummary?.receivingQuality?.majorDefect || 0) * 100
          const firmness = growerQualitySummary?.receivingQuality?.firmness || 0
          const totalBin = growerQualitySummary?.receivingQuality?.totalBin || 0

          return (
            <TableRow key={id_variety}>
              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{varietyName}</TableCell>
              <TableCell className={`${classes.tableCell}`}>{estimatedPO.toFixed(0)}%</TableCell>
              <TableCell className={`${classes.tableCell}`}>{minorDefect.toFixed(0)}%</TableCell>
              <TableCell className={`${classes.tableCell}`}>{majorDefect.toFixed(0)}%</TableCell>
              <TableCell className={`${classes.tableCell}`}>{firmness.toFixed(0)}</TableCell>
              <TableCell className={`${classes.tableCell}`}>{totalBin.toFixed(0)}</TableCell>
            </TableRow>
          )
        })}
        <TableRow>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Average & Totals</TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
            {avgEstimatedPO.toFixed(0)}%
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
            {avgMinorDefect.toFixed(0)}%
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
            {avgMajorDefect.toFixed(0)}%
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{avgFirmness.toFixed(0)}</TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{totalBins.toFixed(0)}</TableCell>
        </TableRow>
      </>
    )
  }

  const renderQualityUpStream = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const avgEstimatedPO = meanBy(data, (x) => x?.growerQualitySummary?.upstreamQuality?.estimatedPackOut || 0) * 100
    const avgMinorDefect = meanBy(data, (x) => x?.growerQualitySummary?.upstreamQuality?.minorDefect || 0) * 100
    const avgMajorDefect = meanBy(data, (x) => x?.growerQualitySummary?.upstreamQuality?.majorDefect || 0) * 100
    const avgFirmness = meanBy(data, (x) => x?.growerQualitySummary?.upstreamQuality?.firmness || 0)

    return (
      <>
        {data.map((item) => {
          const { growerQualitySummary, id_variety } = item
          const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
          const estimatedPO = (growerQualitySummary?.upstreamQuality?.estimatedPackOut || 0) * 100
          const minorDefect = (growerQualitySummary?.upstreamQuality?.minorDefect || 0) * 100
          const majorDefect = (growerQualitySummary?.upstreamQuality?.majorDefect || 0) * 100
          const firmness = growerQualitySummary?.upstreamQuality?.firmness || 0

          return (
            <TableRow key={id_variety}>
              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{varietyName}</TableCell>
              <TableCell className={`${classes.tableCell}`}>{estimatedPO.toFixed(0)}%</TableCell>
              <TableCell className={`${classes.tableCell}`}>{minorDefect.toFixed(0)}%</TableCell>
              <TableCell className={`${classes.tableCell}`}>{majorDefect.toFixed(0)}%</TableCell>
              <TableCell className={`${classes.tableCell}`}>{firmness.toFixed(0)}</TableCell>
            </TableRow>
          )
        })}
        <TableRow>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Average</TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
            {avgEstimatedPO.toFixed(0)}%
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
            {avgMinorDefect.toFixed(0)}%
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
            {avgMajorDefect.toFixed(0)}%
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{avgFirmness.toFixed(0)}</TableCell>
        </TableRow>
      </>
    )
  }

  const renderQualityInLine = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const avgEstimatedPO = meanBy(data, (x) => x?.growerQualitySummary?.inLineQuality?.estimatedPackOut || 0) * 100
    const avgMinorDefect = meanBy(data, (x) => x?.growerQualitySummary?.inLineQuality?.minorDefect || 0) * 100
    const avgMajorDefect = meanBy(data, (x) => x?.growerQualitySummary?.inLineQuality?.majorDefect || 0) * 100
    const avgFirmness = meanBy(data, (x) => x?.growerQualitySummary?.inLineQuality?.firmness || 0)

    return (
      <>
        {data.map((item) => {
          const { growerQualitySummary, id_variety } = item
          const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
          const estimatedPO = (growerQualitySummary?.inLineQuality?.estimatedPackOut || 0) * 100
          const minorDefect = (growerQualitySummary?.inLineQuality?.minorDefect || 0) * 100
          const majorDefect = (growerQualitySummary?.inLineQuality?.majorDefect || 0) * 100
          const firmness = growerQualitySummary?.inLineQuality?.firmness || 0

          return (
            <TableRow key={id_variety}>
              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{varietyName}</TableCell>
              <TableCell className={`${classes.tableCell}`}>{estimatedPO.toFixed(0)}%</TableCell>
              <TableCell className={`${classes.tableCell}`}>{minorDefect.toFixed(0)}%</TableCell>
              <TableCell className={`${classes.tableCell}`}>{majorDefect.toFixed(0)}%</TableCell>
              <TableCell className={`${classes.tableCell}`}>{firmness.toFixed(0)}</TableCell>
            </TableRow>
          )
        })}
        <TableRow>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Average</TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
            {avgEstimatedPO.toFixed(0)}%
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
            {avgMinorDefect.toFixed(0)}%
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
            {avgMajorDefect.toFixed(0)}%
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{avgFirmness.toFixed(0)}</TableCell>
        </TableRow>
      </>
    )
  }

  const renderQualityFinishedBox = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const avgEstimatedPO = meanBy(data, (x) => x?.growerQualitySummary?.finishBoxQuality?.estimatedPackOut || 0) * 100
    const avgMinorDefect = meanBy(data, (x) => x?.growerQualitySummary?.finishBoxQuality?.minorDefect || 0) * 100
    const avgMajorDefect = meanBy(data, (x) => x?.growerQualitySummary?.finishBoxQuality?.majorDefect || 0) * 100
    const avgFirmness = meanBy(data, (x) => x?.growerQualitySummary?.finishBoxQuality?.firmness || 0)

    return (
      <>
        {data.map((item) => {
          const { growerQualitySummary, id_variety } = item
          const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
          const estimatedPO = (growerQualitySummary?.finishBoxQuality?.estimatedPackOut || 0) * 100
          const minorDefect = (growerQualitySummary?.finishBoxQuality?.minorDefect || 0) * 100
          const majorDefect = (growerQualitySummary?.finishBoxQuality?.majorDefect || 0) * 100
          const firmness = growerQualitySummary?.finishBoxQuality?.firmness || 0

          return (
            <TableRow key={id_variety}>
              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{varietyName}</TableCell>
              <TableCell className={`${classes.tableCell}`}>{estimatedPO.toFixed(0)}%</TableCell>
              <TableCell className={`${classes.tableCell}`}>{minorDefect.toFixed(0)}%</TableCell>
              <TableCell className={`${classes.tableCell}`}>{majorDefect.toFixed(0)}%</TableCell>
              <TableCell className={`${classes.tableCell}`}>{firmness.toFixed(0)}</TableCell>
            </TableRow>
          )
        })}
        <TableRow>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Average</TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
            {avgEstimatedPO.toFixed(0)}%
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
            {avgMinorDefect.toFixed(0)}%
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
            {avgMajorDefect.toFixed(0)}%
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{avgFirmness.toFixed(0)}</TableCell>
        </TableRow>
      </>
    )
  }

  const renderQualityCulls = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const avgGoodInBad = meanBy(data, (x) => x?.growerQualitySummary?.goodInBadQuality?.goodInBad || 0) * 100

    return (
      <>
        {data.map((item) => {
          const { growerQualitySummary, id_variety } = item
          const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
          const goodInBad = (growerQualitySummary?.goodInBadQuality?.goodInBad || 0) * 100

          return (
            <TableRow key={id_variety}>
              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{varietyName}</TableCell>
              <TableCell className={`${classes.tableCell}`}>{goodInBad.toFixed(0)}%</TableCell>
            </TableRow>
          )
        })}
        <TableRow>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Average</TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{avgGoodInBad.toFixed(0)}%</TableCell>
        </TableRow>
      </>
    )
  }

  const renderQualityPeddler = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const avgGood = meanBy(data, (x) => x?.growerQualitySummary?.peddlerQuality?.good || 0) * 100

    return (
      <>
        {data.map((item) => {
          const { growerQualitySummary, id_variety } = item
          const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
          const good = (growerQualitySummary?.peddlerQuality?.good || 0) * 100

          return (
            <TableRow key={id_variety}>
              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{varietyName}</TableCell>
              <TableCell className={`${classes.tableCell}`}>{good.toFixed(0)}%</TableCell>
            </TableRow>
          )
        })}
        <TableRow>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Average</TableCell>
          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{avgGood.toFixed(0)}%</TableCell>
        </TableRow>
      </>
    )
  }

  const renderSizingReceivingHeader = () => {
    if (growerSummaryData?.length === 0) return []

    const data = growerSummaryData
    const allCols = data.reduce((result, item) => {
      const currentReceivingHeader = map(item.growerSizingSummary.receivingSizing, 'name')
      const newResult = [...result, ...currentReceivingHeader]
      return newResult
    }, [])

    const numberCols = uniq(allCols).filter((item) => item.includes('Row'))
    const notNumberCols = uniq(allCols).filter((item) => !item.includes('Row'))

    const result = ['Variety', ...notNumberCols, ...numberCols]

    return result
  }

  const renderSizingReceiving = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const sizingReceivingHeader = renderSizingReceivingHeader(data)

    return (
      <>
        {data.map((item) => {
          const { growerSizingSummary, id_variety } = item
          const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
          const receivingSizing = growerSizingSummary?.receivingSizing || []

          return (
            <TableRow key={id_variety}>
              {sizingReceivingHeader.map((col) => {
                const currentCol = receivingSizing.find((x) => x.name === col)
                if (col === 'Variety')
                  return (
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={col}>
                      {varietyName}
                    </TableCell>
                  )
                return (
                  <TableCell className={`${classes.tableCell}`} key={col}>
                    {((currentCol?.percentage || 0) * 100).toFixed(0)}%
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          {sizingReceivingHeader.map((col) => {
            const avg = meanBy(data, (x) => {
              const currentCol = x?.growerSizingSummary?.receivingSizing?.find((item) => item.name === col)
              return (currentCol?.percentage || 0) * 100
            })
            if (col === 'Variety')
              return (
                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={col}>
                  Average
                </TableCell>
              )
            return (
              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={col}>
                {avg.toFixed(0)}%
              </TableCell>
            )
          })}
        </TableRow>
      </>
    )
  }

  const renderSizingUpStreamHeader = () => {
    if (growerSummaryData?.length === 0) return []

    const data = growerSummaryData
    const allCols = data.reduce((result, item) => {
      const upStreamSizingData = item?.growerSizingSummary?.upstreamSizing || []
      const currentUpStreamHeader = map(upStreamSizingData, 'name')
      const newResult = [...result, ...currentUpStreamHeader]
      return newResult
    }, [])

    const numberCols = uniq(allCols).filter((item) => item.includes('Row'))
    const notNumberCols = uniq(allCols).filter((item) => !item.includes('Row'))

    if (isEmpty(numberCols) && isEmpty(notNumberCols)) return []

    const result = ['Variety', ...notNumberCols, ...numberCols]

    return result
  }

  const renderSizingUpStream = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const sizingUpStreamHeader = renderSizingUpStreamHeader(data)

    if (isEmpty(sizingUpStreamHeader)) {
      return (
        <div className={classes.emptyDataContainer}>
          <StorageIcon fontSize="large" />
          <p>No data</p>
        </div>
      )
    }

    return (
      <>
        {data.map((item) => {
          const { growerSizingSummary, id_variety } = item
          const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
          const upStreamSizing = growerSizingSummary?.upstreamSizing || []

          return (
            <TableRow key={id_variety} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {sizingUpStreamHeader.map((col) => {
                const currentCol = upStreamSizing.find((x) => x.name === col)
                if (col === 'Variety')
                  return (
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={col}>
                      {varietyName}
                    </TableCell>
                  )
                return (
                  <TableCell className={`${classes.tableCell}`} key={col}>
                    {((currentCol?.percentage || 0) * 100).toFixed(0)}%
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          {sizingUpStreamHeader.map((col) => {
            const avg = meanBy(data, (x) => {
              const currentCol = x?.growerSizingSummary?.upstreamSizing?.find((item) => item.name === col)
              return (currentCol?.percentage || 0) * 100
            })
            if (col === 'Variety')
              return (
                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={col}>
                  Average
                </TableCell>
              )
            return (
              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={col}>
                {avg.toFixed(0)}%
              </TableCell>
            )
          })}
        </TableRow>
      </>
    )
  }

  const renderSizingInLineHeader = () => {
    if (growerSummaryData?.length === 0) return []

    const data = growerSummaryData
    const allCols = data.reduce((result, item) => {
      const currentInLineHeader = Object.keys(item?.growerSizingSummary?.inLineSizing || {})
      const newResult = [...result, ...currentInLineHeader]
      return newResult
    }, [])

    if (isEmpty(allCols)) return []

    return ['variety', ...uniq(allCols).filter((item) => item !== 'name')]
  }

  const renderSizingInLine = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const sizingInLineHeader = renderSizingInLineHeader(data)

    if (isEmpty(sizingInLineHeader)) {
      return (
        <div className={classes.emptyDataContainer}>
          <StorageIcon fontSize="large" />
          <p>No data</p>
        </div>
      )
    }

    return (
      <>
        {data.map((item) => {
          const { growerSizingSummary, id_variety } = item
          const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
          const inLineSizing = growerSizingSummary?.inLineSizing

          return (
            <TableRow key={id_variety} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {sizingInLineHeader.map((col) => {
                const currentCol = inLineSizing?.[col] || []
                const numberCols = currentCol.filter((x) => x?.name?.includes('Row'))
                const data = numberCols.length === 0 ? 0 : meanBy(numberCols, (x) => x?.percentage || 0) * 100
                if (col === 'variety')
                  return (
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={col}>
                      {varietyName}
                    </TableCell>
                  )
                return (
                  <TableCell className={`${classes.tableCell}`} key={col}>
                    {data.toFixed(0)}%
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          {sizingInLineHeader.map((col) => {
            const avg = meanBy(data, (x) => {
              const currentCol = x?.growerSizingSummary?.inLineSizing?.[col] || []
              const numberCols = currentCol.filter((y) => y?.name?.includes('Row'))
              return numberCols.length === 0 ? 0 : meanBy(numberCols, (z) => z?.percentage || 0) * 100
            })
            if (col === 'variety')
              return (
                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={col}>
                  Average
                </TableCell>
              )
            return (
              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={col}>
                {avg.toFixed(0)}%
              </TableCell>
            )
          })}
        </TableRow>
      </>
    )
  }

  const renderSizingFinishedBoxHeader = () => {
    if (growerSummaryData?.length === 0) return []

    const data = growerSummaryData
    const allCols = data.reduce((result, item) => {
      const currentFinishedBoxHeader = Object.keys(item?.growerSizingSummary?.finishBoxSizing || {})
      const newResult = [...result, ...currentFinishedBoxHeader]
      return newResult
    }, [])

    if (isEmpty(allCols)) return []

    return ['variety', ...uniq(allCols).filter((item) => item !== 'name')]
  }

  const renderSizingFinishedBox = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const sizingFinishedBoxHeader = renderSizingFinishedBoxHeader(data)

    if (isEmpty(sizingFinishedBoxHeader)) {
      return (
        <div className={classes.emptyDataContainer}>
          <StorageIcon fontSize="large" />
          <p>No data</p>
        </div>
      )
    }

    return (
      <>
        {data.map((item) => {
          const { growerSizingSummary, id_variety } = item
          const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
          const finishedBoxSizing = growerSizingSummary?.finishBoxSizing

          return (
            <TableRow key={id_variety} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {sizingFinishedBoxHeader.map((col) => {
                const currentCol = finishedBoxSizing?.[col] || []
                const numberCols = currentCol.filter((x) => x?.name?.includes('Row'))
                const data = numberCols.length === 0 ? 0 : meanBy(numberCols, (x) => x?.percentage || 0) * 100
                if (col === 'variety')
                  return (
                    <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={col}>
                      {varietyName}
                    </TableCell>
                  )
                return (
                  <TableCell className={`${classes.tableCell}`} key={col}>
                    {data.toFixed(0)}%
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          {sizingFinishedBoxHeader.map((col) => {
            const avg = meanBy(data, (x) => {
              const currentCol = x?.growerSizingSummary?.finishBoxSizing?.[col] || []
              const numberCols = currentCol.filter((y) => y?.name?.includes('Row'))
              return numberCols.length === 0 ? 0 : meanBy(numberCols, (z) => z?.percentage || 0) * 100
            })
            if (col === 'variety')
              return (
                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={col}>
                  Average
                </TableCell>
              )
            return (
              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={col}>
                {avg.toFixed(0)}%
              </TableCell>
            )
          })}
        </TableRow>
      </>
    )
  }

  const qualityReceivingBody = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const avgEstimatedPO = meanBy(data, (x) => x?.growerQualitySummary?.receivingQuality?.estimatedPackOut || 0) * 100
    const avgMinorDefect = meanBy(data, (x) => x?.growerQualitySummary?.receivingQuality?.minorDefect || 0) * 100
    const avgMajorDefect = meanBy(data, (x) => x?.growerQualitySummary?.receivingQuality?.majorDefect || 0) * 100
    const avgFirmness = meanBy(data, (x) => x?.growerQualitySummary?.receivingQuality?.firmness || 0)
    const totalBins = sumBy(data, (x) => x?.growerQualitySummary?.receivingQuality?.totalBin || 0)
    const qualityReceivingData = data.map((item) => {
      const { growerQualitySummary, id_variety } = item
      const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
      const estimatedPO = (growerQualitySummary?.receivingQuality?.estimatedPackOut || 0) * 100
      const minorDefect = (growerQualitySummary?.receivingQuality?.minorDefect || 0) * 100
      const majorDefect = (growerQualitySummary?.receivingQuality?.majorDefect || 0) * 100
      const firmness = growerQualitySummary?.receivingQuality?.firmness || 0
      const totalBin = growerQualitySummary?.receivingQuality?.totalBin || 0
      return { varietyName, estimatedPO, minorDefect, majorDefect, firmness, totalBin }
    })

    return { qualityReceivingData, avgEstimatedPO, avgMinorDefect, avgMajorDefect, avgFirmness, totalBins }
  }

  const qualityUpStreamBody = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const avgEstimatedPO = meanBy(data, (x) => x?.growerQualitySummary?.upstreamQuality?.estimatedPackOut || 0) * 100
    const avgMinorDefect = meanBy(data, (x) => x?.growerQualitySummary?.upstreamQuality?.minorDefect || 0) * 100
    const avgMajorDefect = meanBy(data, (x) => x?.growerQualitySummary?.upstreamQuality?.majorDefect || 0) * 100
    const avgFirmness = meanBy(data, (x) => x?.growerQualitySummary?.upstreamQuality?.firmness || 0)
    const qualityUpStreamData = data.map((item) => {
      const { growerQualitySummary, id_variety } = item
      const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
      const estimatedPO = (growerQualitySummary?.upstreamQuality?.estimatedPackOut || 0) * 100
      const minorDefect = (growerQualitySummary?.upstreamQuality?.minorDefect || 0) * 100
      const majorDefect = (growerQualitySummary?.upstreamQuality?.majorDefect || 0) * 100
      const firmness = growerQualitySummary?.upstreamQuality?.firmness || 0
      return { varietyName, estimatedPO, minorDefect, majorDefect, firmness }
    })

    return { qualityUpStreamData, avgEstimatedPO, avgMinorDefect, avgMajorDefect, avgFirmness }
  }

  const qualityInLineBody = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const avgEstimatedPO = meanBy(data, (x) => x?.growerQualitySummary?.inLineQuality?.estimatedPackOut || 0) * 100
    const avgMinorDefect = meanBy(data, (x) => x?.growerQualitySummary?.inLineQuality?.minorDefect || 0) * 100
    const avgMajorDefect = meanBy(data, (x) => x?.growerQualitySummary?.inLineQuality?.majorDefect || 0) * 100
    const avgFirmness = meanBy(data, (x) => x?.growerQualitySummary?.inLineQuality?.firmness || 0)
    const qualityInLineData = data.map((item) => {
      const { growerQualitySummary, id_variety } = item
      const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
      const estimatedPO = (growerQualitySummary?.inLineQuality?.estimatedPackOut || 0) * 100
      const minorDefect = (growerQualitySummary?.inLineQuality?.minorDefect || 0) * 100
      const majorDefect = (growerQualitySummary?.inLineQuality?.majorDefect || 0) * 100
      const firmness = growerQualitySummary?.inLineQuality?.firmness || 0
      return { varietyName, estimatedPO, minorDefect, majorDefect, firmness }
    })

    return { qualityInLineData, avgEstimatedPO, avgMinorDefect, avgMajorDefect, avgFirmness }
  }

  const qualityFinishedBoxBody = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const avgEstimatedPO = meanBy(data, (x) => x?.growerQualitySummary?.finishBoxQuality?.estimatedPackOut || 0) * 100
    const avgMinorDefect = meanBy(data, (x) => x?.growerQualitySummary?.finishBoxQuality?.minorDefect || 0) * 100
    const avgMajorDefect = meanBy(data, (x) => x?.growerQualitySummary?.finishBoxQuality?.majorDefect || 0) * 100
    const avgFirmness = meanBy(data, (x) => x?.growerQualitySummary?.finishBoxQuality?.firmness || 0)
    const qualityFinishedBoxData = data.map((item) => {
      const { growerQualitySummary, id_variety } = item
      const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
      const estimatedPO = (growerQualitySummary?.finishBoxQuality?.estimatedPackOut || 0) * 100
      const minorDefect = (growerQualitySummary?.finishBoxQuality?.minorDefect || 0) * 100
      const majorDefect = (growerQualitySummary?.finishBoxQuality?.majorDefect || 0) * 100
      const firmness = growerQualitySummary?.finishBoxQuality?.firmness || 0
      return { varietyName, estimatedPO, minorDefect, majorDefect, firmness }
    })

    return { qualityFinishedBoxData, avgEstimatedPO, avgMinorDefect, avgMajorDefect, avgFirmness }
  }

  const qualityCullsBody = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const avgGoodInBad = meanBy(data, (x) => x?.growerQualitySummary?.goodInBadQuality?.goodInBad || 0) * 100
    const qualityCullsData = data.map((item) => {
      const { growerQualitySummary, id_variety } = item
      const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
      const goodInBad = (growerQualitySummary?.goodInBadQuality?.goodInBad || 0) * 100
      return { varietyName, goodInBad }
    })

    return { qualityCullsData, avgGoodInBad }
  }

  const qualityPeddlerBody = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const avgGood = meanBy(data, (x) => x?.growerQualitySummary?.peddlerQuality?.good || 0) * 100
    const qualityPeddlerData = data.map((item) => {
      const { growerQualitySummary, id_variety } = item
      const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
      const good = (growerQualitySummary?.peddlerQuality?.good || 0) * 100
      return { varietyName, good }
    })

    return { qualityPeddlerData, avgGood }
  }

  const sizingReceivingBody = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const sizingReceivingHeader = renderSizingReceivingHeader(data)
    const sizingReceivingData = data.map((item) => {
      const { growerSizingSummary, id_variety } = item
      const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
      const receivingSizing = growerSizingSummary?.receivingSizing || []
      const receivingSizingCols = sizingReceivingHeader.map((col) => {
        const currentCol = receivingSizing.find((x) => x.name === col)
        const currentColData = (currentCol?.percentage || 0) * 100
        if (col === 'Variety') return varietyName
        return currentColData
      })
      return receivingSizingCols
    })
    const sizingReceivingFooter = sizingReceivingHeader.map((col) => {
      const avg = meanBy(data, (x) => {
        const currentCol = x?.growerSizingSummary?.receivingSizing?.find((item) => item.name === col)
        return (currentCol?.percentage || 0) * 100
      })
      if (col === 'Variety') return 'Average'
      return avg
    })

    sizingReceivingData.push(sizingReceivingFooter)
    return { sizingReceivingData }
  }

  const sizingUpStreamBody = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const sizingUpStreamHeader = renderSizingUpStreamHeader(data)
    const sizingUpStreamData = data.map((item) => {
      const { growerSizingSummary, id_variety } = item
      const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
      const upStreamSizing = growerSizingSummary?.upstreamSizing || []
      const upStreamSizingCols = sizingUpStreamHeader.map((col) => {
        const currentCol = upStreamSizing.find((x) => x.name === col)
        const currentColData = (currentCol?.percentage || 0) * 100
        if (col === 'Variety') return varietyName
        return currentColData
      })
      return upStreamSizingCols
    })
    const sizingUpStreamFooter = sizingUpStreamHeader.map((col) => {
      const avg = meanBy(data, (x) => {
        const currentCol = x?.growerSizingSummary?.upstreamSizing?.find((item) => item.name === col)
        return (currentCol?.percentage || 0) * 100
      })
      if (col === 'Variety') return 'Average'
      return avg
    })

    sizingUpStreamData.push(sizingUpStreamFooter)
    return { sizingUpStreamData }
  }

  const sizingInLineBody = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const sizingInLineHeader = renderSizingInLineHeader(data)
    const sizingInLineData = data.map((item) => {
      const { growerSizingSummary, id_variety } = item
      const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
      const inLineSizing = growerSizingSummary?.inLineSizing
      const inLineSizingCols = sizingInLineHeader.map((col) => {
        const currentCol = inLineSizing?.[col] || []
        const numberCols = currentCol.filter((x) => x?.name?.includes('Row'))
        const cellData = numberCols.length === 0 ? 0 : meanBy(numberCols, (x) => x?.percentage || 0) * 100
        if (col === 'variety') return varietyName
        return cellData
      })
      return inLineSizingCols
    })
    const sizingInLineFooter = sizingInLineHeader.map((col) => {
      const avg = meanBy(data, (x) => {
        const currentCol = x?.growerSizingSummary?.inLineSizing?.[col] || []
        const numberCols = currentCol.filter((y) => y?.name?.includes('Row'))
        return numberCols.length === 0 ? 0 : meanBy(numberCols, (z) => z?.percentage || 0) * 100
      })
      if (col === 'variety') return 'Average'
      return avg
    })

    sizingInLineData.push(sizingInLineFooter)
    return { sizingInLineData }
  }

  const sizingFinishedBoxBody = () => {
    if (growerSummaryData?.length === 0) return null

    const data = growerSummaryData
    const sizingFinishedBoxHeader = renderSizingFinishedBoxHeader(data)
    const sizingFinishedBoxData = data.map((item) => {
      const { growerSizingSummary, id_variety } = item
      const varietyName = varietyQuery.data.find((x) => x?.id === id_variety)?.name || ''
      const finishedBoxSizing = growerSizingSummary?.finishBoxSizing
      const finishedBoxSizingCols = sizingFinishedBoxHeader.map((col) => {
        const currentCol = finishedBoxSizing?.[col] || []
        const numberCols = currentCol.filter((x) => x?.name?.includes('Row'))
        const cellData = numberCols.length === 0 ? 0 : meanBy(numberCols, (x) => x?.percentage || 0) * 100
        if (col === 'variety') return varietyName
        return cellData
      })
      return finishedBoxSizingCols
    })
    const sizingFinishedBoxFooter = sizingFinishedBoxHeader.map((col) => {
      const avg = meanBy(data, (x) => {
        const currentCol = x?.growerSizingSummary?.finishBoxSizing?.[col] || []
        const numberCols = currentCol.filter((y) => y?.name?.includes('Row'))
        return numberCols.length === 0 ? 0 : meanBy(numberCols, (z) => z?.percentage || 0) * 100
      })
      if (col === 'variety') return 'Average'
      return avg
    })

    sizingFinishedBoxData.push(sizingFinishedBoxFooter)
    return { sizingFinishedBoxData }
  }

  const printDocument = () => {
    if (growerSummaryData.length === 0) return
    if (summaryType === SUMMARY_TYPE.Quality) {
      PdfMakerService.generateReport(`GrowerQualitySummary_${dateReport}`, 'GrowerQualitySummary', {
        growerQualitySummary: {
          growerName: selectedGrower.description,
          lotNumber: selectedLot.name,
          dateReport,
          totalReceiving: totalQuality('receivingQuality'),
          receivingHeader: qualityReceivingHeader,
          receivingBody: qualityReceivingBody(),
          totalUpStream: totalQuality('upstreamQuality'),
          upStreamHeader: qualityUpStreamHeader,
          upStreamBody: qualityUpStreamBody(),
          totalInLine: totalQuality('inLineQuality'),
          inLineHeader: qualityInLineHeader,
          inLineBody: qualityInLineBody(),
          totalFinishedBox: totalQuality('finishBoxQuality'),
          finishedBoxHeader: qualityFinishedBoxHeader,
          finishedBoxBody: qualityFinishedBoxBody(),
          totalCulls: totalQuality('goodInBadQuality'),
          cullsHeader: qualityCullsHeader,
          cullsBody: qualityCullsBody(),
          totalPeddler: totalQuality('peddlerQuality'),
          peddlerHeader: qualityPeddlerHeader,
          peddlerBody: qualityPeddlerBody(),
        },
      })
    }
    if (summaryType === SUMMARY_TYPE.Sizing) {
      PdfMakerService.generateReport(`GrowerSizingSummary_${dateReport}`, 'GrowerSizingSummary', {
        growerSizingSummary: {
          growerName: selectedGrower.description,
          lotNumber: selectedLot.name,
          dateReport,
          totalReceiving: totalSizing('receivingSizing'),
          receivingHeader: renderSizingReceivingHeader(growerSummaryData),
          receivingBody: sizingReceivingBody(),
          totalUpStream: totalSizing('upstreamSizing'),
          upStreamHeader: renderSizingUpStreamHeader(growerSummaryData),
          upStreamBody: sizingUpStreamBody(),
          totalInLine: totalSizing('inLineSizing'),
          inLineHeader: renderSizingInLineHeader(growerSummaryData),
          inLineBody: sizingInLineBody(),
          totalFinishedBox: totalSizing('finishBoxSizing'),
          finishedBoxHeader: renderSizingFinishedBoxHeader(growerSummaryData),
          finishedBoxBody: sizingFinishedBoxBody(),
        },
      })
    }
  }

  const printfReportExcel = () => {
    if (growerSummaryData.length === 0) return

    if (summaryType === SUMMARY_TYPE.Quality) {
      const dataExcel = []
      dataExcel.push([{ v: 'Grower Quality Summary', t: 's', s: { font: { bold: true, sz: 11 } } }])
      dataExcel.push([])

      const qualityReceiving = qualityReceivingBody()
      const receivingTitle = [
        { v: `Receiving: ${totalQuality('receivingQuality')}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      const receivingHeader = qualityReceivingHeader.map((text) => ({
        v: text,
        t: 's',
        s: { font: { bold: true, sz: 11 } },
      }))
      const receivingFooter = [
        { v: 'Average & Totals', t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityReceiving.avgEstimatedPO.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityReceiving.avgMinorDefect.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityReceiving.avgMajorDefect.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityReceiving.avgFirmness.toFixed(0)}`, t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityReceiving.totalBins.toFixed(0)}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      dataExcel.push(receivingTitle)
      dataExcel.push(receivingHeader)
      qualityReceiving.qualityReceivingData.forEach((item) => {
        const receivingRow = [
          { v: item.varietyName, t: 's', s: { font: { bold: true, sz: 11 } } },
          { v: `${item.estimatedPO.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
          { v: `${item.minorDefect.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
          { v: `${item.majorDefect.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
          { v: `${item.firmness.toFixed(0)}`, t: 's', s: { font: { sz: 11 } } },
          { v: `${item.totalBin.toFixed(0)}`, t: 's', s: { font: { sz: 11 } } },
        ]
        dataExcel.push(receivingRow)
      })
      dataExcel.push(receivingFooter)
      dataExcel.push([])

      const qualityUpStream = qualityUpStreamBody()
      const upStreamTitle = [
        { v: `Up-Stream: ${totalQuality('upstreamQuality')}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      const upStreamHeader = qualityUpStreamHeader.map((text) => ({
        v: text,
        t: 's',
        s: { font: { bold: true, sz: 11 } },
      }))
      const upStreamFooter = [
        { v: 'Average', t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityUpStream.avgEstimatedPO.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityUpStream.avgMinorDefect.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityUpStream.avgMajorDefect.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityUpStream.avgFirmness.toFixed(0)}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      dataExcel.push(upStreamTitle)
      dataExcel.push(upStreamHeader)
      qualityUpStream.qualityUpStreamData.forEach((item) => {
        const upStreamRow = [
          { v: item.varietyName, t: 's', s: { font: { bold: true, sz: 11 } } },
          { v: `${item.estimatedPO.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
          { v: `${item.minorDefect.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
          { v: `${item.majorDefect.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
          { v: `${item.firmness.toFixed(0)}`, t: 's', s: { font: { sz: 11 } } },
        ]
        dataExcel.push(upStreamRow)
      })
      dataExcel.push(upStreamFooter)
      dataExcel.push([])

      const qualityInLine = qualityInLineBody()
      const inLineTitle = [
        { v: `In-Line: ${totalQuality('inLineQuality')}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      const inLineHeader = qualityInLineHeader.map((text) => ({
        v: text,
        t: 's',
        s: { font: { bold: true, sz: 11 } },
      }))
      const inLineFooter = [
        { v: 'Average', t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityInLine.avgEstimatedPO.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityInLine.avgMinorDefect.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityInLine.avgMajorDefect.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityInLine.avgFirmness.toFixed(0)}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      dataExcel.push(inLineTitle)
      dataExcel.push(inLineHeader)
      qualityInLine.qualityInLineData.forEach((item) => {
        const inLineRow = [
          { v: item.varietyName, t: 's', s: { font: { bold: true, sz: 11 } } },
          { v: `${item.estimatedPO.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
          { v: `${item.minorDefect.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
          { v: `${item.majorDefect.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
          { v: `${item.firmness.toFixed(0)}`, t: 's', s: { font: { sz: 11 } } },
        ]
        dataExcel.push(inLineRow)
      })
      dataExcel.push(inLineFooter)
      dataExcel.push([])

      const qualityFinishedBox = qualityFinishedBoxBody()
      const finishedBoxTitle = [
        { v: `Finished-Box: ${totalQuality('finishBoxQuality')}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      const finishedBoxHeader = qualityFinishedBoxHeader.map((text) => ({
        v: text,
        t: 's',
        s: { font: { bold: true, sz: 11 } },
      }))
      const finishedBoxFooter = [
        { v: 'Average', t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityFinishedBox.avgEstimatedPO.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityFinishedBox.avgMinorDefect.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityFinishedBox.avgMajorDefect.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityFinishedBox.avgFirmness.toFixed(0)}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      dataExcel.push(finishedBoxTitle)
      dataExcel.push(finishedBoxHeader)
      qualityFinishedBox.qualityFinishedBoxData.forEach((item) => {
        const finishedBoxRow = [
          { v: item.varietyName, t: 's', s: { font: { bold: true, sz: 11 } } },
          { v: `${item.estimatedPO.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
          { v: `${item.minorDefect.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
          { v: `${item.majorDefect.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
          { v: `${item.firmness.toFixed(0)}`, t: 's', s: { font: { sz: 11 } } },
        ]
        dataExcel.push(finishedBoxRow)
      })
      dataExcel.push(finishedBoxFooter)
      dataExcel.push([])

      const qualityCulls = qualityCullsBody()
      const cullsTitle = [
        { v: `Culls: ${totalQuality('goodInBadQuality')}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      const cullsHeader = qualityCullsHeader.map((text) => ({
        v: text,
        t: 's',
        s: { font: { bold: true, sz: 11 } },
      }))
      const cullsFooter = [
        { v: 'Average', t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityCulls.avgGoodInBad.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      dataExcel.push(cullsTitle)
      dataExcel.push(cullsHeader)
      qualityCulls.qualityCullsData.forEach((item) => {
        const cullsRow = [
          { v: item.varietyName, t: 's', s: { font: { bold: true, sz: 11 } } },
          { v: `${item.goodInBad.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
        ]
        dataExcel.push(cullsRow)
      })
      dataExcel.push(cullsFooter)
      dataExcel.push([])

      const qualityPeddler = qualityPeddlerBody()
      const peddlerTitle = [
        { v: `Peddler: ${totalQuality('peddlerQuality')}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      const peddlerHeader = qualityPeddlerHeader.map((text) => ({
        v: text,
        t: 's',
        s: { font: { bold: true, sz: 11 } },
      }))
      const peddlerFooter = [
        { v: 'Average', t: 's', s: { font: { bold: true, sz: 11 } } },
        { v: `${qualityPeddler.avgGood.toFixed(0)}%`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      dataExcel.push(peddlerTitle)
      dataExcel.push(peddlerHeader)
      qualityPeddler.qualityPeddlerData.forEach((item) => {
        const peddlerRow = [
          { v: item.varietyName, t: 's', s: { font: { bold: true, sz: 11 } } },
          { v: `${item.good.toFixed(0)}%`, t: 's', s: { font: { sz: 11 } } },
        ]
        dataExcel.push(peddlerRow)
      })
      dataExcel.push(peddlerFooter)
      dataExcel.push([])

      const sizeDistribution = XLSXStyle.utils.aoa_to_sheet(dataExcel)
      const sizeDistributionCols = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }]
      sizeDistribution['!cols'] = sizeDistributionCols
      const excelFile = XLSXStyle.utils.book_new()
      XLSXStyle.utils.book_append_sheet(excelFile, sizeDistribution, 'Grower Quality Summary')
      XLSXStyle.writeFile(excelFile, `GrowerQualitySummary_${dateReport}.xlsx`)
    }

    if (summaryType === SUMMARY_TYPE.Sizing) {
      const dataExcel = []
      dataExcel.push([{ v: 'Grower Sizing Summary', t: 's', s: { font: { bold: true, sz: 11 } } }])
      dataExcel.push([])

      const sizingReceiving = sizingReceivingBody()
      const sizingReceivingHeader = renderSizingReceivingHeader(growerSummaryData)
      const receivingTitle = [
        { v: `Receiving: ${totalSizing('receivingSizing')}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      const receivingHeader = sizingReceivingHeader.map((text) => ({
        v: text,
        t: 's',
        s: { font: { bold: true, sz: 11 } },
      }))
      dataExcel.push(receivingTitle)
      dataExcel.push(receivingHeader)
      sizingReceiving.sizingReceivingData.forEach((row, rowIndex) => {
        const isAvgRow = sizingReceiving.sizingReceivingData.length - 1 === rowIndex
        const customRow = row.map((text, colIndex) => {
          let font = {}
          let displayText = ''

          if (isAvgRow) {
            font = { bold: true, sz: 11 }
          } else {
            font = colIndex === 0 ? { bold: true, sz: 11 } : { sz: 11 }
          }

          if (colIndex === 0) {
            displayText = text
          } else {
            displayText = `${text.toFixed(0)}%`
          }

          return { v: displayText, t: 's', s: { font } }
        })
        dataExcel.push(customRow)
      })
      dataExcel.push([])

      const sizingUpStream = sizingUpStreamBody()
      const sizingUpStreamHeader = renderSizingUpStreamHeader(growerSummaryData)
      const upStreamTitle = [
        { v: `Up-Stream: ${totalSizing('upstreamSizing')}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      const upStreamHeader = sizingUpStreamHeader.map((text) => ({
        v: text,
        t: 's',
        s: { font: { bold: true, sz: 11 } },
      }))
      dataExcel.push(upStreamTitle)
      dataExcel.push(upStreamHeader)
      sizingUpStream.sizingUpStreamData.forEach((row, rowIndex) => {
        const isAvgRow = sizingUpStream.sizingUpStreamData.length - 1 === rowIndex
        const customRow = row.map((text, colIndex) => {
          let font = {}
          let displayText = ''

          if (isAvgRow) {
            font = { bold: true, sz: 11 }
          } else {
            font = colIndex === 0 ? { bold: true, sz: 11 } : { sz: 11 }
          }

          if (colIndex === 0) {
            displayText = text
          } else {
            displayText = `${text.toFixed(0)}%`
          }

          return { v: displayText, t: 's', s: { font } }
        })
        dataExcel.push(customRow)
      })
      dataExcel.push([])

      const sizingInLine = sizingInLineBody()
      const sizingInLineHeader = renderSizingInLineHeader(growerSummaryData)
      const inLineTitle = [
        { v: `In-Line: ${totalSizing('inLineSizing')}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      const inLineHeader = sizingInLineHeader.map((text) => ({
        v: `${upperFirst(text)}`,
        t: 's',
        s: { font: { bold: true, sz: 11 } },
      }))
      dataExcel.push(inLineTitle)
      dataExcel.push(inLineHeader)
      sizingInLine.sizingInLineData.forEach((row, rowIndex) => {
        const isAvgRow = sizingInLine.sizingInLineData.length - 1 === rowIndex
        const customRow = row.map((text, colIndex) => {
          let font = {}
          let displayText = ''

          if (isAvgRow) {
            font = { bold: true, sz: 11 }
          } else {
            font = colIndex === 0 ? { bold: true, sz: 11 } : { sz: 11 }
          }

          if (colIndex === 0) {
            displayText = text
          } else {
            displayText = `${text.toFixed(0)}%`
          }

          return { v: displayText, t: 's', s: { font } }
        })
        dataExcel.push(customRow)
      })
      dataExcel.push([])

      const sizingFinishedBox = sizingFinishedBoxBody()
      const sizingFinishedBoxHeader = renderSizingFinishedBoxHeader(growerSummaryData)
      const finishedBoxTitle = [
        { v: `Finished-Box: ${totalSizing('finishBoxSizing')}`, t: 's', s: { font: { bold: true, sz: 11 } } },
      ]
      const finishedBoxHeader = sizingFinishedBoxHeader.map((text) => ({
        v: `${upperFirst(text)}`,
        t: 's',
        s: { font: { bold: true, sz: 11 } },
      }))
      dataExcel.push(finishedBoxTitle)
      dataExcel.push(finishedBoxHeader)
      sizingFinishedBox.sizingFinishedBoxData.forEach((row, rowIndex) => {
        const isAvgRow = sizingFinishedBox.sizingFinishedBoxData.length - 1 === rowIndex
        const customRow = row.map((text, colIndex) => {
          let font = {}
          let displayText = ''

          if (isAvgRow) {
            font = { bold: true, sz: 11 }
          } else {
            font = colIndex === 0 ? { bold: true, sz: 11 } : { sz: 11 }
          }

          if (colIndex === 0) {
            displayText = text
          } else {
            displayText = `${text.toFixed(0)}%`
          }

          return { v: displayText, t: 's', s: { font } }
        })
        dataExcel.push(customRow)
      })

      const sizeDistribution = XLSXStyle.utils.aoa_to_sheet(dataExcel)
      const sizeDistributionCols = sizingReceivingHeader.map(() => ({ wch: 20 }))
      sizeDistribution['!cols'] = sizeDistributionCols
      const excelFile = XLSXStyle.utils.book_new()
      XLSXStyle.utils.book_append_sheet(excelFile, sizeDistribution, 'Grower Sizing Summary')
      XLSXStyle.writeFile(excelFile, `GrowerSizingSummary_${dateReport}.xlsx`)
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <SearchableInput
            disabled={machineQuery.data.length === 0}
            value={machineQuery?.data?.find((machine) => machine.id === selectedMachine?.id) ?? 0}
            options={machineQuery.data}
            onChange={(event) => {
              const { value } = event.target
              const machine = machineQuery?.data?.find((x) => x.id === value)
              setSelectedMachine(machine)
            }}
            label={t('machine')}
            id="id_machine"
            name="id_machine"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SearchableInput
            showName
            disabled={lotQuery.data.length === 0}
            value={lots.find((lot) => lot.id === selectedLot?.id) ?? 0}
            options={lots}
            label={t('lot_number')}
            id="lots"
            name="lots"
            onChange={(event) => {
              const { value } = event.target
              const lot = lots?.find((x) => x.id === value)
              setSelectedLot(lot)
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SearchableInput
            disabled={growerQuery.data.length === 0}
            value={growerQuery?.data?.find((grower) => grower.id === selectedGrower?.id) ?? 0}
            options={growerQuery.data}
            onChange={(event) => {
              const { value } = event.target
              const grower = growerQuery?.data?.find((x) => x.id === value)
              setSelectedGrower(grower)
            }}
            label={t('grower')}
            id="id_grower"
            name="id_grower"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SingleDateTimePicker
            disabled={isLoading}
            style={{ width: '100%' }}
            label={t('start_date')}
            name="startDate"
            value={startDate}
            disableFuture
            onChange={(date) => {
              const startDate = DateTime.fromJSDate(date)
              setStartDateISODate(startDate.toISODate())
              setStartDate(startDate.toISO({ includeOffset: false }))
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SingleDateTimePicker
            disabled={isLoading}
            style={{ width: '100%' }}
            label={t('end_date')}
            name="endDate"
            value={endDate}
            disableFuture
            onChange={(date) => {
              const endDate = DateTime.fromJSDate(date)
              setEndDateISODate(endDate.toISODate())
              setEndDate(endDate.toISO({ includeOffset: false }))
            }}
          />
        </Grid>
        <Grid item xs={6} md={2} className={`${classes.justifyFilterBtn}`}>
          <PrimaryButton
            variant="contained"
            disabled={machineQuery.isFetching || growerQuery.isFetching || isLoading}
            onClick={loadData}
          >
            {t('filter')}
          </PrimaryButton>
          <FormControlLabel
            control={
              <Switch
                checked={summaryType === SUMMARY_TYPE.Sizing}
                onChange={() =>
                  setSummaryType(summaryType === SUMMARY_TYPE.Quality ? SUMMARY_TYPE.Sizing : SUMMARY_TYPE.Quality)
                }
                name="sizeSum"
                color="primary"
              />
            }
            label={summaryType === SUMMARY_TYPE.Sizing ? t('sizing') : t('quality')}
          />
        </Grid>
        <Grid item xs={6} md={12} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          {!showPrint ? null : <PrintButton printDocument={printDocument} printfReportExcel={printfReportExcel} />}
        </Grid>
      </Grid>
      <br />
      {growerSummaryData.length !== 0 && summaryType === SUMMARY_TYPE.Quality && (
        <Grid container id="report" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Receiving: {totalQuality('receivingQuality')}
            </Typography>
            <TableContainer>
              <Table size="small" key="Quality Receiving">
                <TableHead>
                  <TableRow>
                    {qualityReceivingHeader.map((item) => (
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={item}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{renderQualityReceiving()}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Up-Stream: {totalQuality('upstreamQuality')}
            </Typography>
            <TableContainer>
              <Table size="small" key="Quality Up-Stream">
                <TableHead>
                  <TableRow>
                    {qualityUpStreamHeader.map((item) => (
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={item}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{renderQualityUpStream()}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              In-Line: {totalQuality('inLineQuality')}
            </Typography>
            <TableContainer>
              <Table size="small" key="Quality In-Line">
                <TableHead>
                  <TableRow>
                    {qualityInLineHeader.map((item) => (
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={item}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{renderQualityInLine()}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Finished Box: {totalQuality('finishBoxQuality')}
            </Typography>
            <TableContainer>
              <Table size="small" key="Quality Finished Box">
                <TableHead>
                  <TableRow>
                    {qualityFinishedBoxHeader.map((item) => (
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={item}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{renderQualityFinishedBox()}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Culls: {totalQuality('goodInBadQuality')}
            </Typography>
            <TableContainer>
              <Table size="small" key="Quality Culls">
                <TableHead>
                  <TableRow>
                    {qualityCullsHeader.map((item) => (
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={item}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{renderQualityCulls()}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Peddler: {totalQuality('peddlerQuality')}
            </Typography>
            <TableContainer>
              <Table size="small" key="Quality Peddler">
                <TableHead>
                  <TableRow>
                    {qualityPeddlerHeader.map((item) => (
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={item}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{renderQualityPeddler()}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
      {growerSummaryData.length !== 0 && summaryType === SUMMARY_TYPE.Sizing && (
        <Grid container id="report" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Receiving: {totalSizing('receivingSizing')}
            </Typography>
            <TableContainer>
              <Table size="small" key="Sizing Receiving">
                <TableHead>
                  <TableRow>
                    {renderSizingReceivingHeader(growerSummaryData).map((item) => (
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={item}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{renderSizingReceiving(growerSummaryData)}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Up-Stream: {totalSizing('upstreamSizing')}
            </Typography>
            <TableContainer>
              <Table size="small" key="Sizing Up-Stream">
                <TableHead>
                  <TableRow>
                    {renderSizingUpStreamHeader(growerSummaryData).map((item) => (
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={item}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{renderSizingUpStream(growerSummaryData)}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              In-Line: {totalSizing('inLineSizing')}
            </Typography>
            <TableContainer>
              <Table size="small" key="Sizing In-Line">
                <TableHead>
                  <TableRow>
                    {renderSizingInLineHeader(growerSummaryData).map((item) => (
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={item}>
                        {upperFirst(item)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{renderSizingInLine(growerSummaryData)}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Finished Box: {totalSizing('finishBoxSizing')}
            </Typography>
            <TableContainer>
              <Table size="small" key="Sizing Finished Box">
                <TableHead>
                  <TableRow>
                    {renderSizingFinishedBoxHeader(growerSummaryData).map((item) => (
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} key={item}>
                        {upperFirst(item)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{renderSizingFinishedBox(growerSummaryData)}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
      <SimpleBackdrop open={isLoading} />
    </>
  )
}

const mapStateToProps = (state) => ({
  selectedLot: state.reports.selectedLot,
  selectedLotName: state.reports.selectedLotName,
  selectedStartDate: state.reports.selectedStartDate,
  selectedEndDate: state.reports.selectedEndDate,
  currentFacility: state.facility.currentFacility,
  selectedMachine: state.reports.selectedMachine,
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedLot: (lot) => dispatch(reportsSlice.actions.updateSelectedLot(lot)),
  setSelectedLotName: (lotName) => dispatch(reportsSlice.actions.updateSelectedLotName(lotName)),
  setSelectedStartDate: (startDate) => dispatch(reportsSlice.actions.updateSelectedStartDate(startDate)),
  setSelectedEndDate: (endDate) => dispatch(reportsSlice.actions.updateSelectedEndDate(endDate)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GrowerSummary)
