import axios from 'axios'

let _blankInstance = null
let _defaultInstance = null

const defaultInstance = () => {
  if (!_defaultInstance) {
    _defaultInstance = axios
  }
  return _defaultInstance
}

const blankInstance = () => {
  if (!_blankInstance) {
    _blankInstance = axios.create()
  }
  return _blankInstance
}

/**
 * get Http Axios Instance, Blank only using interceptor for response Error. Not Blank using success, and error interceptor
 * @param {boolean} isBlank
 * @returns {axios}
 */
export const getHttpInstance = (isBlank = false) => {
  if (isBlank) return blankInstance()
  return defaultInstance()
}
