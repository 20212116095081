import { useQuery } from 'react-query'
import RequestService from '../../services/request/request-service'
import { INCLUDE_INACTIVE_FLAG } from '../constants/general'
import { SAMPLE_TYPE_QUERY } from '../constants/queries'

const useSampleTypeQuery = ({ showActive = true, page = 0, pageZize = 10000 } = {}) => {
  return useQuery({
    queryKey: [SAMPLE_TYPE_QUERY, showActive, page, pageZize],
    queryFn: async ({ queryKey }) => {
      const url = `${queryKey[0]}?${!queryKey[1] ? INCLUDE_INACTIVE_FLAG : ''}&startRowIndex=${queryKey[2]}&pageSize=${
        queryKey[3]
      }`
      const { data } = await RequestService.Get(url)
      return data
    },
    initialData: [],
  })
}
export default useSampleTypeQuery
