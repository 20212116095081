export const groupBy = (arr, fn) =>
  arr.map(typeof fn === 'function' ? fn : (val) => val[fn]).reduce((acc, val, i) => {
    acc[val] = (acc[val] || []).concat(arr[i])
    return acc
  }, {})

export const sumBy = (arr, fn) => {
  return arr.map(typeof fn === 'function' ? fn : (val) => val?.[fn]).reduce((sum, val) => {
    sum += val || 0 // eslint-disable-line no-param-reassign
    return sum
  }, 0)
}

export const meanBy = (arr, fn) => {
  return (
    arr.map(typeof fn === 'function' ? fn : (val) => val?.[fn]).reduce((sum, val) => {
      sum += val || 0 // eslint-disable-line no-param-reassign
      return sum
    }, 0) / arr.length
  )
}

export const map = (arr, fn) => arr.map(typeof fn === 'function' ? fn : (val) => val[fn])

export const uniq = (arr) => {
  const checkArr = arr.reduce((obj, item) => {
    if (!obj[item]) obj[item] = true
    return obj
  }, {})
  return Object.keys(checkArr)
}

export const upperFirst = (string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const isObject = (input) => {
  if (typeof input === 'object' && !Array.isArray(input) && input !== null) {
    return true
  }
  return false
}

const isArray = (input) => {
  if (typeof input === 'object' && Array.isArray(input)) return true
  return false
}

export const isEmpty = (input) => {
  if (isObject(input)) return JSON.stringify(input) === '{}'
  if (isArray(input)) return !input.length
  return !input
}
