import React from 'react'
import Grid from '@material-ui/core/Grid'
import CssBaseline from '@material-ui/core/CssBaseline'
import Header from '../header/header.component'
import useStyles from './secondary-layout.styles'

const SecondaryLayout = ({ children }) => {
  const classes = useStyles()
  return (
    <div>
      <Header />
      <Grid container component="main" className={classes.root}>
        <CssBaseline />

        <Grid item xs={false} sm={4} md={5} className={classes.image} />
        {children}
      </Grid>
    </div>
  )
}

export default SecondaryLayout
