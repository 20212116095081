import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    height: '40px !important',
    '&:div': {
      height: '40px !important',
    },
  },
  input: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    height: '40px !important',
    '& > div > div': {
      paddingLeft: '14px',
      margin: '0 !important',
    },
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&:focus-within': {
      borderWidth: '2px',
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
  },
  textField: {
    height: '40px !important',
    '& > div': {
      height: '40px !important',
    },
    '& > label': {
      marginLeft: '5px',
      top: '-10px',
      backgroundColor: '#fff',
      padding: '0 9px',
    },
  },
}))

export default useStyles
