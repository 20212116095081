import { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import RequestService from '../../services/request/request-service'

const useHttpInactive = (entityUrl) => {
  const [loadingInactive, setLoadingInactive] = useState(false)
  const [valueInactive, setValueInactive] = useState(null)
  const [errorInactive, setErrorInactive] = useState(null)
  const [successInactive, setSuccessInactive] = useState(false)
  const history = useHistory()

  const executeInactive = useCallback(async (id) => {
    setLoadingInactive(true)
    let success = false
    let value = null
    let error = null
    try {
      const res = await RequestService.Delete(`${entityUrl}?id=${id}`, history)
      setValueInactive(res)
      setSuccessInactive(true)
      success = true
      value = res.data
    } catch (err) {
      setErrorInactive(err)
      setSuccessInactive(false)
      error = err
    } finally {
      setLoadingInactive(false)
    }
    return { success, value, error }
  }, [])
  return { executeInactive, loadingInactive, successInactive, valueInactive, errorInactive }
}

export default useHttpInactive
