import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { useHistory } from 'react-router-dom'

import { selectLoginData } from '../../redux/user/user.selectors'
import { selectFacilityData } from '../../redux/facility/facility.selectors'
import facilitySlice from '../../redux/facility/facility.slice'

import { USERS_FACILITIES_QUERY } from '../../shared/constants/queries'
import RequestService from '../../services/request/request-service'

const FacilityWrapper = ({
  children,
  loginData,
  setFacilitiesDispatch,
  setCurrentFacilityIdDispatch,
  setCurrentFacilityDispatch,
  setMoreThanOneFacilityDispatch,
  facilityData,
}) => {
  const history = useHistory()
  const { currentFacilityId } = facilityData

  useEffect(async () => {
    if (loginData.id !== '' && currentFacilityId === 0) await getFacilitiesByUser(loginData.id)
  }, [loginData])

  const getFacilitiesByUser = async (userId) => {
    try {
      const url = `${USERS_FACILITIES_QUERY}?id=${userId}`
      const response = await RequestService.Get(url, history)
      const parsedFacilities = response.data.map((facility) => facility.second).filter((x) => x.active === true)
      // Default to first facility and let the user change selection
      // if more than 1 facility assigned.
      if (parsedFacilities.length > 0) {
        setCurrentFacilityIdDispatch(parsedFacilities[0].id)
        setCurrentFacilityDispatch(parsedFacilities[0])
        setFacilitiesDispatch(parsedFacilities)
        setMoreThanOneFacilityDispatch(parsedFacilities.length > 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

const mapStateToProps = createStructuredSelector({
  loginData: selectLoginData,
  facilityData: selectFacilityData,
})

const mapDispatchToProps = (dispatch) => ({
  setFacilitiesDispatch: (facilities) => dispatch(facilitySlice.actions.updateFacilities(facilities)),
  setCurrentFacilityIdDispatch: (facilityId) => dispatch(facilitySlice.actions.updateCurrentFacilityId(facilityId)),
  setCurrentFacilityDispatch: (facility) => dispatch(facilitySlice.actions.updateCurrentFacility(facility)),
  setMoreThanOneFacilityDispatch: (moreThanOne) =>
    dispatch(facilitySlice.actions.updateMoreThanOneFacility(moreThanOne)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FacilityWrapper)
