import React, { useRef, useEffect } from 'react'

const useInterval = (callback, delay) => {
  const intervalId = useRef(null)
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const tick = () => savedCallback.current()

    if (typeof delay === 'number') {
      intervalId.current = window.setInterval(tick, delay)

      return () => window.clearInterval(intervalId.current)
    }
  }, [delay])

  return intervalId.current
}

export default useInterval
