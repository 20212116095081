import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import { TextField, Button } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'

import RequestService from '../../services/request/request-service'

import { QUALITYMATRIX_QUERY, MACHINE_QUERY } from '../../shared/constants/queries'

import useStyles from './quality_matrix.styles'

const QualityMatrix = ({ currentFacilityId, currentMachine }) => {
  const classes = useStyles()
  const history = useHistory()
  const { t, i18n } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [minutes, setMinutes] = useState(0)
  const [machines, setMachines] = useState([])
  const [selectedMachineId, setSelectedMachineId] = useState(0)

  const initRows = [
    { id: 1, field: `${t('good')} %`, upstream: 0, inline: 0, finished_box: 0, cull: 0, peddler: 0 },
    { id: 2, field: 'Min. Defect %', upstream: 0, inline: 0, finished_box: 0, cull: 0, peddler: 0 },
    { id: 3, field: 'Maj. Defect %', upstream: 0, inline: 0, finished_box: 0, cull: 0, peddler: 0 },
    { id: 4, field: `${t('bad')} %`, upstream: 0, inline: 0, finished_box: 0, cull: 0, peddler: 0 },
    { id: 5, field: t('samples'), upstream: 0, inline: 0, finished_box: 0, cull: 0, peddler: 0 },
  ]
  const locations = {
    upstream: 'upstream',
    inline: 'inline',
    finished_box: 'finished_box',
    cull: 'cull',
    peddler: 'peddler',
  }
  const [rows, setRows] = useState(initRows)

  // columns definition for table
  const columns = [
    {
      field: 'field',
      headerName: ' ',
      headerClassName: classes.tableHeader,
      sortable: false,
      flex: 1,
      minWidth: 100,
      align: 'center',
      renderCell: (params) => {
        return (
          <Typography variant="subtitle2" className={classes.tableHeader} color="primary">
            {params.value}
          </Typography>
        )
      },
    },
    {
      field: locations.upstream,
      headerName: t('upstream'),
      headerClassName: classes.tableHeader,
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => cellFormat(params.value, params.row.id),
    },
    {
      field: locations.inline,
      headerName: t('in_line'),
      headerClassName: classes.tableHeader,
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => cellFormat(params.value, params.row.id),
    },
    {
      field: locations.finished_box,
      headerName: t('finished_box'),
      headerClassName: classes.tableHeader,
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => cellFormat(params.value, params.row.id),
    },
    {
      field: locations.cull,
      headerName: 'G.I.B',
      headerClassName: classes.tableHeader,
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return cellFormat(params.value, params.row.id, params.field)
      },
    },
    {
      field: locations.peddler,
      headerName: 'G.I.P',
      headerClassName: classes.tableHeader,
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return cellFormat(params.value, params.row.id, params.field)
      },
    },
  ]

  // Initialize rows with translation
  useEffect(() => {
    const newRows = rows.map((row, index) => {
      let field
      switch (index) {
        case 0:
          field = `${t('good')} %`
          break
        case 1:
          field = `${t('bad')} %`
          break
        case 2:
          field = t('samples')
          break
        default:
          field = row.field
      }
      return { ...row, field }
    })
    setRows(newRows)
  }, [i18n.language])

  useEffect(async () => {
    if (currentFacilityId > 0) {
      await loadMachine()
    }
  }, [])

  const loadData = async () => {
    if (currentFacilityId <= 0 || minutes <= 0) {
      setRows(initRows)
      return
    }
    try {
      setRows(initRows)
      setLoading(true)
      const realMinutes = new Date().getTimezoneOffset() / -1 - parseInt(minutes, 10)
      /* eslint-disable-next-line max-len */
      const url = `${QUALITYMATRIX_QUERY}?id_facility=${currentFacilityId}&id_machine=${selectedMachineId}&minutes=${realMinutes}`
      const response = await RequestService.Get(url, history)
      parseRowsData(response?.data)
      //   setRows(data)
    } catch (error) {
      setLoading(false)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const loadMachine = async () => {
    try {
      const url = `${MACHINE_QUERY}/getmachinesbyidfacility?id_facility=${currentFacilityId}`
      setLoading(true)
      const resMachines = await RequestService.Get(url, history)
      setMachines(resMachines.data)
      if (resMachines.data?.length > 0) setSelectedMachineId(resMachines.data[0].id)
    } finally {
      setLoading(false)
    }
  }

  const parseRowsData = (data) => {
    if (!data) return initRows

    const qualityMatrix = { ...initRows }
    const mapDataRow = (location, value) => {
      qualityMatrix[0][location] = value[location].good
      qualityMatrix[1][location] = value[location].min_defect === 0 ? '_' : value[location].min_defect
      qualityMatrix[2][location] = value[location].maj_defect === 0 ? '_' : value[location].maj_defect
      qualityMatrix[3][location] = value[location].bad
      qualityMatrix[4][location] = value[location].samples_count
    }

    mapDataRow(locations.upstream, data)
    mapDataRow(locations.inline, data)
    mapDataRow(locations.finished_box, data)
    mapDataRow(locations.cull, data)
    mapDataRow(locations.peddler, data)

    return qualityMatrix
  }

  const handleChangeMinutes = (event) => {
    const { name, value } = event.target
    setMinutes(value)
  }

  const cellFormat = (value, quality, field) => {
    let color = 'green'
    if (quality === 5) {
      return (
        <Typography style={{ color }} variant="h6">
          {value}
        </Typography>
      )
    }
    const valueCheck = Math.floor(value * 100)
    let green = [0, 0]
    let orange = [0, 0]
    let red = [0, 0]
    if (field === 'cull' || field === 'peddler') {
      switch (quality) {
        case 1:
          green = [0, 5]
          orange = [6, 15]
          red = [16, 100]
          break
        case 4:
          green = [95, 100]
          orange = [85, 94]
          red = [0, 84]
          break
        default:
          break
      }
    } else {
      switch (quality) {
        case 1:
          red = [0, 69]
          orange = [70, 84]
          green = [85, 100]
          break
        case 2:
        case 3:
        case 4:
          green = [0, 5]
          orange = [6, 9]
          red = [10, 100]
          break
        default:
          break
      }
    }

    if (green[0] <= valueCheck && valueCheck <= green[1]) {
      color = 'green'
    } else if (orange[0] <= valueCheck && valueCheck <= orange[1]) {
      color = 'orange'
    } else if (red[0] <= valueCheck && valueCheck <= red[1]) {
      color = 'red'
    }
    return (
      <Typography style={{ color }} variant="h6">
        {value === '_' ? '_' : (value * 100).toFixed(1)}
      </Typography>
    )
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('quality_matrix')}
        </Typography>
      </Box>
      <Grid container spacing={1} style={{ justifyContent: 'center' }}>
        {machines.length > 0 ? (
          <Grid item xs={12} sm={4} md={3}>
            <FormControl margin="none" variant="outlined" size="small" fullWidth>
              <InputLabel id="select-machine-label">{t('machine')}</InputLabel>
              <Select
                disabled={loading}
                labelId="select-machine-label"
                label={t('machine')}
                value={selectedMachineId}
                onChange={(event) => {
                  setSelectedMachineId(event.target.value)
                }}
              >
                {machines.map((item) => (
                  <MenuItem key={`machine-${item.id}`} value={item.id}>
                    {item.name ? item.name : item.description ? item.description : ''}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
        <Grid item xs={9} sm={4} md={3}>
          {/* <Typography
              style={{ alignSelf: 'center', marginRight: '5px' }}
              component="h6"
              variant="subtitle2"
              color="textSecondary"
            >
              Minutes to include:
            </Typography> */}
          <TextField
            fullWidth
            disabled={loading}
            id="outlined-basic"
            size="small"
            variant="outlined"
            type="number"
            inputProps={{ min: 0 }}
            label={t('minutes_to_include')}
            onChange={handleChangeMinutes}
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 8)
            }}
            onKeyDown={async (ev) => {
              if (ev.key === 'Enter') {
                await loadData()
                ev.preventDefault()
              }
            }}
          />
        </Grid>
        <Grid item xs={3} sm={1} md={1}>
          <Button
            className={classes.buttonFilter}
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={loadData}
          >
            {t('filter')}
          </Button>
        </Grid>
      </Grid>
      <div className={classes.card}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              loading={loading}
              style={{ borderStyle: 'none' }}
              columns={columns.map((column) => ({
                ...column,
                filterable: false,
              }))}
              rows={rows}
              hideFooterPagination
              disableSelectionOnClick
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentFacilityId: state.facility.currentFacilityId,
  currentMachine: state.machine,
})

export default connect(mapStateToProps, null)(QualityMatrix)
