import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import userSlice from './user/user.slice'
import drawerSlice from './drawer/drawer.slice'
import lotsSlice from './lots/lots.slice'
import reportsSlice from './reports/reports.slice'
import facilitySlice from './facility/facility.slice'
import growerSlice from './grower/grower.slice'
import subscriptionSlice from './subscription/subscription.slice'
import dashboardSlice from './dashboard/dashboard.slice'
import machineSlice from './machine/machine.slice'
import notificationPusherSlice from './notification/notification-pusher.slice'

const rootReducer = combineReducers({
  user: userSlice.reducer,
  drawer: drawerSlice.reducer,
  lots: lotsSlice.reducer,
  reports: reportsSlice.reducer,
  facility: facilitySlice.reducer,
  grower: growerSlice.reducer,
  subscription: subscriptionSlice.reducer,
  dashboard: dashboardSlice.reducer,
  machine: machineSlice.reducer,
  notificationPusher: notificationPusherSlice.reducer,
})

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['user', 'lots', 'subscription', 'facility', 'machine', 'lottime'], // will persist
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
})

export const persistor = persistStore(store)
