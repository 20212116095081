import React from 'react'

import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'

import useStyles from './searchable-input.styles'

const SearchableInput = React.memo((props) => {
  const classes = useStyles()
  const { value, options, onChange, label, id, disabled, showName, required } = props

  return (
    <FormControl fullWidth margin="normal" variant="filled" className={classes.container}>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          onChange({ target: { name: id, value: newValue?.id ?? 0 } })
        }}
        id={id}
        options={options}
        renderInput={(params) => <TextField className={classes.textField} {...params} label={label} required />}
        getOptionSelected={() => true}
        getOptionLabel={(option) => {
          if (showName) {
            return option.name ? option.name : option.description ? option.description : ''
          }
          return option.description ? option.description : option.name ? option.name : ''
        }}
        className={classes.input}
        noOptionsText="No results"
        disabled={disabled}
      />
    </FormControl>
  )
})

export default SearchableInput
