import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  notificationsBox: {
    '& div.MuiPaper-root': {
      '@media (max-width: 600px)': {
        top: '61px !important',
        left: 'unset !important',
        right: '15px',
        minWidth: '200px',
      },
    },
  },
})

export default useStyles
