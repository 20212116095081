import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Icon from '@material-ui/core/Icon'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { DataGrid } from '@material-ui/data-grid'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import logo from '../../logo.png'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import useFacilityQuery from '../../shared/hooks/useFacilityQuery'
import useLocationFacilityQuery from '../../shared/hooks/useLocationFacilityQuery'
import useDefectSummaryQuery from '../../shared/hooks/useDefectSummaryQuery'

import useStyles from './defect_params.styles'
import SingleDatePicker from '../../components/single-datepicker/simple-datepicker.component'

const cellFormat = (value) => {
  let color = 'green'
  if (value > 5) color = 'orange'
  if (value > 9) color = 'red'
  return (
    <Typography style={{ color }} variant="h6">
      {value}%
    </Typography>
  )
}

const DefectParams = ({ currentLot, userEmail, currentFacilityId, currentMachine }) => {
  const classes = useStyles()
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState({ minor: [], major: [] })
  const [isError, setError] = useState(false)
  const [defectType, setDefectType] = useState(true)
  const [facilityId, setFacilityId] = useState(currentFacilityId)

  const locationFacilityQuery = useLocationFacilityQuery({
    filter: { id_facility: facilityId },
  })
  const facilityQuery = useFacilityQuery()
  const defectSummaryQuery = useDefectSummaryQuery({
    filter: {
      id_facility: facilityId,
      start_date: currentLot.startDate,
      end_date: currentLot.endDate,
      id_lot: currentLot.lot_number,
      id_variety: currentLot.id_variety,
      id_machine: currentMachine.id,
    },
  })
  // columns definition for table
  const columns = [
    {
      field: 'name',
      headerName: t('defect'),
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div className={classes.defectContainer}>
            <img src={logo} className={classes.logo} alt="Logo" />
            <Typography variant="subtitle2" className={classes.defectTag} color="primary">
              {params.row.name}
            </Typography>
          </div>
        )
      },
    },
    {
      field: 'receivingPercentage',
      hide: true,
    },
    {
      field: 'receiving',
      headerName: `${t('receiving')} %`,
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 190,
      renderCell: (params) => cellFormat(params.value),
      sortComparator: (v1, v2, param1, param2) => {
        return (
          param1.api.getCellValue(param1.id, 'receivingPercentage') -
          param2.api.getCellValue(param2.id, 'receivingPercentage')
        )
      },
    },
    {
      field: 'upstreamPercentage',
      hide: true,
    },
    {
      field: 'upstream',
      headerName: `${t('upstream')} %`,
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => cellFormat(params.value),
      sortComparator: (v1, v2, param1, param2) => {
        return (
          param1.api.getCellValue(param1.id, 'upstreamPercentage') -
          param2.api.getCellValue(param2.id, 'upstreamPercentage')
        )
      },
    },
    {
      field: 'inlinePercentage',
      hide: true,
    },
    {
      field: 'inline',
      headerName: `${t('in_line')} %`,
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 190,
      renderCell: (params) => cellFormat(params.value),
      sortComparator: (v1, v2, param1, param2) => {
        return (
          param1.api.getCellValue(param1.id, 'inlinePercentage') -
          param2.api.getCellValue(param2.id, 'inlinePercentage')
        )
      },
    },
    {
      field: 'finishedPercentage',
      hide: true,
    },
    {
      field: 'finished',
      headerName: `${t('finished_box')} %`,
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 190,
      renderCell: (params) => cellFormat(params.value),
      sortComparator: (v1, v2, param1, param2) => {
        return (
          param1.api.getCellValue(param1.id, 'finishedPercentage') -
          param2.api.getCellValue(param2.id, 'finishedPercentage')
        )
      },
    },
  ]

  useEffect(() => {
    if (currentFacilityId) setFacilityId(currentFacilityId)
  }, [currentFacilityId])

  useEffect(async () => {
    let isMounted = true
    const loadData = async () => {
      setLoading(true)
      try {
        facilityQuery.refetch()
        setLoading(false)
      } catch (error) {
        setError(true)
        setLoading(false)
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    if (isMounted) {
      await loadData()
    }
    trackEvent(LOG_PAGE_LOADS.LOAD_DEFECT_PARAMETERS_PAGE, { email: userEmail })
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(async () => {
    let isMounted = true

    const loadLocation = async () => {
      try {
        if (isMounted) {
          setLoading(true)
          locationFacilityQuery.refetch()
          await loadData()
        }
      } catch (error) {
        if (isMounted) {
          setError(true)
          setLoading(false)
          console.error(error)
        }
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    const loadData = async () => {
      try {
        if (isMounted) {
          setRows({ minor: [], major: [] })
          setLoading(true)
          defectSummaryQuery.refetch()
        }
      } catch (error) {
        if (isMounted) {
          setLoading(false)
          console.error(error)
        }
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    if (facilityId !== 0) {
      setLoading(true)
      await loadLocation()
    }
    return () => {
      isMounted = false
    }
  }, [facilityId])

  useEffect(async () => {
    let isMounted = true
    const minor = defectSummaryQuery.data.minor
      ? defectSummaryQuery.data.minor.map((item, index) => transformRow(item, index, locationFacilityQuery.data))
      : []
    const major = defectSummaryQuery.data.major
      ? defectSummaryQuery.data.major.map((item, index) => transformRow(item, index, locationFacilityQuery.data))
      : []
    if (isMounted) {
      setRows({ minor, major })
    }

    return () => {
      isMounted = false
    }
  }, [defectSummaryQuery.data])

  const transformRow = (item, index, passedLocations) => {
    const receiving = item.find((i) => i.id_location === passedLocations.find((l) => l.id === 1).id)
    const upstream = item.find((i) => i.id_location === passedLocations.find((l) => l.id === 2).id)
    const inline = item.find((i) => i.id_location === passedLocations.find((l) => l.id === 3).id)
    const finished = item.find((i) => i.id_location === passedLocations.find((l) => l.id === 4).id)
    return {
      id: index,
      name: item[0].name_defect,
      receiving: receiving ? (receiving.percentage * 100).toFixed(0) : '0',
      receivingPercentage: receiving?.percentage ? receiving.percentage : 0,
      upstream: upstream ? (upstream.percentage * 100).toFixed(0) : '0',
      upstreamPercentage: upstream?.percentage ? upstream.percentage : 0,
      inline: inline ? (inline.percentage * 100).toFixed(0) : '0',
      inlinePercentage: inline?.percentage ? inline.percentage : 0,
      finished: finished ? (finished.percentage * 100).toFixed(0) : '0',
      finishedPercentage: finished?.percentage ? finished.percentage : 0,
    }
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') return
    setError(false)
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary" style={{ display: 'flex', alignItems: 'center' }}>
          {`${t('defect_parameters')} `}
          <Icon color="secondary" style={{ display: 'inline-block', marginLeft: '5px' }}>
            assessment
          </Icon>
        </Typography>
      </Box>
      <Grid className={classes.controlBox} container spacing={2}>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth margin="normal">
            <TextField
              InputProps={{
                readOnly: true,
              }}
              label={t('machine')}
              variant="outlined"
              value={currentMachine?.name}
              size="small"
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl size="small" variant="outlined" margin="normal" fullWidth>
            <InputLabel id="facility-label">{t('facility')}</InputLabel>
            <Select
              value={facilityId}
              id="demo-simple-select"
              labelId="facility-label"
              label={t('facility')}
              onChange={(event) => setFacilityId(event.target.value)}
              disabled
            >
              <MenuItem value={facilityId}>
                <em>{t('select_a_facility')}</em>
              </MenuItem>
              {facilityQuery.data?.map((item) => (
                <MenuItem key={`fac-${item.id}`} value={item.id}>
                  {item.description ? item.description : item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth margin="normal">
            <TextField
              InputProps={{
                readOnly: true,
              }}
              label={t('current_lot')}
              variant="outlined"
              value={currentLot.lot_name}
              size="small"
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SingleDatePicker
            format="MMM-dd-yyyy"
            style={{ width: '100%' }}
            label={t('start_date')}
            name="startDate"
            disabled
            value={currentLot?.startDate}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SingleDatePicker
            format="MMM-dd-yyyy"
            style={{ width: '100%' }}
            label={t('end_date')}
            name="endDate"
            disabled
            value={currentLot?.endDate}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={12}
          style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
        >
          <Chip
            style={{ marginRight: 10 }}
            color={defectType ? 'primary' : 'default'}
            onClick={() => setDefectType(!defectType)}
            label={t('minor')}
          />
          <Chip
            color={!defectType ? 'primary' : 'default'}
            onClick={() => setDefectType(!defectType)}
            label={t('major')}
          />
        </Grid>
      </Grid>
      <div className={classes.card}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              style={{ borderStyle: 'none' }}
              columns={columns.map((column) => ({
                ...column,
                filterable: false,
              }))}
              rows={defectType ? rows.minor : rows.major}
              loading={loading}
              hideFooterPagination
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentLot: state.lots.currentLot,
  userEmail: state.user.email,
  currentFacilityId: state.facility.currentFacilityId,
  currentMachine: state.machine,
})

export default connect(mapStateToProps, null)(DefectParams)
