import { createSlice } from '@reduxjs/toolkit'

export default createSlice({
  name: 'drawer',
  initialState: {
    isOpen: false,
    menuSelected: {
      parent: 'dashboard',
      child: '',
    },
    previousLocation: '',
  },
  reducers: {
    toggleDrawerAction: (state) => {
      state.isOpen = !state.isOpen
    },
    updateMenuSelectedAction: (state, action) => {
      state.menuSelected = action.payload
    },
    updatePreviousLocationAction: (state, action) => {
      state.previousLocation = action.payload
    },
    logOutAction: (state) => {
      state.isOpen = false
    },
  },
})
