import { makeStyles } from '@material-ui/core/styles'

export const staffStyles = makeStyles({
  cardContent: {
    height: '90vh',
    '@media (max-width: 600px)': {
      '& div.MuiGrid-container': {
        paddingTop: '12px',
        '& div.MuiGrid-item': {
          paddingTop: '0',
        },
      },
      '& div.MuiFormControl-marginNormal': {
        marginTop: '10px',
      },
    },
  },
  dataGrid: {
    '& div.MuiDataGrid-row': {
      cursor: 'pointer',
    },
  },
  searchBox: {
    width: 'auto',
    display: 'flex',
    margin: '10px 0 5px 0',
    justifyContent: 'flex-start',
  },
})

export const overviewListStyles = makeStyles({
  card: {
    minWidth: 275,
    height: '80vh',
    '& div.MuiDataGrid-root': {
      border: 'none',
    },
    '& div.MuiDataGrid-columnsContainer': {
      border: 'none',
    },
  },
  tableHeader: {
    color: '#9b9da0',
  },
  searchBox: {
    width: 'auto',
    display: 'flex',
    margin: '10px 0 5px 0',
    justifyContent: 'flex-end',
  },
})

export const overviewStyles = makeStyles(() => ({
  card: {
    minWidth: 275,
    height: '80vh',
    '& div.MuiDataGrid-root': {
      border: 'none',
    },
  },
  tableHeader: {
    color: '#9b9da0',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}))
