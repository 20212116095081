import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  card: {
    height: '80vh',
    '& div.MuiDataGrid-root': {
      border: 'none',
    },
    '& div.MuiDataGrid-cell': {
      borderBottom: 'none !important',
      paddingTop: 10,
      paddingBottom: 10,
    },
    '& div.MuiDataGrid-columnsContainer': {
      borderBottom: 'none !important',
    },
    '& div.MuiDataGrid-row': {
      border: 'none',
      marginTop: 15,
      borderRadius: 5,
      backgroundColor: '#fff',
      boxShadow: '0 3px 6px 0 rgba(0,0,0,0.1)',
    },
    '& div.MuiDataGrid-viewport': {
      height: '100vh',
    },
  },
  tableHeader: {
    fontSize: 20,
    // color: '#9b9da0',
  },
  buttonFilter: {
    textTransform: 'none',
    letterSpacing: '1.5px',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  locationWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  locationTitle: {
    fontWeight: 600,
    cursor: 'pointer',
  },
})

export default useStyles
