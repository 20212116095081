import { createSlice } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'

const INITIAL_STATE = {
  selectedLot: 0,
  selectedLotName: '-',
  selectedVariety: 0,
  selectedVarietyName: '-',
  selectedGrower: 0,
  selectedGrowerName: '-',
  selectedSeason: '',
  selectedStartDate: DateTime.now().startOf('day').toISO({ includeOffset: false }),
  selectedEndDate: DateTime.now().endOf('day').toISO({ includeOffset: false }),
  selectedStartDateISO: DateTime.now().startOf('day').toISODate(),
  selectedEndDateISO: DateTime.now().endOf('day').toISODate(),
  selectedMachine: { id: 0 },
}

export default createSlice({
  name: 'drawer',
  initialState: INITIAL_STATE,
  reducers: {
    updateSelectedLot: (state, action) => {
      state.selectedLot = action.payload
    },
    updateSelectedVariety: (state, action) => {
      state.selectedVariety = action.payload
    },
    updateSelectedGrower: (state, action) => {
      state.selectedGrower = action.payload
    },
    updateSelectedLotName: (state, action) => {
      state.selectedLotName = action.payload
    },
    updateSelectedSeason: (state, action) => {
      state.selectedSeason = action.payload
    },
    updateSelectedVarietyName: (state, action) => {
      state.selectedVarietyName = action.payload
    },
    updateSelectedGrowerName: (state, action) => {
      state.selectedGrowerName = action.payload
    },
    updateSelectedStartDate: (state, action) => {
      state.selectedStartDate = action.payload
    },
    updateSelectedEndDate: (state, action) => {
      state.selectedEndDate = action.payload
    },
    updateSelectedStartDateISO: (state, action) => {
      state.selectedStartDateISO = action.payload
    },
    updateSelectedEndDateISO: (state, action) => {
      state.selectedEndDateISO = action.payload
    },
    updateSelectedMachine: (state, action) => {
      state.selectedMachine = action.payload
    },
  },
})
