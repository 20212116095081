export const EMAIL_REGEX_PATTERN =
  // eslint-disable-next-line max-len
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i

export const OBJECT_ID_ZERO = { id: 0 }
export const ALERT_TYPES = {
  success: 'success',
  error: 'error',
}
export const INCLUDE_INACTIVE_FLAG = 'includeInactive=true'
export const DRAWER_WIDTH = 240

export const DASHBOARD_STEPS = [
  {
    target: '#lot-change-button',
    content: 'To correctly see data in the dashboard, please select a Lot number and Variety.',
  },
]

export const ALERT_SUBSCRIPTION = 'alert'
export const DASHBOARD_SUBSCRIPTION = 'dashboard'
export const DEFAULT_ACTIVE = { description: 'N/D' }

export const INTERVAL_STATUS_RUNNING = 'running'
export const INTERVAL_STATUS_IDLE = 'idle'
export const INTERVAL_SECONDS = 60

export const DATE_FORMAT = 'yyyy/MM/dd'
export const DATE_TIME_FORMAT = 'yyyy/MM/dd hh:mm:ss a Z'

export const POSITION_ANCHOREL = { top: 0, left: 0 }
