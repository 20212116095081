import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

const AlertContainer = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Alert = ({ ...props }) => {
  return (
    <Snackbar open={props.open} autoHideDuration={props.duration ?? 5000} onClose={props.onClose}>
      <AlertContainer onClose={props.onClose} severity={props.severity}>
        {props.children}
      </AlertContainer>
    </Snackbar>
  )
}

export default Alert
