import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import useInterval from '../../shared/hooks/useInterval'

const Clock = () => {
  const [date, setDate] = useState(new Date())

  useInterval(() => {
    setDate(new Date())
  }, 1000)

  return (
    <div>
      <Typography component="h1" variant="subtitle1" color="primary">
        {date.toLocaleDateString()} {date.toLocaleTimeString()}
      </Typography>
    </div>
  )
}

export default Clock
