import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'

import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import TableContainer from '../../components/table-container/table-container'
import MultipleSelect from '../../components/multi-select/multi-select'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import SearchableInput from '../../components/searchable-input/searchable-input.component'
import ManyToManyInput from '../../components/many-many-input/many-many-input.component'

import { buildPayload, getManyToManyData } from '../../shared/utils/utils'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import { DEFAULT_DEPENDENCIES_ERROR_MESSAGE, DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'
import { POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import {
  ACTIONS_COLUMN,
  BASE_TABLE_COLUMNS,
  DEFAULT_ROW_DATA,
  DEFAULT_TABLE_CONFIGURATION,
} from '../../shared/constants/table'
import { FACILITIES_LOCATIONS_QUERY, FACILITY_QUERY } from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import useHttpInactive from '../../shared/hooks/useHttpInactive'
import useFacilityQuery from '../../shared/hooks/useFacilityQuery'
import useRegionQuery from '../../shared/hooks/useRegionQuery'
import useLocationQuery from '../../shared/hooks/useLocationQuery'

const Facility = ({ userEmail }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [openLocation, setOpenLocation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState({ ...DEFAULT_ROW_DATA })
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [filterValue, setFilterValue] = useState(0)
  const [deletable, setDeletable] = useState(true)
  const [showActive, setShowActive] = useState(true)
  const [selectedLocations, setSelectedLocations] = useState([])

  const facilityQuery = useFacilityQuery({ showActive, filter: { id_region: filterValue } })
  const regionQuery = useRegionQuery()
  const locationQuery = useLocationQuery()
  const isLoadingQuery = facilityQuery.isFetching || regionQuery.isFetching || locationQuery.isFetching
  // columns definition for table
  const translatedColumns = BASE_TABLE_COLUMNS.map((column) => ({
    ...column,
    headerName: t(column.headerName),
  }))

  const columns = [
    ...translatedColumns,
    {
      field: 'locations',
      headerName: t('locations'),
      flex: 1,
      minWidth: 140,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setOpenLocation(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_locations')}
        </Button>
      ),
    },
    {
      ...ACTIONS_COLUMN,
      headerName: t(ACTIONS_COLUMN.headerName),
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  // load facility types
  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_FACILITY_PAGE, { email: userEmail })
  }, [])

  // insert and edit
  const insertEditData = async (method, data) => {
    setLoading(true)

    let url = `${FACILITY_QUERY}`
    let response
    let id_facility
    try {
      if (method === POST_METHOD) {
        response = await RequestService.Post(url, history, data)
        id_facility = response.data.id
      }
      if (method === PUT_METHOD) {
        response = await RequestService.Put(url, history, data)
        id_facility = response.data.id
      }
      const facilitiesLocationsPayload = buildPayload(id_facility, selectedLocations)
      url = `${FACILITIES_LOCATIONS_QUERY}`
      response = await RequestService.Put(url, history, facilitiesLocationsPayload, { isBlankInstance: true })

      setSelectedLocations([])
      setOpen(false)
      facilityQuery.refetch()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    setLoading(true)
    if (type === 'Edit') {
      const facilitieslocations = await getManyToManyData(
        `${FACILITIES_LOCATIONS_QUERY}?id=${params.id}`,
        history,
        locationQuery.data,
        true
      )
      setSelectedLocations(facilitieslocations)
      setRowData({
        id: params.id,
        name: params.name,
        description: params.description,
        active: params.active,
        id_region: params.id_region,
      })
    } else {
      setSelectedLocations([])
      setRowData(DEFAULT_ROW_DATA)
    }
    setModalType(type)
    setOpen(true)
    setLoading(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType !== 'Edit' ? POST_METHOD : PUT_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      active: !params.active,
      id_region: params.id_region,
    })
    setDelOpen(true)
  }
  const { executeInactive, loadingInactive } = useHttpInactive(FACILITY_QUERY)

  const onClickDelConfirmation = async () => {
    const data = { ...rowData }
    const url = `${FACILITIES_LOCATIONS_QUERY}?id=${data.id}&useFirst=false`
    const response = await RequestService.Get(url, history)
    if (response.data.length > 0) {
      setDeletable(false)
      return
    }

    const { success } = await executeInactive(data.id)
    if (success) {
      setDelOpen(false)
      facilityQuery.refetch()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('facilities')}
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        filterValues={regionQuery.data}
        filterValue={filterValue}
        filter={t('regions')}
        filterChange={(event) => {
          setFilterValue(event.target.value)
        }}
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={facilityQuery.data}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={isLoadingQuery}
      />
      <MultipleSelect
        open={openLocation}
        onClose={() => {
          setOpenLocation(false)
        }}
        title={t('locations')}
        getUrl="location"
        getRelationUrl="facilitieslocations"
        mainId={rowData.id}
        lefttoright
      />
      <FormDialog
        title={`${modalType} facility`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          autoFocus
          variant="outlined"
          margin="normal"
          name="name"
          label={t('name')}
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label={t('description')}
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <SearchableInput
          value={regionQuery.data.find((gr) => gr.id === rowData.id_region) ?? 0}
          options={regionQuery.data}
          onChange={(event) => handleChange(event)}
          label={t('region')}
          id="id_region"
          name="id_region"
        />
        <ManyToManyInput
          inputLabel={t('locations')}
          name="id_location"
          selectedValues={selectedLocations}
          allValues={locationQuery.data}
          setSelectedValues={setSelectedLocations}
        />
        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title={t('deactivate_facility')}
        okButton="Deactivate"
        loading={loadingInactive}
        open={openDelDialog}
        onClose={() => {
          setDelOpen(false)
          setDeletable(true)
        }}
        onOk={onClickDelConfirmation}
      >
        {t(DEFAULT_COMFIRM_INACTIVE_MESSAGE)} {rowData.name}?
        {!deletable && (
          <Alert style={{ marginTop: 10 }} severity="error">
            {t(DEFAULT_DEPENDENCIES_ERROR_MESSAGE)}
          </Alert>
        )}
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
})

export default connect(mapStateToProps, null)(Facility)
