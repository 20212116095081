import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { DataGrid } from '@material-ui/data-grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { DateTime } from 'luxon'
import Chip from '@material-ui/core/Chip'

import Alert from '../../components/alert/alert.component'
import SingleDatePicker from '../../components/single-datepicker/simple-datepicker.component'
import PrimaryButton from '../../components/button/button.component'
import SearchBar from '../../components/SearchBar/SearchBar.component'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import useStyles from './lot_time.style'

import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE } from '../../shared/constants/messages'
import { ALERT_TYPES } from '../../shared/constants/general'
import { DEFAULT_TABLE_CONFIGURATION, ROW_PER_PAGE_OPTIONS } from '../../shared/constants/table'
import {
  searchFullColumns,
  convertFormatDateTime,
  convertMillisecondsToHoursAndMinutes,
} from '../../shared/utils/utils'

import { LOT_QUERY, LOTTIME_QUERY, VARIETY_QUERY } from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'

const LotTime = ({ userEmail, role, currentFacility }) => {
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const history = useHistory()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [rowsResponse, setRowsResponse] = useState([])
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [openAlert, setOpenAlert] = useState(false)
  const [alertSeverity, setAlertSeverity] = useState(ALERT_TYPES.success)
  const [lots, setLots] = useState(null)
  const [varieties, setVarieties] = useState(null)
  const [startDate, setStartDate] = useState(DateTime.now().startOf('day').toISO({ includeOffset: false }))
  const [endDate, setEndDate] = useState(DateTime.now().endOf('day').toISO({ includeOffset: false }))

  let onFilter = false

  const classes = useStyles()
  // columns definition for table
  const columns = [
    { field: 'lotName', headerName: `${t('lot')} #`, flex: 1, minWidth: 150, sortable: false },
    { field: 'varietyName', headerName: t('variety'), flex: 1, minWidth: 150, sortable: false },
    { field: 'bin_count', headerName: t('bin_count'), flex: 1, minWidth: 150, sortable: false },
    {
      field: 'start_time',
      headerName: t('start_time'),
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return convertFormatDateTime(params.value, true)
      },
    },
    {
      field: 'end_time',
      headerName: t('end_time'),
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          convertFormatDateTime(params.value, true) ?? (
            <Chip key={params.id} label={t('running')} variant="outlined" size="small" />
          )
        )
      },
    },
    {
      field: 'run_time',
      headerName: t('run_time'),
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return convertMillisecondsToHoursAndMinutes(params.value)
      },
    },
    {
      field: 'average_bph',
      headerName: `${t('average_bin')}/hr.`,
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return params.value.toFixed(1)
      },
    },
    {
      field: 'standard_deviation',
      headerName: t('standard_deviation'),
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return params.value.toFixed(1)
      },
    },
  ]

  // load data
  const loadData = async () => {
    try {
      setRows([])
      let dateQuery = ''
      if (startDate) {
        dateQuery += `&start_date=${startDate}`
      }
      if (endDate) {
        dateQuery += `&end_date=${endDate}`
      }

      let _page = tableConf.page - 1
      if (onFilter) {
        _page = 0
        onFilter = false
      }
      /* eslint-disable-next-line max-len */
      const url = `${LOTTIME_QUERY}?startRowIndex=${_page}&id_facility=${currentFacility.id}&pageSize=${tableConf.pageSize}${dateQuery}`
      setLoading(true)
      const response = await RequestService.Get(url, history)
      const resultData = response.data.map((x) => renderRowLog(x))
      setRowsResponse(resultData)
      setRows(resultData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderRowLog = (item) => {
    const lot = lots?.find((l) => l.id === item.id_lot)
    const variety = varieties?.find((v) => v.id === item.id_variety)
    const result = {
      ...item,
      lotName: lot?.name,
      varietyName: variety?.description,
    }
    return result
  }

  useEffect(async () => {
    const loadDataInIt = async () => {
      setLoading(true)
      try {
        const loadLots = new Promise(() => {
          const url = `${LOT_QUERY}?pageSize=1000`
          RequestService.Get(url, history).then((response) => {
            setLots(response.data)
          })
        })

        const loadVarieties = new Promise(() => {
          const url = `${VARIETY_QUERY}?startRowIndex=0&pageSize=1000`
          RequestService.Get(url, history).then((response) => {
            setVarieties(response.data)
          })
        })

        await Promise.all([loadLots, loadVarieties])
      } catch (error) {
        onError(error)
      } finally {
        setLoading(false)
      }
    }
    await loadDataInIt()
    trackEvent(LOG_PAGE_LOADS.LOAD_LOT_INFORMATION_PAGE, { email: userEmail })
  }, [])

  useEffect(async () => {
    if (lots && varieties) {
      await loadData()
    }
  }, [lots, varieties])

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setOpenAlert(true)
    setAlertSeverity(ALERT_TYPES.error)
    setLoading(false)
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpenAlert(false)
  }

  const onPageSizeChange = (event) => {
    setTableConf({ page: 1, pageSize: event })
  }

  const handleChangeSearch = (event) => {
    const text = event.target.value
    searchFullColumns(rowsResponse, text, setRows, columns)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box pt={5} pb={3}>
          <Typography component="h1" variant="h5" color="primary">
            {t('lot_times')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Card raised>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <SingleDatePicker
                  maxDate={endDate}
                  disabled={loading}
                  style={{ width: '100%' }}
                  label={t('start_date')}
                  name="startDate"
                  value={startDate}
                  disableFuture
                  onChange={(date) => {
                    const startDate = DateTime.fromJSDate(date)
                    setStartDate(startDate.startOf('day').toISO({ includeOffset: false }))
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <SingleDatePicker
                  minDate={startDate}
                  disabled={loading}
                  style={{ width: '100%' }}
                  label={t('end_date')}
                  name="endDate"
                  value={endDate}
                  disableFuture
                  onChange={(date) => {
                    const endDate = DateTime.fromJSDate(date)
                    setEndDate(endDate.endOf('day').toISO({ includeOffset: false }))
                  }}
                />
              </Grid>
              <Grid item xs={3} md={1} style={{ display: 'flex', alignItems: 'end' }}>
                <PrimaryButton
                  variant="contained"
                  disabled={loading}
                  onClick={() => {
                    loadData()
                  }}
                >
                  {t('filter')}
                </PrimaryButton>
              </Grid>
              <Grid item xs={9} md={5}>
                <div className={classes.searchBox}>
                  <SearchBar onChange={handleChangeSearch} />
                </div>
              </Grid>
            </Grid>
            <div style={{ display: 'flex', height: '100%', flexGrow: 1 }}>
              <DataGrid
                className={classes.dataGrid}
                rows={rows}
                columns={columns}
                rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                pageSize={tableConf.pageSize}
                disableSelectionOnClick
                loading={loading}
                onPageSizeChange={onPageSizeChange}
              />
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Alert onClose={handleErrorClose} open={openAlert} severity={alertSeverity}>
        {alertSeverity === ALERT_TYPES.error ? t(DEFAULT_ERROR_MESSAGE) : t(DEFAULT_SUCCESS_MESSAGE)}
      </Alert>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
  role: state.user.role,
  currentFacility: state.facility.currentFacility,
})

export default connect(mapStateToProps, null)(LotTime)
