import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    minHeight: 126,
  },
  flex: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    color: '#c2c7d6',
    marginRight: 12,
  },
  iconBtn: {
    marginRight: 12,
    fontSize: 20,
  },
  value: {
    fontSize: 32,
    color: '#b2b7c4',
  },
  name: {
    color: '#b2b7c4',
  },
  containerChart: {
    minHeight: '35vh',
    height: '-webkit-fill-available',
  },
})

export default useStyles
