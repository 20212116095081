import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  currentFacilityId: 0,
  currentFacility: [],
  facilities: [],
  moreThanOneFacility: false,
}

export default createSlice({
  name: 'facility',
  initialState: INITIAL_STATE,
  reducers: {
    updateCurrentFacilityId: (state, action) => {
      state.currentFacilityId = action.payload
    },
    updateFacilities: (state, action) => {
      state.facilities = action.payload
    },
    updateCurrentFacility: (state, action) => {
      state.currentFacility = action.payload
    },
    updateMoreThanOneFacility: (state, action) => {
      state.moreThanOneFacility = action.payload
    },
    resetFacilityState: () => {
      return INITIAL_STATE
    },
  },
})
