import { useQuery } from 'react-query'
import RequestService from '../../services/request/request-service'
import { INCLUDE_INACTIVE_FLAG } from '../constants/general'
import { FACILITY_QUERY } from '../constants/queries'

const useFacilityQuery = ({ showActive = true, page = 0, pageZize = 10000, filter = { id_region: 0 } } = {}) => {
  return useQuery({
    queryKey: [FACILITY_QUERY, showActive, page, pageZize, filter],
    queryFn: async ({ queryKey }) => {
      let url = `${queryKey[0]}?${!queryKey[1] ? INCLUDE_INACTIVE_FLAG : ''}&startRowIndex=${queryKey[2]}&pageSize=${
        queryKey[3]
      }`
      if (filter?.id_region) {
        url = `${url}&id_region=${filter.id_region}`
      }
      const { data } = await RequestService.Get(url)
      return data
    },
    initialData: [],
  })
}
export default useFacilityQuery
