import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    border: '1px solid #C2C7D6',
    borderRadius: 3,
  },
  box: {
    display: 'flex',
    width: '100%',
    minHeight: 183,
    backgroundColor: '#2A3858',
    clipPath: 'polygon(100% 0, 0 100%, 0 0)',
  },
  container1: {
    padding: 15,
    color: 'white',
  },
  container2: {
    textAlign: 'right',
    padding: 15,
    marginTop: -90,
    color: '#2A3858',
  },
})

export default useStyles
