import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {},
  message: {
    textAlign: 'center',
  },
  logo: {
    width: '150px',
    height: '150px',
  },
}))

export default useStyles
