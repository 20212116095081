import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  isLoggedIn: false,
  email: '',
  token: '',
  expiresOn: '',
  role: '',
  afterLoginPage: '',
  id: '',
  refreshToken: '',
}

export default createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentUserAction: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    logoutCurrenUserAction: () => {
      return INITIAL_STATE
    },
  },
})
