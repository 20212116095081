import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import Icon from '@material-ui/core/Icon'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import SizingSummary from './reports/sizing.component'
import Receiving from './reports/receiving.component'
import Quality from './reports/quality.component'
import ProductionSummary from './reports/production component'
import DefectComponent from './reports/defect.component'
import CustomReport from './reports/custom.component'
import PackTypeSummary from './reports/pack_type.component'
import GradeBySize from './reports/grade_by_size.component'
import GrowerSummary from './reports/grower.component'
import useFacilityLocationQuery from '../../shared/hooks/useFacilityLocationQuery'

import useStyles from './reports.styles'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import { TEMPLATES_LOC } from '../../shared/constants/template-location'
import BinsComponent from './reports/bins.component'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth={false}>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Reports = ({ userEmail, currentFacilityId }) => {
  const classes = useStyles()
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const [value, setValue] = useState(0)
  const [desTemplates, setDesTemplates] = useState({})
  const { t } = useTranslation()
  const facilityLocationQuery = useFacilityLocationQuery({
    filter: { id: currentFacilityId },
  })

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_REPORTS_PAGE, { email: userEmail })
  }, [])

  useEffect(() => {
    let newDesTempalte = {}
    if (facilityLocationQuery.data) {
      Object.keys(TEMPLATES_LOC).forEach((key) => {
        const templates = facilityLocationQuery.data.filter((item) => item.second.template === TEMPLATES_LOC[key])
        if (templates.length) {
          newDesTempalte = { ...newDesTempalte, [key]: templates[templates.length - 1].second.description }
        }
      })
    }
    setDesTemplates(newDesTempalte)
  }, [facilityLocationQuery.data])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography
          component="h1"
          variant="h5"
          color="primary"
          style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
        >
          {t('reports')} <Icon>pie_chart</Icon>
        </Typography>
      </Box>
      <Card raised>
        <CardContent style={{ paddingLeft: 0, paddingRight: 0, flexGrow: 1, width: '100%' }}>
          <AppBar className={classes.appBar} variant="outlined" color="transparent" position="relative">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Reports tab"
              variant="scrollable"
              scrollButtons="auto"
              className={classes.tabs}
            >
              <Tab className={classes.tab} label={t('receiving_reports')} {...a11yProps(0)} />
              <Tab className={classes.tab} label={t('sizing_summary')} {...a11yProps(1)} />
              <Tab className={classes.tab} label={t('quality_summary')} {...a11yProps(2)} />
              <Tab className={classes.tab} label={t('production_summary')} {...a11yProps(3)} />
              <Tab className={classes.tab} label={t('grade_by_size_report')} {...a11yProps(4)} />
              <Tab className={classes.tab} label={t('pack_type_summary')} {...a11yProps(5)} />
              <Tab className={classes.tab} label={t('defect_parameters')} {...a11yProps(6)} />
              <Tab className={classes.tab} label="Bins Summary" {...a11yProps(7)} />
              <Tab className={classes.tab} label={t('grower_summary')} {...a11yProps(8)} />
              <Tab className={classes.tab} label={t('custom_reports')} {...a11yProps(9)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Receiving />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SizingSummary desTemplates={desTemplates} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Quality desTemplates={desTemplates} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ProductionSummary desTemplates={desTemplates} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <GradeBySize desTemplates={desTemplates} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <PackTypeSummary desTemplates={desTemplates} />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <DefectComponent desTemplates={desTemplates} />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <BinsComponent desTemplates={desTemplates} />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <GrowerSummary desTemplates={desTemplates} />
          </TabPanel>
          <TabPanel value={value} index={9}>
            <CustomReport />
          </TabPanel>
        </CardContent>
      </Card>
      <br />
      <br />
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
  currentFacilityId: state.facility.currentFacilityId,
})

export default connect(mapStateToProps)(Reports)
