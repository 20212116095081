import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'

const ActionButtons = (params, handleClickOpen, handleClickDelOpen) => {
  const { active } = params
  return (
    <div>
      <Tooltip title="Edit" arrow>
        <IconButton onClick={handleClickOpen('Edit', params)} size="small" color="primary">
          <Icon>edit</Icon>
        </IconButton>
      </Tooltip>
      {active ? (
        <Tooltip title="Deactivate" arrow>
          <IconButton onClick={handleClickDelOpen(params)} size="small">
            <Icon style={{ color: '#D93B3B' }}>highlight_off</Icon>
          </IconButton>
        </Tooltip>
      ) : null}
    </div>
  )
}

export default ActionButtons
