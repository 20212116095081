import React, { useEffect, useState, useContext } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import html2canvas from 'html2canvas'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import Grid from '@material-ui/core/Grid'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import Autocomplete from '@material-ui/lab/Autocomplete'
import HelpIcon from '@material-ui/icons/Help'
import TooltipMui from '@material-ui/core/Tooltip'
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Chip,
  DialogContentText,
  CircularProgress,
  Fade,
} from '@material-ui/core'
import {
  Bar,
  BarChart,
  PieChart,
  Pie,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
} from 'recharts'
import Drawer from '@material-ui/core/Drawer'

import AppInsightsTrackingContext from '../../../context/app-insights-tracking/AppInsightsTrackingContext'
import SimpleBackdrop from '../../../components/simple-backdrop/simple-backdrop.component'
import SingleDateTimePicker from '../../../components/single-datetimepicker/simple-datetimepicker.component'
import PrimaryButton from '../../../components/button/button.component'
import PdfMakerService from '../../../services/pdf-maker/pdf-maker-service'
import RequestService from '../../../services/request/request-service'
import Recovery from '../../../components/recovery/recovery.component'
import SearchBar from '../../../components/SearchBar/SearchBar.component'
import { funcExportExcel } from '../../../components/export-to-excel/exportexcel.component'
import { BLUE, BLACK, DARKBLUE, LIGHTBLUE, DARKGRAY, LIGHTGRAY } from '../../../shared/constants/pdf'
import { ROW_PER_PAGE_OPTIONS, DEFAULT_TABLE_CONFIGURATION } from '../../../shared/constants/table'
import {
  DELETE_RECEIVING_SUMMARY,
  INSERT_BIN_WEIGHT_RECEIVING_SUMMARY,
  SEND_EMAIL_REPORT,
} from '../../../shared/constants/queries'
import useRecevingReportQuery from '../../../shared/hooks/useRecevingReportQuery'
import useColorQuery from '../../../shared/hooks/useColorQuery'
import useVerietyQuery from '../../../shared/hooks/useVerietyQuery'
import useSizeQuery from '../../../shared/hooks/useSizeQuery'
import useUserQuery from '../../../shared/hooks/useUserQuery'
import useLotsFacilityQuery from '../../../shared/hooks/useLotsFacilityQuery'
import { SUPER_ADMIN_ROLE, QUALITY_CONTROL_MANAGER_ROLE } from '../../../shared/constants/roles'

import Alert from '../../../components/alert/alert.component'
import makeStyles from '../reports.styles'
import { numberToPercentage, searchFullColumns, validateEmail } from '../../../shared/utils/utils'
import { groupBy } from '../../../shared/utils/array-utils'
import { printfCartornReportSummary } from './export_report'

let startDateCus
let endDateCus
const Receiving = ({ currentFacility, userEmail, role }) => {
  const classes = makeStyles()
  const history = useHistory()
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const { t, i18n } = useTranslation()
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showDatesError, setShowDatesError] = useState(false)
  const [columns, setColumns] = useState([])
  const [columnsPrint, setColumnsPrint] = useState([])
  const [rows, setRows] = useState([])
  const [rowsResponse, setRowsResponse] = useState([])
  const [loading, setLoading] = useState(false)
  const [sizeDistributionChartData, setSizeDistributionChartData] = useState([])
  const [minorDefectGraphData, setMinorDefectGraphData] = useState([])
  const [majorDefectGraphData, setMajorDefectGraphData] = useState([])
  const [colorChartData, setColorChartData] = useState([])
  const [dateRepotString, setDateRepotString] = useState('')
  const maxMinorDefect = minorDefectGraphData.length
    ? Math.ceil(Math.max(...minorDefectGraphData.map((x) => +x.percentage))) * 1.25
    : 5

  const maxMajorDefect = majorDefectGraphData.length
    ? Math.ceil(Math.max(...majorDefectGraphData.map((x) => +x.percentage))) * 1.25
    : 5

  const maxSizeDistribution = sizeDistributionChartData.length
    ? Math.ceil(Math.max(...sizeDistributionChartData.map((x) => +x.percentage))) * 1.25
    : 5
  const [startDate, setStartDate] = useState(DateTime.now().startOf('day').toISO({ includeOffset: false }))

  const [endDate, setEndDate] = useState(DateTime.now().endOf('day').toISO({ includeOffset: false }))
  const [startDateISODate, setStartDateISODate] = useState(DateTime.now().startOf('day').toISODate())
  const [endDateISODate, setEndDateISODate] = useState(DateTime.now().endOf('day').toISODate())
  const [confirmOpenDelete, setConfirmOpenDelete] = useState(false)
  const [confirmOpenMailSender, setConfirmOpenMailSender] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState([])

  const [reportDate] = useState(DateTime.now().toFormat('LL-dd-yy'))
  const [openAddValue, setOpenAddValue] = useState(false)
  const [binCount, setBinCount] = useState(0)
  const [netWeight, setNetWeight] = useState(0)
  const [emails, setEmails] = useState([userEmail])
  const [mailSubject, setMailSubject] = useState('')
  const [mailBody, setMailBody] = useState('')
  const [checkNullBin, setCheckNullBin] = useState(false)
  const [checkNullWeight, setCheckNullWeight] = useState(false)
  const [isDrawerOpen, setDrawer] = useState(false)
  const [sendSigleEmail, setSendSigleEmail] = useState(true)
  const [hiddenColumn, setHiddenColumn] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  const [emailInvalid, setEmailInvalid] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [recevingReportQueryResult, setRecevingReportQueryResult] = useState([])
  const openMenu = Boolean(anchorEl)
  const apiRef = useGridApiRef()
  const [draggedRowId, setDraggedRowId] = React.useState(null)

  const recevingReportQuery = useRecevingReportQuery({
    filter: { id_facility: currentFacility.id },
    start_date: startDate,
    end_date: endDate,
  })
  const colorQuery = useColorQuery()
  const verietyQuery = useVerietyQuery()
  const sizeQuery = useSizeQuery()
  const userQuery = useUserQuery()
  const lotsFacilityQuery = useLotsFacilityQuery({
    filter: { id: currentFacility.id },
    optional: '&filterByFirst=false&all=true',
  })

  const isLoadingQuery =
    recevingReportQuery.isFetching || colorQuery.isFetching || verietyQuery.isFetching || sizeQuery.isFetching

  const infoColumns = [
    { field: 'pick_date', headerName: t('pick_date'), minWidth: 140 },
    { field: 'lot_number', headerName: `${t('lot')} #`, minWidth: 110 },
    { field: 'grower', headerName: `${t('grower')}`, minWidth: 110 },
    { field: 'variety_name', headerName: t('variety'), minWidth: 125 },
    { field: 'bin_count', headerName: t('bin_count'), minWidth: 140 },
    { field: 'net_weight', headerName: t('net_weight'), minWidth: 145 },
    {
      field: 'field_average',
      headerName: t('field_average'),
      minWidth: 175,
    },
    { field: 'estimated_po', headerName: 'Est. PO', minWidth: 125 },
    { field: 'peak_size', headerName: 'Peak Size', minWidth: 125 },
    { field: 'minor_defect', headerName: 'Min. Def.', minWidth: 135 },
    { field: 'major_defect', headerName: 'Maj. Def.', minWidth: 135 },
    { field: 'firmness', headerName: t('firmness'), minWidth: 140 },
    { field: 'brix', headerName: 'Brix', minWidth: 110 },
  ]
  const printColumn = [
    {
      field: 'print',
      headerName: t('print'),
      minWidth: 110,
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => printDocument(params.row)}>
            <Icon>print</Icon>
          </IconButton>
          <IconButton color="primary" onClick={() => openSendMailDialog(params.row, true)}>
            <Icon>mail</Icon>
          </IconButton>
        </>
      ),
    },
  ]

  const deleteColumn = [
    {
      field: 'delete',
      headerName: t('delete'),
      minWidth: 120,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => openDeleteDialog(params.row)}>
          <Icon>delete</Icon>
        </IconButton>
      ),
    },
  ]

  const actionColumn = [
    {
      field: 'action',
      headerName: t('actions'),
      minWidth: 120,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => openAddDialog(params.row)}>
          <Icon>addchart</Icon>
        </IconButton>
      ),
    },
  ]

  useEffect(async () => {
    let isMounted = true
    console.log('0')
    if (isMounted) {
      try {
        colorQuery.refetch()
        sizeQuery.refetch()
        verietyQuery.refetch()
        userQuery.refetch()
        lotsFacilityQuery.refetch()
      } catch (error) {
        console.log(error)
      } finally {
        const hiddenColSet = new Set(hiddenColumn)
        setHiddenColumn(() => hiddenColSet.add('print').add('action').add('delete'))
      }
    }
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    return () => {
      setHiddenColumn([]) // Clear the array when the component unmounts
      setColumns([])
      setColumnsPrint([])
      setRows([])
      setRowsResponse([])
      setSizeDistributionChartData([])
      setMinorDefectGraphData([])
      setMajorDefectGraphData([])
      setSelectedRowData([])
    }
  }, [])

  useEffect(async () => {
    let isMounted = true
    try {
      const parsedColors = parseResponseToHeaders(colorQuery.data, 'color')
      const parsedSizes = parseResponseToHeaders(sizeQuery.data, 'size')
      console.log(parsedColors, 'color', parsedSizes, 'size')
      if (isMounted) {
        if (role === SUPER_ADMIN_ROLE || role === QUALITY_CONTROL_MANAGER_ROLE) {
          setColumns([
            ...printColumn,
            ...actionColumn,
            ...infoColumns,
            ...parsedColors,
            ...parsedSizes,
            ...deleteColumn,
          ])
        } else {
          setColumns([...printColumn, ...infoColumns, ...parsedColors, ...parsedSizes])
        }
        setColumnsPrint([...infoColumns, ...parsedColors, ...parsedSizes])
        setBinCount(selectedRowData?.bin_count)
      }
    } catch (error) {
      console.log(error)
    }
    return () => {
      isMounted = false
    }
  }, [colorQuery.data, sizeQuery.data])

  useEffect(async () => {
    console.log('3')
    try {
      const parsedColors = parseResponseToHeaders(colorQuery.data, 'color')
      const parsedSizes = parseResponseToHeaders(sizeQuery.data, 'size')
      console.log(parsedColors, 'color', parsedSizes, 'size')

      if (role === SUPER_ADMIN_ROLE || role === QUALITY_CONTROL_MANAGER_ROLE) {
        setColumns([...printColumn, ...actionColumn, ...infoColumns, ...parsedColors, ...parsedSizes, ...deleteColumn])
      } else {
        setColumns([...printColumn, ...infoColumns, ...parsedColors, ...parsedSizes])
      }
      setColumnsPrint([...infoColumns, ...parsedColors, ...parsedSizes])
      setBinCount(selectedRowData?.bin_count)
    } catch (error) {
      console.log(error)
    }
  }, [i18n, i18n.language])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setRows(parseDataToRows(recevingReportQueryResult) || [])
      setRowsResponse(parseDataToRows(recevingReportQueryResult) || [])
      let dateReport = ''
      if (DateTime.fromISO(startDateCus).toFormat('MM-dd-yy') === DateTime.fromISO(endDateCus).toFormat('MM-dd-yy')) {
        dateReport = DateTime.fromISO(startDateCus).toFormat('MM-dd-yy')
      } else {
        dateReport = `${DateTime.fromISO(startDateCus).toFormat('MM-dd-yy')}_${DateTime.fromISO(endDateCus).toFormat(
          'MM-dd-yy'
        )}`
      }
      setDateRepotString(dateReport)
    }
    return () => {
      isMounted = false
    }
  }, [recevingReportQueryResult])

  const openDeleteDialog = async (selectedRowData) => {
    setSelectedRowData(selectedRowData)
    setConfirmOpenDelete(true)
  }

  const handleCloseDel = () => {
    setConfirmOpenDelete(false)
  }

  const openSendMailDialog = async (selectedRowData, single) => {
    setSendSigleEmail(single)
    setSelectedRowData(selectedRowData)
    setEmails([userEmail])
    setConfirmOpenMailSender(true)
  }

  const handleCloseSendMail = () => {
    setConfirmOpenMailSender(false)
  }

  const deleteDocument = async (selectedRowData) => {
    if (selectedRowData?.id_lot) {
      try {
        setIsLoading(true)
        /* eslint-disable-next-line max-len */
        const params = [
          `id_facility=${currentFacility.id}`,
          `id_lot=${selectedRowData.id_lot}`,
          `id_variety=${selectedRowData.id_variety}`,
          `start_date=${startDateCus}Z`,
          `end_date=${endDateCus}Z`,
        ]

        const url = `${DELETE_RECEIVING_SUMMARY}?${params.join('&')}`
        await RequestService.Delete(url, history)
        recevingReportQuery.refetch()
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
        setConfirmOpenDelete(false)
        setSelectedRowData([])
      }
    }
  }
  const onPageSizeChange = (event) => {
    setTableConf({ page: 1, pageSize: event })
  }

  const parseDataToRows = (data) => {
    return data?.map((item, index) => {
      let minor_defect = 0
      let major_defect = 0
      let minor_defect_number = 0
      let major_defect_number = 0
      let maxObj = null
      let maxValue = 0
      let peakSize = ''
      const chartData = []
      const colorsParsedArray = []
      const colorsParsed = {}
      const sizeData = {}
      const {
        grade,
        size,
        average_firmness,
        brix,
        temperature,
        id_lot,
        id_variety,
        colors,
        estimated_packout,
        grower,
        stemcondition,
        size_sample_count,
        size_sample_total,
        grade_sample_count,
        grade_sample_total,
        defects,
        pick_date,
        bin_count,
        net_weight,
        field_average,
        sample_notes_summarys,
      } = item

      const lotNumber = lotsFacilityQuery.data.map((lot) => lot.first)?.find((lot) => id_lot === lot.id)?.name
      const varietyName = verietyQuery.data?.find((variety) => id_variety === variety.id)?.description
      let estimated_po = estimated_packout || 0
      const estimated_packout_number = estimated_po
      estimated_po = numberToPercentage((estimated_packout * 100).toFixed(1))
      const binCount = Number((Number(bin_count) || 0).toFixed()).toLocaleString('en-US')
      const netWeight = Number((Number(net_weight) || 0).toFixed()).toLocaleString('en-US')
      if (grade) {
        grade.forEach((gradeItem) => {
          const { description, percentage } = gradeItem
          if (description === 'Minor') {
            minor_defect_number = percentage
            minor_defect = numberToPercentage((percentage * 100).toFixed(1))
          }
          if (description === 'Major') {
            major_defect_number = percentage
            major_defect = numberToPercentage((percentage * 100).toFixed(1))
          }
        })
      }

      if (size) {
        size.forEach((sizeItem) => {
          const { percentage, description, pieces } = sizeItem
          if (percentage > maxValue) {
            maxObj = sizeItem
            maxValue = percentage
            peakSize = description
          }
          let data = {
            description,
            percentage: numberToPercentage((percentage * 100).toFixed(1)),
            pieces: +pieces,
          }
          if (description === 'Undersize') {
            data.number = 1
            chartData.push({
              name: 'Unders',
              percentage: Number((percentage * 100).toFixed(1)),
              number: 13,
              pieces: +pieces,
            })
          } else if (description === 'Oversize') {
            data.number = 13
          } else {
            data = {
              ...data,
              number: +description.split(' ')[0],
            }
            chartData.push({
              name: +description.split(' ')[0],
              percentage: Number((percentage * 100).toFixed(1)),
              number: +description.split(' ')[0],
              pieces: +pieces,
              formattedName: description,
            })
          }
          sizeData[description] = data
        })
      }
      chartData.sort((a, b) => b.number - a.number)

      if (colors) {
        colors.forEach((colorItem) => {
          const { description, percentage, name, id } = colorItem
          colorsParsed[description] = numberToPercentage((percentage * 100).toFixed(1))
          colorsParsedArray.push({
            id,
            name,
            description,
            percentage: numberToPercentage((percentage * 100).toFixed(1)),
          })
        })
      }

      if (sample_notes_summarys) {
        for (let i = 0; i < sample_notes_summarys.length; i++) {
          const user = userQuery.data?.find((x) => x.id === sample_notes_summarys[i].id_user)
          sample_notes_summarys[i].userName = user?.name
        }
      }
      return {
        id: index,
        estimated_packout_number,
        estimated_po,
        minor_defect,
        minor_defect_number,
        major_defect,
        major_defect_number,
        firmness: Number((Number(average_firmness) || 0).toFixed()).toLocaleString('en-US'),
        brix: brix.toFixed(1),
        temperature: temperature.toFixed(2),
        id_lot,
        id_variety,
        chartData,
        grower,
        peakSize: maxObj?.description ?? '',
        stemCondition: stemcondition,
        numberSamples: size_sample_count,
        sampleSize: size_sample_total,
        numberSamplesGrade: grade_sample_count,
        sampleSizeGrade: grade_sample_total,
        defects: defects || [],
        pick_date: pick_date ? DateTime.fromISO(pick_date).toUTC().toFormat('LL-dd-yy') : '',
        lot_number: lotNumber,
        variety_name: varietyName,
        bin_count: binCount,
        net_weight: netWeight,
        peak_size: peakSize,
        ...sizeData,
        ...colorsParsed,
        colorsParsedArray,
        field_average: `${Number((Number(field_average * 100) || 0).toFixed(1)).toLocaleString('en-US')}%`,
        house_average: `${((recevingReportQuery?.data?.houseAverage ?? 0) * 100).toFixed(2)}%`,
        sample_notes_summarys,
      }
    })
  }
  const parseResponseToHeaders = (data, type = 'color') => {
    if (type === 'color') {
      return [...data].map((color) => {
        const { description } = color
        return {
          field: description,
          headerName: description,
          minWidth: 160,
        }
      })
    }
    return [...data]
      .map((size) => {
        const { description, value } = size
        return {
          field: description,
          headerName: description,
          renderCell: (params) => params?.row[params.field]?.percentage || '',
          value: description === 'Undersize' ? 15 : description === 'Oversize' ? 1 : value,
          minWidth: 160,
        }
      })
      .sort((a, b) => b.value - a.value)
  }

  const generateGeneralData = (selectedRowData) => {
    const {
      pick_date,
      peakSize,
      lot_number,
      grower,
      variety_name,
      bin_count,
      net_weight,
      field_average,
      house_average,
      sample_notes_summarys,
    } = selectedRowData
    return {
      pickDate: pick_date,
      peakSize,
      lotNumber: lot_number,
      grower,
      variety_name,
      bin_count:
        typeof bin_count === 'string'
          ? Number(parseFloat(bin_count.replace(/,/g, '')).toFixed()).toLocaleString('en-US')
          : Number((Number(bin_count) || 0).toFixed()).toLocaleString('en-US'),
      net_weight:
        typeof net_weight === 'string'
          ? Number(parseFloat(net_weight.replace(/,/g, '')).toFixed()).toLocaleString('en-US')
          : Number((Number(net_weight) || 0).toFixed()).toLocaleString('en-US'),
      field_average: Number((Number(field_average.replace('%', '')) || 0).toFixed(1)).toLocaleString('en-US'),
      house_average,
      sample_notes_summarys,
    }
  }

  const generateQualityData = (selectedRowData) => {
    const {
      minor_defect_number,
      major_defect_number,
      firmness,
      minor_defect,
      major_defect,
      stemCondition,
      estimated_packout_number,
      estimated_po,
      brix,
      temperature,
      numberSamplesGrade,
      sampleSizeGrade,
      colorsParsedArray,
    } = selectedRowData
    const data = {
      packableFruit: ((1 - minor_defect_number - major_defect_number) * 100).toFixed(1),
      aveFirmness: firmness,
      minorDefect: minor_defect,
      majorDefect: major_defect,
      estimatedPackOut: estimated_packout_number,
      estimatedPackOutPercentage: estimated_po,
      aveBrix: brix,
      stemCondition,
      temperature,
      numberSamplesGrade,
      sampleSizeGrade,
      colors: {},
    }
    colorsParsedArray.forEach((color) => {
      data.colors = {
        ...data.colors,
        [color.description]: color.percentage,
      }
    })

    return {
      packableFruit: ((1 - minor_defect_number - major_defect_number) * 100).toFixed(1),
      aveFirmness: (Number(firmness) || 0).toFixed(),
      minorDefect: typeof minor_defect === 'string' ? (Number(minor_defect.replace('%', '')) || 0).toFixed(1) : '0',
      majorDefect: typeof major_defect === 'string' ? (Number(major_defect.replace('%', '')) || 0).toFixed(1) : '0',
      estimatedPackOut: Number((Number(estimated_packout_number) || 0).toFixed(1)).toLocaleString('en-US'),
      estimatedPackOutPercentage: (Number(estimated_po.replace('%', '')) || 0).toFixed(1),
      aveBrix: (Number(brix) || 0).toFixed(1),
      stemCondition,
      temperature,
      numberSamplesGrade,
      sampleSizeGrade,
      color: data.colors,
      colorArray: colorsParsedArray,
    }
  }

  const generateSizeData = (selectedRowData) => {
    const { numberSamples, sampleSize, chartData } = selectedRowData
    const data = {}
    data.numberSamples = numberSamples
    data.sampleSize = sampleSize
    data.data = {}
    chartData?.forEach((size) => {
      data.data = {
        ...data.data,
        [size.name]: {
          pieces: size?.pieces || 0,
          percentage: size?.percentage.toFixed(1),
        },
      }
    })
    return data
  }

  const generalCartonData = (selectedRowData) => {
    console.log(selectedRowData)
    const { net_weight, chartData, estimated_po } = selectedRowData
    const Epo = Number(estimated_po.replace('%', '')) / 100
    const netWeightPacked = Epo * (parseFloat(net_weight.replace(/,/g, '')) ?? 0)
    const data = []

    chartData?.forEach((size) => {
      data.push({
        name: size?.name || 0,
        pieces: size?.pieces || 0,
        carton: (((size?.percentage || 0) / 100) * netWeightPacked) / 18,
      })
    })
    return data
  }

  const getPackableWeight = (totalData) => {
    let varietyEPO = 0
    let netWeight = 0
    Object.values(totalData).forEach(function (item, index) {
      const key = Object.keys(totalData[index]) // eslint-disable-line no-param-reassign

      switch (key[0]) {
        case 'net_weight':
          netWeight = Number(totalData[index][key[0]].toFixed())
          break
        case 'estimated_po':
          varietyEPO = Number(totalData[index][key[0]].toFixed(2)) / 100
          break
        default:
          break
      }
    })
    const packableWeight = netWeight * varietyEPO
    return packableWeight
  }
  const getTotal18lb = (totalData, packableWeight, sizeQuery) => {
    let total18 = 0
    Object.values(totalData).forEach(function (item, index) {
      const key = Object.keys(totalData[index]) // eslint-disable-line no-param-reassign
      const keySizeQuery = sizeQuery.data.find((size) => size.description === key[0])
      switch (key[0]) {
        case 'Undersize':
        case `${keySizeQuery ? keySizeQuery.description : ''}`:
          total18 += Number(
            (((Number(totalData[index][key[0]] || 0).toFixed(1) / 100) * packableWeight) / 18).toFixed()
          )
          break
        default:
          break
      }
    })

    return total18
  }
  const printDocument = async (selectedRowData) => {
    setShowBackdrop(true)
    const majorDefects = []
    const minorDefects = []
    const { lot_number, variety_name } = selectedRowData
    selectedRowData.defects.forEach((defect) => {
      const { name, percentage, description } = defect
      const defectInformation = { defect: name, percentage: (percentage * 100).toFixed(2) }
      if (description === 'Major') majorDefects.push(defectInformation)
      if (description === 'Minor') minorDefects.push(defectInformation)
    })
    setMinorDefectGraphData(minorDefects)
    setMajorDefectGraphData(majorDefects)
    setSizeDistributionChartData(selectedRowData.chartData)

    const qualityData = {
      quality: generateQualityData(selectedRowData),
    }
    const { quality } = qualityData

    if (quality?.colorArray?.length > 0) {
      const sortedArray = quality?.colorArray?.sort((a, b) => a.id - b.id)
      const dataArray = sortedArray.map((x) => {
        const valueWithoutPercentSign = x.percentage.replace('%', '')
        const fillData = colorQuery.data.find((y) => y.id === x.id)
        const parsedValue = parseFloat(valueWithoutPercentSign)
        return {
          name: x.description,
          value: parsedValue,
          fill: fillData.hex,
        }
      })
      setColorChartData(dataArray)
    }
    // Timeout to give enough time to set graph data.
    setTimeout(async () => {
      const generalData = {
        general: {
          ...generateGeneralData(selectedRowData),
          facility: currentFacility?.description,
          reportDate,
        },
      }
      const qualityData = {
        quality: generateQualityData(selectedRowData),
      }

      const sizeData = {
        size: generateSizeData(selectedRowData),
        sizeChartData: selectedRowData.chartData,
        sizeCartonData: generalCartonData(selectedRowData),
      }
      const minorDefectGraphBarChart = document.getElementById('minorDefectGraph')
      const majorDefectGraphBarChart = document.getElementById('majorDefectGraph')
      const colorGraphPieChart = document.getElementById('colorGraph')

      const sizeDistributionBarChart = document.getElementById('sizeDistributionBarChart')
      const minorDefectGraphBarChartImage = (await html2canvas(minorDefectGraphBarChart)).toDataURL('image/png')
      const majorDefectGraphBarChartImage = (await html2canvas(majorDefectGraphBarChart)).toDataURL('image/png')
      const colorGraphPieChartImage = (await html2canvas(colorGraphPieChart)).toDataURL('image/png')
      const sizeDistributionBarChartImage = (await html2canvas(sizeDistributionBarChart)).toDataURL('image/png')
      PdfMakerService.generateReport(
        `Receiving_${lot_number?.trim() ?? ''}_${variety_name?.trim() ?? ''}`,
        'ReceivingReport',
        {
          ...generalData,
          ...qualityData,
          ...sizeData,
        },
        [
          minorDefectGraphBarChartImage,
          majorDefectGraphBarChartImage,
          sizeDistributionBarChartImage,
          colorGraphPieChartImage,
        ]
      )
      setShowBackdrop(false)
    }, 1000)
  }

  const attachToEmailDocument = async (selectedRowData) => {
    const majorDefects = []
    const minorDefects = []
    const { lot_number, variety_name } = selectedRowData
    selectedRowData.defects.forEach((defect) => {
      const { name, percentage, description } = defect
      const defectInformation = { defect: name, percentage: (percentage * 100).toFixed(2) }
      if (description === 'Major') majorDefects.push(defectInformation)
      if (description === 'Minor') minorDefects.push(defectInformation)
    })
    setMinorDefectGraphData(minorDefects)
    setMajorDefectGraphData(majorDefects)
    setSizeDistributionChartData(selectedRowData.chartData)

    const qualityData = {
      quality: generateQualityData(selectedRowData),
    }
    const { quality } = qualityData

    if (quality?.colorArray?.length > 0) {
      const sortedArray = quality?.colorArray?.sort((a, b) => a.id - b.id)
      const dataArray = sortedArray.map((x) => {
        const valueWithoutPercentSign = x.percentage.replace('%', '')
        const fillData = colorQuery.data.find((y) => y.id === x.id)
        const parsedValue = parseFloat(valueWithoutPercentSign)
        return {
          name: x.description,
          value: parsedValue,
          fill: fillData.hex,
        }
      })
      setColorChartData(dataArray)
    }
    // Timeout to give enough time to set graph data.
    setTimeout(async () => {
      const generalData = {
        general: {
          ...generateGeneralData(selectedRowData),
          facility: currentFacility?.description,
          reportDate,
        },
      }
      const qualityData = {
        quality: generateQualityData(selectedRowData),
      }
      const sizeData = {
        size: generateSizeData(selectedRowData),
        sizeChartData: selectedRowData.chartData,
        sizeCartonData: generalCartonData(selectedRowData),
      }

      const minorDefectGraphBarChart = document.getElementById('minorDefectGraph')
      const majorDefectGraphBarChart = document.getElementById('majorDefectGraph')
      const sizeDistributionBarChart = document.getElementById('sizeDistributionBarChart')
      const colorGraphPieChart = document.getElementById('colorGraph')

      const minorDefectGraphBarChartImage = (await html2canvas(minorDefectGraphBarChart)).toDataURL('image/png')
      const majorDefectGraphBarChartImage = (await html2canvas(majorDefectGraphBarChart)).toDataURL('image/png')
      const colorGraphPieChartImage = (await html2canvas(colorGraphPieChart)).toDataURL('image/png')
      const sizeDistributionBarChartImage = (await html2canvas(sizeDistributionBarChart)).toDataURL('image/png')
      const attachment = PdfMakerService.attachToEmail(
        `Receiving_${lot_number ?? ''}_${variety_name ?? ''}`,
        'ReceivingReport',
        {
          ...generalData,
          ...qualityData,
          ...sizeData,
        },
        [
          minorDefectGraphBarChartImage,
          majorDefectGraphBarChartImage,
          sizeDistributionBarChartImage,
          colorGraphPieChartImage,
        ]
      )

      attachment.getBase64(async (data) => {
        const Attachments = [
          { item1: `Receiving_${lot_number ?? ''}_${variety_name ?? ''}_ReceivingReport`, item2: data },
        ]

        const mailData = {
          Emails: emails,
          Subject: mailSubject,
          Body: mailBody,
          FromName: userEmail,
          FromEmail: userEmail,
          Attachments,
        }
        const url = `${SEND_EMAIL_REPORT}`
        await RequestService.Post(url, history, mailData)
        handleCloseSendMail()
        setLoading(false)
        setShowBackdrop(false)
      })
    }, 1000)
  }

  const printfReportRunList = () => {
    const header = []
    const headerPdf = []

    const dataRow = []
    columnsPrint.forEach((item) => {
      if (item.field !== 'Oversize') {
        if (!hiddenColumn.has(item.field)) {
          header.push(item)
        }
      }
    })
    header.forEach((item) => {
      headerPdf.push({ text: `${item.headerName}`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
    })
    const runListData = [headerPdf]

    rows.forEach((item) => {
      const data = {}
      header.forEach((hdItem) => {
        data[hdItem] = item[hdItem]
      })

      dataRow.push(data)
    })

    rows.forEach((data) => {
      const tempRowData = []
      header.forEach((key) => {
        if (
          key.field !== 'pick_date' &&
          key.field !== 'variety_name' &&
          key.field !== 'lot_number' &&
          key.field !== 'grower' &&
          key.field !== 'peak_size'
        ) {
          if (data[key.field] !== undefined) {
            if (sizeQuery.data.find((item) => item.description === key.field) || key.field === 'Undersize') {
              const tempVal = data[key.field].percentage.replace('%', '')
              const a = parseFloat(tempVal)
              tempRowData.push({
                text: `${Number(a.toFixed(1))}%`,
                style: 'tableLabelValue7',
              })
            } else {
              const temp = typeof data[key.field] === 'number' ? data[key.field] : data[key.field].replace(',', '')
              const a = parseFloat(temp)
              if (key.field === 'bin_count' || key.field === 'net_weight' || key.field === 'firmness') {
                tempRowData.push({
                  text: `${Number(a.toFixed()).toLocaleString('en-US')}`,
                  style: 'tableLabelValue7',
                })
              } else if (key.field === 'brix') {
                tempRowData.push({
                  text: `${Number(a.toFixed(1))}`,
                  style: 'tableLabelValue7',
                })
              } else {
                tempRowData.push({
                  text: `${Number(a.toFixed(1))}%`,
                  style: 'tableLabelValue7',
                })
              }
            }
          } else if (key.field === 'bin_count' || key.field === 'net_weight' || key.field === 'firmness') {
            tempRowData.push({ text: `0`, style: 'tableLabelValue7' })
          } else if (key.field === 'brix') {
            tempRowData.push({ text: `0`, style: 'tableLabelValue7' })
          } else {
            tempRowData.push({ text: `0%`, style: 'tableLabelValue7' })
          }
        } else if (key.field === 'peak_size') {
          const value = data[key.field] ? data[key.field] : '0'
          data[key.field] = value.replace(' Row', '')
          tempRowData.push({ text: `${data[key.field]}`, style: 'tableLabelValue7' })
        } else {
          tempRowData.push({ text: `${data[key.field]}`, style: 'tableLabelValue7' })
        }
      })

      runListData.push(tempRowData)
    })

    PdfMakerService.generateRunListSummary(`Receiving_RunList_${dateRepotString}`, runListData)
  }

  const printfReportSummary = (action) => {
    if (rows.length === 0) return

    const header = []
    const headerHide = []
    const headerPdf = []
    const dataRow = []
    const arrGroupByCategory = []

    columns.forEach((item) => {
      if (item.field !== 'Oversize') {
        if (!hiddenColumn.has(item.field)) {
          header.push(item.field)
        } else {
          headerHide.push(item.field)
        }
      }
    })

    header.forEach((item) => {
      switch (item) {
        case 'pick_date':
          headerPdf.push({ text: `Pick date`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'lot_number':
          headerPdf.push({ text: `Lot`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'grower':
          headerPdf.push({ text: `Grower`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'variety_name':
          headerPdf.push({ text: `Variety`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'estimated_po':
          headerPdf.push({ text: `EPO`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'peak_size':
          headerPdf.push({ text: `PS`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'minor_defect':
          headerPdf.push({ text: `Min`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'major_defect':
          headerPdf.push({ text: `Maj`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'firmness':
          headerPdf.push({ text: `FM`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'brix':
          headerPdf.push({ text: `BX`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'bin_count':
          headerPdf.push({ text: `BC`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'net_weight':
          headerPdf.push({ text: `NW`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'field_average':
          headerPdf.push({ text: `FA`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'Rojo Oscuro ':
          headerPdf.push({ text: `1`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'Rojo':
          headerPdf.push({ text: `2`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'Rojo Claro':
          headerPdf.push({ text: `3`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'Rosada':
          headerPdf.push({ text: `4`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'Verde':
          headerPdf.push({ text: `5`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'Undersize':
          headerPdf.push({ text: `U`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case '12 Row':
          headerPdf.push({ text: `12`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case '11.5 Row':
          headerPdf.push({ text: `11.5`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case '11 Row':
          headerPdf.push({ text: `11`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case '10.5 Row':
          headerPdf.push({ text: `10.5`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case '10 Row':
          headerPdf.push({ text: `10`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case '9.5 Row':
          headerPdf.push({ text: `9.5`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case '9 Row':
          headerPdf.push({ text: `9`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case '8.5 Row':
          headerPdf.push({ text: `8.5`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case '8 Row':
          headerPdf.push({ text: `8`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          break
        case 'Oversize':
          break
        default:
          if (sizeQuery.data) {
            const newHeader = sizeQuery.data.find((size) => size.description === item)
            if (newHeader) {
              headerPdf.push({ text: `${newHeader.value}`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
            }
          }
          if (colorQuery.data) {
            const newHeader = colorQuery.data.find((size) => size.description === item)
            if (newHeader) {
              let text = newHeader.description ? newHeader.description : ''
              if (newHeader.description) {
                text = newHeader.description
                  ?.split(' ')
                  .map((word) => word[0])
                  .join('')
                  .toUpperCase()
              }
              headerPdf.push({ text: `${text}`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
            }
          } else {
            headerPdf.push({ text: `${item}`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          }
      }
    })

    rows.forEach((item) => {
      const data = {}

      header.forEach((hdItem) => {
        if (hdItem === 'peak_size') {
          const value = item[hdItem] ? item[hdItem] : '0'
          data[hdItem] = value.replace(' Row', '')
        } else {
          data[hdItem] = item[hdItem] ? item[hdItem] : '0'
        }
      })

      dataRow.push(data)
    })

    const groupByCategory = groupBy(dataRow, (product) => {
      return product.variety_name
    })

    const SummaryData = [headerPdf]
    Object.keys(groupByCategory).forEach(function (key) {
      arrGroupByCategory.push(groupByCategory[key])
    })
    const tempTotalFinal = header.map((item) => {
      const data = {}
      data[item] = 0
      return data
    })
    const tempTotal18Final = header.map((item) => {
      const data = {}
      data[item] = 0
      return data
    })
    const tempCountTotal = header.map((item) => {
      const data = {}
      data[item] = 0
      return data
    })
    arrGroupByCategory.forEach((variety) => {
      const tempI = header.map((item) => {
        const data = {}
        data[item] = 0
        return data
      })

      const tempTotal = header.map((item) => {
        const data = {}
        data[item] = 0
        return data
      })

      variety.forEach((data) => {
        const tempRowData = []
        header.forEach((key, index) => {
          if (
            key !== 'pick_date' &&
            key !== 'variety_name' &&
            key !== 'lot_number' &&
            key !== 'grower' &&
            key !== 'peak_size'
          ) {
            if (data[key] !== undefined) {
              if (sizeQuery.data.find((item) => item.description === key) || key === 'Undersize') {
                const tempVal = data[key].percentage ? data[key]?.percentage?.replace('%', '') : '0'
                const a = parseFloat(tempVal)
                tempRowData.push({
                  text: `${Number(a.toFixed(1))}%`,
                  style: 'tableLabelValue7',
                })
              } else {
                const temp = typeof data[key] === 'number' ? data[key] : data[key].replace(',', '')
                const a = parseFloat(temp)
                if (key === 'bin_count' || key === 'net_weight' || key === 'firmness') {
                  tempRowData.push({
                    text: `${Number(a.toFixed()).toLocaleString('en-US')}`,
                    style: 'tableLabelValue7',
                  })
                } else if (key === 'brix') {
                  tempRowData.push({
                    text: `${Number(a.toFixed(1))}`,
                    style: 'tableLabelValue7',
                  })
                } else {
                  tempRowData.push({
                    text: `${Number(a.toFixed(1))}%`,
                    style: 'tableLabelValue7',
                  })
                }
              }
            } else if (key === 'bin_count' || key === 'net_weight' || key === 'firmness') {
              tempRowData.push({ text: `0`, style: 'tableLabelValue7' })
            } else if (key === 'brix') {
              tempRowData.push({ text: `0`, style: 'tableLabelValue7' })
            } else {
              tempRowData.push({ text: `0%`, style: 'tableLabelValue7' })
            }
          } else {
            tempRowData.push({ text: `${data[key]}`, style: 'tableLabelValue7' })
          }

          if (
            key !== 'pick_date' &&
            key !== 'variety_name' &&
            key !== 'lot_number' &&
            key !== 'grower' &&
            key !== 'peak_size'
          ) {
            if (data[key] !== undefined) {
              if (sizeQuery.data.find((item) => item.description === key) || key === 'Undersize') {
                if (data[key].percentage !== '0.0%') {
                  tempI[index][key]++
                }
                const tempVal = data[key].percentage ? data[key]?.percentage?.replace('%', '') : '0'
                tempTotal[index][key] += parseFloat(tempVal)
              } else if (typeof data[key] === 'number') {
                if (key === 'bin_count' || key === 'net_weight' || key === 'firmness') {
                  if (data[key] !== 0) {
                    tempI[index][key]++
                  }
                  tempTotal[index][key] += Number(data[key].toFixed())
                } else {
                  if (data[key] !== 0) {
                    tempI[index][key]++
                  }
                  tempTotal[index][key] += parseFloat(data[key])
                }
              } else if (key === 'bin_count' || key === 'net_weight' || key === 'firmness') {
                if (data[key] !== '0.0%' && data[key] !== '0%' && data[key] !== '0.0' && data[key] !== '0') {
                  tempI[index][key]++
                }
                let tempVal = data[key].replace('%', '')
                tempVal = tempVal.replace(',', '')
                tempTotal[index][key] += Number(Number(tempVal).toFixed())
              } else {
                if (data[key] !== '0.0%' && data[key] !== '0%' && data[key] !== '0.0' && data[key] !== '0') {
                  tempI[index][key]++
                }
                const tempVal = data[key].replace('%', '')
                tempTotal[index][key] += parseFloat(data[key])
              }
            } else {
              tempTotal[index][key] += 0
            }
          }
        })

        SummaryData.push(tempRowData)
      })

      const totalVariety = []
      const total18lb = []
      const blank = []
      let packableWeight = 0
      let total18 = 0
      Object.values(tempTotal).forEach(function (item, index) {
        const key = Object.keys(tempTotal[index]) // eslint-disable-line no-param-reassign
        if (key[0] !== 'bin_count' && key[0] !== 'net_weight') {
          tempTotal[index][key[0]] /= tempI[index][key[0]] > 0 ? tempI[index][key[0]] : 1
        }
      })

      Object.values(tempTotal).forEach(function (item, index) {
        const key = Object.keys(tempTotal[index]) // eslint-disable-line no-param-reassign
        // if (key[0] !== 'bin_count' && key[0] !== 'net_weight') {
        //   tempTotal[index][key[0]] /= tempI[index][key[0]] > 0 ? tempI[index][key[0]] : 1
        // }

        tempCountTotal[index][key]++
        const keySizeQuery = sizeQuery.data.find((size) => size.description === key[0])
        switch (key[0]) {
          case 'pick_date':
          case 'lot_number':
          case 'grower':
          case 'peak_size':
            totalVariety.push({ text: ``, style: 'tableLabel7', fillColor: DARKGRAY })
            total18lb.push({ text: '', style: 'tableLabel7', fillColor: LIGHTGRAY })
            blank.push({ text: ' ', style: 'tableLabel7' })
            tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
            break
          case 'variety_name':
            totalVariety.push({ text: `Variety Totals`, style: 'tableLabel7', fillColor: DARKGRAY })
            total18lb.push({ text: '18lb Cartons', style: 'tableLabel7', fillColor: LIGHTGRAY })
            blank.push({ text: ' ', style: 'tableLabel7' })
            tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
            break
          case 'bin_count': {
            const packWeight = getPackableWeight(tempTotal)
            total18 = getTotal18lb(tempTotal, packWeight, sizeQuery)
            totalVariety.push({
              text: `${Number(Number(tempTotal[index][key[0]].toFixed())).toLocaleString('en-US')}`,
              style: 'tableLabel7',
              fillColor: DARKGRAY,
            })
            total18lb.push({
              text: Number(total18.toFixed()).toLocaleString('en-US'),
              style: 'tableLabel7',
              fillColor: LIGHTGRAY,
            })
            blank.push({ text: ' ', style: 'tableLabel7' })
            tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
            tempTotal18Final[index][key] += Number(total18.toFixed()) || 0
            break
          }
          case 'net_weight':
          case 'firmness':
            totalVariety.push({
              text: `${Number(tempTotal[index][key[0]].toFixed()).toLocaleString('en-US')}`,
              style: 'tableLabel7',
              fillColor: DARKGRAY,
            })
            total18lb.push({ text: '', style: 'tableLabel7', fillColor: LIGHTGRAY })
            blank.push({ text: ' ', style: 'tableLabel7' })
            tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
            break
          case 'brix':
            totalVariety.push({
              text: `${tempTotal[index][key[0]].toFixed(1)}`,
              style: 'tableLabel7',
              fillColor: DARKGRAY,
            })
            total18lb.push({ text: '', style: 'tableLabel7', fillColor: LIGHTGRAY })
            blank.push({ text: ' ', style: 'tableLabel7' })
            tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
            break
          case 'estimated_po':
            if (tempTotal[index][key[0]] !== 0) {
              packableWeight = getPackableWeight(tempTotal)
            }
            totalVariety.push({
              text: `${
                tempTotal[index][key[0]] === 100 || tempTotal[index][key[0]] === 0
                  ? tempTotal[index][key[0]].toFixed()
                  : tempTotal[index][key[0]].toFixed(1)
              }%`,
              style: 'tableLabel7',
              fillColor: DARKGRAY,
            })
            total18lb.push({
              text: ` `,
              style: 'tableLabel7',
              fillColor: LIGHTGRAY,
            })
            blank.push({ text: ' ', style: 'tableLabel7' })
            tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
            break
          case 'Undersize':
          case `${keySizeQuery ? keySizeQuery.description : ''}`:
            totalVariety.push({
              text: `${Number(tempTotal[index][key[0]].toFixed(1))}%`,
              style: 'tableLabel7',
              fillColor: DARKGRAY,
            })
            total18lb.push({
              text: `${(((Number(tempTotal[index][key[0]] || 0).toFixed(1) / 100) * packableWeight) / 18).toFixed()}`,
              style: 'tableLabel7',
              fillColor: LIGHTGRAY,
            })
            blank.push({ text: ' ', style: 'tableLabel7' })
            tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
            tempTotal18Final[index][key] += Number(
              (((Number(tempTotal[index][key[0]] || 0).toFixed(1) / 100) * packableWeight) / 18).toFixed()
            )

            break
          default:
            totalVariety.push({
              text: `${tempTotal[index][key[0]] ? Number(tempTotal[index][key[0]].toFixed(1)) : 0}%`,
              style: 'tableLabel7',
              fillColor: DARKGRAY,
            })
            total18lb.push({ text: '', style: 'tableLabel7', fillColor: LIGHTGRAY })
            blank.push({ text: ' ', style: 'tableLabel7' })
            tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
        }
      })

      SummaryData.push(totalVariety)
      SummaryData.push(total18lb)
      SummaryData.push(blank)
    })
    const totalFinal = []
    const total18Final = []
    Object.values(tempTotalFinal).forEach(function (item, index) {
      const key = Object.keys(tempTotalFinal[index]) // eslint-disable-line no-param-reassign
      const keySizeQuery = sizeQuery.data.find((size) => size.description === key[0])

      switch (key[0]) {
        case 'pick_date':
        case 'lot_number':
        case 'grower':
        case 'peak_size':
          totalFinal.push({ text: ``, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          total18Final.push({ text: ``, style: 'tableSumaryLabel8', fillColor: LIGHTBLUE })

          break
        case 'variety_name':
          totalFinal.push({ text: `Totals`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          total18Final.push({ text: `18lb Total`, style: 'tableSumaryLabel8', fillColor: LIGHTBLUE })
          break
        case 'bin_count':
          totalFinal.push({
            text: `${Number(tempTotalFinal[index][key[0]].toFixed()).toLocaleString('en-US')}`,
            style: 'tableSumaryLabel',
            fillColor: DARKBLUE,
          })
          total18Final.push({
            text: `${Number(tempTotal18Final[index][key[0]].toFixed()).toLocaleString('en-US')}`,
            style: 'tableSumaryLabel8',
            fillColor: LIGHTBLUE,
          })
          break
        case 'brix':
          totalFinal.push({
            text: `${(tempTotalFinal[index][key[0]] / tempCountTotal[index][key]).toFixed(1)}`,
            style: 'tableSumaryLabel',
            fillColor: DARKBLUE,
          })
          total18Final.push({ text: ``, style: 'tableSumaryLabel8', fillColor: LIGHTBLUE })
          break
        case 'firmness':
          totalFinal.push({
            text: `${(tempTotalFinal[index][key[0]] / tempCountTotal[index][key]).toFixed(1)}`,
            style: 'tableSumaryLabel',
            fillColor: DARKBLUE,
          })
          total18Final.push({ text: ``, style: 'tableSumaryLabel8', fillColor: LIGHTBLUE })
          break
        case 'net_weight':
          totalFinal.push({
            text: `${Number(tempTotalFinal[index][key[0]].toFixed()).toLocaleString('en-US')}`,
            style: 'tableSumaryLabel',
            fillColor: DARKBLUE,
          })
          total18Final.push({ text: ``, style: 'tableSumaryLabel8', fillColor: LIGHTBLUE })
          break
        case 'Undersize':
        case `${keySizeQuery ? keySizeQuery.description : ''}`:
          totalFinal.push({
            text: `${Number((tempTotalFinal[index][key[0]] / tempCountTotal[index][key]).toFixed(1))}%`,
            style: 'tableSumaryLabel',
            fillColor: DARKBLUE,
          })
          total18Final.push({
            text: Number(tempTotal18Final[index][key[0]].toFixed()).toLocaleString('en-US'),
            style: 'tableSumaryLabel8',
            fillColor: LIGHTBLUE,
          })
          break
        default:
          totalFinal.push({
            text: `${Number((tempTotalFinal[index][key[0]] / tempCountTotal[index][key]).toFixed(1))}%`,
            style: 'tableSumaryLabel',
            fillColor: DARKBLUE,
          })
          total18Final.push({
            text: ' ',
            style: 'tableSumaryLabel8',
            fillColor: LIGHTBLUE,
          })
      }
    })

    SummaryData.push(totalFinal)
    SummaryData.push(total18Final)
    if (action === 'print') {
      PdfMakerService.generateReportSummary(
        `Receiving_Summary_${dateRepotString}`,
        SummaryData,
        lotsFacilityQuery.data.map((lot) => lot.first)
      )
    } else {
      const attachment = PdfMakerService.attachSummarryToEmail(
        `Receiving_Summary_${dateRepotString}`,
        SummaryData,
        lotsFacilityQuery.data.map((lot) => lot.first)
      )
      attachment.getBase64(async (data) => {
        const Attachments = [
          {
            item1: `ReceivingReportSummary_${dateRepotString}`,
            item2: data,
          },
        ]

        const mailData = {
          Emails: emails,
          Subject: mailSubject,
          Body: mailBody,
          FromName: userEmail,
          FromEmail: userEmail,
          Attachments,
        }
        const url = `${SEND_EMAIL_REPORT}`
        await RequestService.Post(url, history, mailData)
        handleCloseSendMail()
        setLoading(false)
        setShowBackdrop(false)
      })
    }
  }

  const openAddDialog = async (selectedRowData) => {
    setSelectedRowData(selectedRowData)
    setOpenAddValue(true)
  }

  const handleCloseAddValue = () => {
    setOpenAddValue(false)
  }

  const handleAddValue = async () => {
    if (selectedRowData?.id_lot) {
      try {
        let sendBincount = 0
        let sendNetweight = 0
        if (binCount !== 0) {
          sendBincount = binCount
        } else {
          sendBincount = parseFloat((selectedRowData?.bin_count || '0').replace(/,/g, '')) || 0
        }

        if (netWeight !== 0) {
          sendNetweight = netWeight
        } else {
          sendNetweight = parseFloat((selectedRowData?.net_weight || '0').replace(/,/g, '')) || 0
        }
        /* eslint-disable-next-line max-len */
        const url = `${INSERT_BIN_WEIGHT_RECEIVING_SUMMARY}?&id_facility=${currentFacility.id}&id_Lot=${selectedRowData.id_lot}&id_Variety=${selectedRowData.id_variety}&start_date=${startDateCus}Z&end_date=${endDateCus}Z&bin_count=${sendBincount}&net_weight=${sendNetweight}`
        const res = await RequestService.Post(url, history)
        if (res.message === 'ReceivingSummary Updated') {
          rows.forEach((item) => {
            if (item.id_lot === selectedRowData.id_lot && item.id_variety === selectedRowData.id_variety) {
              if (binCount !== 0) {
                item.bin_count = Number((Number(binCount) || 0).toFixed()).toLocaleString('en-US')
              } else {
                item.bin_count = Number(
                  parseFloat((selectedRowData?.bin_count || '0').replace(/,/g, '')).toFixed()
                ).toLocaleString('en-US')
              }
              if (netWeight !== 0) {
                item.net_weight = Number((Number(netWeight) || 0).toFixed()).toLocaleString('en-US')
              } else {
                item.net_weight = Number(
                  parseFloat((selectedRowData?.net_weight || '0').replace(/,/g, '')).toFixed()
                ).toLocaleString('en-US')
              }
            }
          })
          setRows(rows)
          setBinCount(0)
          setNetWeight(0)
        }
        setIsLoading(true)
        setOpenAddValue(false)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    } else {
      setShowDatesError(true)
    }
  }

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fontSize={25} textAnchor="middle" dominantBaseline="middle" fill="black">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }
  const handleChangeBin = (event) => {
    setBinCount(event.target.value)
  }
  const handleChangeNet = (event) => {
    setNetWeight(event.target.value)
  }
  const handleSubject = (event) => {
    setMailSubject(event.target.value)
  }
  const handleBody = (event) => {
    setMailBody(event.target.value)
  }

  const handleSendEmail = (event) => {
    event.preventDefault()
    setShowBackdrop(true)
    setLoading(true)

    if (sendSigleEmail) {
      attachToEmailDocument(selectedRowData)
    } else {
      printfReportSummary('send')
    }
  }

  const onBlur = (e) => {
    if (e.target.value.trim().length > 0) {
      if (validateEmail(e.target.value.trim())) {
        setEmailInvalid(false)
        setEmails([...emails, e.target.value.trim()])
      } else {
        setEmailInvalid(true)
        setOpenAlert(true)
      }
    }
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpenAlert(false)
  }

  const handleChangeSearch = (event) => {
    const text = event.target.value
    searchFullColumns(rowsResponse, text, setRows, columns)
  }

  const handleClickMenu = (event) => {
    console.log(event)
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const formatterPercentege = (value) => (+value > 0 ? `${value}% ` : '')
  const printfReportExcel = () => {
    const header = []
    columnsPrint.forEach((item) => {
      if (item.field !== 'Oversize') {
        if (!hiddenColumn.has(item.field)) {
          header.push(item)
        }
      }
    })
    funcExportExcel(rows, header, `Summary report ${dateRepotString}`)
  }
  const handleRowOrderChange = async (params) => {
    setLoading(true)
    const newRows = await updateRowPosition(params.oldIndex, params.targetIndex, rows)

    setRows(newRows)
    setLoading(false)
  }
  function updateRowPosition(initialIndex, newIndex, rows) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows]
        const row = rowsClone.splice(initialIndex, 1)[0]
        rowsClone.splice(newIndex, 0, row)
        resolve(rowsClone)
      }, Math.random() * 500 + 100) // simulate network latency
    })
  }

  const handleRowDragStart = (params) => {
    setDraggedRowId(params.row.id)
  }

  const handleRowDragEnd = (params) => {
    setDraggedRowId(null)
  }

  useEffect(() => {
    apiRef.current?.subscribeEvent('rowDragStart', handleRowDragStart)
    apiRef.current?.subscribeEvent('rowDragEnd', handleRowDragEnd)

    return () => {
      apiRef.current?.unsubscribeEvent('rowDragStart', handleRowDragStart)
      apiRef.current?.unsubscribeEvent('rowDragEnd', handleRowDragEnd)
    }
  }, [apiRef])

  const getRowClassName = (params) => {
    const classNames = []
    if (params.row.id === draggedRowId) {
      classNames.push(classes.highlight)
    }
    return classNames.join(' ')
  }

  return (
    <>
      <Drawer variant="temporary" anchor="right" open={isDrawerOpen}>
        <Recovery
          lots={lotsFacilityQuery.data.map((lot) => lot.first)}
          varieties={verietyQuery.data}
          onClose={() => setDrawer(false)}
        />
      </Drawer>
      <Dialog open={openAddValue} onClose={handleCloseAddValue}>
        <DialogTitle>Add values</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="bins"
            label={t('total_amount_of_bins')}
            variant="standard"
            fullWidth
            defaultValue={
              selectedRowData?.bin_count && selectedRowData?.bin_count.replace(/,/g, '') !== '0'
                ? parseFloat(selectedRowData?.bin_count.replace(/,/g, ''))
                : undefined
            }
            placeholder="0"
            type="number"
            required
            error={checkNullBin}
            inputProps={{
              min: 0,
              inputMode: 'numeric',
              pattern: 'd*',
            }}
            onBlur={() => {
              if (binCount === '') {
                setCheckNullBin(true)
              } else {
                setCheckNullBin(false)
              }
            }}
            onChange={handleChangeBin}
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                event.preventDefault()
              }
            }}
          />

          <TextField
            autoFocus
            margin="dense"
            id="netweight"
            label={t('total_net_weight')}
            type="number"
            fullWidth
            defaultValue={
              selectedRowData?.net_weight && selectedRowData?.net_weight.replace(/,/g, '') !== '0'
                ? parseFloat(selectedRowData?.net_weight.replace(/,/g, ''))
                : undefined
            }
            placeholder="0"
            variant="standard"
            required
            error={checkNullWeight}
            inputProps={{ min: 0, step: 'any', inputMode: 'numeric', pattern: 'd*' }}
            onBlur={() => {
              if (netWeight === '') {
                setCheckNullWeight(true)
              } else {
                setCheckNullWeight(false)
              }
            }}
            onChange={handleChangeNet}
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+') {
                event.preventDefault()
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddValue}>Cancel</Button>
          <Button disabled={checkNullBin || checkNullWeight} onClick={handleAddValue}>
            {selectedRowData?.bin_count === null || selectedRowData?.net_weight === null ? t('add') : t('update')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpenDelete} onClose={() => handleCloseDel()}>
        <DialogTitle>{t('are_you_sure_you_want_to_delete_this_lot')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleCloseDel()}>{t('cancel')}</Button>
          <Button onClick={() => deleteDocument(selectedRowData)}>{t('delete')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={confirmOpenMailSender} onClose={() => handleCloseSendMail()}>
        <DialogTitle>{t('send_report')}</DialogTitle>
        <form onSubmit={handleSendEmail}>
          <DialogContent>
            <DialogContentText style={{ margin: 0 }}>
              <Trans i18nKey="please_fill_in_the_information_below">Please fill in the information below</Trans>
            </DialogContentText>
            <Grid container>
              <Grid item xs={11}>
                <Autocomplete
                  multiple
                  id="emails"
                  options={[]}
                  value={emails}
                  freeSolo
                  required
                  onChange={(e, newval) => {
                    if (e.type !== 'click' && e.target.value.trim().length > 0) {
                      if (validateEmail(e.target.value.trim())) {
                        setEmailInvalid(false)
                        setEmails(newval)
                      } else {
                        setEmailInvalid(true)
                        setOpenAlert(true)
                      }
                    } else {
                      setEmails(newval)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t('receiver_emails')}
                      placeholder="Email"
                      margin="normal"
                      name="name"
                      type="text"
                      onBlur={onBlur}
                      required={emails.length === 0}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <TooltipMui
                  classes={{ tooltip: classes.tooltip }}
                  /* eslint-disable-next-line max-len */
                  title={`${t('each_email_must_be_separated_by_an_enter_sign')}!`}
                >
                  <IconButton style={{ marginTop: '10px', color: '#5a7a98' }} aria-label="tooltip">
                    <HelpIcon />
                  </IconButton>
                </TooltipMui>
              </Grid>
            </Grid>

            <TextField
              variant="outlined"
              margin="normal"
              id="subject"
              name="subject"
              label={t('subject')}
              type="text"
              fullWidth
              required
              onChange={handleSubject}
            />
            <TextField
              variant="outlined"
              margin="normal"
              id="body"
              name="body"
              label={t('body')}
              type="text"
              fullWidth
              required
              onChange={handleBody}
            />
          </DialogContent>
          <DialogActions>
            <Button style={{ textTransform: 'none' }} onClick={() => handleCloseSendMail()} color="primary">
              {t('cancel')}
            </Button>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <PrimaryButton variant="contained" disabled={loading || emailInvalid} type="submit">
                {t('send')}
              </PrimaryButton>
              {loading && <CircularProgress size={24} className={classes.circularProgress} />}
            </div>
          </DialogActions>
        </form>
        <Alert onClose={handleAlertClose} open={openAlert} severity="error">
          {t('wrong_email_format')}
        </Alert>
      </Dialog>
      <Grid container spacing={1}>
        <Grid xs={12} item>
          <Typography align="center" variant="h6">
            {t('receiving_reports')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <SingleDateTimePicker
            disabled={isLoading}
            style={{ width: '100%' }}
            label={t('start_date')}
            name="startDate"
            value={startDate}
            disableFuture
            onChange={(date) => {
              const startDate = DateTime.fromJSDate(date)
              setStartDateISODate(startDate.toISODate())
              setStartDate(startDate.toISO({ includeOffset: false }))
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SingleDateTimePicker
            disabled={isLoading}
            style={{ width: '100%' }}
            label={t('end_date')}
            name="endDate"
            value={endDate}
            disableFuture
            onChange={(date) => {
              const endDate = DateTime.fromJSDate(date)
              setEndDateISODate(endDate.toISODate())
              setEndDate(endDate.toISO({ includeOffset: false }))
            }}
          />
        </Grid>
        <Grid item xs={6} md={1} style={{ display: 'flex', alignItems: 'end' }}>
          <PrimaryButton
            variant="contained"
            disabled={isLoading}
            onClick={() => {
              startDateCus = startDate
              endDateCus = endDate
              if (endDateISODate >= startDateISODate) {
                setShowDatesError(false)
                trackEvent('FILTE_BUTTON_CLICK', { email: userEmail })
                recevingReportQuery.refetch().then((res) => {
                  setRecevingReportQueryResult(res.data.result)
                })
              } else {
                setShowDatesError(true)
              }
            }}
          >
            {t('filter')}
          </PrimaryButton>
        </Grid>
        <Grid item xs={6} md={5} style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }}>
          <IconButton
            aria-label="more"
            id="menu-button"
            aria-controls={openMenu ? 'menu' : undefined}
            aria-expanded={openMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClickMenu}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() => {
                trackEvent('PRINT_REPORT_SUMMARY_CLICK', { email: userEmail })
                printfReportSummary('print')
              }}
            >
              {t('summary_report')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                trackEvent('SEND_MAIL_REPORT_SUMMARY_CLICK', { email: userEmail })
                openSendMailDialog([], false)
              }}
            >
              {t('email_summary')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                trackEvent('EXPORT_EXCEL_REPORT_SUMMARY_CLICK', { email: userEmail })
                printfReportExcel()
              }}
            >
              {t('export_report')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                trackEvent('PRINT_RUNLIST_REPORT_CLICK', { email: userEmail })
                printfReportRunList()
              }}
            >
              {t('create_run_list')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                trackEvent('PRINT_CARTORN_REPORT_SUMMARY_CLICK', { email: userEmail })
                printfCartornReportSummary('print', rows, columns, hiddenColumn, dateRepotString, sizeQuery, colorQuery)
              }}
            >
              {t('carton_summary')}
            </MenuItem>
            {role === SUPER_ADMIN_ROLE || role === QUALITY_CONTROL_MANAGER_ROLE ? (
              <MenuItem
                onClick={() => {
                  trackEvent('DATA_RECOVERY_CLICK', { email: userEmail })
                  setDrawer(true)
                }}
              >
                Data Recovery
              </MenuItem>
            ) : null}
          </Menu>
        </Grid>

        {showDatesError ? (
          <Grid item xs={12} md={6}>
            <Typography align="left" variant="subtitle2" color="error">
              {t('end_date_must_be_after_or_the_same_as_start_date_please_adjust_and_try_again')}
            </Typography>
          </Grid>
        ) : null}
        <Grid container>
          <Grid item xs={6} md={6} style={{ fontWeight: 'bold' }}>
            <Chip
              style={{ margin: '3px' }}
              label={`${t('house_average')}: ${((recevingReportQuery?.data?.houseAverage ?? 0) * 100).toFixed(2)}%`}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.searchBox}>
              <SearchBar onChange={handleChangeSearch} />
            </div>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item className={classes.hideChart}>
          <div className={classes.chartContainer}>
            <ResponsiveContainer id="minorDefectGraph" minWidth={872.5} minHeight={400}>
              <BarChart width={800} height={200} data={minorDefectGraphData} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="percentage"
                  type="number"
                  style={{ fill: 'black', fontSize: '150%' }}
                  tickSize={10}
                  tickFormatter={(value) => `${Number(Number(value).toFixed(1))}%`}
                  domain={[0, maxMinorDefect]}
                />
                <YAxis dataKey="defect" type="category" width={300} style={{ fill: 'black', fontSize: '140%' }} />
                <Tooltip formatter={(value) => `${value}%`} />
                <Bar dataKey="percentage" fill={BLUE}>
                  <LabelList
                    dataKey="percentage"
                    fill={BLACK}
                    position="right"
                    style={{ fill: 'black', fontSize: '150%' }}
                    formatter={(value) => formatterPercentege(Number(Number(value).toFixed(1)))}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid xs={12} md={6} item className={classes.hideChart}>
          <div className={classes.chartContainer}>
            <ResponsiveContainer id="colorGraph" width={473} height={400}>
              <PieChart width={200} height={300}>
                <Pie
                  data={colorChartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={200}
                  fill="#8884d8"
                  labelLine={false}
                  label={renderCustomizedLabel}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid xs={12} md={6} item className={classes.hideChart}>
          <div className={classes.chartContainer}>
            <ResponsiveContainer id="majorDefectGraph" minWidth={873} minHeight={400}>
              <BarChart width={800} height={200} data={majorDefectGraphData} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="percentage"
                  type="number"
                  style={{ fill: 'black', fontSize: '150%' }}
                  tickSize={10}
                  tickFormatter={(value) => `${value}%`}
                  domain={[0, maxMajorDefect]}
                />
                <YAxis dataKey="defect" type="category" width={300} style={{ fill: 'black', fontSize: '140%' }} />
                <Tooltip formatter={(value) => `${value}%`} />
                <Bar dataKey="percentage" fill={BLUE}>
                  <LabelList
                    dataKey="percentage"
                    fill={BLACK}
                    position="right"
                    style={{ fill: 'black', fontSize: '150%' }}
                    formatter={(value) => formatterPercentege(Number(Number(value).toFixed(1)))}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid xs={12} md={6} item className={classes.hideChart}>
          <div className={classes.chartContainer}>
            <ResponsiveContainer id="sizeDistributionBarChart" minWidth={872.5} minHeight={400}>
              <BarChart width={500} height={200} data={sizeDistributionChartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" style={{ fill: 'black' }} />
                <YAxis
                  type="number"
                  domain={[0, maxSizeDistribution]}
                  tickSize={5}
                  tickFormatter={(value) => `${value}%`}
                  style={{ fill: 'black' }}
                  width={100}
                />
                <Tooltip formatter={(value) => `${value}%`} />
                <Bar dataKey="percentage" fill={BLUE}>
                  <LabelList
                    dataKey="percentage"
                    fill={BLACK}
                    position="top"
                    style={{ fill: 'black' }}
                    formatter={(value) => formatterPercentege(Number(Number(value).toFixed(1)))}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid xs={12} item>
          <div
            style={{
              height: '80vh',
            }}
          >
            <DataGridPro
              apiRef={apiRef}
              rows={rows}
              columns={columns}
              rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
              pageSize={tableConf.pageSize}
              disableSelectionOnClick
              loading={isLoadingQuery}
              onPageSizeChange={onPageSizeChange}
              onColumnVisibilityModelChange={(newModel) => {
                const hiddenColSet = new Set(hiddenColumn)
                const keys = Object.keys(newModel)
                keys.forEach((key) => {
                  if (key !== '__reorder__' && key !== 'delete' && key !== 'action' && key !== 'print') {
                    if (newModel[key] === true) {
                      hiddenColSet.delete(key)
                      hiddenColSet.add('print')
                      hiddenColSet.add('action')
                      hiddenColSet.add('delete')
                    } else {
                      hiddenColSet.add(key)
                      hiddenColSet.add('print')
                      hiddenColSet.add('action')
                      hiddenColSet.add('delete')
                    }
                  }
                })

                setHiddenColumn(hiddenColSet)
              }}
              rowReordering
              onRowOrderChange={handleRowOrderChange}
              getRowClassName={getRowClassName}
            />
          </div>
        </Grid>
        <SimpleBackdrop open={showBackdrop} />
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => ({
  currentFacility: state.facility.currentFacility,
  userEmail: state.user.email,
  role: state.user.role,
})

export default connect(mapStateToProps)(Receiving)
