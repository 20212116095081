import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import * as XLSXStyle from 'xlsx-js-style'

import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import SimpleBackdrop from '../../../components/simple-backdrop/simple-backdrop.component'
import SingleDateTimePicker from '../../../components/single-datetimepicker/simple-datetimepicker.component'
import PrimaryButton from '../../../components/button/button.component'
import SearchableInput from '../../../components/searchable-input/searchable-input.component'
import PrintButton from '../../../components/print-button/print-button.component'

import RequestService from '../../../services/request/request-service'
import { PRODUCTION_SUMMARY_QUERY, VARIETY_QUERY } from '../../../shared/constants/queries'
import useMachineQuery from '../../../shared/hooks/useMachineQuery'
import { numberToPercentage } from '../../../shared/utils/utils'

import PdfMakerService from '../../../services/pdf-maker/pdf-maker-service'
import makeStyles from '../reports.styles'
import reportsSlice from '../../../redux/reports/reports.slice'

const ProductionSummary = ({
  currentFacility,
  selectedLot,
  selectedVariety,
  currentMachine,
  setSelectedMachine,
  selectedMachine,
  desTemplates,
}) => {
  const classes = makeStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [productionSummaryData, setProductionSummaryData] = useState([])
  const [varieties, setVarieties] = useState([])
  const [dateReport, setDateReport] = useState('')
  const [totalRecvingSizing, setTotalRecvingSizing] = useState([])
  const [totalUpstreamSizing, setTotalUpstreamSizing] = useState([])
  const [totalInLineSizing, setTotalInLineSizing] = useState([])
  const [totalFinishedBoxSizing, setTotalFinishedBoxSizing] = useState([])
  const [totalRecvingQuality, setTotalRecvingQuality] = useState({})
  const [totalUpstreamQuality, setTotalUpstreamQuality] = useState({})
  const [totalInlineQuality, setTotalInlineQuality] = useState({})
  const [totalFinishedBoxQuality, setTotalFinishedBoxQuality] = useState({})
  const [totalGIBQuality, setTotalGIBQuality] = useState({})
  const [totalGIPQuality, setTotalGIPQuality] = useState({})
  const [varietiesName, setVarietiesName] = useState([])
  const [startDate, setStartDate] = useState(DateTime.now().startOf('day').toISO({ includeOffset: false }))
  const [endDate, setEndDate] = useState(DateTime.now().endOf('day').toISO({ includeOffset: false }))
  const [startDateISODate, setStartDateISODate] = useState(DateTime.now().startOf('day').toISODate())
  const [endDateISODate, setEndDateISODate] = useState(DateTime.now().endOf('day').toISODate())
  const [machineSelected, setMachineSelected] = useState({})
  const [machineName, setMachineName] = useState('')
  const [sizeSum, setSizeSum] = useState(true)
  const [dataTable, setDataTable] = useState([])
  const [headerTable, setHeaderTable] = useState({})

  const tableCellHeaderClassNames = `${classes.tableCell} ${classes.tableCellLabel}`
  const tableCellClassNames = `${classes.tableCell}`

  const machineQuery = useMachineQuery({
    byFacility: true,
    filter: { id_facility: currentFacility.id },
  })

  useEffect(async () => {
    await initLoadData()
    return () => {
      // Clear the array when the component unmounts
      setProductionSummaryData([])
      setVarieties([])
      setDateReport('')
      setTotalRecvingSizing([])
      setTotalUpstreamSizing([])
      setTotalInLineSizing([])
      setTotalFinishedBoxSizing([])
      setTotalRecvingQuality({})
      setTotalUpstreamQuality({})
      setTotalInlineQuality({})
      setTotalFinishedBoxQuality({})
      setTotalGIBQuality({})
      setVarietiesName([])
      setMachineSelected({})
      setDataTable([])
      setHeaderTable({})
    }
  }, [])

  useEffect(() => {
    if (varieties.length === 0 || productionSummaryData.length === 0) {
      setShowReport(false)
    } else {
      let DateReport = ''
      if (DateTime.fromISO(startDate).toFormat('MMM-dd-yy') === DateTime.fromISO(endDate).toFormat('MMM-dd-yy')) {
        DateReport = DateTime.fromISO(startDate).toFormat('MMM-dd-yy')
      } else {
        DateReport = `${DateTime.fromISO(startDate).toFormat('MMM-dd-yy')} to ${DateTime.fromISO(endDate).toFormat(
          'MMM-dd-yy'
        )}`
      }
      setDateReport(DateReport)
      varietyIdToName()
      setDataTable([])
      setHeaderTable({})
      caculateTotal()
      setShowReport(true)
    }
  }, [varieties, productionSummaryData])

  useEffect(() => {
    let header = {}

    if (Object.keys(dataTable)) {
      Object.keys(dataTable).map((locName) => {
        let headerLOC = []
        Object.keys(dataTable[locName]).map((key) => {
          headerLOC = [...headerLOC, ...dataTable[locName][key]]
          return null
        })
        headerLOC = headerLOC.map((item) => item.name)
        headerLOC = headerLOC.filter((item, pos) => {
          return headerLOC.indexOf(item) === pos
        })
        header = { ...header, [locName]: headerLOC }
        return null
      })
      setHeaderTable({ ...header })
    }
  }, [dataTable])

  const loadData = async () => {
    if (endDateISODate >= startDateISODate) {
      try {
        setProductionSummaryData([])
        setIsLoading(true)

        const filter = [`id_facility=${currentFacility.id}`, `start_date=${startDate}`, `end_date=${endDate}`]
        if (machineSelected?.id > 0) {
          filter.push(`id_machine=${machineSelected.id}`)
          setMachineName(
            machineSelected.name ? machineSelected.name : machineSelected.description ? machineSelected.description : ''
          )
        }

        const url = `${PRODUCTION_SUMMARY_QUERY}?${filter.join('&')}`
        const response = await RequestService.Get(url, history)
        await setProductionSummaryData(response.data)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const initLoadData = async () => {
    const url = `${VARIETY_QUERY}?startRowIndex=0&pageSize=10000`
    const tskVariety = RequestService.Get(url, history)
    machineQuery.refetch()

    const [resVariety] = await Promise.all([tskVariety])

    setVarieties(resVariety.data)
  }

  const varietyIdToName = () => {
    //  const varietyName =  productionSummaryData.map((item)=> forea )
    const temp = []
    productionSummaryData.forEach((item) => {
      const findItem = varieties.find((x) => x.id === item.id_variety)
      if (findItem != null) {
        temp.push(findItem.name)
      }
    })
    setVarietiesName(temp)
  }
  const caculateTotal = () => {
    const TotalRecvingSizing = []
    let TotalRecvingSizingCount = 0
    const TotalUpstreamSizing = []
    let TotalUpstreamSizingCount = 0
    const TotalInLineSizing = []
    const TotalFinishedBoxSizing = []
    const TotalRecvingQuality = {
      totalEpo: 0,
      totalMinDef: 0,
      totalMajDef: 0,
      totalFirm: 0,
      totalBrix: 0,
      totalCount: 0,
    }
    const TotalUpstreamQuality = { totalEpo: 0, totalMinDef: 0, totalMajDef: 0, totalCount: 0 }
    const TotalInlineQuality = { totalEpo: 0, totalMinDef: 0, totalMajDef: 0, totalCount: 0 }
    const TotalFinishedBoxQuality = { totalEpo: 0, totalMinDef: 0, totalMajDef: 0, totalCount: 0 }
    const TotalGIBQuality = { totalGoodInBad: 0, totalDobleSpurs: 0, totalCorrect: 0, totalCount: 0 }
    const TotalGIPQuality = { totalGood: 0, totalBad: 0, totalPeddler: 0, totalCount: 0 }

    productionSummaryData.forEach((item, index) => {
      item?.productionSizingSummary?.receivingSizing?.forEach((receivingSizingItem) => {
        if (receivingSizingItem.name !== 'Oversize') {
          const item = { key: receivingSizingItem.name, value: receivingSizingItem.percentage }
          const key = TotalRecvingSizing.find((x) => x.key === receivingSizingItem.name)

          if (key === undefined) {
            TotalRecvingSizing.push(item)
          } else {
            TotalRecvingSizing.find((x) => x.key === receivingSizingItem.name).value += receivingSizingItem.percentage
          }
        }
      })
      if (item?.productionSizingSummary?.receivingSizing !== null) {
        TotalRecvingSizingCount++
        const findItem = varieties.find((varity) => varity.id === item.id_variety)
        setDataTable((pre) => ({
          ...pre,
          'LOC-REC': { ...pre['LOC-REC'], [findItem?.name]: item?.productionSizingSummary?.receivingSizing },
        }))
      }
      item?.productionSizingSummary?.upstreamSizing?.forEach((upstreamSizingItem) => {
        if (upstreamSizingItem.name !== 'Oversize') {
          const item = { key: upstreamSizingItem.name, value: upstreamSizingItem.percentage }
          const key = TotalUpstreamSizing.find((x) => x.key === upstreamSizingItem.name)
          if (key === undefined) {
            TotalUpstreamSizing.push(item)
          } else {
            TotalUpstreamSizing.find((x) => x.key === upstreamSizingItem.name).value += upstreamSizingItem.percentage
          }
        }
      })
      if (item?.productionSizingSummary?.upstreamSizing !== null) {
        TotalUpstreamSizingCount++
        const findItem = varieties.find((varity) => varity.id === item.id_variety)
        setDataTable((pre) => ({
          ...pre,
          'LOC-UPS': { ...pre['LOC-UPS'], [findItem.name]: item?.productionSizingSummary?.upstreamSizing },
        }))
      }
      if (item?.productionSizingSummary?.inLineSizing !== null) {
        const TotalInLinePerVariety = { variety: item.id_variety }
        let TotalInlineCorrectSizing = 0
        let TotalInlineUnderSizing = 0
        let TotalInlineOverSizing = 0
        let TotalInLineSizingCount = 0

        item?.productionSizingSummary?.inLineSizing?.correct?.forEach((itemInlineCorrect, index) => {
          if (
            itemInlineCorrect.name !== 'Undersize' &&
            itemInlineCorrect.name !== 'Oversize' &&
            (item?.productionSizingSummary?.inLineSizing?.under[index]?.percentage !== 0 ||
              itemInlineCorrect?.percentage !== 0 ||
              item?.productionSizingSummary?.inLineSizing?.over[index]?.percentage !== 0)
          ) {
            TotalInLineSizingCount++
            TotalInlineCorrectSizing += itemInlineCorrect?.percentage || 0
            TotalInlineUnderSizing += item?.productionSizingSummary?.inLineSizing?.under[index]?.percentage || 0
            TotalInlineOverSizing += item?.productionSizingSummary?.inLineSizing?.over[index]?.percentage || 0
          }
          TotalInLinePerVariety.correct = TotalInlineCorrectSizing
          TotalInLinePerVariety.under = TotalInlineUnderSizing
          TotalInLinePerVariety.over = TotalInlineOverSizing
          TotalInLinePerVariety.count = TotalInLineSizingCount
        })
        TotalInLineSizing.push(TotalInLinePerVariety)
      }

      if (item?.productionSizingSummary?.finishBoxSizing !== null) {
        const TotalFinishBoxPerVariety = { variety: item.id_variety }
        let TotalFinishBoxCorrectSizing = 0
        let TotalFinishBoxUnderSizing = 0
        let TotalFinishBoxOverSizing = 0
        let TotalFinishedBoxSizingCount = 0

        item?.productionSizingSummary?.finishBoxSizing?.correct?.forEach((itemInlineCorrect, index) => {
          if (
            itemInlineCorrect.name !== 'Undersize' &&
            itemInlineCorrect.name !== 'Oversize' &&
            (item?.productionSizingSummary?.finishBoxSizing?.under[index]?.percentage !== 0 ||
              itemInlineCorrect?.percentage !== 0 ||
              item?.productionSizingSummary?.finishBoxSizing?.over[index]?.percentage !== 0)
          ) {
            TotalFinishedBoxSizingCount++
            TotalFinishBoxCorrectSizing += itemInlineCorrect?.percentage || 0
            TotalFinishBoxUnderSizing += item?.productionSizingSummary?.finishBoxSizing?.under[index]?.percentage || 0
            TotalFinishBoxOverSizing += item?.productionSizingSummary?.finishBoxSizing?.over[index]?.percentage || 0
          }
          TotalFinishBoxPerVariety.correct = TotalFinishBoxCorrectSizing
          TotalFinishBoxPerVariety.under = TotalFinishBoxUnderSizing
          TotalFinishBoxPerVariety.over = TotalFinishBoxOverSizing
          TotalFinishBoxPerVariety.count = TotalFinishedBoxSizingCount
        })
        TotalFinishedBoxSizing.push(TotalFinishBoxPerVariety)
      }
      if (item?.productionQualitySummary?.receivingQuality !== null) {
        TotalRecvingQuality.totalEpo += item?.productionQualitySummary?.receivingQuality?.estimatedPackOut || 0
        TotalRecvingQuality.totalMinDef += item?.productionQualitySummary?.receivingQuality?.minorDefect || 0
        TotalRecvingQuality.totalMajDef += item?.productionQualitySummary?.receivingQuality?.majorDefect || 0
        TotalRecvingQuality.totalFirm += item?.productionQualitySummary?.receivingQuality?.firmness || 0
        TotalRecvingQuality.totalBrix += item?.productionQualitySummary?.receivingQuality?.brix || 0
        TotalRecvingQuality.totalCount++
      }
      if (item?.productionQualitySummary?.upstreamQuality !== null) {
        TotalUpstreamQuality.totalEpo += item?.productionQualitySummary?.upstreamQuality?.estimatedPackOut || 0
        TotalUpstreamQuality.totalMinDef += item?.productionQualitySummary?.upstreamQuality?.minorDefect || 0
        TotalUpstreamQuality.totalMajDef += item?.productionQualitySummary?.upstreamQuality?.majorDefect || 0
        TotalUpstreamQuality.totalCount++
      }
      if (item?.productionQualitySummary?.inLineQuality !== null) {
        TotalInlineQuality.totalEpo += item?.productionQualitySummary?.inLineQuality?.estimatedPackOut || 0
        TotalInlineQuality.totalMinDef += item?.productionQualitySummary?.inLineQuality?.minorDefect || 0
        TotalInlineQuality.totalMajDef += item?.productionQualitySummary?.inLineQuality?.majorDefect || 0
        TotalInlineQuality.totalCount++
      }
      if (item?.productionQualitySummary?.finishBoxQuality !== null) {
        TotalFinishedBoxQuality.totalEpo += item?.productionQualitySummary?.finishBoxQuality?.estimatedPackOut || 0
        TotalFinishedBoxQuality.totalMinDef += item?.productionQualitySummary?.finishBoxQuality?.minorDefect || 0
        TotalFinishedBoxQuality.totalMajDef += item?.productionQualitySummary?.finishBoxQuality?.majorDefect || 0
        TotalFinishedBoxQuality.totalCount++
      }
      if (item?.productionQualitySummary?.goodInBadQuality !== null) {
        TotalGIBQuality.totalGoodInBad += item?.productionQualitySummary?.goodInBadQuality?.goodInBad || 0
        TotalGIBQuality.totalDobleSpurs += item?.productionQualitySummary?.goodInBadQuality?.dobleSpurs || 0
        TotalGIBQuality.totalCorrect += item?.productionQualitySummary?.goodInBadQuality?.correct || 0
        TotalGIBQuality.totalCount++
      }
      if (item?.productionQualitySummary?.peddlerQuality !== null) {
        TotalGIPQuality.totalGood += item?.productionQualitySummary?.peddlerQuality?.good || 0
        TotalGIPQuality.totalBad += item?.productionQualitySummary?.peddlerQuality?.bad || 0
        TotalGIPQuality.totalPeddler += item?.productionQualitySummary?.peddlerQuality?.peddler || 0
        TotalGIPQuality.totalCount++
      }
    })

    TotalRecvingSizing.forEach((item) => {
      item.value /= TotalRecvingSizingCount
    })
    setTotalRecvingSizing(TotalRecvingSizing)

    TotalUpstreamSizing.forEach((item) => {
      item.value /= TotalUpstreamSizingCount
    })
    setTotalUpstreamSizing(TotalUpstreamSizing)

    TotalInLineSizing.forEach((item) => {
      item.correct /= item.count
      item.under /= item.count
      item.over /= item.count
    })

    setTotalInLineSizing(TotalInLineSizing)

    TotalFinishedBoxSizing.forEach((item) => {
      item.correct /= item.count
      item.under /= item.count
      item.over /= item.count
    })
    setTotalFinishedBoxSizing(TotalFinishedBoxSizing)

    TotalRecvingQuality.totalEpo /= TotalRecvingQuality.totalCount
    TotalRecvingQuality.totalMinDef /= TotalRecvingQuality.totalCount
    TotalRecvingQuality.totalMajDef /= TotalRecvingQuality.totalCount
    TotalRecvingQuality.totalFirm /= TotalRecvingQuality.totalCount
    TotalRecvingQuality.totalBrix /= TotalRecvingQuality.totalCount
    setTotalRecvingQuality(TotalRecvingQuality)

    TotalUpstreamQuality.totalEpo /= TotalUpstreamQuality.totalCount
    TotalUpstreamQuality.totalMinDef /= TotalUpstreamQuality.totalCount
    TotalUpstreamQuality.totalMajDef /= TotalUpstreamQuality.totalCount
    setTotalUpstreamQuality(TotalUpstreamQuality)

    TotalInlineQuality.totalEpo /= TotalInlineQuality.totalCount
    TotalInlineQuality.totalMinDef /= TotalInlineQuality.totalCount
    TotalInlineQuality.totalMajDef /= TotalInlineQuality.totalCount
    setTotalInlineQuality(TotalInlineQuality)

    TotalFinishedBoxQuality.totalEpo /= TotalFinishedBoxQuality.totalCount
    TotalFinishedBoxQuality.totalMinDef /= TotalFinishedBoxQuality.totalCount
    TotalFinishedBoxQuality.totalMajDef /= TotalFinishedBoxQuality.totalCount
    setTotalFinishedBoxQuality(TotalFinishedBoxQuality)

    TotalGIBQuality.totalGoodInBad /= TotalGIBQuality.totalCount
    TotalGIBQuality.totalDobleSpurs /= TotalGIBQuality.totalCount
    TotalGIBQuality.totalCorrect /= TotalGIBQuality.totalCount
    setTotalGIBQuality(TotalGIBQuality)

    TotalGIPQuality.totalBad /= TotalGIPQuality.totalCount
    TotalGIPQuality.totalGood /= TotalGIPQuality.totalCount
    TotalGIPQuality.totalPeddler /= TotalGIPQuality.totalCount
    setTotalGIPQuality(TotalGIPQuality)
  }
  const printDocument = () => {
    if (productionSummaryData.length === 0) return
    if (sizeSum) {
      PdfMakerService.generateReport(`ProductionSizingSummary_${dateReport}`, 'ProductionSizingSummary', {
        productionSummaryData,
        totalRecvingSizing,
        totalUpstreamSizing,
        totalInLineSizing,
        totalFinishedBoxSizing,
        totalRecvingQuality,
        totalUpstreamQuality,
        totalInlineQuality,
        totalFinishedBoxQuality,
        totalGIBQuality,
        totalGIPQuality,
        varietiesName,
        dateReport,
        machineName,
        desTemplates,
      })
    } else {
      PdfMakerService.generateReport(`ProductionQualitySummary_${dateReport}`, 'ProductionQualitySummary', {
        productionSummaryData,
        totalRecvingSizing,
        totalUpstreamSizing,
        totalInLineSizing,
        totalFinishedBoxSizing,
        totalRecvingQuality,
        totalUpstreamQuality,
        totalInlineQuality,
        totalFinishedBoxQuality,
        totalGIBQuality,
        totalGIPQuality,
        varietiesName,
        dateReport,
        machineName,
        desTemplates,
      })
    }
  }

  const printfReportExcel = () => {
    if (sizeSum) {
      const receivingSizeExcelName = []
      const receivingSizeExcelHeader = []
      let receivingSizeExcelBody = []
      const receivingSizeExcelToTal = []

      const upstreamSizeExcelName = []
      const upstreamSizeExcelHeader = []
      let upstreamSizeExcelBody = []
      const upstreamSizeExcelToTal = []

      const inLineSizingExcelToTal = []

      const finishBoxSizingExcelToTal = []

      if (Object.keys(dataTable).length > 0 && Object.keys(dataTable['LOC-REC']).length > 0) {
        receivingSizeExcelName.push({
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `${desTemplates.TEMPLATE_LOC_REC || 'Receiving'} Size Distribution`,
        })

        const header = headerTable['LOC-REC']?.map((header) => {
          return {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: header,
          }
        })

        receivingSizeExcelHeader.push(
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: '',
          },
          ...header
        )
        receivingSizeExcelBody = Object.keys(dataTable['LOC-REC']).map((key) => {
          const body = headerTable['LOC-REC']?.map((headerName, i) => {
            const value = dataTable['LOC-REC'][key]?.find((item) => item?.name === headerName)
            if (value?.name !== 'Oversize') {
              return {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(((value?.percentage || 0) * 100).toFixed(2)),
              }
            }
            return null
          })
          return [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: key,
            },
            ...body,
          ]
        })

        receivingSizeExcelToTal.push({
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Total',
        })
        receivingSizeExcelToTal.push(
          ...totalRecvingSizing.map((item, index) => {
            return {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((item.value * 100).toFixed(2)),
            }
          })
        )
      }
      if (Object.keys(dataTable).length > 0 && Object.keys(dataTable['LOC-UPS']).length > 0) {
        upstreamSizeExcelName.push({
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: `${desTemplates.TEMPLATE_LOC_UPS || 'Upstream'} Size Distribution`,
        })

        const header = headerTable['LOC-UPS']?.map((header) => {
          return {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: header,
          }
        })
        upstreamSizeExcelHeader.push(
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: '',
          },
          ...header
        )
        upstreamSizeExcelBody = Object.keys(dataTable['LOC-UPS']).map((key) => {
          const body = headerTable['LOC-UPS']?.map((headerName, i) => {
            const value = dataTable['LOC-UPS'][key]?.find((item) => item?.name === headerName)
            if (value?.name !== 'Oversize') {
              return {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(((value?.percentage || 0) * 100).toFixed(2)),
              }
            }
            return null
          })
          return [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: key,
            },
            ...body,
          ]
        })

        upstreamSizeExcelToTal.push({
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Total',
        })
        upstreamSizeExcelToTal.push(
          ...totalUpstreamSizing.map((item, index) => {
            return {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((item.value * 100).toFixed(2)),
            }
          })
        )
      }

      productionSummaryData?.forEach((item, index) => {
        if (item?.productionSizingSummary?.inLineSizing !== null) {
          const body = []
          item?.productionSizingSummary?.inLineSizing?.correct?.forEach((itemCorrect, index) => {
            if (
              itemCorrect.name !== 'Undersize' &&
              itemCorrect.name !== 'Oversize' &&
              (item?.productionSizingSummary?.inLineSizing?.under[index]?.percentage !== 0 ||
                itemCorrect.percentage !== 0 ||
                item?.productionSizingSummary?.inLineSizing?.over[index]?.percentage !== 0)
            ) {
              body.push([
                {
                  t: 's',
                  s: { font: { bold: true, sz: 11 } },
                  v: itemCorrect.name,
                },
                {
                  t: 's',
                  s: { font: { sz: 11 } },
                  v: numberToPercentage(
                    ((item?.productionSizingSummary?.inLineSizing?.under[index]?.percentage || 0) * 100).toFixed(2)
                  ),
                },
                {
                  t: 's',
                  s: { font: { sz: 11 } },
                  v: numberToPercentage((itemCorrect.percentage * 100).toFixed(2)),
                },
                {
                  t: 's',
                  s: { font: { sz: 11 } },
                  v: numberToPercentage(
                    ((item?.productionSizingSummary?.inLineSizing?.over[index]?.percentage || 0) * 100).toFixed(2)
                  ),
                },
              ])
            }
          })

          inLineSizingExcelToTal.push(
            [
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `${desTemplates.TEMPLATE_LOC_INL || 'In-Line'} Size Accuracy ${varietiesName[index]}`,
              },
            ],
            [
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: ``,
              },
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `Under`,
              },
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `Correct`,
              },
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `Over`,
              },
            ],
            ...body,
            [
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `Total`,
              },
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `${numberToPercentage(
                  ((totalInLineSizing.find((x) => x.variety === item.id_variety).under || 0) * 100).toFixed(2)
                )}`,
              },
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `${numberToPercentage(
                  ((totalInLineSizing.find((x) => x.variety === item.id_variety).correct || 0) * 100).toFixed(2)
                )}`,
              },
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `${numberToPercentage(
                  ((totalInLineSizing.find((x) => x.variety === item.id_variety).over || 0) * 100).toFixed(2)
                )}`,
              },
            ],
            []
          )
        }
        return null
      })

      productionSummaryData?.forEach((item, index) => {
        if (item?.productionSizingSummary?.finishBoxSizing !== null) {
          const body = []
          item?.productionSizingSummary?.finishBoxSizing?.correct?.forEach((itemCorrect, index) => {
            if (
              itemCorrect.name !== 'Undersize' &&
              itemCorrect.name !== 'Oversize' &&
              (item?.productionSizingSummary?.finishBoxSizing?.under[index]?.percentage !== 0 ||
                itemCorrect.percentage !== 0 ||
                item?.productionSizingSummary?.finishBoxSizing?.over[index]?.percentage !== 0)
            ) {
              body.push([
                {
                  t: 's',
                  s: { font: { bold: true, sz: 11 } },
                  v: itemCorrect.name,
                },
                {
                  t: 's',
                  s: { font: { sz: 11 } },
                  v: numberToPercentage(
                    ((item?.productionSizingSummary?.finishBoxSizing?.under[index]?.percentage || 0) * 100).toFixed(2)
                  ),
                },
                {
                  t: 's',
                  s: { font: { sz: 11 } },
                  v: numberToPercentage((itemCorrect.percentage * 100).toFixed(2)),
                },
                {
                  t: 's',
                  s: { font: { sz: 11 } },
                  v: numberToPercentage(
                    ((item?.productionSizingSummary?.finishBoxSizing?.over[index]?.percentage || 0) * 100).toFixed(2)
                  ),
                },
              ])
            }
          })

          finishBoxSizingExcelToTal.push(
            [
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `${desTemplates.TEMPLATE_LOC_FIN || 'Finished Box'} Size Accuracy ${varietiesName[index]}`,
              },
            ],
            [
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: ``,
              },
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `Under`,
              },
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `Correct`,
              },
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `Over`,
              },
            ],
            ...body,
            [
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `Total`,
              },
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `${numberToPercentage(
                  ((totalFinishedBoxSizing.find((x) => x.variety === item.id_variety).under || 0) * 100).toFixed(2)
                )}`,
              },
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `${numberToPercentage(
                  ((totalFinishedBoxSizing.find((x) => x.variety === item.id_variety).correct || 0) * 100).toFixed(2)
                )}`,
              },
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: `${numberToPercentage(
                  ((totalFinishedBoxSizing.find((x) => x.variety === item.id_variety).over || 0) * 100).toFixed(2)
                )}`,
              },
            ],
            []
          )
        }
        return null
      })

      const dataExcel = [
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Production Sizing Summary',
          },
        ],
        [],
        receivingSizeExcelName,
        receivingSizeExcelHeader,
        ...receivingSizeExcelBody,
        receivingSizeExcelToTal,
        [],
        upstreamSizeExcelName,
        upstreamSizeExcelHeader,
        ...upstreamSizeExcelBody,
        upstreamSizeExcelToTal,
        [],
        ...inLineSizingExcelToTal,
        [],
        ...finishBoxSizingExcelToTal,
      ]

      const sizeDistribution = XLSXStyle.utils.aoa_to_sheet(dataExcel)
      const excelFile = XLSXStyle.utils.book_new()
      XLSXStyle.utils.book_append_sheet(excelFile, sizeDistribution, 'Production Sizing Summary')
      XLSXStyle.writeFile(excelFile, `ProductionSizingSummary_${dateReport}.xlsx`)
    } else {
      const productionQualityExcelToTal = []
      const upstreamQualityExcelToTal = []
      const inLineQualityExcelToTal = []
      const finishBoxQualityExcelToTal = []
      const GIBQualityExcelToTal = []
      const GIPQualityExcelToTal = []

      if (
        productionSummaryData.length > 0 &&
        productionSummaryData.find((x) => x.productionQualitySummary?.receivingQuality !== null)
      ) {
        const body = []
        productionSummaryData.forEach((item, index) => {
          if (item?.productionQualitySummary?.receivingQuality !== null) {
            body.push([
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: varietiesName[index],
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.receivingQuality?.estimatedPackOut || 0) * 100).toFixed(2)
                ),
              },

              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.receivingQuality?.minorDefect || 0) * 100).toFixed(2)
                ),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.receivingQuality?.majorDefect || 0) * 100).toFixed(2)
                ),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: (item?.productionQualitySummary?.receivingQuality?.firmness || 0).toFixed(2),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: (item?.productionQualitySummary?.receivingQuality?.brix || 0).toFixed(2),
              },
            ])
          }
          return null
        })

        productionQualityExcelToTal.push(
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `${desTemplates.TEMPLATE_LOC_REC || 'Receiving'} Quality`,
            },
          ],
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: ``,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Estimated Packout`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Minor Defect`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Major Defect`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Firmness`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Brix`,
            },
          ],
          ...body,
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Total',
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalRecvingQuality.totalEpo * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalRecvingQuality.totalMinDef * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalRecvingQuality.totalMajDef * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: (totalRecvingQuality.totalFirm || 0).toFixed(2),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: (totalRecvingQuality.totalBrix || 0).toFixed(2),
            },
          ]
        )
      }

      if (
        productionSummaryData.length > 0 &&
        productionSummaryData.find((x) => x.productionQualitySummary?.upstreamQuality !== null)
      ) {
        const body = []
        productionSummaryData.forEach((item, index) => {
          if (item?.productionQualitySummary?.upstreamQuality !== null) {
            body.push([
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: varietiesName[index],
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.upstreamQuality?.estimatedPackOut || 0) * 100).toFixed(2)
                ),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.upstreamQuality?.minorDefect || 0) * 100).toFixed(2)
                ),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.upstreamQuality?.majorDefect || 0) * 100).toFixed(2)
                ),
              },
            ])
          }
          return null
        })

        upstreamQualityExcelToTal.push(
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `${desTemplates.TEMPLATE_LOC_UPS || 'Upstream'} Quality`,
            },
          ],
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: ``,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Estimated Packout`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Minor Defect`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Major Defect`,
            },
          ],
          ...body,
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Total',
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalUpstreamQuality.totalEpo * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalUpstreamQuality.totalMinDef * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalUpstreamQuality.totalMajDef * 100).toFixed(2)),
            },
          ]
        )
      }

      if (
        productionSummaryData.length > 0 &&
        productionSummaryData.find((x) => x.productionQualitySummary?.inLineQuality !== null)
      ) {
        const body = []
        productionSummaryData.forEach((item, index) => {
          if (item?.productionQualitySummary?.inLineQuality !== null) {
            body.push([
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: varietiesName[index],
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.inLineQuality?.estimatedPackOut || 0) * 100).toFixed(2)
                ),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.inLineQuality?.minorDefect || 0) * 100).toFixed(2)
                ),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.inLineQuality?.majorDefect || 0) * 100).toFixed(2)
                ),
              },
            ])
          }
          return null
        })

        inLineQualityExcelToTal.push(
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `${desTemplates.TEMPLATE_LOC_INL || 'In-Line'} Quality`,
            },
          ],
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: ``,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Estimated Packout`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Minor Defect`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Major Defect`,
            },
          ],
          ...body,
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Total',
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalInlineQuality.totalEpo * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalInlineQuality.totalMinDef * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalInlineQuality.totalMajDef * 100).toFixed(2)),
            },
          ]
        )
      }

      if (
        productionSummaryData.length > 0 &&
        productionSummaryData.find((x) => x.productionQualitySummary?.finishBoxQuality !== null)
      ) {
        const body = []
        productionSummaryData.forEach((item, index) => {
          if (item?.productionQualitySummary?.finishBoxQuality !== null) {
            body.push([
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: varietiesName[index],
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.finishBoxQuality?.estimatedPackOut || 0) * 100).toFixed(2)
                ),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.finishBoxQuality?.minorDefect || 0) * 100).toFixed(2)
                ),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.finishBoxQuality?.majorDefect || 0) * 100).toFixed(2)
                ),
              },
            ])
          }
          return null
        })

        finishBoxQualityExcelToTal.push(
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `${desTemplates.TEMPLATE_LOC_FIN || 'Finished Box'} Quality`,
            },
          ],
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: ``,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Estimated Packout`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Minor Defect`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Major Defect`,
            },
          ],
          ...body,
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Total',
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalFinishedBoxQuality.totalEpo * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalFinishedBoxQuality.totalMinDef * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalFinishedBoxQuality.totalMajDef * 100).toFixed(2)),
            },
          ]
        )
      }

      if (
        productionSummaryData.length > 0 &&
        productionSummaryData.find((x) => x.productionQualitySummary?.goodInBadQuality !== null)
      ) {
        const body = []
        productionSummaryData.forEach((item, index) => {
          if (item?.productionQualitySummary?.goodInBadQuality !== null) {
            body.push([
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: varietiesName[index],
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.goodInBadQuality?.goodInBad || 0) * 100).toFixed(2)
                ),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.goodInBadQuality?.dobleSpurs || 0) * 100).toFixed(2)
                ),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.goodInBadQuality?.correct || 0) * 100).toFixed(2)
                ),
              },
            ])
          }
          return null
        })

        GIBQualityExcelToTal.push(
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Good In Bad`,
            },
          ],
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: ``,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `G.I.B%`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Doubles & Spurs %`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Correct %`,
            },
          ],
          ...body,
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Total',
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalGIBQuality.totalGoodInBad * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalGIBQuality.totalDobleSpurs * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalGIBQuality.totalCorrect * 100).toFixed(2)),
            },
          ]
        )
      }
      if (
        productionSummaryData.length > 0 &&
        productionSummaryData.find((x) => x.productionQualitySummary?.peddlerQuality != null)
      ) {
        const body = []
        productionSummaryData.forEach((item, index) => {
          if (item?.productionQualitySummary?.goodInBadQuality !== null) {
            body.push([
              {
                t: 's',
                s: { font: { bold: true, sz: 11 } },
                v: varietiesName[index],
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(((item?.productionQualitySummary?.peddlerQuality?.good || 0) * 100).toFixed(2)),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(((item?.productionQualitySummary?.peddlerQuality?.bad || 0) * 100).toFixed(2)),
              },
              {
                t: 's',
                s: { font: { sz: 11 } },
                v: numberToPercentage(
                  ((item?.productionQualitySummary?.peddlerQuality?.peddler || 0) * 100).toFixed(2)
                ),
              },
            ])
          }
          return null
        })
        GIPQualityExcelToTal.push(
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `${desTemplates.TEMPLATE_LOC_PED || 'Peddler'} Quality`,
            },
          ],
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: ``,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `G.I.P%`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `Bad %`,
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: `${desTemplates.TEMPLATE_LOC_PED || 'Peddler'} %`,
            },
          ],
          ...body,
          [
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: 'Total',
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalGIPQuality.totalGood * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalGIPQuality.totalBad * 100).toFixed(2)),
            },
            {
              t: 's',
              s: { font: { bold: true, sz: 11 } },
              v: numberToPercentage((totalGIPQuality.totalPeddler * 100).toFixed(2)),
            },
          ]
        )
      }

      const dataExcel = [
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Production Quality Summary',
          },
        ],
        ...productionQualityExcelToTal,
        [],
        ...upstreamQualityExcelToTal,
        [],
        ...inLineQualityExcelToTal,
        [],
        ...finishBoxQualityExcelToTal,
        [],
        ...GIBQualityExcelToTal,
        [],
        ...GIPQualityExcelToTal,
      ]

      const sizeDistribution = XLSXStyle.utils.aoa_to_sheet(dataExcel)
      const sizeDistributionCols = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }]
      sizeDistribution['!cols'] = sizeDistributionCols
      const excelFile = XLSXStyle.utils.book_new()
      XLSXStyle.utils.book_append_sheet(excelFile, sizeDistribution, 'Production Quality Summary')
      XLSXStyle.writeFile(excelFile, `ProductionQualitySummary_${dateReport}.xlsx`)
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <SearchableInput
            disabled={machineQuery.data.length === 0}
            value={machineQuery?.data?.find((machine) => machine.id === selectedMachine?.id) ?? 0}
            options={machineQuery.data}
            onChange={(event) => {
              const { value } = event.target
              const machine = machineQuery?.data?.find((x) => x.id === value)
              setMachineSelected(machine)
              setSelectedMachine(machine)
            }}
            label={t('machine')}
            id="id_machine"
            name="id_machine"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SingleDateTimePicker
            disabled={isLoading}
            style={{ width: '100%' }}
            label={t('start_date')}
            name="startDate"
            value={startDate}
            disableFuture
            onChange={(date) => {
              const startDate = DateTime.fromJSDate(date)
              setStartDateISODate(startDate.toISODate())
              setStartDate(startDate.toISO({ includeOffset: false }))
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SingleDateTimePicker
            disabled={isLoading}
            style={{ width: '100%' }}
            label={t('end_date')}
            name="endDate"
            value={endDate}
            disableFuture
            onChange={(date) => {
              const endDate = DateTime.fromJSDate(date)
              setEndDateISODate(endDate.toISODate())
              setEndDate(endDate.toISO({ includeOffset: false }))
            }}
          />
        </Grid>
        <Grid item xs={6} md={2} style={{ display: 'flex', alignItems: 'end' }}>
          <PrimaryButton variant="contained" disabled={isLoading} onClick={() => loadData()}>
            {t('filter')}
          </PrimaryButton>
        </Grid>
        <Grid item xs={6} md={1} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          <FormControlLabel
            control={<Switch checked={sizeSum} onChange={() => setSizeSum(!sizeSum)} name="sizeSum" color="primary" />}
            label={sizeSum ? t('sizing') : t('quality')}
          />
        </Grid>
        {!showReport ? null : <PrintButton printDocument={printDocument} printfReportExcel={printfReportExcel} />}
      </Grid>

      <Grid container id="report">
        {!showReport ? null : (
          <>
            {sizeSum ? (
              <>
                <Grid xs={12} item>
                  {machineSelected?.id > 0 ? (
                    <Typography align="right" variant="subtitle2">
                      Machine: {machineName}
                    </Typography>
                  ) : null}

                  <Typography align="right" variant="subtitle2">
                    {dateReport}
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <Typography align="center" variant="h6">
                    Production Sizing Summary
                  </Typography>
                </Grid>
                {Object.keys(dataTable).length > 0 && Object.keys(dataTable['LOC-REC']).length > 0 ? (
                  <Grid xs={12} item className={classes.grid}>
                    <Typography align="left" variant="h6">
                      {desTemplates.TEMPLATE_LOC_REC || 'Receiving'} Size Distribution
                    </Typography>
                    <TableContainer>
                      <Table size="small" key="Receiving Size Distribution">
                        <TableHead>
                          <TableRow>
                            <TableCell className={`${classes.tableCell}`} />
                            {headerTable['LOC-REC']?.map((header) => (
                              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                                {header}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.keys(dataTable['LOC-REC']).map((key) => (
                            <TableRow>
                              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{key}</TableCell>
                              {headerTable['LOC-REC']?.map((headerName, i) => {
                                const value = dataTable['LOC-REC'][key]?.find((item) => item?.name === headerName)
                                if (value?.name !== 'Oversize') {
                                  return (
                                    <TableCell
                                      key={`${i}-${value?.percentage}`}
                                      className={tableCellClassNames}
                                      align="center"
                                    >
                                      {numberToPercentage(((value?.percentage || 0) * 100).toFixed(2))}
                                    </TableCell>
                                  )
                                }
                                return null
                              })}
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Total</TableCell>
                            {totalRecvingSizing.map((item, index) => {
                              return (
                                <TableCell
                                  key={`${index}-${item.value}`}
                                  className={`${classes.tableCell} ${classes.tableCellLabel}`}
                                  align="center"
                                >
                                  {numberToPercentage((item.value * 100).toFixed(2))}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : (
                  <Grid xs={12} item className={classes.grid}>
                    <TableContainer>
                      <div className={classes.noDataContainer} />
                    </TableContainer>
                  </Grid>
                )}
                <Grid xs={12} item>
                  <Typography> </Typography>
                </Grid>
                {Object.keys(dataTable).length > 0 && Object.keys(dataTable['LOC-UPS']).length > 0 ? (
                  <Grid xs={12} item className={classes.grid}>
                    <Typography align="left" variant="h6">
                      {desTemplates.TEMPLATE_LOC_UPS || 'Upstream'} Size Distribution
                    </Typography>
                    <TableContainer>
                      <Table size="small" key="Upstream Size Distribution">
                        <TableHead>
                          <TableRow>
                            <TableCell className={`${classes.tableCell}`} />
                            {headerTable['LOC-UPS']?.map((header) => (
                              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                                {header}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.keys(dataTable['LOC-UPS']).map((key) => (
                            <TableRow>
                              <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{key}</TableCell>
                              {headerTable['LOC-UPS']?.map((headerName, i) => {
                                const value = dataTable['LOC-UPS'][key]?.find((item) => item.name === headerName)
                                if (value?.name !== 'Oversize') {
                                  return (
                                    <TableCell
                                      key={`${i}-${value?.percentage}`}
                                      className={tableCellClassNames}
                                      align="center"
                                    >
                                      {numberToPercentage(((value?.percentage || 0) * 100).toFixed(2))}
                                    </TableCell>
                                  )
                                }
                                return null
                              })}
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Total</TableCell>
                            {totalUpstreamSizing.map((item, index) => {
                              return (
                                <TableCell
                                  key={`${index}-${item.value}`}
                                  className={`${classes.tableCell} ${classes.tableCellLabel}`}
                                  align="center"
                                >
                                  {numberToPercentage((item.value * 100).toFixed(2))}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : (
                  <Grid xs={12} item className={classes.grid}>
                    <TableContainer>
                      <div className={classes.noDataContainer} />
                    </TableContainer>
                  </Grid>
                )}
                <Grid xs={12} item>
                  <Typography> </Typography>
                </Grid>
                {productionSummaryData?.map((item, index) => {
                  if (item?.productionSizingSummary?.inLineSizing !== null) {
                    return (
                      <Grid xs={12} md={6} xl={3} item className={classes.grid}>
                        <Typography align="left" variant="h6">
                          {desTemplates.TEMPLATE_LOC_INL || 'In-Line'} Size Accuracy {varietiesName[index]}
                        </Typography>
                        <TableContainer style={{ width: '98%' }}>
                          <Table size="small" key={`In-Line Size Accuracy ${varietiesName[index]}`}>
                            <TableBody>
                              <TableRow key={`In-Line Size Accuracy Row ${varietiesName[index]}`}>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}> </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  Under
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  Correct
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Over</TableCell>
                              </TableRow>
                              {item?.productionSizingSummary?.inLineSizing?.correct?.map((itemCorrect, index) => {
                                if (
                                  itemCorrect.name !== 'Undersize' &&
                                  itemCorrect.name !== 'Oversize' &&
                                  (item?.productionSizingSummary?.inLineSizing?.under[index]?.percentage !== 0 ||
                                    itemCorrect.percentage !== 0 ||
                                    item?.productionSizingSummary?.inLineSizing?.over[index]?.percentage !== 0)
                                ) {
                                  return (
                                    <TableRow key={`numberSamples-inLineSizing ${index}`}>
                                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                        {itemCorrect.name}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {numberToPercentage(
                                          (
                                            (item?.productionSizingSummary?.inLineSizing?.under[index]?.percentage ||
                                              0) * 100
                                          ).toFixed(2)
                                        )}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {numberToPercentage((itemCorrect.percentage * 100).toFixed(2))}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {numberToPercentage(
                                          (
                                            (item?.productionSizingSummary?.inLineSizing?.over[index]?.percentage ||
                                              0) * 100
                                          ).toFixed(2)
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )
                                }
                                return null
                              })}
                              <TableRow key="TotalInLineSizing">
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  Total
                                </TableCell>

                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {numberToPercentage(
                                    (
                                      (totalInLineSizing.find((x) => x.variety === item.id_variety).under || 0) * 100
                                    ).toFixed(2)
                                  )}
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {numberToPercentage(
                                    (
                                      (totalInLineSizing.find((x) => x.variety === item.id_variety).correct || 0) * 100
                                    ).toFixed(2)
                                  )}
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {numberToPercentage(
                                    (
                                      (totalInLineSizing.find((x) => x.variety === item.id_variety).over || 0) * 100
                                    ).toFixed(2)
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )
                  }
                  return null
                })}
                <Grid xs={12} item>
                  <Typography> </Typography>
                </Grid>
                {productionSummaryData?.map((item, index) => {
                  if (item?.productionSizingSummary?.finishBoxSizing !== null) {
                    return (
                      <Grid xs={12} md={6} xl={3} item className={classes.grid}>
                        <Typography align="left" variant="h6">
                          {desTemplates.TEMPLATE_LOC_FIN || 'Finished Box'} Size Accuracy {varietiesName[index]}
                        </Typography>
                        <TableContainer style={{ width: '98%' }}>
                          <Table size="small" key={`Finished Box Size Accuracy ${varietiesName[index]}`}>
                            <TableBody>
                              <TableRow key={`Finished Box Size Accuracy row ${varietiesName[index]}`}>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}> </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  Under
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  Correct
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Over</TableCell>
                              </TableRow>
                              {item?.productionSizingSummary?.finishBoxSizing?.correct?.map((itemCorrect, index) => {
                                if (
                                  itemCorrect.name !== 'Undersize' &&
                                  itemCorrect.name !== 'Oversize' &&
                                  (item?.productionSizingSummary?.finishBoxSizing?.under[index]?.percentage !== 0 ||
                                    itemCorrect.percentage !== 0 ||
                                    item?.productionSizingSummary?.finishBoxSizing?.over[index]?.percentage !== 0)
                                ) {
                                  return (
                                    <TableRow key={`numberSamples-finishBoxSizing ${index}`}>
                                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                        {itemCorrect.name}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {numberToPercentage(
                                          (
                                            (item?.productionSizingSummary?.finishBoxSizing?.under[index]?.percentage ||
                                              0) * 100
                                          ).toFixed(2)
                                        )}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {numberToPercentage((itemCorrect.percentage * 100).toFixed(2))}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {numberToPercentage(
                                          (
                                            (item?.productionSizingSummary?.finishBoxSizing?.over[index]?.percentage ||
                                              0) * 100
                                          ).toFixed(2)
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )
                                }
                                return null
                              })}
                              <TableRow key="TotalFinishedBoxSizing">
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  Total
                                </TableCell>

                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {numberToPercentage(
                                    (
                                      (totalFinishedBoxSizing.find((x) => x.variety === item.id_variety).under || 0) *
                                      100
                                    ).toFixed(2)
                                  )}
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {numberToPercentage(
                                    (
                                      (totalFinishedBoxSizing.find((x) => x.variety === item.id_variety).correct || 0) *
                                      100
                                    ).toFixed(2)
                                  )}
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                  {numberToPercentage(
                                    (
                                      (totalFinishedBoxSizing.find((x) => x.variety === item.id_variety).over || 0) *
                                      100
                                    ).toFixed(2)
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )
                  }
                  return null
                })}
              </>
            ) : (
              <>
                <Grid xs={12} item>
                  {machineSelected?.id > 0 ? (
                    <Typography align="right" variant="subtitle2">
                      Machine: {machineName}
                    </Typography>
                  ) : null}

                  <Typography align="right" variant="subtitle2">
                    {dateReport}
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <Typography align="center" variant="h6">
                    Production Quality Summary
                  </Typography>
                </Grid>
                {productionSummaryData.length > 0 &&
                productionSummaryData.find((x) => x.productionQualitySummary?.receivingQuality != null) ? (
                  <Grid xs={12} item className={classes.grid}>
                    <Typography align="left" variant="h6">
                      {desTemplates.TEMPLATE_LOC_REC || 'Receiving'} Quality
                    </Typography>
                    <TableContainer>
                      <Table size="small" key="Receiving Quality">
                        <TableHead>
                          <TableRow key="Receiving Quality Header">
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}> </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              Estimated Packout
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              Minor Defect
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              Major Defect
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Firmness</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Brix</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productionSummaryData.map((item, index) => {
                            if (item?.productionQualitySummary?.receivingQuality !== null) {
                              return (
                                <TableRow key={`Receiving Quality Row ${index} `}>
                                  <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                    {varietiesName[index]}
                                  </TableCell>

                                  <TableCell
                                    key={`estimatedPackOut${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      (
                                        (item?.productionQualitySummary?.receivingQuality?.estimatedPackOut || 0) * 100
                                      ).toFixed(2)
                                    )}
                                  </TableCell>
                                  <TableCell
                                    key={`minorDefect${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      (
                                        (item?.productionQualitySummary?.receivingQuality?.minorDefect || 0) * 100
                                      ).toFixed(2)
                                    )}
                                  </TableCell>
                                  <TableCell
                                    key={`majorDefect${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      (
                                        (item?.productionQualitySummary?.receivingQuality?.majorDefect || 0) * 100
                                      ).toFixed(2)
                                    )}
                                  </TableCell>
                                  <TableCell
                                    key={`firmness${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {(item?.productionQualitySummary?.receivingQuality?.firmness || 0).toFixed(2)}
                                  </TableCell>
                                  <TableCell
                                    key={`brix${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {(item?.productionQualitySummary?.receivingQuality?.brix || 0).toFixed(2)}
                                  </TableCell>
                                </TableRow>
                              )
                            }
                            return null
                          })}
                          <TableRow>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Total</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalRecvingQuality.totalEpo * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalRecvingQuality.totalMinDef * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalRecvingQuality.totalMajDef * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {(totalRecvingQuality.totalFirm || 0).toFixed(2)}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {(totalRecvingQuality.totalBrix || 0).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : (
                  <Grid xs={12} item className={classes.grid}>
                    <TableContainer>
                      <div className={classes.noDataContainer} />
                    </TableContainer>
                  </Grid>
                )}
                {productionSummaryData.length > 0 &&
                productionSummaryData.find((x) => x.productionQualitySummary?.upstreamQuality !== null) ? (
                  <Grid xs={12} md={12} item className={classes.grid}>
                    <Typography align="left" variant="h6">
                      {desTemplates.TEMPLATE_LOC_UPS || 'Upstream'} Quality
                    </Typography>
                    <TableContainer style={{ width: '98%' }}>
                      <Table key="Upstream Quality">
                        <TableHead>
                          <TableRow key="UpstreamQualityHeader">
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}> </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              Estimated Packout
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              Minor Defect
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              Major Defect
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productionSummaryData.map((item, index) => {
                            if (item?.productionQualitySummary?.upstreamQuality !== null) {
                              return (
                                <TableRow key={`UpstreamQualityRow${varietiesName[index]}`}>
                                  <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                    {varietiesName[index]}
                                  </TableCell>

                                  <TableCell
                                    key={`UpstreamestimatedPackOut${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      (
                                        (item?.productionQualitySummary?.upstreamQuality?.estimatedPackOut || 0) * 100
                                      ).toFixed(2)
                                    )}
                                  </TableCell>
                                  <TableCell
                                    key={`UpstreamminorDefect${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      (
                                        (item?.productionQualitySummary?.upstreamQuality?.minorDefect || 0) * 100
                                      ).toFixed(2)
                                    )}
                                  </TableCell>
                                  <TableCell
                                    key={`UpstreammajorDefect${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      (
                                        (item?.productionQualitySummary?.upstreamQuality?.majorDefect || 0) * 100
                                      ).toFixed(2)
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            }
                            return null
                          })}

                          <TableRow>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Total</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalUpstreamQuality.totalEpo * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalUpstreamQuality.totalMinDef * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalUpstreamQuality.totalMajDef * 100).toFixed(2))}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : (
                  <Grid xs={12} item className={classes.grid}>
                    <TableContainer>
                      <div />
                    </TableContainer>
                  </Grid>
                )}
                {productionSummaryData.length > 0 &&
                productionSummaryData.find((x) => x.productionQualitySummary?.inLineQuality != null) ? (
                  <Grid xs={12} md={6} xl={4} item className={classes.grid}>
                    <Typography align="left" variant="h6">
                      {desTemplates.TEMPLATE_LOC_INL || 'In-Line'} Quality
                    </Typography>
                    <TableContainer style={{ width: '98%' }}>
                      <Table key="In-Line Quality">
                        <TableHead>
                          <TableRow key="In-LineHeader">
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}> </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Correct</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              Minor Defect
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              Major Defect
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productionSummaryData.map((item, index) => {
                            if (item?.productionQualitySummary?.inLineQuality !== null) {
                              return (
                                <TableRow key={`InlineQuality ${varietiesName[index]}`}>
                                  <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                    {varietiesName[index]}
                                  </TableCell>

                                  <TableCell
                                    key={`InlineQualityestimatedPackOut${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      (
                                        (item?.productionQualitySummary?.inLineQuality?.estimatedPackOut || 0) * 100
                                      ).toFixed(2)
                                    )}
                                  </TableCell>
                                  <TableCell
                                    key={`InlineQualityminorDefect${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      ((item?.productionQualitySummary?.inLineQuality?.minorDefect || 0) * 100).toFixed(
                                        2
                                      )
                                    )}
                                  </TableCell>
                                  <TableCell
                                    key={`InlineQualitymajorDefect${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      ((item?.productionQualitySummary?.inLineQuality?.majorDefect || 0) * 100).toFixed(
                                        2
                                      )
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            }
                            return null
                          })}
                          <TableRow>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Total</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalInlineQuality.totalEpo * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalInlineQuality.totalMinDef * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalInlineQuality.totalMajDef * 100).toFixed(2))}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : (
                  <Grid xs={12} item className={classes.grid}>
                    <TableContainer>
                      <div className={classes.noDataContainer} />
                    </TableContainer>
                  </Grid>
                )}
                {productionSummaryData.length > 0 &&
                productionSummaryData.find((x) => x.productionQualitySummary?.finishBoxQuality != null) ? (
                  <Grid xs={12} md={6} xl={4} item className={classes.grid}>
                    <Typography align="left" variant="h6">
                      {desTemplates.TEMPLATE_LOC_FIN || 'Finished Box'} Quality
                    </Typography>
                    <TableContainer style={{ width: '98%' }}>
                      <Table key="Finished Box Quality">
                        <TableHead>
                          <TableRow key="FinishedBoxQualityHeader">
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}> </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Correct</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              Minor Defect
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              Major Defect
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productionSummaryData.map((item, index) => {
                            if (item?.productionQualitySummary?.finishBoxQuality !== null) {
                              return (
                                <TableRow key={`finishBoxQuality${varietiesName[index]}`}>
                                  <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                    {varietiesName[index]}
                                  </TableCell>

                                  <TableCell
                                    key={`finishBoxQualityestimatedPackOut${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      (
                                        (item?.productionQualitySummary?.finishBoxQuality?.estimatedPackOut || 0) * 100
                                      ).toFixed(2)
                                    )}
                                  </TableCell>
                                  <TableCell
                                    key={`finishBoxQualityminorDefect${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      (
                                        (item?.productionQualitySummary?.finishBoxQuality?.minorDefect || 0) * 100
                                      ).toFixed(2)
                                    )}
                                  </TableCell>
                                  <TableCell
                                    key={`finishBoxQualitymajorDefect${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      (
                                        (item?.productionQualitySummary?.finishBoxQuality?.majorDefect || 0) * 100
                                      ).toFixed(2)
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            }
                            return null
                          })}
                          <TableRow>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Total</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalFinishedBoxQuality.totalEpo * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalFinishedBoxQuality.totalMinDef * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalFinishedBoxQuality.totalMajDef * 100).toFixed(2))}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : (
                  <Grid xs={12} item className={classes.grid}>
                    <TableContainer>
                      <div className={classes.noDataContainer} />
                    </TableContainer>
                  </Grid>
                )}
                {productionSummaryData.length > 0 &&
                productionSummaryData.find((x) => x.productionQualitySummary?.goodInBadQuality != null) ? (
                  <Grid xs={12} md={6} xl={4} item className={classes.grid}>
                    <Typography align="left" variant="h6">
                      Good In Bad
                    </Typography>
                    <TableContainer style={{ width: '98%' }}>
                      <Table key="Good In Bad">
                        <TableHead>
                          <TableRow key="goodInBadQualityHeader">
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}> </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>G.I.B%</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              Doubles & Spurs %
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              Correct %
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productionSummaryData.map((item, index) => {
                            if (item?.productionQualitySummary?.goodInBadQuality !== null) {
                              return (
                                <TableRow key={`goodInBadQuality ${varietiesName[index]}`}>
                                  <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                    {varietiesName[index]}
                                  </TableCell>

                                  <TableCell
                                    key={`goodInBadQuality goodInBad ${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      (
                                        (item?.productionQualitySummary?.goodInBadQuality?.goodInBad || 0) * 100
                                      ).toFixed(2)
                                    )}
                                  </TableCell>
                                  <TableCell
                                    key={`goodInBadQuality dobleSpurs ${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      (
                                        (item?.productionQualitySummary?.goodInBadQuality?.dobleSpurs || 0) * 100
                                      ).toFixed(2)
                                    )}
                                  </TableCell>
                                  <TableCell
                                    key={`goodInBadQuality correct${varietiesName[index]}`}
                                    className={tableCellClassNames}
                                    align="center"
                                  >
                                    {numberToPercentage(
                                      ((item?.productionQualitySummary?.goodInBadQuality?.correct || 0) * 100).toFixed(
                                        2
                                      )
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            }
                            return null
                          })}
                          <TableRow>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Total</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalGIBQuality.totalGoodInBad * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalGIBQuality.totalDobleSpurs * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalGIBQuality.totalCorrect * 100).toFixed(2))}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : (
                  <Grid xs={12} item className={classes.grid}>
                    <TableContainer>
                      <div className={classes.noDataContainer} />
                    </TableContainer>
                  </Grid>
                )}
                {productionSummaryData.length > 0 &&
                productionSummaryData.find((x) => x.productionQualitySummary?.peddlerQuality != null) ? (
                  <Grid xs={12} md={6} xl={4} item className={classes.grid}>
                    <Typography align="left" variant="h6">
                      {desTemplates.TEMPLATE_LOC_PED || 'Peddler'} Quality
                    </Typography>
                    <TableContainer style={{ width: '98%' }}>
                      <Table key="Peddler Quality">
                        <TableHead>
                          <TableRow key="">
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}> </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>G.I.P%</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Bad %</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                              {desTemplates.TEMPLATE_LOC_PED || 'Peddler'} %
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productionSummaryData.map((item, index) => {
                            if (item?.productionQualitySummary?.peddlerQuality !== null) {
                              return (
                                <TableRow>
                                  <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>
                                    {varietiesName[index]}
                                  </TableCell>

                                  <TableCell key={`${index}`} className={tableCellClassNames} align="center">
                                    {numberToPercentage(
                                      ((item?.productionQualitySummary?.peddlerQuality?.good || 0) * 100).toFixed(2)
                                    )}
                                  </TableCell>
                                  <TableCell key={`${index}`} className={tableCellClassNames} align="center">
                                    {numberToPercentage(
                                      ((item?.productionQualitySummary?.peddlerQuality?.bad || 0) * 100).toFixed(2)
                                    )}
                                  </TableCell>
                                  <TableCell key={`${index}`} className={tableCellClassNames} align="center">
                                    {numberToPercentage(
                                      ((item?.productionQualitySummary?.peddlerQuality?.peddler || 0) * 100).toFixed(2)
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            }
                            return null
                          })}
                          <TableRow>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Total</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalGIPQuality.totalGood * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalGIPQuality.totalBad * 100).toFixed(2))}
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
                              {numberToPercentage((totalGIPQuality.totalPeddler * 100).toFixed(2))}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : (
                  <Grid xs={12} item className={classes.grid}>
                    <TableContainer>
                      <div className={classes.noDataContainer} />
                    </TableContainer>
                  </Grid>
                )}
              </>
            )}
            <div />
          </>
        )}
        <SimpleBackdrop open={showBackdrop} />
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => ({
  selectedLot: state.reports.selectedLot,
  selectedLotName: state.reports.selectedLotName,
  selectedVariety: state.reports.selectedVariety,
  selectedVarietyName: state.reports.selectedVarietyName,
  selectedGrowerName: state.reports.selectedGrowerName,
  selectedStartDate: state.reports.selectedStartDate,
  selectedEndDate: state.reports.selectedEndDate,
  currentFacility: state.facility.currentFacility,
  currentMachine: state.machine,
  selectedMachine: state.reports.selectedMachine,
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedMachine: (machine) => dispatch(reportsSlice.actions.updateSelectedMachine(machine)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductionSummary)
