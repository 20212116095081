import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  rightGrid: {
    backgroundColor: '#fff',
  },
  paper: {
    marginRight: '20%',
    marginLeft: '20%',
    margin: theme.spacing(8, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  headerShadow: {
    boxShadow: 'inset 1px -6px 35px -20px !important',
  },
  goBackButton: {
    float: 'right',
  },
}))

export default useStyles
