import { makeStyles } from '@material-ui/core/styles'
import { DRAWER_WIDTH } from '../../shared/constants/general'

const useStyles = makeStyles((theme) => ({
  buenaFrutaBold: {
    fontFamily: '"Futura"',
    letterSpacing: -1,
    fontWeight: 600,
  },
  buenaFruta: {
    fontFamily: '"Futura"',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    marginLeft: '65px',
    [theme.breakpoints.down('xs')]: {
      width: '220px',
      marginLeft: '0',
    },
  },
  drawerTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 3),
    justifyContent: 'flex-start',
  },
  drawerLogo: {
    display: 'none',
    height: '50px',
    width: '50px',
    '@media (max-width: 600px)': {
      display: 'block',
    },
  },
  drawerItemHover: {
    backgroundColor: 'rgba(0, 0, 0, 0.04);',
    textDecoration: 'none',
  },
  listColor: {
    color: '#b2b7c4',
  },
  selectedListColor: {
    color: '#2a3858',
  },
}))

export default useStyles
