import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const SingleDatePicker = React.memo(({ ...props }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        format={props.format}
        maxDate={props.maxDate}
        minDate={props.minDate}
        disabled={props.disabled ? props.disabled : false}
        size="small"
        margin="normal"
        disableFuture={props.disableFuture}
        views={props.views}
        label={props.label}
        name={props.name}
        inputVariant="outlined"
        style={props.style}
        value={props.value}
        onChange={props.onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <Icon>calendar_today</Icon>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  )
})

export default SingleDatePicker
