import { createSlice } from '@reduxjs/toolkit'

export default createSlice({
  name: 'dashboard',
  initialState: {
    mode: true,
    sizeDistributionVal: '1',
    sizeResultsVal: '1',
    message: {},
    marketing: {},
  },
  reducers: {
    updateCurrentDashboardData: (state, action) => {
      state.message = action.payload
    },
    modeChangeAction: (state) => {
      state.mode = !state.mode
    },
    sizeDistributionAction: (state, action) => {
      state.sizeDistributionVal = action.payload
    },
    sizeResultsAction: (state, action) => {
      state.sizeResultsVal = action.payload
    },
    setCurrentMarketingAction: (state, action) => {
      state.marketing = action.payload
    },
  },
})
