import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  Select,
  MenuItem,
  Button,
  OutlinedInput,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Box,
  Typography,
  Switch,
} from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import { useSnackbar } from 'notistack'

export default function ActionForm(props) {
  const { t, i18n } = useTranslation()
  const typeSelection = [
    { id: 1, name: 'SendWebAppNotify', description: t('send_web_app_notify'), isChecked: false },
    { id: 2, name: 'SendEmailNotify', description: t('send_email_notify_in_future'), isChecked: true },
  ]

  const { rowSelected, userId, submitForm, isNext, setHandleNext, openDialog } = { ...props }
  const [rowData, setRowData] = useState(rowSelected)
  const [rows, setRows] = useState(rowSelected?.actions || [])
  const [typeSelector, setTypeSelector] = useState(typeSelection)
  const { enqueueSnackbar } = useSnackbar()

  const handleChange = (id, name, value) => {
    rows.forEach((x) => {
      if (x.id === id) {
        const a = { [name]: value }
        const returnedTarget = Object.assign(x, a)
        setRowData({
          ...rowData,
          returnedTarget,
        })
      }
    })
  }

  useEffect(async () => {
    setTypeSelector([
      {
        id: 1,
        name: 'SendWebAppNotify',
        description: typeSelector[0].description,
        isChecked: typeSelection[0].isChecked,
      },
      {
        id: 2,
        name: 'SendEmailNotify',
        description: typeSelector[1].description,
        isChecked: typeSelection[1].isChecked,
      },
    ])
  }, [i18n.language])

  useEffect(async () => {
    setTypeSelector([
      { id: 1, name: 'SendWebAppNotify', description: typeSelector[0].description, isChecked: false },
      { id: 2, name: 'SendEmailNotify', description: typeSelector[1].description, isChecked: true },
    ])
  }, [openDialog])

  useEffect(async () => {
    rows.forEach((x) => {
      const id = x.type
      const newrows = typeSelector.map((el) => el)
      const index = newrows.map((e) => e.id).indexOf(id)
      if (index > -1) {
        newrows[index].isChecked = true
      }
      setTypeSelector(newrows)
    })
  }, [rows])

  useEffect(async () => {
    if (isNext) {
      if (rows.length > 0) {
        await submitForm('actions', rows)
        setHandleNext(true)
      } else {
        enqueueSnackbar(t('please_add_action_to_config_alert'), { variant: 'warning' })
        setHandleNext(false)
      }
    }
  }, [isNext, rows])

  const makeId = (length) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
      counter += 1
    }
    return result
  }

  const handleDelete = (rowId, params) => {
    const newSelector = typeSelector.map((el) => el)
    const indexSelector = newSelector.map((e) => e.id).indexOf(params.row.type)
    if (indexSelector > -1) {
      newSelector[indexSelector].isChecked = false
    }
    setTypeSelector(newSelector)

    const newrows = rows.map((el) => el)
    const index = newrows.map((e) => e.id).indexOf(rowId)
    if (index > -1) {
      newrows.splice(index, 1)
    }
    setRows(newrows)
  }

  const columns = [
    {
      field: 'value',
      headerName: t('message'),
      flex: 1,

      renderCell: (params) => {
        return (
          <TextField
            variant="outlined"
            type="text"
            id="value"
            fullWidth
            defaultValue={params?.value}
            required
            onKeyDown={(event) => {
              event.stopPropagation()
            }}
            onChange={(event) => {
              handleChange(params.id, params.field, event.target.value)
            }}
          />
        )
      },
    },
    {
      field: 'type',
      headerName: t('type'),
      width: 315,
      renderCell: (params) => {
        return (
          <FormControl fullWidth size="small" variant="outlined" margin="none">
            <Select
              id="demo-simple-select"
              value={params.value}
              onChange={(event) => {
                handleChange(params.id, params.field, event.target.value)
                const newrows = typeSelector.map((el) => el)
                let index = newrows.map((e) => e.id).indexOf(params.value)
                if (index > -1) {
                  newrows[index].isChecked = false
                }
                index = newrows.map((e) => e.id).indexOf(event.target.value)
                if (index > -1) {
                  newrows[index].isChecked = true
                }
                setTypeSelector(newrows)
                event.stopPropagation()
              }}
              required={params.value === 0}
            >
              {typeSelector.map((item) => (
                <MenuItem disabled={item.name === 'SendEmailNotify'} key={`type-${item.id}`} value={item.id}>
                  {item.description ? item.description : item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      },
    },
    {
      field: 'disabled',
      headerName: t('active'),
      width: 175,
      renderCell: (params) => (
        <div className="flex">
          <FormControl margin="none" variant="outlined" fullWidth>
            <Grid container alignItems="center">
              <Grid item>
                <Switch
                  checked={!params.value}
                  onChange={() => handleChange(params.id, params.field, !params.value)}
                  name="statusCheckbox"
                />
              </Grid>
            </Grid>
          </FormControl>
        </div>
      ),
    },
    {
      field: 'id',
      headerName: t('action'),
      width: 115,
      renderCell: (params) => {
        return <Button color="primary" startIcon={<DeleteIcon />} onClick={() => handleDelete(params.id, params)} />
      },
    },
  ]
  useEffect(async () => {
    if (rowSelected.action) {
      const newRows = rows.map((x) => {
        return { ...x }
      })

      rowSelected.action.forEach((item) => {
        const index = rows.findIndex((x) => x.id === item.id)
        newRows[index] = item
      })
      setRows(newRows)
    }
  }, [])

  const handleAddRow = () => {
    setRows((prevRows) => [...prevRows, { value: '', type: 1, disabled: false, id: makeId(5) }])
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t('action_alert_config')}
      </Typography>
      <Grid container spacing={3}>
        <Button disabled={rows.length === 1} color="primary" startIcon={<AddIcon />} onClick={handleAddRow}>
          {t('add_action')}
        </Button>
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} hideFooterPagination disableSelectionOnClick />
        </Box>
      </Grid>
    </>
  )
}
