import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: 240,
    fontSize: '14px',
  },
  formControl: {
    margin: `0 ${theme.spacing(1)}`,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}))

export default useStyles
