import { createSlice } from '@reduxjs/toolkit'

export default createSlice({
  name: 'notificationPusher',
  initialState: null,
  reducers: {
    setCurrentNotificationAction: (state, action) => {
      return {
        ...action.payload,
      }
    },
  },
})
