import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  boxImages: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    width: '50%',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
      gap: '5px',
      margin: '0 -8px',
    },
  },
  boxImage: {
    border: 'solid 1px lightgray',
    padding: '0 5px 5px',
    borderRadius: '5px',
    height: 'fit-content',
  },
  boxTemplate: {
    display: 'flex',
    justifyContent: 'center',
    '& div.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  img: {
    borderRadius: '4px',
    width: '130px',
    height: '200px',
    objectFit: 'fill',
    '@media (max-width: 600px)': {
      width: '80px',
      height: '140px',
    },
  },
  titleImage: {
    display: 'table',
    margin: '-7px 0 auto',
    backgroundColor: '#FFF',
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '0 30px',
    '@media (max-width: 600px)': {
      padding: '0 10px',
    },
  },
})

export default useStyles
