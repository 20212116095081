import { QueryClient } from 'react-query'

// config useQuery
export const queryClientSettings = new QueryClient({
  defaultOptions: {
    queries: {
      // Enable refresh call api when focus window
      refetchOnWindowFocus: false,
    },
  },
})
