import { DateTime } from 'luxon'

/**
 * @enum {string}
 */
export const TIME_OF_UNIT = {
  DAY: 'Day',
  WEEK: 'Week',
  SEASON: 'Season',
}

/**
 * Receive minutes and convert to readable format.
 * @param {number} minutes
 * @returns {string} - minutes in '{hours} hrs. ${minutes} mins.' format.
 */
export const convertMinutesToHoursAndMinutes = (minutes) => {
  const hours = Math.floor(minutes / 60)
  return `${hours} hrs. ${minutes % 60} mins.`
}

export const convertLocalDateToUTCDate = (dateStr) => {
  const date = new Date(dateStr)
  const now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
  return date
}

/**
 * Receive a startDate of TimeUnit.
 * @param {'Day'| 'Week' | 'Season'} timeOfUnit
 * @returns {DateTime} from
 */
export const getDateFromByTimeOfUnit = (timeOfUnit = TIME_OF_UNIT.DAY) => {
  let from
  const now = DateTime.now()
  switch (timeOfUnit) {
    case TIME_OF_UNIT.DAY:
      from = now.startOf('day')
      break
    case TIME_OF_UNIT.WEEK:
      from = now.minus({ days: 6 })
      break
    case TIME_OF_UNIT.SEASON:
      from = now.startOf('year')
      break
    default:
      from = now.startOf('day')
  }
  return from
}
