import { useSnackbar } from 'notistack'
import { getHttpInstance } from '../../services/request/http-instance'

import { GET_METHOD } from '../../shared/constants/requests'

let interceptorId = null
const HttpAlert = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()

  const handleResposneSuccess = (res) => {
    const { method } = res.config
    const resposeMessage = res.data?.message
    if (method.toLowerCase() !== GET_METHOD.toLocaleLowerCase() && resposeMessage) {
      enqueueSnackbar(resposeMessage, { variant: 'success' })
    }
    return res
  }

  const handleResponseError = (err) => {
    const { method } = err.config
    const errorMessage = err.response?.data.message || err.response?.data?.error || err.message

    if (method.toLowerCase() !== GET_METHOD.toLocaleLowerCase() && errorMessage) {
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }

    return Promise.reject(err)
  }

  if (interceptorId == null) {
    const httpInstance = getHttpInstance()
    const httpInstanceBlank = getHttpInstance(true)
    httpInstanceBlank.interceptors.response.use(null, handleResponseError)
    interceptorId = httpInstance.interceptors.response.use(handleResposneSuccess, handleResponseError)
  }

  return children
}

export default HttpAlert
