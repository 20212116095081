import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  currentLot: { lot_number: '', lot_name: '', id_variety: '', variety: '' },
  savedLot: { lot_number: '', lot_name: '', id_variety: '', variety: '' },
  currentLotTime: { id_facility: 0, id_lot: 0, id_variety: 0 },
}

export default createSlice({
  name: 'lot',
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentLotAction: (state, action) => {
      state.currentLot = action.payload
    },
    setSavedLotAction: (state, action) => {
      state.savedLot = action.payload
    },
    resetLotStateAction: () => {
      return INITIAL_STATE
    },
    setCurrentLotTimeAction: (state, action) => {
      state.currentLotTime = action.payload
    },
  },
})
