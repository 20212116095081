import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@material-ui/core/Card'
import { connect } from 'react-redux'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import { DataGrid } from '@material-ui/data-grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Fade } from '@material-ui/core'

import SearchBar from '../SearchBar/SearchBar.component'
import PrimaryButton from '../button/button.component'
import useStyles from './catalog-grid.styles'
import { ROW_PER_PAGE_OPTIONS } from '../../shared/constants/table'
import { searchFullColumns } from '../../shared/utils/utils'
import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'

const CatalogGrid = ({
  rows,
  columns,
  onToggle,
  toggleValue,
  header,
  haveSearchInput,
  disableNew,
  onNew,
  filter,
  filterValue,
  filterChange,
  filterValues,
  pageSize,
  onPageSizeChange,
  loading,
  isExport,
  userEmail,
  printfReportExcel,
}) => {
  const classes = useStyles()
  const [showToggle, setShowToggle] = useState(false)
  const [rowData, setRowData] = useState([])
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const handleChangeSearch = (event) => {
    const text = event.target.value
    if (rows && rows.length) {
      searchFullColumns(rows, text, setRowData, columns)
    }
  }

  const handleClickMenu = (event) => {
    console.log(event)
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setShowToggle(onToggle && toggleValue !== undefined)
  }, [toggleValue])

  useEffect(() => {
    setRowData(rows)
  }, [rows])

  return (
    <div>
      <Grid container className={classes.topRow}>
        <Grid container>
          {showToggle ? (
            <Grid item xs={12} sm={6}>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography variant="button" className={classes.switchText}>
                    {t('show_all')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Switch checked={toggleValue} onChange={onToggle} name="checkedC" />
                </Grid>
                <Grid item>
                  <Typography variant="button" className={classes.switchText}>
                    {t('show_active')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            sm={showToggle ? 6 : 12}
            style={{
              textAlign: 'right',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            {haveSearchInput && <SearchBar onChange={handleChangeSearch} />}
            {!disableNew && (
              <PrimaryButton className={classes.buttonAddNew} onClick={onNew}>
                {t('add_new')}
              </PrimaryButton>
            )}
            {isExport && (
              <Grid item style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }}>
                <IconButton
                  aria-label="more"
                  id="menu-button"
                  aria-controls={openMenu ? 'menu' : undefined}
                  aria-expanded={openMenu ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClickMenu}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  TransitionComponent={Fade}
                >
                  <MenuItem
                    onClick={() => {
                      trackEvent('EXPORT_EXCEL_REPORT_SUMMARY_CLICK', { email: userEmail })
                      printfReportExcel()
                    }}
                  >
                    {t('export_report')}
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          </Grid>
          <Grid item sm={12} style={{ textAlign: 'right', display: 'flex', width: '100%' }}>
            {header}
          </Grid>
        </Grid>
      </Grid>
      <Card raised>
        <CardContent className={classes.card}>
          {filter && (
            <FormControl size="small" variant="outlined" margin="normal" className={classes.formControlFilter}>
              <InputLabel id="demo-simple-select-label">{filter}</InputLabel>
              <Select
                value={filterValue}
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                label={filter}
                onChange={filterChange}
              >
                <MenuItem key="cats-0" value={0}>
                  {t('all')}
                </MenuItem>
                {filterValues.map((item) => (
                  <MenuItem key={`cats-${item.id}`} value={item.id}>
                    {item.description ? item.description : item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <div className={classes.dataGridContainer}>
            <div className={classes.dataGridInnerContainer}>
              <DataGrid
                className={classes.dataGrid}
                columns={columns.map((column) => ({
                  ...column,
                  filterable: false,
                }))}
                rows={rowData}
                pageSize={pageSize}
                rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                onPageSizeChange={onPageSizeChange}
                hideFooterSelectedRowCount
                loading={loading || false}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
const mapStateToProps = (state) => ({
  userEmail: state.user.email,
})

export default connect(mapStateToProps)(CatalogGrid)
