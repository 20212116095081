import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import TableContainer from '../../components/table-container/table-container'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import { getManyToManyData } from '../../shared/utils/utils'

import {
  ACTIONS_COLUMN,
  BASE_TABLE_COLUMNS,
  DEFAULT_ROW_DATA,
  DEFAULT_TABLE_CONFIGURATION,
} from '../../shared/constants/table'
import { DEFAULT_DEPENDENCIES_ERROR_MESSAGE, DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'
import { DELETE_METHOD, POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import { MACHINE_QUERY, MACHINES_FACILITY_QUERY, FACILITY_QUERY } from '../../shared/constants/queries'
import useMachineQuery from '../../shared/hooks/useMachineQuery'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import useHttpInactive from '../../shared/hooks/useHttpInactive'
import useStyles from './users.styles'

const SampleType = ({ userEmail }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [deletable, setDeletable] = useState(true)
  const [showActive, setShowActive] = useState(true)
  const [selectedFacilities, setSelectedFacilities] = useState([])
  const [facilities, setFacilities] = useState([])
  const [openFacilities, setOpenFacilities] = useState(false)
  const [facilitySelected, setFacilitySelected] = useState(null)
  const machineQuery = useMachineQuery({ showActive, startRowIndex: tableConf.page - 1, pageSize: 10000 })
  // columns definition for table
  const translatedColumns = BASE_TABLE_COLUMNS.map((column) => ({
    ...column,
    headerName: t(column.headerName),
  }))

  const columns = [
    ...translatedColumns,
    {
      field: 'facility',
      headerName: t('facility'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={async () => {
            await loadDataReplation(params.row.id)
            setOpenFacilities(true)
            setRowData({ id: params.row.id })
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_facility')}
        </Button>
      ),
    },
    {
      ...ACTIONS_COLUMN,
      headerName: t(ACTIONS_COLUMN.headerName),
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  const loadDataReplation = async (id) => {
    try {
      const url = `${MACHINES_FACILITY_QUERY}?id=${id}&startRowIndex=0&pageSize=10000&lefttoright=true`
      setLoading(true)
      const response = await RequestService.Get(url, history)
      setLoading(false)
      if (response?.data?.length > 0) {
        const value = { facilityId: response.data[0].second.id }
        setFacilitySelected(value)
      } else {
        setFacilitySelected(null)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_SAMPLE_TYPE, { email: userEmail })
  }, [])

  // load data
  useEffect(() => {
    loadData()
  }, [tableConf, showActive])

  const loadData = async () => {
    try {
      setLoading(true)
      machineQuery.refetch()

      const url = `${FACILITY_QUERY}?startRowIndex=0&pageSize=10000`
      const response = await RequestService.Get(url, history)
      setFacilities(response.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  // insert and edit
  const insertEditData = async (method, data) => {
    try {
      setLoading(true)

      let id = ''
      if (method === DELETE_METHOD) {
        id = `?id=${data.id}`
      }
      let url = `${MACHINE_QUERY}${id}`
      let response
      if (method === POST_METHOD) response = await RequestService.Post(url, history, data)
      if (method === PUT_METHOD) response = await RequestService.Put(url, history, data)
      if (method === DELETE_METHOD) response = await RequestService.Delete(url, history, data)

      setOpen(false)
      // Created stem condition id
      id = response.data.id

      const payload = [
        {
          first: { id },
          second: { id: data.id_facility },
        },
      ]
      url = `${MACHINES_FACILITY_QUERY}?filterByFirst=true`
      const res = await RequestService.Put(url, history, payload, { isBlankInstance: true })
      if (response?.hasError) {
        onError(response.errorMessage)
        return
      }

      setTableConf({ ...tableConf })
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setLoading(false)
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    if (type === 'Edit') {
      const res = await getManyToManyData(
        `${MACHINES_FACILITY_QUERY}?id=${params.id}&filterByFirst=true`,
        history,
        facilities,
        true
      )
      const facility = res?.length > 0 ? res[0] : null

      setRowData({
        id: params.id,
        name: params.name,
        description: params.description,
        active: params.active,
        id_facility: facility?.id,
      })
    } else {
      setRowData({ ...DEFAULT_ROW_DATA })
      setSelectedFacilities([])
    }
    setModalType(type)
    setOpen(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType === 'Edit' ? PUT_METHOD : POST_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      active: !params.active,
    })
    setDelOpen(true)
  }
  const { executeInactive, loadingInactive } = useHttpInactive(MACHINE_QUERY)
  const onClickDelConfirmation = async () => {
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setDelOpen(false)
      loadData()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }

  const handleChangeSelected = (event) => {
    const { value } = event.target
    setFacilitySelected({ facilityId: value })
  }
  const handleSubmitFacilityMachines = async (event) => {
    event.preventDefault()
    try {
      const payload = [
        {
          first: { id: rowData.id },
          second: { id: facilitySelected.facilityId },
        },
      ]
      setLoading(true)
      const url = `${MACHINES_FACILITY_QUERY}?filterByFirst=true`
      const res = await RequestService.Put(url, history, payload, { isBlankInstance: true })
      if (res?.hasError) {
        onError(res.errorMessage)
        return
      }

      setOpenFacilities(false)
      setTableConf({ ...tableConf })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <div>
      <FormDialog
        open={openFacilities}
        onClose={() => {
          setOpenFacilities(false)
        }}
        title={t('facility')}
        loading={loading}
        handleSubmit={handleSubmitFacilityMachines}
      >
        <div>
          <FormControl className={classes.formControl} variant="outlined" fullWidth>
            <Select
              required
              label={t('facility')}
              labelId="select-facility-label"
              onChange={handleChangeSelected}
              defaultValue={facilitySelected?.facilityId ? facilitySelected.facilityId : ''}
            >
              {facilities.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </FormDialog>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('machine')}
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={machineQuery.data}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={loading}
      />
      <FormDialog
        title={`${modalType} pack-type`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          autoFocus
          variant="outlined"
          margin="normal"
          name="name"
          label={t('name')}
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label={t('description')}
          type="text"
          fullWidth
          onChange={handleChange}
        />
        <FormControl margin="normal" variant="outlined" fullWidth>
          <InputLabel id="select-facility-label">{t('facility')} *</InputLabel>
          <Select
            required
            labelId="select-facility-label"
            label={t('facility')}
            name="id_facility"
            onChange={handleChange}
            value={rowData.id_facility}
          >
            {facilities.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title={t('deactivate_machine')}
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setDelOpen(false)
          setDeletable(true)
        }}
        onOk={onClickDelConfirmation}
      >
        {t(DEFAULT_COMFIRM_INACTIVE_MESSAGE)} {rowData.name}?
        {!deletable && (
          <Alert style={{ marginTop: 10 }} severity="error">
            {t(DEFAULT_DEPENDENCIES_ERROR_MESSAGE)}
          </Alert>
        )}
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
})

export default connect(mapStateToProps, null)(SampleType)
