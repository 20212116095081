import { createSlice } from '@reduxjs/toolkit'

export default createSlice({
  name: 'machine',
  initialState: { id: 0 },
  reducers: {
    setCurrentMachineAction: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})
