import React, { useContext, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import TableContainer from '../../components/table-container/table-container'
import MultipleSelect from '../../components/multi-select/multi-select'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import ManyToManyInput from '../../components/many-many-input/many-many-input.component'

import { buildPayload, getManyToManyData } from '../../shared/utils/utils'

import RequestService from '../../services/request/request-service'

import { DEFAULT_DEPENDENCIES_ERROR_MESSAGE, DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'

import { DELETE_METHOD, POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import {
  ACTIONS_COLUMN,
  BASE_TABLE_COLUMNS,
  DEFAULT_ROW_DATA,
  DEFAULT_TABLE_CONFIGURATION,
} from '../../shared/constants/table'
import {
  FACILITIES_LOCATIONS_QUERY,
  FACILITY_QUERY,
  LOCATION_QUERY,
  OUTLET_QUERY,
  TEMPLATELOCATION_QUERY,
  LOCATIONS_DEFECTS_QUERY,
} from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import useHttpInactive from '../../shared/hooks/useHttpInactive'
import useLocationQuery from '../../shared/hooks/useLocationQuery'
import useFacilityQuery from '../../shared/hooks/useFacilityQuery'
import useDefectQuery from '../../shared/hooks/useDefectQuery'
import { TEMPLATES_IMG } from '../../shared/constants/template-img'
import useStyles from './location.style'
import PrimaryButton from '../../components/button/button.component'

const Location = ({ userEmail }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()
  const refDialog = useRef()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openFacilities, setOpenFacilities] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [openDefectsDialog, setDefectsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [deletable, setDeletable] = useState(true)
  const [showActive, setShowActive] = useState(true)
  const [selectedFacilities, setSelectedFacilities] = useState([])
  const [selectedDefects, setSelectedDefects] = useState([])
  const locationQuery = useLocationQuery({ showActive })
  const facilityQuery = useFacilityQuery()
  const isLoadingQuery = locationQuery.isFetching || facilityQuery.isFetching
  const [templateLocations, setTemplateLocations] = useState([])
  const [openTemplate, setOpenTemplate] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(1)
  const [methodTempalte, setMethodTempalte] = useState('Edit')
  const defectQuery = useDefectQuery()
  // columns definition for table
  const translatedColumns = BASE_TABLE_COLUMNS.map((column) => ({
    ...column,
    headerName: t(column.headerName),
  }))

  const columns = [
    ...translatedColumns,
    {
      field: 'template_name',
      headerName: t('template'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setSelectedTemplate(params.row?.template)
            setRowData(params.row)
            setOpenTemplate(true)
            setMethodTempalte('submit')
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_template')}
        </Button>
      ),
    },
    {
      field: 'facility',
      headerName: t('facilities'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setOpenFacilities(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_facilities')}
        </Button>
      ),
    },
    {
      field: 'defects',
      headerName: 'Defects',
      flex: 1,
      minWidth: 130,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setDefectsOpen(true)
          }}
          style={{ textTransform: 'none' }}
        >
          See Defects
        </Button>
      ),
    },
    {
      ...ACTIONS_COLUMN,
      headerName: t(ACTIONS_COLUMN.headerName),
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  useEffect(async () => {
    const url = `${TEMPLATELOCATION_QUERY}`
    const response = await RequestService.Get(url, history)
    setTemplateLocations(response.data)

    trackEvent(LOG_PAGE_LOADS.LOAD_LOCATION_PAGE, { email: userEmail })
  }, [])

  const templateImage = TEMPLATES_IMG.find((img) => img.value === selectedTemplate)

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_LOCATION_PAGE, { email: userEmail })
  }, [])

  // load data
  useEffect(async () => {
    const url = `${TEMPLATELOCATION_QUERY}`
    const response = await RequestService.Get(url, history)
    setTemplateLocations(response.data)
  }, [])

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setLoading(false)
  }

  // insert and edit
  const insertEditData = async (method, data) => {
    setLoading(true)
    try {
      let url = `${LOCATION_QUERY}`
      let response
      if (method === POST_METHOD) response = await RequestService.Post(url, history, data)
      if (method === PUT_METHOD) response = await RequestService.Put(url, history, data)

      const { id } = response.data

      const locationFacilitiesPayload = buildPayload(id, selectedFacilities, null, true)
      url = `${FACILITIES_LOCATIONS_QUERY}?filterByFirst=false`
      response = await RequestService.Put(url, history, locationFacilitiesPayload, { isBlankInstance: true })

      const locationsDefectsPayload = buildPayload(id, selectedDefects, null)
      url = `${LOCATIONS_DEFECTS_QUERY}`
      await RequestService.Put(url, history, locationsDefectsPayload, { isBlankInstance: true })

      setSelectedFacilities([])
      setSelectedDefects([])
      setLoading(false)
      setOpen(false)
      locationQuery.refetch()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const updateTemplate = async (method, data) => {
    setLoading(true)

    const url = `${LOCATION_QUERY}`
    let response
    try {
      if (method === PUT_METHOD) {
        response = await RequestService.Put(url, history, data)
      }

      if (response?.hasError) {
        onError(response.errorMessage)
        setLoading(false)
        return
      }

      setTableConf({ ...tableConf })
      setLoading(false)
      setOpenTemplate(false)
      locationQuery.refetch()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    setLoading(true)
    if (type === 'Edit') {
      const locationsfacilities = await getManyToManyData(
        `${FACILITIES_LOCATIONS_QUERY}?id=${params.id}&filterByFirst=false`,
        history,
        facilityQuery.data
      )
      const locationsDefects = await getManyToManyData(
        `${LOCATIONS_DEFECTS_QUERY}?id=${params.id}`,
        history,
        defectQuery.data,
        true
      )
      setSelectedFacilities(locationsfacilities)
      setSelectedDefects(locationsDefects)
      setRowData(params)
    } else {
      setSelectedFacilities([])
      setSelectedDefects([])
      setRowData(DEFAULT_ROW_DATA)
    }
    setModalType(type)
    setOpen(true)
    setLoading(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType !== 'Edit' ? POST_METHOD : PUT_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      active: !params.active,
    })
    setDelOpen(true)
  }
  const { executeInactive, loadingInactive } = useHttpInactive(LOCATION_QUERY)

  const onClickDelConfirmation = async () => {
    const data = { ...rowData }
    const url = `${OUTLET_QUERY}?id_location=${data.id}`
    const response = await RequestService.Get(url, history)
    if (response.data.length > 0) {
      setDeletable(false)
      return
    }
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setDelOpen(false)
      locationQuery.refetch()
      defectQuery.refetch()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }

  // Save change tempalte
  const handleSaveTemplate = (event) => {
    event.preventDefault()

    if (methodTempalte === 'submit') {
      const data = { ...rowData, template: selectedTemplate }
      updateTemplate(PUT_METHOD, data)
    } else {
      setRowData({
        ...rowData,
        template: selectedTemplate,
        template_name: templateLocations.find((template) => template.value === selectedTemplate)?.name,
      })

      setOpenTemplate(false)
    }
  }

  useEffect(() => {
    if (refDialog.current) {
      if (openTemplate) {
        refDialog.current.style.display = 'none'
      } else {
        refDialog.current.style.display = 'block'
      }
    }
  }, [openTemplate])

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('location')}
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={locationQuery.data}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={isLoadingQuery}
      />
      <FormDialog
        ref={refDialog}
        title={`${modalType} location`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          variant="outlined"
          margin="normal"
          name="name"
          label={t('name')}
          type="text"
          fullWidth
          required
          onChange={handleChange}
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label={t('description')}
          type="text"
          fullWidth
          onChange={handleChange}
        />
        <Box className={classes.boxTemplate}>
          <FormControl margin="normal" variant="outlined" fullWidth>
            <InputLabel id="select-template-label">{t('template')} * </InputLabel>
            <Select
              required
              labelId="select-template-label"
              label={t('template')}
              name="template"
              readOnly
              disabled
              inputProps={{ IconComponent: () => null }}
              value={rowData.template}
            >
              {templateLocations.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <PrimaryButton
            style={{ margin: '16px 0 0 5px', height: '56px' }}
            onClick={() => {
              setSelectedTemplate(rowData.template ? rowData.template : 1)
              setOpenTemplate(true)
              setMethodTempalte('Edit')
            }}
          >
            {t('select')}
          </PrimaryButton>
        </Box>
        <ManyToManyInput
          inputLabel={t('facilities')}
          name="id_facility"
          selectedValues={selectedFacilities}
          allValues={facilityQuery.data}
          setSelectedValues={setSelectedFacilities}
        />
        <ManyToManyInput
          inputLabel="Defects"
          name="id_defect"
          selectedValues={selectedDefects}
          allValues={defectQuery.data}
          setSelectedValues={setSelectedDefects}
        />
        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <MultipleSelect
        open={openFacilities}
        onClose={() => {
          setOpenFacilities(false)
        }}
        title={t('facilities')}
        getUrl="facility"
        getRelationUrl="facilitieslocations"
        useLast
        mainId={rowData.id}
        lefttoright
      />
      <MultipleSelect
        open={openDefectsDialog}
        onClose={() => {
          setDefectsOpen(false)
        }}
        title="Defects"
        value={defectQuery.data}
        getRelationUrl="locationsdefects"
        mainId={rowData.id}
        lefttoright
      />
      <ConfirmDialog
        title={t('deactivate_location')}
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setDelOpen(false)
          setDeletable(true)
        }}
        onOk={onClickDelConfirmation}
      >
        {t(DEFAULT_COMFIRM_INACTIVE_MESSAGE)} {rowData.name}?
        {!deletable && (
          <Alert style={{ marginTop: 10 }} severity="error">
            {t(DEFAULT_DEPENDENCIES_ERROR_MESSAGE)}
          </Alert>
        )}
      </ConfirmDialog>
      <FormDialog
        title={t('template')}
        open={openTemplate}
        loading={loading}
        onClose={() => {
          setOpenTemplate(false)
        }}
        handleSubmit={(event) => handleSaveTemplate(event)}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <RadioGroup
            aria-labelledby="radio-buttons-group-template"
            value={selectedTemplate}
            name="template-group"
            onChange={(e) => setSelectedTemplate(Number(e.target.value))}
          >
            {templateLocations.map((item) => (
              <FormControlLabel
                value={item.value}
                key={item.value}
                control={<Radio checked={item.value === selectedTemplate} />}
                label={item.name}
              />
            ))}
          </RadioGroup>
          <div className={classes.boxImages}>
            {templateImage?.src.map((item) => (
              <div className={classes.boxImage} key={item}>
                <h3 className={classes.titleImage}>{item.includes('grade') ? 'Grade' : 'Size'}</h3>
                <img className={classes.img} src={item} alt="imageTempalte" />
              </div>
            ))}
          </div>
        </Box>
      </FormDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
})

export default connect(mapStateToProps, null)(Location)
