import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Icon from '@material-ui/core/Icon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Badge from '@material-ui/core/Badge'
import { useTranslation } from 'react-i18next'
import { languageOptions } from '../../locales'
import DashboardContext from '../../context/dashboard/DashboardContext'
import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import logo from '../../logo.png'
import './sidebar.styles.scss'
import { selectLoginData } from '../../redux/user/user.selectors'
import { selectSubscriptionData } from '../../redux/subscription/subscription.selectors'
import drawerSlice from '../../redux/drawer/drawer.slice'
import facilitySlice from '../../redux/facility/facility.slice'
import subscriptionSlice from '../../redux/subscription/subscription.slice'
import { setLanguage } from '../../redux/language/language.action'
import AuthService from '../../services/auth/auth-service'
import { ALERT_SUBSCRIPTION, DASHBOARD_SUBSCRIPTION } from '../../shared/constants/general'
import { LOG_ACTIONS } from '../../shared/constants/logging'
import Notification from './notification/notification.component'

const SideBar = ({
  toggleDrawerDispatch,
  loginData,
  subscriptionData,
  updateCurrentAlertInformationDispatch,
  updateCurrentDashboardInformationDispatch,
  resetFacilityStateDispatch,
  logOutDispatch,
  setLanguageDispatch,
}) => {
  const { cleanUpDashboardContext, cleanUpAlertContext, deleteSubscription } = useContext(DashboardContext)
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const dispatch = useDispatch()
  const { dashboardInformation, alertInformation } = subscriptionData
  const { afterLoginPage } = loginData

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElNotifications, setAnchorElNotifications] = useState(null)
  const [openNotifications, setOpenNotifications] = useState(false)
  const [unreadCount, setUnreadCount] = useState(0)
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null)

  const { t, i18n } = useTranslation()

  const handleLanguageClick = (event) => {
    setLanguageAnchorEl(event.currentTarget)
  }

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null)
  }

  const handleLanguageChange = (event, newValue) => {
    const language = newValue ? newValue.substring(0, 2) : ''
    i18n.changeLanguage(language)
    setLanguageDispatch(language)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOpenNotifications = (event) => {
    setAnchorElNotifications(event.currentTarget)
    setOpenNotifications(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = async () => {
    await cleanUpDashboardContext()
    await deleteSubscription(
      dashboardInformation.topicName,
      dashboardInformation.subscriptionName,
      DASHBOARD_SUBSCRIPTION
    )
    await updateCurrentDashboardInformationDispatch({
      topicName: null,
      subscriptionName: null,
    })
    await cleanUpAlertContext()
    await deleteSubscription(alertInformation.topicName, alertInformation.subscriptionName, ALERT_SUBSCRIPTION)
    await updateCurrentAlertInformationDispatch({
      topicName: null,
      subscriptionName: null,
    })
    await resetFacilityStateDispatch()
    trackEvent(LOG_ACTIONS.LOGOUT_SUCCESSFUL, { email: loginData.email })
    AuthService.logoutUser(dispatch)
    logOutDispatch()
  }

  return (
    <div className="sidebar">
      <div className="logo-container">
        <Link to={afterLoginPage} className="link-logo">
          <img src={logo} className="logo" alt="Logo" />
        </Link>
        <Icon onClick={toggleDrawerDispatch} className="link-menu">
          menu_open
        </Icon>
        <div className="menu-stack">
          <Badge className="link-menu" badgeContent={unreadCount || 0} color="secondary">
            <Icon onClick={handleOpenNotifications}>mail</Icon>
          </Badge>
          <Icon onClick={handleClick} className="link-menu settings">
            settings
          </Icon>
          <Menu
            id="simple-menu"
            className="menu-logout"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLanguageClick}>{t('select_language')}</MenuItem>
            <MenuItem onClick={handleLogOut}>{t('logout')}</MenuItem>
          </Menu>
        </div>
      </div>
      <Notification
        anchorEl={anchorElNotifications}
        onClose={() => setOpenNotifications(false)}
        open={openNotifications}
        setUnreadCount={setUnreadCount}
      />
      <Menu
        id="language-menu"
        className="menu-language"
        anchorEl={languageAnchorEl}
        keepMounted
        open={Boolean(languageAnchorEl)}
        onClose={handleLanguageClose}
      >
        <Autocomplete
          options={Object.keys(languageOptions)}
          getOptionLabel={(key) => languageOptions[key].nativeName}
          style={{ width: 300 }}
          disableClearable
          onChange={handleLanguageChange}
          renderInput={(params) => (
            <TextField {...params} label={t('choose_app_language')} variant="outlined" fullWidth />
          )}
        />
      </Menu>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  toggleDrawerDispatch: () => dispatch(drawerSlice.actions.toggleDrawerAction()),
  resetFacilityStateDispatch: () => dispatch(facilitySlice.actions.resetFacilityState()),
  updateCurrentAlertInformationDispatch: (alertInformation) =>
    dispatch(subscriptionSlice.actions.setCurrentAlertInformationAction(alertInformation)),
  updateCurrentDashboardInformationDispatch: (dashboardInformation) =>
    dispatch(subscriptionSlice.actions.setCurrentDashboardInformationAction(dashboardInformation)),
  logOutDispatch: () => dispatch(drawerSlice.actions.logOutAction()),
  setLanguageDispatch: (language) => dispatch(setLanguage(language)),
})

const mapStateToProps = createStructuredSelector({
  loginData: selectLoginData,
  subscriptionData: selectSubscriptionData,
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
