import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  card: {
    height: '80vh',
    '& div.MuiDataGrid-root': {
      border: 'none',
    },
    '& div.MuiDataGrid-cell': {
      borderBottom: 'none !important',
      paddingTop: 10,
      paddingBottom: 10,
    },
    '& div.MuiDataGrid-columnsContainer': {
      borderBottom: 'none !important',
    },
    '& div.MuiDataGrid-row': {
      border: 'none',
      marginTop: 15,
      borderRadius: 5,
      backgroundColor: '#fff',
      boxShadow: '0 3px 6px 0 rgba(0,0,0,0.1)',
    },
    '& div.MuiDataGrid-viewport': {
      height: '100vh',
    },
  },
  tableHeader: {
    fontSize: 20,
    color: '#9b9da0',
  },
  defectContainer: {
    display: 'flex',
  },
  logo: {
    maxHeight: 40,
    filter: 'grayscale(1)',
  },
  defectTag: {
    marginTop: 15,
    marginLeft: 5,
  },
  controlBox: {
    paddingBottom: '10px',
    '@media (max-width: 600px)': {
      '& div.MuiGrid-item': {
        padding: '6px',
      },
      '& div.MuiFormControl-marginNormal': {
        marginTop: '10px',
      },
    },
    '& div.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& label.MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54) !important',
    },
    '& button.MuiIconButton-root': {
      color: 'rgba(0, 0, 0, 0.26)',
      cursor: 'default',
      padding: '0',
    },
  },
})

export default useStyles
