import { makeStyles } from '@material-ui/core/styles'

const overviewStyles = makeStyles((theme) => ({
  dataGrid: {
    borderStyle: 'none',
    height: '80vh',
  },
  // cardContent: {
  // },
  searchBox: {
    width: 'auto',
    display: 'flex',
    margin: '15px 0 0 0',
    justifyContent: 'flex-end',
  },
  root: {
    width: '60vh',
    padding: 20,
  },
  closeBtn: {
    maxWidth: '24px',
    maxHeight: '26px',
    minWidth: '24px',
    minHeight: '26px',
  },
  currentLabel: {
    marginTop: 30,
  },
}))

export default overviewStyles
