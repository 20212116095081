import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Icon from '@material-ui/core/Icon'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'

import Overview from './overview.component'

import RequestService from '../../services/request/request-service'
import { overviewStyles } from './staff.styles'
import { LOCATION_QUERY, USER_QUERY } from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth={false}>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const StaffOverview = ({ userEmail, ...props }) => {
  const query = {
    userId: props.userId,
    startDate: props.startDate,
    endDate: props.endDate,
    idFacility: props.idFacility,
    idLocation: props.idLocation,
  }
  const classes = overviewStyles()
  const history = useHistory()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [userId, setUserId] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [idFacility, setIdFacility] = useState('')
  const [idLocation, setIdLocation] = useState('')
  const [locations, setLocations] = useState([])

  const [activeTab, setActiveTab] = useState(0)
  const [user, setUser] = useState(undefined)

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_STAFF_OVERVIEW_PAGE, { email: userEmail })
  }, [])

  useEffect(() => {
    if (query.userId && query.startDate && query.endDate && query.idFacility) {
      setUserId(query.userId)
      setStartDate(query.startDate)
      setEndDate(query.endDate)
      setIdFacility(query.idFacility)
      setIdLocation(query.idLocation)
    }
  }, [query])

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  useEffect(async () => {
    const loadData = async () => {
      try {
        const url = `${USER_QUERY}?id=${userId}`
        const response = await RequestService.Get(url, history)
        const currentUser = response.data.find((item) => item.id === userId)
        setUser(currentUser)
      } catch (error) {
        console.error(error)
      }
    }
    if (userId !== '' && !user) await loadData()
  }, [userId])

  useEffect(async () => {
    const loadData = async () => {
      try {
        const url = `${LOCATION_QUERY}`
        const response = await RequestService.Get(url, history)
        setLocations(response.data)
      } catch (error) {
        console.error(error)
      }
    }
    if (locations.length === 0) await loadData()
  }, [locations])

  return (
    <div>
      <Grid item xs={12}>
        <Box className={classes.title} pt={5} pb={3}>
          <IconButton onClick={() => props.onPrevious()} aria-label="delete">
            <Icon color="secondary">chevron_left</Icon>
          </IconButton>

          <Box>
            <Typography component="h1" variant="h6">
              {user ? user.name : ''}
            </Typography>
            <Typography color="secondary" component="h2" variant="subtitle1">
              {user ? user.email : ''}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Card raised>
          <CardContent style={{ paddingLeft: 0, paddingRight: 0, flexGrow: 1, width: '100%' }}>
            <AppBar
              style={{ border: 'none', borderBottom: '2px solid #eee' }}
              variant="outlined"
              color="transparent"
              position="static"
            >
              <Tabs value={activeTab} onChange={handleChange} aria-label="simple tabs example">
                <Tab style={{ textTransform: 'none' }} label="Samples Overview" {...a11yProps(0)} />
              </Tabs>
            </AppBar>
            <TabPanel value={activeTab} index={0}>
              <Overview
                startDate={startDate}
                endDate={endDate}
                idFacility={idFacility}
                userId={userId}
                locations={locations}
                idLocation={idLocation}
              />
            </TabPanel>
          </CardContent>
        </Card>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
})

export default connect(mapStateToProps, null)(StaffOverview)
