import { useQuery } from 'react-query'
import RequestService from '../../services/request/request-service'
import { DEFECTS_SUMMARY_QUERY } from '../constants/queries'

const useDefectSummaryQuery = ({
  filter = { id_machine: 0, id_facility: 0, id_lot: 0, id_variety: 0, start_date: 0, end_date: 0 },
} = {}) => {
  return useQuery({
    queryKey: [DEFECTS_SUMMARY_QUERY, filter],
    queryFn: async ({ queryKey }) => {
      // eslint-disable-next-line max-len
      const url = `${queryKey[0]}?id_facility=${filter.id_facility}&id_machine=${filter.id_machine}&start_date=${filter.start_date}&end_date=${filter.end_date}&id_lot=${filter.id_lot}&id_variety=${filter.id_variety}`
      const { data } = await RequestService.Get(url)
      return data
    },
    initialData: [],
  })
}
export default useDefectSummaryQuery
