import { useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import DashboardContext from './DashboardContext'
import RequestService from '../../services/request/request-service'
import { AB_SUBSCRIPTION_QUERY } from '../../shared/constants/queries'
import { ALERT_SUBSCRIPTION, DASHBOARD_SUBSCRIPTION } from '../../shared/constants/general'

const DashboardContextProvider = ({ children }) => {
  const [dashboardClient, setDashboardClient] = useState(null)
  const [dashboardSubscription, setDashboardSubscription] = useState(null)
  const [dashboardReceiver, setDashboardReceiver] = useState(null)
  const [alertClient, setAlertClient] = useState(null)
  const [alertSubscription, setAlertSubscription] = useState(null)
  const [alertReceiver, setAlertReceiver] = useState(null)

  const history = useHistory()

  const cleanUpDashboardContext = async () => {
    await closeDashboardSubscription()
    await closeDashboardReceiver()
    await closeDashboardClient()
  }

  const cleanUpAlertContext = async () => {
    await closeAlertSubscription()
    await closeAlertReceiver()
    await closeAlertClient()
  }

  const closeDashboardClient = async () => {
    if (dashboardClient) {
      await dashboardClient.close()
      setDashboardClient(null)
    }
  }

  const closeAlertClient = async () => {
    if (alertClient) {
      await alertClient.close()
      setAlertClient(null)
    }
  }

  const closeDashboardReceiver = async () => {
    if (dashboardReceiver) {
      await dashboardReceiver.close()
      setDashboardReceiver(null)
    }
  }

  const closeAlertReceiver = async () => {
    if (alertReceiver) {
      await alertReceiver.close()
      setAlertReceiver(null)
    }
  }

  const closeDashboardSubscription = async () => {
    if (dashboardSubscription) {
      await dashboardSubscription.close()
      setDashboardSubscription(null)
    }
  }

  const closeAlertSubscription = async () => {
    if (alertSubscription) {
      await alertSubscription.close()
      setAlertSubscription(null)
    }
  }

  const deleteSubscription = async (topicName, subscriptionName, type = DASHBOARD_SUBSCRIPTION) => {
    try {
      if (topicName && subscriptionName) {
        const url = `${AB_SUBSCRIPTION_QUERY}?topic_name=${topicName}&subscription_name=${subscriptionName}`
        return await RequestService.Delete(url, history, { isBlankInstance: true })
      }
    } catch (error) {
      if (type === DASHBOARD_SUBSCRIPTION) await cleanUpDashboardContext()
      if (type === ALERT_SUBSCRIPTION) await cleanUpAlertContext()
      console.log(error)
    }
    return null
  }

  const value = useMemo(
    () => ({
      dashboardClient,
      setDashboardClient,
      alertClient,
      setAlertClient,
      dashboardSubscription,
      setDashboardSubscription,
      closeDashboardSubscription,
      dashboardReceiver,
      setDashboardReceiver,
      closeDashboardReceiver,
      alertSubscription,
      setAlertSubscription,
      closeAlertSubscription,
      alertReceiver,
      setAlertReceiver,
      closeAlertReceiver,
      deleteSubscription,
      cleanUpDashboardContext,
      cleanUpAlertContext,
    }),
    [dashboardClient, dashboardSubscription, dashboardReceiver, alertClient, alertSubscription, alertReceiver]
  )

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
}

export default DashboardContextProvider
