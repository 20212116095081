import { createSlice } from '@reduxjs/toolkit'

export default createSlice({
  name: 'grower',
  initialState: {
    growerName: '',
  },
  reducers: {
    setCurrentGrowerNameAction: (state, action) => {
      state.growerName = action.payload
    },
  },
})
