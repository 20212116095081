import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { DataGrid } from '@material-ui/data-grid'
import Grid from '@material-ui/core/Grid'

import SimpleBackdrop from '../../components/simple-backdrop/simple-backdrop.component'
import SearchBar from '../../components/SearchBar/SearchBar.component'

import { overviewListStyles } from './staff.styles'

import { SAMPLE_QUERY } from '../../shared/constants/queries'
import { DEFAULT_TABLE_CONFIGURATION, ROW_PER_PAGE_OPTIONS } from '../../shared/constants/table'

import RequestService from '../../services/request/request-service'

import { convertMillisecondsToMinutesAndSeconds, searchFullColumns } from '../../shared/utils/utils'

const OverviewList = ({ ...props }) => {
  const classes = overviewListStyles()
  const history = useHistory()
  // columns definition for table
  const columns = [
    {
      field: 'lotName',
      headerName: 'Lot #',
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 110,
    },
    {
      field: 'growerName',
      headerName: 'Grower Name',
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 110,
    },
    {
      field: 'varietyName',
      headerName: 'Variety',
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 125,
    },
    {
      field: 'locationName',
      headerName: 'Location',
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 140,
    },
    {
      field: 'sampleType',
      headerName: 'Sample Type',
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 160,
    },
    {
      field: 'time',
      hide: true,
    },
    {
      field: 'sampleTime',
      headerName: 'Sample Time',
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 190,
      sortComparator: (v1, v2, param1, param2) => {
        return param1.api.getCellValue(param1.id, 'time') - param2.api.getCellValue(param2.id, 'time')
      },
    },
  ]

  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [rowsResponse, setRowsResponse] = useState([])

  useEffect(async () => {
    if (props.userId !== '') {
      await fetchData()
    }
  }, [tableConf, props.userId])

  const fetchData = async () => {
    try {
      const response = await RequestService.Get(
        `${SAMPLE_QUERY}?id_user=${props.userId}&startRowIndex=${tableConf.page - 1}&id_facility=${
          props.idFacility
        }&id_location=${props.idLocation}&start_date=${props.startDate}Z&end_date=${props.endDate}Z&pageSize=10000`,
        history
      )
      const resParse = response.data.result.map((item, index) => renderRow(item, index))
      setRowsResponse(resParse)
      setRows(resParse)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const renderRow = (item, index) => {
    return {
      ...item,
      id: index,
      lotName: item.name_Lot,
      varietyName: item.name_Variety,
      locationName: item.name_Location,
      sampleType: item.id_Sample_Type === 1 ? 'Size' : 'Grade',
      growerName: item.name_Grower,
      time: item.sample_Time,
      sampleTime: convertMillisecondsToMinutesAndSeconds(item.sample_Time),
      date: new Date(item.created_date).toLocaleString(),
    }
  }

  const handleChangeSearch = (event) => {
    const text = event.target.value
    searchFullColumns(rowsResponse, text, setRows, columns)
  }

  const onPageSizeChange = (event) => {
    setTableConf({ page: 1, pageSize: event })
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <div className={classes.searchBox}>
            <SearchBar onChange={handleChangeSearch} />
          </div>
        </Grid>
        <Grid item xs={12} style={{ minHeight: '70vh' }}>
          <div style={{ display: 'flex', height: '100%', flexGrow: 1 }}>
            <DataGrid
              style={{ borderStyle: 'none' }}
              columns={columns.map((column) => ({
                ...column,
                filterable: false,
              }))}
              rows={rows}
              pageSize={tableConf.pageSize}
              rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
              onPageSizeChange={onPageSizeChange}
              hideFooterSelectedRowCount
            />
          </div>
        </Grid>
      </Grid>
      <SimpleBackdrop
        open={loading}
        onClick={() => {
          setLoading(false)
        }}
      />
    </>
  )
}

export default OverviewList
