import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media (max-width: 600px)': {
      fontSize: '10px',
      width: '100%',
      justifyContent: 'space-between',
    },
  },

  container2: {
    width: '30%',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '@media (max-width: 600px)': {
      fontSize: '10px',
      width: '100%',
      justifyContent: 'space-between',
    },
  },
})

export default useStyles
