import React from 'react'
import Typography from '@material-ui/core/Typography'
import styles from './singledata.styles'

const SingleData = ({ ...props }) => {
  const classes = styles()
  return (
    <div className={classes.module}>
      <Typography color="primary" variant="caption" className={classes.title}>
        {props.title}
      </Typography>
      <Typography className={classes.value} color="secondary" variant="subtitle1">
        {props.value}
      </Typography>
    </div>
  )
}

export default SingleData
