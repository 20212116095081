import React from 'react'
import Typography from '@material-ui/core/Typography'
import useStyles from './dashboard-bar.styles'
import BorderLinearProgress from '../border-linear-progress/border-linear-progress.component'

const DashboardBar = ({ ...props }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="subtitle1" color="primary">
          {props.title}
        </Typography>
        <Typography variant="h6" className={classes.value}>
          {props.value}%
        </Typography>
      </div>
      <BorderLinearProgress variant="determinate" value={parseFloat(props.value)} />
    </div>
  )
}

export default DashboardBar
