import { enUS, esES } from '@material-ui/core/locale'

const locales = {
  enUS,
  esES,
}

const languageOptions = {
  enUS: { nativeName: 'English' },
  esES: { nativeName: 'Spanish' },
}

export { locales, languageOptions }
