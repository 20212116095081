import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import TableContainer from '../../components/table-container/table-container'
import MultipleSelect from '../../components/multi-select/multi-select'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import ManyToManyInput from '../../components/many-many-input/many-many-input.component'

import { buildPayload, getManyToManyData } from '../../shared/utils/utils'
import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'
import useHttpInactive from '../../shared/hooks/useHttpInactive'

import {
  ACTIONS_COLUMN,
  BASE_TABLE_COLUMNS,
  DEFAULT_ROW_DATA,
  DEFAULT_TABLE_CONFIGURATION,
} from '../../shared/constants/table'
import { DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'
import { POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import {
  OUTLETS_SIZES_QUERY,
  SIZE_QUERY,
  UNITS_SIZES_QUERY,
  VARIETIES_SIZES_QUERY,
} from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import useSizeQuery from '../../shared/hooks/useSizeQuery'
import useVerietyQuery from '../../shared/hooks/useVerietyQuery'
import useUnitQuery from '../../shared/hooks/useUnitQuery'
import useOutletQuery from '../../shared/hooks/useOutletQuery'
import { SUPER_ADMIN_ROLE } from '../../shared/constants/roles'

const Size = ({ userEmail, role }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [openUnits, setOpenUnits] = useState(false)
  const [openOutlets, setOpenOutlets] = useState(false)
  const [openVarieties, setOpenVarieties] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)

  const [showActive, setShowActive] = useState(true)
  const [selectedVarieties, setSelectedVarieties] = useState([])
  const [selectedUnits, setSelectedUnits] = useState([])
  const [selectedOutlets, setSelectedOutlets] = useState([])
  const { executeInactive, loadingInactive } = useHttpInactive(SIZE_QUERY)

  const sizeQuery = useSizeQuery({ showActive })
  const varietyQuery = useVerietyQuery()
  const unitQuery = useUnitQuery()
  const outletQuery = useOutletQuery()
  const isLoadingQuery =
    sizeQuery.isFetching || varietyQuery.isFetching || unitQuery.isFetching || outletQuery.isFetching
  // columns definition for table
  const translatedColumns = BASE_TABLE_COLUMNS.map((column) => ({
    ...column,
    headerName: t(column.headerName),
  }))

  const columns = [
    ...translatedColumns,
    { field: 'value', headerName: t('size'), flex: 1, minWidth: 105 },
    {
      field: 'varieties',
      headerName: t('varieties'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setOpenVarieties(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_varieties')}
        </Button>
      ),
    },
    {
      field: 'unit',
      headerName: t('units'),
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setOpenUnits(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_units')}
        </Button>
      ),
    },
    {
      field: 'outlets',
      headerName: t('outlets'),
      flex: 1,
      minWidth: 125,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setOpenOutlets(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_outlets')}
        </Button>
      ),
    },
    {
      ...ACTIONS_COLUMN,
      headerName: t(ACTIONS_COLUMN.headerName),
      hide: role !== SUPER_ADMIN_ROLE,
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_SIZE_PAGE, { email: userEmail })
  }, [])

  // insert and edit
  const insertEditData = async (method, data) => {
    setLoading(true)
    setOpen(false)

    let url = `${SIZE_QUERY}`
    let response
    try {
      if (method === POST_METHOD) response = await RequestService.Post(url, history, data)
      if (method === PUT_METHOD) response = await RequestService.Put(url, history, data)

      const { id } = response.data

      const sizesVarietiesPayload = buildPayload(id, selectedVarieties, null, true)
      url = `${VARIETIES_SIZES_QUERY}?filterByFirst=false`

      const tasks = []

      response = RequestService.Put(url, history, sizesVarietiesPayload, { isBlankInstance: true })
      tasks.push(response)
      const sizesUnitsPayload = buildPayload(id, selectedUnits, null, true, 'id_unit_type', 'id_unit_type')
      url = `${UNITS_SIZES_QUERY}?filterByFirst=false`
      response = RequestService.Put(url, history, sizesUnitsPayload, { isBlankInstance: true })
      tasks.push(response)
      const sizesOutletsPayload = buildPayload(id, selectedOutlets, null, true, 'id_location', 'id_location')
      url = `${OUTLETS_SIZES_QUERY}?filterByFirst=false`
      response = RequestService.Put(url, history, sizesOutletsPayload, { isBlankInstance: true })
      tasks.push(response)

      await Promise.all(tasks)
      setSelectedVarieties([])
      setSelectedUnits([])
      setSelectedOutlets([])
      setLoading(false)
      sizeQuery.refetch()
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    setLoading(true)
    if (type === 'Edit') {
      const sizesVarietiesTask = getManyToManyData(
        `${VARIETIES_SIZES_QUERY}?id=${params.id}&filterByFirst=false`,
        history,
        varietyQuery.data
      )
      const sizesUnitsTask = getManyToManyData(
        `${UNITS_SIZES_QUERY}?id=${params.id}&filterByFirst=false`,
        history,
        unitQuery.data
      )
      const sizesOutletsTask = getManyToManyData(
        `${OUTLETS_SIZES_QUERY}?id=${params.id}&filterByFirst=false`,
        history,
        outletQuery.data
      )
      const [sizesVarieties, sizesUnits, sizesOutlets] = await Promise.all([
        sizesVarietiesTask,
        sizesUnitsTask,
        sizesOutletsTask,
      ])
      setSelectedVarieties(sizesVarieties)
      setSelectedUnits(sizesUnits)
      setSelectedOutlets(sizesOutlets)

      setRowData({
        id: params.id,
        name: params.name,
        value: params.value,
        description: params.description,
        active: params.active,
      })
    } else {
      setRowData(DEFAULT_ROW_DATA)
      setSelectedVarieties([])
      setSelectedUnits([])
      setSelectedOutlets([])
    }
    setModalType(type)
    setOpen(true)
    setLoading(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = { ...rowData, value: +rowData.value, name: rowData.name.trimEnd().trimStart() }
    const method = modalType === 'Edit' ? PUT_METHOD : POST_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    setRowData({
      id: params.id,
      name: params.name,
      value: params.value,
      description: params.description,
      active: !params.active,
    })
    setDelOpen(true)
  }

  const onClickDelConfirmation = async () => {
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setDelOpen(false)
      sizeQuery.refetch()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('size')}
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={sizeQuery.data}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={isLoadingQuery}
      />
      <MultipleSelect
        open={openVarieties}
        onClose={() => {
          setOpenVarieties(false)
        }}
        title={t('varieties')}
        getUrl="variety"
        getRelationUrl="varietiessizes"
        useLast
        mainId={rowData.id}
        lefttoright
        isAdminShow
      />
      <MultipleSelect
        open={openUnits}
        onClose={() => {
          setOpenUnits(false)
        }}
        title={t('units')}
        getUrl="unit"
        getRelationUrl="unitssizes"
        useLast
        getAdditionalData="id_unit_type"
        mainId={rowData.id}
        lefttoright
        deleteRelationKey="id_unit_type"
        isAdminShow
      />
      <MultipleSelect
        open={openOutlets}
        onClose={() => {
          setOpenOutlets(false)
        }}
        title={t('outlets')}
        getUrl="outlet"
        getRelationUrl="outletssizes"
        useLast
        getAdditionalData="id_location"
        mainId={rowData.id}
        lefttoright
        deleteRelationKey="id_location"
        isAdminShow
      />
      <FormDialog
        title={`${modalType} size`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          autoFocus
          variant="outlined"
          margin="normal"
          name="name"
          label={t('name')}
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.value}
          variant="outlined"
          margin="normal"
          name="value"
          label={t('size')}
          type="number"
          inputProps={{ min: '1', max: '1000', step: '0.5' }}
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label={t('description')}
          type="text"
          fullWidth
          onChange={handleChange}
        />
        <ManyToManyInput
          inputLabel={t('varieties')}
          name="id_variety"
          selectedValues={selectedVarieties}
          allValues={varietyQuery.data}
          setSelectedValues={setSelectedVarieties}
        />
        <ManyToManyInput
          inputLabel={t('units')}
          name="id_unit"
          selectedValues={selectedUnits}
          allValues={unitQuery.data}
          setSelectedValues={setSelectedUnits}
        />
        <ManyToManyInput
          inputLabel={t('outlets')}
          name="id_outlet"
          selectedValues={selectedOutlets}
          allValues={outletQuery.data}
          setSelectedValues={setSelectedOutlets}
        />
        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title={t('deactivate_size')}
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setDelOpen(false)
        }}
        onOk={onClickDelConfirmation}
      >
        {t(DEFAULT_COMFIRM_INACTIVE_MESSAGE)} {rowData.name}?
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
  role: state.user.role,
})

export default connect(mapStateToProps, null)(Size)
