import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import Box from '@material-ui/core/Box'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DetailForm from './form/detail.component'
import ActionForm from './form/action.component'
import RulesForm from './form/rules.component'

const steps = ['Details', 'Rules', 'Actions']

export default function StepperForm(props) {
  const { rowSelected, userId, setRowData, setIsSubmiting, openDialog } = { ...props }
  const [activeStep, setActiveStep] = React.useState(0)
  const [isNext, setIsNext] = React.useState(false)
  const [handleNext, setHandleNext] = React.useState(false)
  const isLastStep = activeStep === steps.length - 1
  const { t } = useTranslation()

  const handleBack = () => {
    setIsSubmiting(true)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <DetailForm
            rowSelected={rowSelected}
            userId={userId}
            submitForm={submitForm}
            isNext={isNext}
            setHandleNext={setHandleNext}
            openDialog={openDialog}
          />
        )
      case 1:
        return (
          <RulesForm
            rowSelected={rowSelected}
            userId={userId}
            submitForm={submitForm}
            isNext={isNext}
            setHandleNext={setHandleNext}
            openDialog={openDialog}
          />
        )
      case 2:
        return (
          <ActionForm
            rowSelected={rowSelected}
            userId={userId}
            submitForm={submitForm}
            isNext={isNext}
            setHandleNext={setHandleNext}
            openDialog={openDialog}
          />
        )
      default:
        return 'Unknown step'
    }
  }
  async function submitForm(name, value) {
    await setRowData({
      ...rowSelected,
      [name]: value,
    })
  }
  const handleSubmit = async (values, actions) => {
    await setIsNext(false)
    if (handleNext) {
      if (isLastStep) {
        setIsSubmiting(false)
        actions.setSubmitting(false)
      } else {
        setActiveStep(activeStep + 1)
        actions.setTouched({})
        actions.setSubmitting(false)
      }
    }
    actions.setSubmitting(false)
    setHandleNext(false)
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {}
          const labelProps = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>{t('all_step_completed_you_are_finished')}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>{t('reset')}</Button>
          </Box>
        </>
      ) : (
        <Formik initialValues={rowSelected} onSubmit={handleSubmit} validator={() => ({})}>
          {({ isSubmitting }) => (
            <Form id="alertForm">
              <Typography sx={{ mt: 2, mb: 1 }}>
                {t('step')} {activeStep + 1}
              </Typography>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  {t('back')}
                </Button>

                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => setIsNext(true)}
                >
                  {isLastStep ? t('finish') : t('next')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  )
}
