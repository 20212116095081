import { makeStyles } from '@material-ui/core/styles'
import { DRAWER_WIDTH } from '../../shared/constants/general'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: 'rgb(249,251,253)',
    height: '100vh',
    position: 'relative',
  },
  content: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      marginLeft: -DRAWER_WIDTH + 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '60px',
      marginLeft: '0px',
    },
    marginLeft: -DRAWER_WIDTH + 65,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 65,
    [theme.breakpoints.down('sm')]: {
      marginLeft: -DRAWER_WIDTH + 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
}))

export default useStyles
