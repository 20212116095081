import { useSelector } from 'react-redux'
import { createTheme } from '@material-ui/core/styles'
import { locales } from './locales'

export const useTheme = () => {
  const language = useSelector((state) => state.language)

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#2a3858',
        },
        secondary: {
          main: '#5a7a98',
        },
      },
    },
    locales[language]
  )

  return theme
}
