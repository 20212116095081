import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import clsx from 'clsx'

import Container from '@material-ui/core/Container'

import DashboardContext from '../../context/dashboard/DashboardContext'
import subscriptionSlice from '../../redux/subscription/subscription.slice'
import SideBar from '../sidebar/sidebar.component'
import DrawerComponent from '../drawer/drawer.component'

import useStyles from './main-layout.styles'
import { ALERT_SUBSCRIPTION, DASHBOARD_SUBSCRIPTION } from '../../shared/constants/general'

const MainLayout = ({
  children,
  alertInformation,
  updateCurrentAlertInformationDispatch,
  dashboardInformation,
  updateCurrentDashboardInformationDispatch,
  ...props
}) => {
  const { cleanUpDashboardContext, cleanUpAlertContext, deleteSubscription } = useContext(DashboardContext)
  const { isDrawerOpen } = props
  const classes = useStyles()
  const dashboardPathname = ['/dashboard']

  const location = useLocation()

  // Run clean up when changing pages.
  useEffect(async () => {
    if (!dashboardPathname.includes(location.pathname)) {
      await cleanUpDashboardContext()
      await deleteSubscription(
        dashboardInformation.topicName,
        dashboardInformation.subscriptionName,
        DASHBOARD_SUBSCRIPTION
      )
      await updateCurrentDashboardInformationDispatch({
        topicName: null,
        subscriptionName: null,
      })
      await cleanUpAlertContext()
      await deleteSubscription(alertInformation.topicName, alertInformation.subscriptionName, ALERT_SUBSCRIPTION)
      await updateCurrentAlertInformationDispatch({
        topicName: null,
        subscriptionName: null,
      })
    }
  }, [location])

  return (
    <div className={classes.root}>
      <SideBar />
      <DrawerComponent />

      <Container
        maxWidth={false}
        className={clsx(classes.content, {
          [classes.contentShift]: isDrawerOpen,
        })}
      >
        {children}
      </Container>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentAlertInformationDispatch: (alertInformation) =>
    dispatch(subscriptionSlice.actions.setCurrentAlertInformationAction(alertInformation)),
  updateCurrentDashboardInformationDispatch: (dashboardInformation) =>
    dispatch(subscriptionSlice.actions.setCurrentDashboardInformationAction(dashboardInformation)),
})

const mapStateToProps = (state) => ({
  isDrawerOpen: state.drawer.isOpen,
  alertInformation: state.subscription.alertInformation,
  dashboardInformation: state.subscription.dashboardInformation,
})

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
