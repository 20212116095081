import React from 'react'
import { Link } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'

import logo from '../../logo.png'
import './header.styles.scss'

const Header = () => (
  <div className="header">
    <Link className="logo-container" to="/">
      <img src={logo} className="logo" alt="Logo" />
    </Link>
    <Typography className="header-title" component="h1" variant="h5">
      <span className="buenaFrutaBold">Buena</span>
      <span className="buenaFruta">Fruta</span>
    </Typography>
  </div>
)

export default Header
