import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { connect, useDispatch, useSelector } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import PrimaryButton from '../button/button.component'
import useStyles from './size-chart.styles'
import { formatReadableToSizes, formatSizesToReadable, getPercentage, getPercentageTri } from '../../shared/utils/utils'
import dashboardSlice from '../../redux/dashboard/dashboard.slice'
import useSizeQuery from '../../shared/hooks/useSizeQuery'
import { OPERATOR_ROLE, SUPER_ADMIN_ROLE } from '../../shared/constants/roles'

const options = (data) => ({
  maintainAspectRatio: false,
  legend: {
    position: 'bottom',
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label(tooltipItem, chart) {
        const { datasets } = chart
        const { index } = tooltipItem
        const dataKey = chart.labels[index]
        const item = Object.values(data).find((item) => item.value === dataKey)
        const actualValue = datasets.length > 1 ? item.quantity + item.undersize + item.oversize : item.quantity

        return `${tooltipItem.value}% (${actualValue})`
      },
    },
  },
})

const height = 80

const SizeResults = ({
  location,
  dataIn,
  dataFb,
  sizeResultsDispatch,
  desTemplateSizeResults,
  userRole,
  handleResetClickData,
  t,
}) => {
  const classes = useStyles()
  const handleChange = (event) => {
    sizeResultsDispatch(event.target.value)
  }
  const [resetClicked, setResetClicked] = useState(false)
  const [resetDisabled, setResetDisabled] = useState(false)
  const [lotChanged, setLotChanged] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState(3600)

  const currentLot = useSelector((state) => state.lots.currentLot)

  const handleResetClick = () => {
    if (!resetClicked) {
      const clickTime = new Date().getTime()
      localStorage.setItem('clickTime', clickTime.toString())
      localStorage.setItem('lotNumber', currentLot.lot_number)
      localStorage.setItem('timeRemaining', timeRemaining.toString())
      handleResetClickData()
      setResetClicked(true)
      setResetDisabled(true)
      const timeout = setTimeout(() => {
        setResetClicked(false)
        setResetDisabled(false)
        setTimeRemaining(3600)
        localStorage.removeItem('clickTime')
        localStorage.removeItem('lotNumber')
        localStorage.removeItem('timeRemaining')
      }, 3600000)
      return () => clearTimeout(timeout)
    }
    return null
  }

  const formatTimeRemaining = (time) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time % 3600) / 60)
    const seconds = time % 60
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  useEffect(() => {
    const clickTime = localStorage.getItem('clickTime')
    if (clickTime) {
      const timeElapsed = new Date().getTime() - Number(clickTime)
      if (timeElapsed < 3600000) {
        setResetDisabled(true)
        setTimeRemaining(Math.ceil((3600000 - timeElapsed) / 1000))
        const timeout = setTimeout(() => {
          setResetClicked(false)
          setResetDisabled(false)
          setTimeRemaining(3600)
          localStorage.removeItem('clickTime')
          localStorage.removeItem('lotNumber')
          localStorage.removeItem('timeRemaining')
        }, 3600000 - timeElapsed)
        return () => clearTimeout(timeout)
      }
      localStorage.removeItem('clickTime')
      localStorage.removeItem('lotNumber')
      localStorage.removeItem('timeRemaining')
    }
    return () => {}
  }, [resetClicked, resetDisabled, lotChanged])

  useEffect(() => {
    const storedLotNumber = localStorage.getItem('lotNumber')
    if (String(storedLotNumber) !== String(currentLot.lot_number)) {
      setResetClicked(false)
      setResetDisabled(false)
      setTimeRemaining(3600)
      setLotChanged(true)
      localStorage.removeItem('clickTime')
      localStorage.removeItem('timeRemaining')
      localStorage.setItem('lotNumber', currentLot.lot_number)
    }
  }, [currentLot.lot_number])

  useEffect(() => {
    // Bắt đầu đếm ngược sau mỗi giây
    const interval = setInterval(() => {
      if (resetClicked && timeRemaining > 0) {
        setTimeRemaining((prevTime) => prevTime - 1)
      }
    }, 1000)
    // Xoá interval khi component unmount hoặc resetClicked không còn được kích hoạt
    return () => {
      clearInterval(interval)
    }
  }, [resetClicked, timeRemaining])

  useEffect(() => {
    // Lấy thời gian còn lại từ localStorage khi component được mount
    const clickTime = localStorage.getItem('clickTime')
    const storedTimeRemaining = localStorage.getItem('timeRemaining')
    if (clickTime && storedTimeRemaining) {
      const timeElapsed = new Date().getTime() - Number(clickTime)
      if (timeElapsed < 3600000) {
        setResetClicked(true)
        setResetDisabled(true)
        setTimeRemaining(Number(storedTimeRemaining))
      }
    }
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item md={2} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{ marginBottom: 5 }}>
            {t('location')}
          </FormLabel>
          <RadioGroup aria-label="gender" name="location" value={location} onChange={handleChange}>
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={`${
                desTemplateSizeResults.TEMPLATE_LOC_INL === 'In-Line'
                  ? t('in_line')
                  : desTemplateSizeResults.TEMPLATE_LOC_INL
              }`}
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label={`${
                desTemplateSizeResults.TEMPLATE_LOC_FIN === 'Finished Box'
                  ? t('finished_box')
                  : desTemplateSizeResults.TEMPLATE_LOC_FIN
              }`}
            />
          </RadioGroup>
        </FormControl>
        {location === '1' && (userRole === OPERATOR_ROLE || userRole === SUPER_ADMIN_ROLE) && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 40 }}>
            <PrimaryButton disabled={resetDisabled} onClick={handleResetClick} style={{ marginRight: 10 }}>
              <Icon className={classes.iconBtn}>refresh</Icon> {t('reset')}
            </PrimaryButton>
            {resetClicked && (
              <Typography variant="body2" color="textSecondary">
                {formatTimeRemaining(timeRemaining)}
              </Typography>
            )}
          </div>
        )}
      </Grid>

      <Grid item xs={12} md={10} className={classes.containerChart}>
        {
          {
            1: <RenderTriBarChart data={dataIn} t={t} />,
            2: <RenderTriBarChart data={dataFb} t={t} />,
          }[location]
        }
      </Grid>
    </Grid>
  )
}

const RenderTriBarChart = ({ t, ...props }) => {
  const [orderedBarKeys, setOrderedKeys] = useState([])
  useEffect(() => {
    if (props.data) {
      // From object, create array and sort.
      const sortable = Object.entries(props.data)
        .sort(([, a], [, b]) => b.id - a.id)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
      const keys = Object.keys(sortable).map((key) => sortable[key].value)
      setOrderedKeys(keys)
    }
  }, [props.data])

  return (
    <Bar
      data={{
        labels: orderedBarKeys,
        datasets: [
          {
            label: t('unders'),
            backgroundColor: '#E84C3D',
            borderColor: '#E84C3D',
            borderWidth: 1,
            data: orderedBarKeys.map((key) => {
              const data = Object.values(props.data).find((item) => item.value === key)
              if (!data) return 0
              return getPercentageTri(data, 0)
            }),
          },
          {
            label: t('corrects'),
            backgroundColor: '#66bb6a',
            borderColor: '#66bb6a',
            borderWidth: 1,
            data: orderedBarKeys.map((key) => {
              const data = Object.values(props.data).find((item) => item.value === key)
              if (!data) return 0
              return getPercentageTri(data, 1)
            }),
          },
          {
            label: t('overs'),
            backgroundColor: '#ffa726',
            borderColor: '#ffa726',
            borderWidth: 1,
            data: orderedBarKeys.map((key) => {
              const data = Object.values(props.data).find((item) => item.value === key)
              if (!data) return 0
              return getPercentageTri(data, 2)
            }),
          },
        ],
      }}
      height={height}
      options={options(props.data)}
    />
  )
}

const SizeDistribution = ({
  location,
  dataRec,
  dataUp,
  totalRec,
  totalUps,
  sizeDistributionDispatch,
  desTemplateSizeDistribution,
  t,
}) => {
  const [dataAverage, setDataAverage] = useState({})
  const classes = useStyles()

  useEffect(() => {
    // calculate Average
    // get all the keys contained in both receiving and upstream data hashes
    const indexes = [...new Set([...Object.keys(dataRec), ...Object.keys(dataUp)])]

    const dataAverageHelper = {}
    indexes.forEach((i) => {
      // calculate totals for ups + rec
      const rec = dataRec && dataRec[i] ? dataRec[i]?.quantity : 0
      const ups = dataUp && dataUp[i] ? dataUp[i]?.quantity : 0
      const id = dataRec[i] ? dataRec[i]?.id : dataUp[i] ? dataUp[i]?.id : 0
      const value = dataRec[i] ? dataRec[i]?.value : dataUp[i] ? dataUp[i]?.value : 0
      dataAverageHelper[i] = { quantity: rec + ups, id, value }
    })

    setDataAverage(dataAverageHelper)
  }, [dataRec, dataUp])

  const handleChange = (event) => {
    sizeDistributionDispatch(event.target.value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={2} xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{ marginBottom: 5 }}>
            {t('location')}
          </FormLabel>
          <RadioGroup aria-label="gender" name="location" value={location} onChange={handleChange}>
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={`${
                desTemplateSizeDistribution.TEMPLATE_LOC_REC === 'Receiving'
                  ? t('receiving')
                  : desTemplateSizeDistribution.TEMPLATE_LOC_REC
              }`}
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label={`${
                desTemplateSizeDistribution.TEMPLATE_LOC_UPS === 'Upstream'
                  ? t('upstream')
                  : desTemplateSizeDistribution.TEMPLATE_LOC_UPS
              }`}
            />
            <FormControlLabel value="3" control={<Radio />} label={t('average')} />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={10} className={classes.containerChart}>
        {
          {
            1: <RenderBarChart total={totalRec} data={dataRec} />,
            2: <RenderBarChart total={totalUps} data={dataUp} />,
            3: <RenderBarChart total={totalRec + totalUps} data={dataAverage} />,
          }[location]
        }
      </Grid>
    </Grid>
  )
}

const RenderBarChart = ({ ...props }) => {
  const barDataOptions = {
    label: '',
    backgroundColor: '#66bb6a',
    borderColor: '#66bb6a',
    borderWidth: 1,
  }

  const [orderedBarKeys, setOrderedKeys] = useState([])

  useEffect(() => {
    if (props.data) {
      // From object, create array and sort.
      const sortable = Object.entries(props.data)
        .sort(([, a], [, b]) => b.id - a.id)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
      let keys = Object.keys(sortable).map((key) => sortable[key].value)

      // Remove "Undersize" from its current position
      keys = keys.filter((key) => key !== 'Undersize')

      // Check if there is any data
      const hasData = Object.values(props.data).some(
        (item) => item.quantity > 0 || item.undersize > 0 || item.oversize > 0
      )

      // Add "Undersize" to the beginning of the array if there is data
      if (hasData) {
        keys.unshift('Undersize')
      }

      setOrderedKeys(keys)
    }
  }, [props.data])

  return (
    <Bar
      data={{
        labels: orderedBarKeys,
        datasets: [
          {
            ...barDataOptions,
            data: orderedBarKeys.map((i) => {
              const data = Object.values(props.data).find((item) => item.value === i)
              if (!data) return 0
              return getPercentage(props.total, data.quantity)
            }),
          },
        ],
      }}
      height={height}
      options={options(props.data)}
    />
  )
}

const SizeChart = ({
  modeChangeDispart,
  mode,
  locationDistribution,
  sizeDistributionDispatch,
  locationResult,
  sizeResultsDispatch,
  desTemplates,
  userRole,
  handleResetClickData,
  t,
  ...props
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const locations = props.locations ?? {}
  const sizeQuery = useSizeQuery()

  const ClearSizeChart = () => {
    setData({})
  }

  const assignDataSize = (dataLocation) => {
    const dataResult = {}
    if (sizeQuery.data.length > 0 && Object.values(dataLocation).length > 0) {
      sizeQuery.data.forEach((size) => {
        const sizeProps = Object.values(dataLocation).find((item) => item.name === size.name)
        if (sizeProps) {
          dataResult[size.name] = { ...sizeProps, ...size }
        } else {
          dataResult[size.name] = {
            ...size,
            oversize: 0,
            quantity: 0,
            undersize: 0,
          }
        }
      })
      const sizeUnder = Object.values(dataLocation).find((item) => item.name === 'Undersize')
      if (sizeUnder) {
        dataResult[sizeUnder.name] = { ...sizeUnder, value: 'Undersize' }
      }
    }
    return dataResult
  }

  useEffect(() => {
    // build the datasets
    const newData = props.newData ?? {}
    const dataSizingResult = newData?.result
    ClearSizeChart()
    if (dataSizingResult?.length > 0) {
      for (let i = 0; i < dataSizingResult?.length; i++) {
        if (newData && dataSizingResult[i].sample_data && dataSizingResult[i].id_sampletype === 1) {
          const dataObject = dataSizingResult[i].sample_data.reduce((acc, obj) => {
            const key = obj.name
            acc[key] = obj
            return acc
          }, {})
          let loc = ''
          if (dataSizingResult[i].template_location === 'LOC_FIN') {
            loc = 'fin'
          }
          if (dataSizingResult[i].template_location === 'LOC_REC') {
            loc = 'rec'
          }
          if (dataSizingResult[i].template_location === 'LOC_UPS') {
            loc = 'ups'
          }
          if (dataSizingResult[i].template_location === 'LOC_INL') {
            loc = 'inl'
          }
          if (loc !== '') {
            setData((prev) => ({ ...prev, [loc]: assignDataSize(dataObject) }))
          }
        }
      }
    }
  }, [props.newData, props.locations, sizeQuery.data])

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div className={classes.container}>
          <div className={classes.flex}>
            <Icon className={classes.icon}>close_fullscreen</Icon>
            <Typography variant="subtitle1" color="primary">
              {mode ? t('sizing_accuracies') : t('size_distribution_of_incoming_lot')}
            </Typography>
          </div>

          <PrimaryButton onClick={() => modeChangeDispart()}>
            <Icon className={classes.iconBtn}>leaderboard</Icon> {`${t('size')} `}
            {mode ? t('distribution') : t('results')}
          </PrimaryButton>
        </div>
        <div style={{ border: '1px solid #eee', marginTop: 5, marginBottom: 15 }} />
        {mode ? (
          <SizeResults
            locations={props.locations}
            location={locationResult}
            desTemplateSizeResults={desTemplates}
            dataIn={data && data.inl ? data.inl : {}}
            dataFb={data && data.fin ? data.fin : {}}
            sizeResultsDispatch={sizeResultsDispatch}
            dataSize={sizeQuery.data}
            userRole={userRole}
            handleResetClickData={handleResetClickData}
            t={t}
          />
        ) : (
          <SizeDistribution
            locations={props.locations}
            location={locationDistribution}
            desTemplateSizeDistribution={desTemplates}
            dataRec={data && data.rec ? data.rec : {}}
            dataUp={data && data.ups ? data.ups : {}}
            totalRec={props.totalRec}
            totalUps={props.totalUps}
            sizeDistributionDispatch={sizeDistributionDispatch}
            dataSize={sizeQuery.data}
            t={t}
          />
        )}
      </CardContent>
    </Card>
  )
}

const mapStateToProps = (state) => ({
  mode: state.dashboard.mode,
  locationDistribution: state.dashboard.sizeDistributionVal,
  locationResult: state.dashboard.sizeResultsVal,
  userRole: state.user.role,
})

const mapDispatchToProps = (dispatch) => ({
  modeChangeDispart: () => dispatch(dashboardSlice.actions.modeChangeAction()),
  sizeDistributionDispatch: (value) => dispatch(dashboardSlice.actions.sizeDistributionAction(value)),
  sizeResultsDispatch: (value) => dispatch(dashboardSlice.actions.sizeResultsAction(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SizeChart)
