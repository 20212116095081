import { useMemo } from 'react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import AppInsightsTrackingContext from './AppInsightsTrackingContext'

const AppInsightsTrackingContextProvider = ({ children }) => {
  const appInsights = useAppInsightsContext()

  const trackEvent = (eventName, data) => {
    appInsights.trackEvent({ name: eventName }, data)
  }

  const value = useMemo(
    () => ({
      trackEvent,
    }),
    [trackEvent]
  )

  return <AppInsightsTrackingContext.Provider value={value}>{children}</AppInsightsTrackingContext.Provider>
}

export default AppInsightsTrackingContextProvider
