import React from 'react'
import Typography from '@material-ui/core/Typography'
import useStyles from './inline-chart.styles'

const InLineChart = ({ ...props }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <div className={classes.container1}>
          <Typography variant="h4">{props.value1}%</Typography>
          <Typography variant="body2">{props.name1}</Typography>
        </div>
      </div>
      <div className={classes.container2}>
        <Typography variant="h4">{props.value2}%</Typography>
        <Typography variant="body2">{props.name2}</Typography>
      </div>
    </div>
  )
}

export default InLineChart
