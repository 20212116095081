import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import * as XLSXStyle from 'xlsx-js-style'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { ImageList, ImageListItem } from '@material-ui/core'
import PrintButton from '../../../components/print-button/print-button.component'
import ReportInputs from '../../../components/report-inputs/report-inputs.component'
import SimpleBackdrop from '../../../components/simple-backdrop/simple-backdrop.component'

import PdfMakerService from '../../../services/pdf-maker/pdf-maker-service'
import RequestService from '../../../services/request/request-service'

import makeStyles from '../reports.styles'
import { numberToPercentage } from '../../../shared/utils/utils'

import { DEFECT_BY_NAME_SUMMARY_QUERY } from '../../../shared/constants/queries'

const Defect = ({
  selectedLot,
  selectedLotName,
  selectedVariety,
  selectedVarietyName,
  selectedGrowerName,
  currentFacility,
  selectedStartDate,
  selectedEndDate,
  selectedMachine,
  desTemplates,
}) => {
  const classes = makeStyles()
  const history = useHistory()

  const [showReport, setShowReport] = useState(false)
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [dateReport, setDateReport] = useState('')
  const [generalData, setGeneralData] = useState({})
  const [machineName, setMachineName] = useState('')

  useEffect(() => {
    if (selectedVariety === 0 || selectedLot === 0) {
      setShowReport(false)
    }
  }, [selectedLot, selectedVariety])

  const loadDefectReport = async () => {
    try {
      setShowBackdrop(true)
      // eslint-disable-next-line max-len
      const url = `${DEFECT_BY_NAME_SUMMARY_QUERY}?id_lot=${selectedLot}&id_variety=${selectedVariety}&id_facility=${currentFacility.id}&start_date=${selectedStartDate}Z&end_date=${selectedEndDate}Z&id_machine=${selectedMachine.id}`
      const response = await RequestService.Get(url, history)
      setGeneralData(response.data)
      let DateReport = ''
      if (
        DateTime.fromISO(selectedStartDate).toFormat('MMM-dd-yy') ===
        DateTime.fromISO(selectedEndDate).toFormat('MMM-dd-yy')
      ) {
        DateReport = DateTime.fromISO(selectedStartDate).toFormat('MMM-dd-yy')
      } else {
        DateReport = `${DateTime.fromISO(selectedStartDate).toFormat('MMM-dd-yy')} to ${DateTime.fromISO(
          selectedEndDate
        ).toFormat('MMM-dd-yy')}`
      }
      setDateReport(DateReport)
      setMachineName(
        selectedMachine.name ? selectedMachine.name : selectedMachine.description ? selectedMachine.description : ''
      )
      setShowBackdrop(false)
      setShowReport(true)
    } catch (error) {
      console.log(error)
    }
  }

  const printDocument = () => {
    PdfMakerService.generateReport(
      `DefectSummary_${selectedLotName?.trim() ?? ''}_${selectedVarietyName?.trim() ?? ''}`,
      'DefectSummary',
      {
        ...generalData,
        dateReport,
        machineName,
        desTemplates,
      }
    )
  }

  const printfReportExcel = () => {
    const receivingExcel = []
    const upstreamQualityExcel = []
    const inLineQuality = []
    const finishBoxQuality = []

    if (
      generalData.receivingQuality.minorDefect !== null &&
      generalData.receivingQuality.majorDefect !== null &&
      generalData.receivingQuality.totalLocations !== 0
    ) {
      receivingExcel.push(
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: `${desTemplates.TEMPLATE_LOC_REC || 'Receving'} (${numberToPercentage(
              Number((generalData.receivingQuality.totalLocations * 100).toFixed(2))
            )})`,
          },
        ],
        []
      )
    }

    if (generalData.receivingQuality.minorDefect !== null && generalData.receivingQuality.minorDefect?.length !== 0) {
      const bodyMinor = generalData?.receivingQuality?.minorDefect?.map((defect) => {
        const { name, value } = defect
        return [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: name,
          },
          {
            t: 's',
            s: { font: { sz: 11 } },
            v: numberToPercentage(Number((value * 100).toFixed(2))),
          },
        ]
      })

      receivingExcel.push(
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Minor Defects',
          },
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Percentage',
          },
        ],
        ...bodyMinor,
        []
      )
    }
    if (generalData.receivingQuality.majorDefect !== null && generalData.receivingQuality.majorDefect?.length !== 0) {
      const bodyMajor = generalData?.receivingQuality?.majorDefect?.map((defect) => {
        const { name, value } = defect
        return [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: name,
          },
          {
            t: 's',
            s: { font: { sz: 11 } },
            v: numberToPercentage(Number((value * 100).toFixed(2))),
          },
        ]
      })

      receivingExcel.push(
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Major Defects',
          },
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Percentage',
          },
        ],
        ...bodyMajor,
        []
      )
    }

    if (
      generalData.upstreamQuality.minorDefect !== null &&
      generalData.upstreamQuality.majorDefect !== null &&
      generalData.upstreamQuality.totalLocations !== 0
    ) {
      upstreamQualityExcel.push(
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: `${desTemplates.TEMPLATE_LOC_UPS || 'Upstream'} (${numberToPercentage(
              Number((generalData.upstreamQuality.totalLocations * 100).toFixed(2))
            )})`,
          },
        ],
        []
      )
    }

    if (generalData.upstreamQuality.minorDefect !== null && generalData.upstreamQuality.minorDefect?.length !== 0) {
      const bodyMinor = generalData?.upstreamQuality?.minorDefect?.map((defect) => {
        const { name, value } = defect
        return [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: name,
          },
          {
            t: 's',
            s: { font: { sz: 11 } },
            v: numberToPercentage(Number((value * 100).toFixed(2))),
          },
        ]
      })

      upstreamQualityExcel.push(
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Minor Defects',
          },
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Percentage',
          },
        ],
        ...bodyMinor,
        []
      )
    }
    if (generalData.upstreamQuality.majorDefect !== null && generalData.upstreamQuality.majorDefect?.length !== 0) {
      const bodyMajor = generalData?.upstreamQuality?.majorDefect?.map((defect) => {
        const { name, value } = defect
        return [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: name,
          },
          {
            t: 's',
            s: { font: { sz: 11 } },
            v: numberToPercentage(Number((value * 100).toFixed(2))),
          },
        ]
      })

      upstreamQualityExcel.push(
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Major Defects',
          },
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Percentage',
          },
        ],
        ...bodyMajor,
        []
      )
    }
    if (
      generalData.inLineQuality.minorDefect !== null &&
      generalData.inLineQuality.majorDefect !== null &&
      generalData.inLineQuality.totalLocations !== 0
    ) {
      inLineQuality.push(
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: `${desTemplates.TEMPLATE_LOC_INL || 'InLine'} (${numberToPercentage(
              Number((generalData.inLineQuality.totalLocations * 100).toFixed(2))
            )})`,
          },
        ],
        []
      )
    }

    if (generalData.inLineQuality.minorDefect !== null && generalData.inLineQuality.minorDefect?.length !== 0) {
      const bodyMinor = generalData?.inLineQuality?.minorDefect?.map((defect) => {
        const { name, value } = defect
        return [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: name,
          },
          {
            t: 's',
            s: { font: { sz: 11 } },
            v: numberToPercentage(Number((value * 100).toFixed(2))),
          },
        ]
      })

      inLineQuality.push(
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Minor Defects',
          },
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Percentage',
          },
        ],
        ...bodyMinor,
        []
      )
    }
    if (generalData.inLineQuality.majorDefect !== null && generalData.inLineQuality.majorDefect?.length !== 0) {
      const bodyMajor = generalData?.inLineQuality?.majorDefect.map((defect) => {
        const { name, value } = defect
        return [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: name,
          },
          {
            t: 's',
            s: { font: { sz: 11 } },
            v: numberToPercentage(Number((value * 100).toFixed(2))),
          },
        ]
      })

      inLineQuality.push(
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Major Defects',
          },
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Percentage',
          },
        ],
        ...bodyMajor,
        []
      )
    }

    if (
      generalData.finishBoxQuality.minorDefect !== null &&
      generalData.finishBoxQuality.majorDefect !== null &&
      generalData.finishBoxQuality.totalLocations !== 0
    ) {
      finishBoxQuality.push(
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: `${desTemplates.TEMPLATE_LOC_FIN || 'FinishBox'} (${numberToPercentage(
              Number((generalData.finishBoxQuality.totalLocations * 100).toFixed(2))
            )})`,
          },
        ],
        []
      )
    }

    if (generalData.finishBoxQuality.minorDefect !== null && generalData.finishBoxQuality.minorDefect?.length !== 0) {
      const bodyMinor = generalData?.finishBoxQuality?.minorDefect?.map((defect) => {
        const { name, value } = defect
        return [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: name,
          },
          {
            t: 's',
            s: { font: { sz: 11 } },
            v: numberToPercentage(Number((value * 100).toFixed(2))),
          },
        ]
      })

      finishBoxQuality.push(
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Minor Defects',
          },
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Percentage',
          },
        ],
        ...bodyMinor,
        []
      )
    }
    if (generalData.finishBoxQuality.majorDefect !== null && generalData.finishBoxQuality.majorDefect?.length !== 0) {
      const bodyMajor = generalData?.finishBoxQuality?.majorDefect?.map((defect) => {
        const { name, value } = defect
        return [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: name,
          },
          {
            t: 's',
            s: { font: { sz: 11 } },
            v: numberToPercentage(Number((value * 100).toFixed(2))),
          },
        ]
      })

      finishBoxQuality.push(
        [
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Major Defects',
          },
          {
            t: 's',
            s: { font: { bold: true, sz: 11 } },
            v: 'Percentage',
          },
        ],
        ...bodyMajor,
        []
      )
    }

    const sizeDistributionData = [
      [
        {
          t: 's',
          s: { font: { bold: true, sz: 11 } },
          v: 'Defect Summary',
        },
      ],
      [],
      ...receivingExcel,
      ...upstreamQualityExcel,
      ...inLineQuality,
      ...finishBoxQuality,
    ]

    const sizeDistribution = XLSXStyle.utils.aoa_to_sheet(sizeDistributionData)
    const sizeDistributionCols = [{ wch: 30 }, { wch: 18 }]
    sizeDistribution['!cols'] = sizeDistributionCols
    const excelFile = XLSXStyle.utils.book_new()
    XLSXStyle.utils.book_append_sheet(excelFile, sizeDistribution, 'Defect Summary')
    XLSXStyle.writeFile(
      excelFile,
      `DefectSummary_${selectedLotName?.trim() ?? ''}_${selectedVarietyName?.trim() ?? ''}.xlsx`
    )
  }

  return (
    <Grid container>
      {!showReport ? null : <PrintButton printDocument={printDocument} printfReportExcel={printfReportExcel} />}
      <ReportInputs loadData={loadDefectReport} isLoading={showBackdrop} />
      {!showReport ? null : (
        <>
          <Grid xs={12} item>
            <Typography align="center" variant="h6">
              Defect Summary
            </Typography>
          </Grid>
          {generalData.receivingQuality.minorDefect !== null &&
          generalData.receivingQuality.majorDefect !== null &&
          generalData.receivingQuality.totalLocations !== 0 ? (
            <Grid xs={12} item>
              <Typography variant="h6">
                {desTemplates.TEMPLATE_LOC_REC || 'Receving'} (
                {numberToPercentage(Number((generalData.receivingQuality.totalLocations * 100).toFixed(2)))})
              </Typography>
            </Grid>
          ) : null}
          {generalData.receivingQuality.minorDefect !== null &&
          generalData.receivingQuality.minorDefect?.length !== 0 ? (
            <Grid xs={6} className={classes.grid} item>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow key="Receving Minor Defect Header">
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Minor Defects</TableCell>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Percentage</TableCell>
                    </TableRow>
                    {generalData?.receivingQuality?.minorDefect?.map((defect) => {
                      const { name, value } = defect
                      return (
                        <TableRow key={`Receving minor Defect ${name}`}>
                          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {numberToPercentage(Number((value * 100).toFixed(2)))}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null}
          {generalData.receivingQuality.majorDefect !== null &&
          generalData.receivingQuality.majorDefect?.length !== 0 ? (
            <Grid xs={6} className={classes.grid} item>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow key="Receving Major Defect Header">
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Major Defects</TableCell>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Percentage</TableCell>
                    </TableRow>
                    {generalData?.receivingQuality?.majorDefect?.map((defect) => {
                      const { name, value } = defect
                      return (
                        <TableRow key={`Receving Major Defect ${name}`}>
                          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {numberToPercentage(Number((value * 100).toFixed(2)))}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null}
          {generalData.upstreamQuality.minorDefect !== null &&
          generalData.upstreamQuality.majorDefect !== null &&
          generalData.upstreamQuality.totalLocations !== 0 ? (
            <Grid xs={12} item>
              <Typography variant="h6">
                {desTemplates.TEMPLATE_LOC_UPS || 'Upstream'} (
                {numberToPercentage(Number((generalData.upstreamQuality.totalLocations * 100).toFixed(2)))})
              </Typography>
            </Grid>
          ) : null}
          {generalData.upstreamQuality.minorDefect !== null && generalData.upstreamQuality.minorDefect?.length !== 0 ? (
            <Grid xs={6} className={classes.grid} item>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow key="Upstream Minor Defect Header">
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Minor Defects</TableCell>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Percentage</TableCell>
                    </TableRow>
                    {generalData?.upstreamQuality?.minorDefect?.map((defect) => {
                      const { name, value } = defect
                      return (
                        <TableRow key={`Upstream minor Defect ${name}`}>
                          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {numberToPercentage(Number((value * 100).toFixed(2)))}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null}
          {generalData.upstreamQuality.majorDefect !== null && generalData.upstreamQuality.majorDefect?.length !== 0 ? (
            <Grid xs={6} className={classes.grid} item>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow key="Upstream Major Defect Header">
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Major Defects</TableCell>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Percentage</TableCell>
                    </TableRow>
                    {generalData?.upstreamQuality?.majorDefect?.map((defect) => {
                      const { name, value } = defect
                      return (
                        <TableRow key={`Upstream Major Defect ${name}`}>
                          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {' '}
                            {numberToPercentage(Number((value * 100).toFixed(2)))}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null}
          {generalData.inLineQuality.minorDefect !== null &&
          generalData.inLineQuality.majorDefect !== null &&
          generalData.inLineQuality.totalLocations !== 0 ? (
            <Grid xs={12} item>
              <Typography variant="h6">
                {desTemplates.TEMPLATE_LOC_INL || 'InLine'} (
                {numberToPercentage(Number((generalData.inLineQuality.totalLocations * 100).toFixed(2)))})
              </Typography>
            </Grid>
          ) : null}
          {generalData.inLineQuality.minorDefect !== null && generalData.inLineQuality.minorDefect?.length !== 0 ? (
            <Grid xs={6} className={classes.grid} item>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow key="InLine Minor Defect Header">
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Minor Defects</TableCell>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Percentage</TableCell>
                    </TableRow>
                    {generalData?.inLineQuality?.minorDefect?.map((defect) => {
                      const { name, value } = defect
                      return (
                        <TableRow key={`InLine minor Defect ${name}`}>
                          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {numberToPercentage(Number((value * 100).toFixed(2)))}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null}
          {generalData.inLineQuality.majorDefect !== null && generalData.inLineQuality.majorDefect?.length !== 0 ? (
            <Grid xs={6} className={classes.grid} item>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow key="InLine Major Defect Header">
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Major Defects</TableCell>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Percentage</TableCell>
                    </TableRow>
                    {generalData?.inLineQuality?.majorDefect?.map((defect) => {
                      const { name, value } = defect
                      return (
                        <TableRow key={`InLine Major Defect ${name}`}>
                          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {' '}
                            {numberToPercentage(Number((value * 100).toFixed(2)))}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null}
          {generalData.finishBoxQuality.minorDefect !== null &&
          generalData.finishBoxQuality.majorDefect !== null &&
          generalData.finishBoxQuality.totalLocations !== 0 ? (
            <Grid xs={12} item>
              <Typography variant="h6">
                {desTemplates.TEMPLATE_LOC_FIN || 'FinishBox'} (
                {numberToPercentage(Number((generalData.finishBoxQuality.totalLocations * 100).toFixed(2)))})
              </Typography>
            </Grid>
          ) : null}
          {generalData.finishBoxQuality.minorDefect !== null &&
          generalData.finishBoxQuality.minorDefect?.length !== 0 ? (
            <Grid xs={6} className={classes.grid} item>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow key="FinishBox Minor Defect Header">
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Minor Defects</TableCell>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Percentage</TableCell>
                    </TableRow>
                    {generalData?.finishBoxQuality?.minorDefect?.map((defect) => {
                      const { name, value } = defect
                      return (
                        <TableRow key={`FinishBox minor Defect ${name}`}>
                          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {numberToPercentage(Number((value * 100).toFixed(2)))}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null}
          {generalData.finishBoxQuality.majorDefect !== null &&
          generalData.finishBoxQuality.majorDefect?.length !== 0 ? (
            <Grid xs={6} className={classes.grid} item>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow key="finishBox Major Defect Header">
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Major Defects</TableCell>
                      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>Percentage</TableCell>
                    </TableRow>
                    {generalData?.finishBoxQuality?.majorDefect?.map((defect) => {
                      const { name, value } = defect
                      return (
                        <TableRow key={`finishBox Major Defect ${name}`}>
                          <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`}>{name}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {' '}
                            {numberToPercentage(Number((value * 100).toFixed(2)))}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null}
        </>
      )}
      <SimpleBackdrop open={showBackdrop} />
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  selectedLot: state.reports.selectedLot,
  selectedLotName: state.reports.selectedLotName,
  selectedVariety: state.reports.selectedVariety,
  selectedVarietyName: state.reports.selectedVarietyName,
  selectedGrowerName: state.reports.selectedGrowerName,
  selectedStartDate: state.reports.selectedStartDate,
  selectedEndDate: state.reports.selectedEndDate,
  currentFacility: state.facility.currentFacility,
  selectedMachine: state.reports.selectedMachine,
})

export default connect(mapStateToProps)(Defect)
