import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createStructuredSelector } from 'reselect'
import { connect, useDispatch } from 'react-redux'

import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { useSnackbar } from 'notistack'
import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import DashboardContext from '../../context/dashboard/DashboardContext'
import SecondaryLayout from '../../components/secondary-layout/secondary-layout.component'
import PrimaryButton from '../../components/button/button.component'
import SimpleBackdrop from '../../components/simple-backdrop/simple-backdrop.component'

import { validateEmail } from '../../shared/utils/utils'
import AuthService from '../../services/auth/auth-service'
import subscriptionSlice from '../../redux/subscription/subscription.slice.js'
import { selectSubscriptionData } from '../../redux/subscription/subscription.selectors'

import './login.styles.scss'
import useStyles from './login.styles.js'
import { ALERT_SUBSCRIPTION, DASHBOARD_SUBSCRIPTION } from '../../shared/constants/general'
import { LOG_PAGE_LOADS, LOG_ACTIONS } from '../../shared/constants/logging'

const Login = ({
  updateCurrentAlertInformationDispatch,
  updateCurrentDashboardInformationDispatch,
  subscriptionData,
}) => {
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const { cleanUpDashboardContext, cleanUpAlertContext, deleteSubscription } = useContext(DashboardContext)
  const { dashboardInformation, alertInformation } = subscriptionData
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const [disableLogin, setDisableLogin] = useState(false)
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    emailError: false,
    emailErrorMsg: '',
    passError: false,
    passErrorMsg: '',
  })

  const { email, emailError, emailErrorMsg, password, passError, passErrorMsg } = credentials
  const { enqueueSnackbar } = useSnackbar()
  // Run clean up when landing here, in case user was kicked out.
  useEffect(async () => {
    await cleanUpDashboardContext()
    await deleteSubscription(
      dashboardInformation.topicName,
      dashboardInformation.subscriptionName,
      DASHBOARD_SUBSCRIPTION
    )
    await updateCurrentDashboardInformationDispatch({
      topicName: null,
      subscriptionName: null,
    })

    await cleanUpAlertContext()
    await deleteSubscription(alertInformation.topicName, alertInformation.subscriptionName, ALERT_SUBSCRIPTION)
    await updateCurrentAlertInformationDispatch({
      topicName: null,
      subscriptionName: null,
    })

    trackEvent(LOG_PAGE_LOADS.LOAD_LOGIN_PAGE, {})
  }, [])

  // ON SUBMIT VALIDATES AND CHECK CREDENTIALS
  const handleSubmit = async (event) => {
    event.preventDefault()
    if (email === '') {
      setCredentials({
        ...credentials,
        emailError: true,
        emailErrorMsg: t('email_is_required'),
      })
      return
    }

    if (!validateEmail(email)) {
      setCredentials({
        ...credentials,
        emailError: true,
        emailErrorMsg: t('email_is_invalid'),
      })
      return
    }

    if (password === '') {
      setCredentials({
        ...credentials,
        passError: true,
        passErrorMsg: t('password_is_required'),
      })
      return
    }

    setDisableLogin(true)
    setShowBackdrop(true)
    trackEvent(LOG_ACTIONS.LOGIN_SUBMITTED, { user: email })
    AuthService.logUser({ email, password }, dispatch)
      .then((response) => {
        enqueueSnackbar(t('you_have_successfully_logged_in'), { variant: 'success' })
        setTimeout(() => {
          trackEvent(LOG_ACTIONS.LOGIN_SUCCESSFUL, { user: email })
          setDisableLogin(false)
          setShowBackdrop(false)
          history.push(response)
        }, 2000)
      })
      .catch((reason) => {
        trackEvent(LOG_ACTIONS.LOGIN_ERROR, { user: email, error: reason })
        console.error('Authentication error has occurred.')
        setDisableLogin(false)
        setShowBackdrop(false)
      })
  }

  // ON CHANGE UPDATES DE CREDENTIALS, CLEAR DE ERROR VALIDATION
  const handleChange = (event) => {
    const { name, value } = event.target
    setCredentials({
      ...credentials,
      [name]: value,
      emailError: false,
      emailErrorMsg: '',
      passError: false,
      passErrorMsg: '',
    })
  }

  return (
    <SecondaryLayout>
      <Grid
        className={`${classes.rightGrid} ${classes.headerShadow}`}
        item
        xs={12}
        sm={8}
        md={7}
        component={Paper}
        elevation={0}
        square
      >
        <div className={classes.paper}>
          <Typography className="welcome-title" component="h1" variant="h4" color="primary">
            {t('welcome_back')}
          </Typography>
          <form className={`${classes.form} login-form`} onSubmit={handleSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('email')}
              name="email"
              autoFocus
              onChange={handleChange}
              error={emailError}
              helperText={emailErrorMsg}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('password')}
              type="password"
              id="password"
              onChange={handleChange}
              error={passError}
              helperText={passErrorMsg}
            />
            <Grid container>
              <Grid item xs />
              <Grid item>
                <PrimaryButton disabled={disableLogin} type="submit" className={classes.submit}>
                  {t('login')}
                </PrimaryButton>
              </Grid>
            </Grid>
          </form>
          <Grid className="forgot-button-container">
            <Link
              component="button"
              align="right"
              variant="body2"
              className={classes.forgotButtonLink}
              onClick={() => {
                history.push('/forgotpassword')
              }}
            >
              <div>{t('forgot_password')}</div>
            </Link>
          </Grid>
        </div>
      </Grid>
      <SimpleBackdrop open={showBackdrop} />
    </SecondaryLayout>
  )
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentAlertInformationDispatch: (alertInformation) =>
    dispatch(subscriptionSlice.actions.setCurrentAlertInformationAction(alertInformation)),
  updateCurrentDashboardInformationDispatch: (dashboardInformation) =>
    dispatch(subscriptionSlice.actions.setCurrentDashboardInformationAction(dashboardInformation)),
})

const mapStateToProps = createStructuredSelector({
  subscriptionData: selectSubscriptionData,
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
