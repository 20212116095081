import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  lotText: {
    marginTop: 5,
    marginBottom: 15,
  },
})

export default useStyles
