import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Icon from '@material-ui/core/Icon'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { DataGrid } from '@material-ui/data-grid'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Fade from '@material-ui/core/Fade'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import SingleDatePicker from '../../components/single-datepicker/simple-datepicker.component'
import SearchBar from '../../components/SearchBar/SearchBar.component'
import StaffOverview from './staff_overview.component'

import RequestService from '../../services/request/request-service'
import { ALERT_TYPES } from '../../shared/constants/general'
import { ROW_PER_PAGE_OPTIONS } from '../../shared/constants/table'
import {
  FACILITIES_LOCATIONS_QUERY,
  STAFF_PERFORMANCE_SAMPLES_QUERY,
  USER_QUERY,
  USERS_FACILITIES_QUERY,
  SHIFT_QUERY,
} from '../../shared/constants/queries'
import { QUALITY_CONTROL_ROLE, SUPER_ADMIN_ROLE, QUALITY_CONTROL_MANAGER_ROLE } from '../../shared/constants/roles'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import { convertMillisecondsToMinutesAndSeconds, searchFullColumns } from '../../shared/utils/utils'
import { funcExportExcel } from '../../components/export-to-excel/exportexcel.component'
import useMachineQuery from '../../shared/hooks/useMachineQuery'

import { staffStyles } from './staff.styles'

const Staff = ({ currentFacility, userId, userEmail }) => {
  const history = useHistory()
  const classes = staffStyles()
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const { t, i18n } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [rowsResponse, setRowsResponse] = useState([])
  const [facilities, setFacilities] = useState([])
  const [machines, setMachines] = useState([])
  const [users, setUsers] = useState([])
  const [tableConf] = useState({ page: 1, pageSize: 20 })
  const [openAlert, setOpenAlert] = useState(false)
  const [severity, setSeverity] = useState(ALERT_TYPES.success)
  const [locations, setLocations] = useState([])
  const [shifts, setShifts] = useState([])
  const [idFacility, setIdFacility] = useState(0)
  const [idMachine, setIdMachine] = useState(0)
  const [idLocation, setIdLocation] = useState(0)
  const [idShift, setIdShift] = useState(0)
  const [startDate, setStartDate] = useState(DateTime.now().startOf('day').toISO({ includeOffset: false }))
  const [endDate, setEndDate] = useState(DateTime.now().endOf('day').toISO({ includeOffset: false }))
  const [startDateISODate, setStartDateISODate] = useState(DateTime.now().startOf('day').toISODate())
  const [endDateISODate, setEndDateISODate] = useState(DateTime.now().endOf('day').toISODate())
  const [showStaffOverview, setShowStaffOverview] = useState(false)
  const [query, setQuery] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)

  const machineQuery = useMachineQuery({
    byFacility: true,
    filter: { id_facility: idFacility },
  })

  // columns definition for table
  const columns = [
    { field: 'user_name', headerName: t('qc_staff_member'), flex: 1, minWidth: 190 },
    {
      field: 'name_location',
      headerName: t('location'),
      flex: 1,
      minWidth: 170,
      // renderCell: (params) => <Chip label={params.value} />,
    },
    { field: 'samples_size_total', headerName: t('size_samples'), flex: 1, minWidth: 215 },
    { field: 'samples_grade_total', headerName: t('grade_samples'), flex: 1, minWidth: 210 },
    { field: 'samples_total', headerName: t('total_samples'), flex: 1, minWidth: 215 },
    { field: 'averageValue', hide: true },
    {
      field: 'average',
      headerName: t('average_sample_times'),
      flex: 1,
      minWidth: 240,
      sortComparator: (v1, v2, param1, param2) => {
        return param1.api.getCellValue(param1.id, 'averageValue') - param2.api.getCellValue(param2.id, 'averageValue')
      },
    },
  ]

  useEffect(() => {
    if (currentFacility && currentFacility.id && facilities.length > 0) setIdFacility(currentFacility.id)
  }, [currentFacility.id, facilities])

  useEffect(async () => {
    async function fetchData() {
      try {
        setLoading(true)
        const loadFacilities = new Promise(() => {
          const facilityURL = `${USERS_FACILITIES_QUERY}?id=${userId}`
          RequestService.Get(facilityURL, history).then((facilityResponse) => {
            setFacilities(facilityResponse.data.map((facility) => facility.second).filter((x) => x.active === true))
          })
        })
        await Promise.all([loadFacilities])
      } catch (error) {
        console.error(error)
        setSeverity(ALERT_TYPES.error)
        setOpenAlert(true)
      }
    }

    await fetchData()
    trackEvent(LOG_PAGE_LOADS.LOAD_STAFF_PAGE, { email: userEmail })
  }, [])

  useEffect(async () => {
    async function fetchData() {
      machineQuery.refetch()
      const loadRoles = new Promise(() => {
        const userURL = `${USER_QUERY}`
        RequestService.Get(userURL, history).then((userResponse) => {
          const users = userResponse.data
            .map((user) => {
              if (
                user.roles.includes(QUALITY_CONTROL_ROLE) ||
                user.roles.includes(SUPER_ADMIN_ROLE) ||
                user.roles.includes(QUALITY_CONTROL_MANAGER_ROLE)
              )
                return user
              return undefined
            })
            .filter((x) => x !== undefined)
          setUsers(users)
        })
      })
      let loadLocations
      let loadShifts
      if (idFacility !== 0) {
        loadLocations = new Promise(() => {
          const url = `${FACILITIES_LOCATIONS_QUERY}?id=${idFacility}&pageSize=100`
          RequestService.Get(url, history)
            .then((response) => {
              const locationsData = response.data.map((item) => ({
                id: item.second.id,
                name: item.second.name,
                description: item.second.description,
                active: item.second.active,
              }))
              setLocations(locationsData)
              console.log('locations', locationsData)
            })
            .catch((error) => {
              console.error(error)
              setSeverity(ALERT_TYPES.error)
              setOpenAlert(true)
            })
        })

        loadShifts = new Promise(() => {
          const url = `${SHIFT_QUERY}?pageSize=100`
          RequestService.Get(url, history)
            .then((response) => {
              setShifts(response.data)
            })
            .catch((error) => {
              console.error(error)
              setSeverity(ALERT_TYPES.error)
              setOpenAlert(true)
            })
        })
      }
      Promise.all([loadLocations, loadRoles, loadShifts])
    }
    await fetchData()
  }, [idFacility])

  // load data
  useEffect(async () => {
    const loadData = async () => {
      try {
        setLoading(true)
        const date = `&start_date=${startDate}Z&end_date=${endDate}Z`
        const location = idLocation !== 0 ? `&id_location=${idLocation}` : ''
        const shift = idShift !== 0 ? `&id_shift=${idShift}` : ''
        const machine = idMachine !== 0 ? `&id_machine=${idMachine}` : ''
        // eslint-disable-next-line max-len
        const url = `${STAFF_PERFORMANCE_SAMPLES_QUERY}?id_facility=${idFacility}${machine}${shift}${date}${location}&pageSize=100000`
        const response = await RequestService.Get(url, history)
        const facilityName = facilities.find((f) => f.id === idFacility).name
        const result = response?.data?.result || []
        const rows = result.map((item, index) => renderRow(item, index, facilityName)).filter((x) => x !== undefined)
        setRowsResponse(rows)
        setRows(rows)
      } catch (error) {
        console.error(error)
        setSeverity(ALERT_TYPES.error)
        setOpenAlert(true)
      } finally {
        setLoading(false)
      }
    }

    if (idFacility !== 0 && users.length > 0 && endDateISODate >= startDateISODate) await loadData()
  }, [idFacility, endDate, startDate, users, idLocation, idShift, idMachine])

  const renderRow = (item, index, facilityName) => {
    const location = locations.find((l) => l.id === item.id_location)
    const user = users.find((u) => u.id === item.id_user)
    if (user) {
      return {
        ...item,
        id: index,
        location: location ? location.name : '',
        userId: item.id_user,
        facility_name: facilityName,
        user_name: user.name,
        averageValue: item.average,
        average: convertMillisecondsToMinutesAndSeconds(item.average),
      }
    }
    return undefined
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpenAlert(false)
  }

  const handleChangeSearch = (event) => {
    const text = event.target.value
    searchFullColumns(rowsResponse, text, setRows, columns)
  }

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const printfReportExcel = () => {
    if (rows.length <= 0) return
    const header = []
    columns.forEach((item) => {
      if (!item.hide) header.push(item)
    })
    let dateReport = ''
    if (DateTime.fromISO(startDate).toFormat('dd-MMM-yy') === DateTime.fromISO(endDate).toFormat('dd-MMM-yy')) {
      dateReport = DateTime.fromISO(startDate).toFormat('dd-MMM-yy')
    } else {
      dateReport = `${DateTime.fromISO(startDate).toFormat('dd-MMM-yy')} to ${DateTime.fromISO(endDate).toFormat(
        'dd-MMM-yy'
      )}`
    }
    const shift = shifts.find((x) => x.id === idShift)?.name || 'All'
    const metaData = [
      [
        {
          v: t('staff_performance'),
          t: 's',
          s: { font: { bold: true, sz: 11 } },
        },
      ],
      [currentFacility.description],
      ['Shift', shift],
      ['Date range', dateReport],
    ]
    funcExportExcel(rows, header, `Staff Production-${dateReport}`, metaData)
  }

  const staff = (
    <div>
      <Grid item xs={12}>
        <Box pt={5} pb={3}>
          <Typography
            component="h1"
            variant="h5"
            color="primary"
            style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            {t('staff_performance')} <Icon color="secondary">people</Icon>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Card raised>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth size="small" variant="outlined" margin="normal">
                  <InputLabel id="demo-simple-select-label">{t('facility')}</InputLabel>
                  <Select
                    disabled={loading}
                    value={idFacility}
                    id="demo-simple-select"
                    labelId="demo-simple-select-label"
                    label={t('facility')}
                    onChange={(event) => setIdFacility(event.target.value)}
                  >
                    <MenuItem value={0}>
                      <em>{t('select_a_facility')}</em>
                    </MenuItem>
                    {facilities.map((item) => (
                      <MenuItem key={`cats-${item.id}`} value={item.id}>
                        {item.description ? item.description : item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth size="small" variant="outlined" margin="normal">
                  <InputLabel id="demo-simple-select-label">{t('machine')}</InputLabel>
                  <Select
                    disabled={loading}
                    value={idMachine}
                    id="demo-simple-select"
                    labelId="demo-simple-select-label"
                    label={t('machine')}
                    onChange={(event) => setIdMachine(event.target.value)}
                  >
                    <MenuItem value={0}>
                      <em>{t('all')}</em>
                    </MenuItem>
                    {machineQuery?.data?.map((item) => (
                      <MenuItem key={`cats-${item.id}`} value={item.id}>
                        {item.description ? item.description : item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth size="small" variant="outlined" margin="normal">
                  <InputLabel id="demo-simple-select-label">{t('location')}</InputLabel>
                  <Select
                    disabled={loading}
                    value={idLocation}
                    id="demo-simple-select"
                    labelId="demo-simple-select-label"
                    label={t('location')}
                    onChange={(event) => setIdLocation(event.target.value)}
                  >
                    <MenuItem value={0}>
                      <em>All</em>
                    </MenuItem>
                    {locations.map((item) => (
                      <MenuItem key={`locs-${item.id}`} value={item.id}>
                        {item.description ? item.description : item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth size="small" variant="outlined" margin="normal">
                  <InputLabel id="demo-simple-select-label">{t('shift')}</InputLabel>
                  <Select
                    disabled={loading}
                    value={idShift}
                    id="demo-simple-select"
                    labelId="demo-simple-select-label"
                    label={t('shift')}
                    onChange={(event) => setIdShift(event.target.value)}
                  >
                    <MenuItem value={0}>
                      <em>All</em>
                    </MenuItem>
                    {shifts.map((item) => (
                      <MenuItem key={`shifts-${item.id}`} value={item.id}>
                        {item.description ? item.description : item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <SingleDatePicker
                  disabled={loading}
                  style={{ width: '100%' }}
                  label={t('start_date')}
                  name="startDate"
                  value={startDate}
                  disableFuture
                  onChange={(date) => {
                    const startDate = DateTime.fromJSDate(date)
                    setStartDateISODate(startDate.toISODate())
                    setStartDate(startDate.startOf('day').toISO({ includeOffset: false }))
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <SingleDatePicker
                  disabled={loading}
                  style={{ width: '100%' }}
                  label={t('end_date')}
                  name="endDate"
                  value={endDate}
                  disableFuture
                  onChange={(date) => {
                    const endDate = DateTime.fromJSDate(date)
                    setEndDateISODate(endDate.toISODate())
                    setEndDate(endDate.endOf('day').toISO({ includeOffset: false }))
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
              <div className={classes.searchBox}>
                <SearchBar onChange={handleChangeSearch} />
              </div>
              <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }}>
                <IconButton
                  aria-label="more"
                  id="menu-button"
                  aria-controls={openMenu ? 'menu' : undefined}
                  aria-expanded={openMenu ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClickMenu}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => printfReportExcel()}>{t('export_excel')}</MenuItem>
                </Menu>
              </div>
            </Grid>
            <div style={{ display: 'flex', height: '100%', flexGrow: 1 }}>
              <DataGrid
                style={{ borderStyle: 'none' }}
                columns={columns.map((column) => ({
                  ...column,
                  filterable: false,
                }))}
                rows={rows}
                pageSize={tableConf.pageSize}
                rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                onRowClick={(params) => {
                  setQuery({
                    userId: params.row.userId,
                    startDate,
                    endDate,
                    idFacility,
                    idLocation: params.row.id_location,
                  })
                  setShowStaffOverview(true)
                }}
                className={classes.dataGrid}
                loading={loading}
              />
            </div>
          </CardContent>
        </Card>
      </Grid>
    </div>
  )

  return !showStaffOverview ? (
    staff
  ) : (
    <StaffOverview
      {...query}
      onPrevious={() => {
        setShowStaffOverview(false)
      }}
    />
  )
}

const mapStateToProps = (state) => ({
  currentFacility: state.facility.currentFacility,
  userId: state.user.id,
  userEmail: state.user.email,
})

export default connect(mapStateToProps, null)(Staff)
