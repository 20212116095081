import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    height: '75vh',
    '& div.MuiDataGrid-root': {
      border: 'none',
    },
  },
  formControlFilter: {
    width: 250,
  },
  dataGridContainer: {
    display: 'flex',
    height: '100%',
  },
  dataGridInnerContainer: {
    flexGrow: 1,
  },
  dataGrid: {
    borderStyle: 'none',
  },
  cardActions: {
    display: 'block',
  },
  cardActionsInnerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formControlPagination: {
    minWidth: 130,
  },
  topRow: {
    marginBottom: 10,
    width: '100%',
  },
  buttonAddNew: {
    marginLeft: '8px',
    minWidth: '100px',
    '@media (max-width: 700px)': {
      padding: '6px 15px !important',
      marginLeft: '5px',
    },
  },
  switchText: {
    color: 'rgb(42, 56, 88)',
    '@media (max-width: 600px)': {
      fontSize: '10px',
    },
  },
})

export default useStyles
