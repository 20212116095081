import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  value: {
    color: '#b2b7c4',
  },
})

export default useStyles
