import { useQuery } from 'react-query'
import RequestService from '../../services/request/request-service'

import { LOTS_FACILITIES_QUERY } from '../constants/queries'

const useLotsFacilityQuery = ({ filter = { id: 0 }, optional = '' } = {}) => {
  return useQuery({
    queryKey: [LOTS_FACILITIES_QUERY, filter, optional],
    queryFn: async ({ queryKey }) => {
      const url = `${queryKey[0]}${filter?.id !== 0 ? `?id=${filter?.id}` : '?'}${queryKey[2]}`
      const { data } = await RequestService.Get(url)
      return data
    },
    initialData: [],
  })
}
export default useLotsFacilityQuery
