import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import { DataGrid } from '@material-ui/data-grid'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import Icon from '@material-ui/core/Icon'
import MenuItem from '@material-ui/core/MenuItem'

import ReportFilter from '../../../components/report-filter/report-filter.component'
import RequestService from '../../../services/request/request-service'
import { ROW_PER_PAGE_OPTIONS, DEFAULT_TABLE_CONFIGURATION } from '../../../shared/constants/table'
import { INTEGRATEDREPORT_QUERY } from '../../../shared/constants/queries'

import useColorQuery from '../../../shared/hooks/useColorQuery'
import useVerietyQuery from '../../../shared/hooks/useVerietyQuery'
import useSizeQuery from '../../../shared/hooks/useSizeQuery'
import useUserQuery from '../../../shared/hooks/useUserQuery'
import useLotsFacilityQuery from '../../../shared/hooks/useLotsFacilityQuery'
import useDefectQuery from '../../../shared/hooks/useDefectQuery'
import useMachineQuery from '../../../shared/hooks/useMachineQuery'
import useStemConditionQuery from '../../../shared/hooks/useStemConditionQuery'
import useSampleTypeQuery from '../../../shared/hooks/useSampleTypeQuery'
import useFacilityLocationQuery from '../../../shared/hooks/useFacilityLocationQuery'

import { QUALITY_CONTROL_ROLE } from '../../../shared/constants/roles'
import { convertFormatDateTime } from '../../../shared/utils/utils'
import { funcExportExcel } from '../../../components/export-to-excel/exportexcel.component'

const CustomReports = ({ currentFacility }) => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [isLoading, setIsLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])
  const [fileName, setFileName] = useState('')
  const machineQuery = useMachineQuery({
    byFacility: true,
    filter: { id_facility: currentFacility.id },
  })
  const colorQuery = useColorQuery()
  const verietyQuery = useVerietyQuery()
  const sizeQuery = useSizeQuery()
  const userQuery = useUserQuery()
  const lotsFacilityQuery = useLotsFacilityQuery({
    filter: { id: currentFacility.id },
    optional: '&filterByFirst=false&all=true',
  })
  const defectQuery = useDefectQuery()
  const stemConditionQuery = useStemConditionQuery()
  const sampleTypeQuery = useSampleTypeQuery()
  const facilityLocationQuery = useFacilityLocationQuery({
    filter: { id: currentFacility.id },
  })
  const isLoadingQuery =
    machineQuery.isFetching ||
    colorQuery.isFetching ||
    verietyQuery.isFetching ||
    sizeQuery.isFetching ||
    userQuery.isFetching ||
    lotsFacilityQuery.isFetching ||
    defectQuery.isFetching ||
    stemConditionQuery.isFetching ||
    sampleTypeQuery.isFetching ||
    facilityLocationQuery.isFetching

  const infoColumnsFirst = [
    {
      field: 'created_date',
      headerName: t('date_submitted'),
      minWidth: 175,
      renderCell: (params) => {
        return convertFormatDateTime(params.value)
      },
    },
    { field: 'staff_member', headerName: t('staff_member'), minWidth: 180 },
    { field: 'lot_number', headerName: `${t('lot')} #`, minWidth: 110 },
    { field: 'variety_name', headerName: t('variety'), minWidth: 125 },
  ]

  const inforColumnsLast = [
    { field: 'size_name', headerName: t('size_name'), minWidth: 150 },
    { field: 'average_firmness', headerName: t('average_firmness'), minWidth: 200 },
    { field: 'brix', headerName: 'Brix', minWidth: 110 },
    { field: 'temperature', headerName: t('temperature'), minWidth: 160 },
    { field: 'stem_condition', headerName: t('stem_condition'), minWidth: 175 },
    { field: 'Good', headerName: t('good'), minWidth: 110 },
    { field: 'Bad', headerName: t('bad'), minWidh: 110 },
    { field: 'Doubles/Spurs', headerName: `${t('double')}/Supr`, minWidth: 165 },
  ]

  useEffect(async () => {
    await getData()
    return () => {
      // Clear the array when the component unmounts
      setColumns([])
      setRows([])
    }
  }, [])

  useEffect(async () => {
    await getData()
  }, [colorQuery.data, defectQuery.data, sizeQuery.data])

  useEffect(() => {
    // Parse column data here
    const parsedColumnColors = parseResponseToHeaders(colorQuery.data)
    const parsedColumnDefects = parseResponseToHeaders(defectQuery.data, 'name')
    const parsedColumnSizes = parseResponseToHeaders(sizeQuery.data)

    // Update columns with translated labels
    const infoColumnsFirstWithLabels = [
      ...infoColumnsFirst.map((column) => ({ ...column, headerName: t(column.headerName) })),
      { field: 'location_name', headerName: t('location'), minWidth: 150 },
      { field: 'sample_type', headerName: t('sample_type'), minWidth: 160 },
      { field: 'sample_size', headerName: t('sample_size'), minWidth: 155 },
      ...parsedColumnColors.map((column) => ({ ...column, headerName: t(column.headerName) })),
      ...parsedColumnDefects.map((column) => ({ ...column, headerName: t(column.headerName) })),
      ...parsedColumnSizes.map((column) => ({ ...column, headerName: t(column.headerName) })),
      ...inforColumnsLast.map((column) => ({ ...column, headerName: t(column.headerName) })),
    ]
    setColumns(infoColumnsFirstWithLabels)
  }, [i18n.language, colorQuery.data, defectQuery.data, sizeQuery.data])

  const getData = async () => {
    try {
      lotsFacilityQuery.refetch()
      verietyQuery.refetch()
      userQuery.refetch()
      facilityLocationQuery.refetch()
      sampleTypeQuery.refetch()
      colorQuery.refetch()
      sizeQuery.refetch()
      stemConditionQuery.refetch()
      defectQuery.refetch()
      machineQuery.refetch()

      if (machineQuery?.data?.length > 0) {
        infoColumnsFirst.push({ field: 'machine_name', headerName: t('machine'), minWidth: 160 })
      }

      const parsedColumnColors = parseResponseToHeaders(colorQuery.data)
      const parsedColumnDefects = parseResponseToHeaders(defectQuery.data, 'name')
      const parsedColumnSizes = parseResponseToHeaders(sizeQuery.data)

      setColumns([
        ...infoColumnsFirst,
        { field: 'location_name', headerName: t('location'), minWidth: 150 },
        { field: 'sample_type', headerName: t('sample_type'), minWidth: 160 },
        { field: 'sample_size', headerName: t('sample_size'), minWidth: 155 },
        ...parsedColumnColors,
        ...parsedColumnDefects,
        ...parsedColumnSizes,
        ...inforColumnsLast,
      ])
    } catch (error) {
      console.log(error)
    }
  }

  const parseResponseToHeaders = (data, fieldName = 'description') => {
    return data.map((x) => {
      const field = x[fieldName]
      return {
        field,
        headerName: field,
        minWidth: field.length * 5 + 110,
      }
    })
  }

  const loadData = async (filter) => {
    const queries = []
    let fileName = 'Report '
    queries.push(`start_date=${filter.startDate}`)
    queries.push(`end_date=${filter.endDate}`)
    fileName += `${DateTime.fromISO(filter.startDate).toFormat('MM-dd-yy')}_${DateTime.fromISO(filter.endDate).toFormat(
      'MM-dd-yy'
    )}_`
    if (filter.id_lot && filter.id_lot > 0) {
      queries.push(`id_lot=${filter.id_lot}`)
      const lot = lotsFacilityQuery.data?.map((x) => x.first)?.find((lot) => lot.id === filter.id_lot)
      fileName += `Lot_${lot.name}_`
    }
    if (filter.id_variety && filter.id_variety > 0) {
      queries.push(`id_variety=${filter.id_variety}`)
      const variety = verietyQuery.data?.find((variety) => variety.id === filter.id_variety)
      fileName += `Variety_${variety.name}_`
    }
    if (filter.id_location && filter.id_location > 0) {
      queries.push(`id_location=${filter.id_location}`)
      fileName += `Location_${filter.id_location}_`
    }
    if (filter.id_sample_type && filter.id_sample_type > 0) {
      queries.push(`id_sample_type=${filter.id_sample_type}`)
      fileName += `SampleType_${filter.id_sample_type}_`
    }
    if (filter.id_staff_member) {
      queries.push(`id_staff_member=${filter.id_staff_member}`)
      const staff = userQuery.data
        ?.filter((user) => user.roles.includes(QUALITY_CONTROL_ROLE))
        ?.find((staff) => staff.id === filter.id_staff_member)
      fileName += `${staff.name}_`
    }
    if (filter.id_machine && filter.id_machine > 0) {
      queries.push(`id_machine=${filter.id_machine}`)
      const machine = machineQuery?.data?.find((machine) => machine.id === filter.id_machine)
      fileName += `${machine.name}_`
    }
    const url = `${INTEGRATEDREPORT_QUERY}?id_facility=${currentFacility.id}&${queries.join('&')}`
    setIsLoading(true)
    const response = await RequestService.Get(url, history)
    setRows(response.data.result.map((x) => parseRowData(x)))
    setFileName(fileName)
    console.log(fileName)
    setIsLoading(false)
  }
  const parseRowData = (dataRow) => {
    const {
      id,
      created_date,
      id_lot,
      id_variety,
      id_location,
      id_sample_type,
      sample_size,
      id_user,
      additional_information,
      sample_data,
      sample_colors,
      id_sample_size,
      id_machine,
    } = dataRow

    const lot = lotsFacilityQuery.data?.map((x) => x.first)?.find((x) => x.id === id_lot)
    const variety = verietyQuery.data?.find((x) => x.id === id_variety)
    const location = facilityLocationQuery.data.map((x) => x.second)?.find((x) => x.id === id_location)
    const sampleType = sampleTypeQuery.data?.find((x) => x.id === id_sample_type)
    const user = userQuery.data
      ?.filter((user) => user.roles.includes(QUALITY_CONTROL_ROLE))
      ?.find((x) => x.id === id_user)
    const machine = machineQuery.data?.find((x) => x.id === id_machine)
    let average_firmness = ''
    let brix = ''
    let temperature = ''
    let stem_condition = ''
    let size_name = ''
    if (
      additional_information &&
      additional_information.stem_condition_value &&
      additional_information.stem_condition_value > 0
    ) {
      average_firmness = additional_information.average_firmness
      brix = additional_information.brix
      temperature = additional_information.temperature
      stem_condition = stemConditionQuery.data?.find(
        (x) => x.id === additional_information.stem_condition_value
      )?.description
    }

    const sampleDataParsed = parseObjRowData(sample_data, 'quantity')

    if (id_sample_size && id_sample_size > 0) {
      size_name = sizeQuery.data?.find((x) => x.id === id_sample_size)?.description
    } else {
      const sizeDatas = sample_data.filter((x) => x.undersize > 0 || x.oversize > 0 || x.quantity > 0)
      if (sizeDatas && sizeDatas.length === 1) {
        const sizeData = sizeDatas[0]
        sampleDataParsed.Oversize = sizeData.oversize
        sampleDataParsed.Undersize = sizeData.undersize
        size_name = sizeData.description !== 'Minor' && sizeData.description !== 'Major' ? sizeData.description : ''
      }
    }

    return {
      id,
      created_date,
      lot_number: lot?.name,
      variety_name: variety?.name,
      location_name: location?.description,
      sample_type: sampleType?.description,
      staff_member: user?.name,
      sample_size,
      machine_name: machine?.name ? machine?.name : machine?.description ? machine?.description : '',
      average_firmness,
      brix,
      temperature,
      stem_condition,
      size_name,
      ...sampleDataParsed,
      ...parseObjRowData(sample_colors, 'quantity'),
    }
  }
  const parseObjRowData = (data, fieldValue) => {
    const res = {}
    for (let index = 0; index < data.length; index++) {
      const element = data[index]
      const field =
        element.description === 'Minor' || element.description === 'Major' ? element.name : element.description
      res[field] = element[fieldValue]
    }
    return res
  }

  const onApplyfilter = async (filter) => {
    await loadData(filter)
  }

  const onPageSizeChange = (event) => {
    setTableConf({ page: 1, pageSize: event })
  }

  return (
    <Grid container spacing={1} style={{ marginTop: '5px' }}>
      <ReportFilter
        onApplyfilter={onApplyfilter}
        lots={lotsFacilityQuery.data?.map((x) => x.first)}
        sampleTypes={sampleTypeQuery.data}
        defects={defectQuery.data}
        rowSizes={sizeQuery.data}
        locations={facilityLocationQuery.data.map((x) => x.second)}
        staffMembers={userQuery.data?.filter((user) => user.roles.includes(QUALITY_CONTROL_ROLE))}
        machines={machineQuery.data}
        loading={isLoading || isLoadingQuery}
      />
      <Grid xs={12} md={8} item>
        {rows.length < 1 ? null : (
          <MenuItem onClick={(e) => funcExportExcel(rows, columns, fileName)}>
            <Icon style={{ marginRight: 10 }}>file_download</Icon> {t('export_report')}
          </MenuItem>
        )}
        <div
          style={{
            height: '80vh',
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
            pageSize={tableConf.pageSize}
            disableSelectionOnClick
            loading={isLoading || isLoadingQuery}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
  currentFacility: state.facility.currentFacility,
})

export default connect(mapStateToProps, null)(CustomReports)
