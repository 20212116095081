import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import { TextField } from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Transition from '../transition/transition.component'
import useStyles from './multi-select.styles'
import FormDialog from '../form-dialog/form-dialog.component'
import { buildPayload } from '../../shared/utils/utils'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import { SERVER_URL } from '../../shared/constants/queries'
import { LOG_MULTISELECT_UPDATE } from '../../shared/constants/logging'
import { SUPER_ADMIN_ROLE, QUALITY_CONTROL_MANAGER_ROLE } from '../../shared/constants/roles'

const MultipleSelect = ({ ...props }) => {
  const history = useHistory()
  const classes = useStyles()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [values, setValues] = useState([])
  const [selectedValues, setSelectedValues] = useState([])
  const [loading, setLoading] = useState(false)

  const [showMultiSelect, setShowMultiSelect] = useState(true)
  const [textFieldVal, settextFieldVal] = useState('')

  const isAllSelected = values.length > 0 && selectedValues.length === values.length
  const useLast = !!props.useLast

  // load data
  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      const url = `${SERVER_URL}/${props.getUrl}?startRowIndex=0&pageSize=10000`
      const response = await RequestService.Get(url, history)
      if (response?.hasError) {
        console.error(response.errorMessage)
      } else {
        setValues(response.data)
      }
      if (props?.isAdminShow) {
        if (props.role === SUPER_ADMIN_ROLE || props.role === QUALITY_CONTROL_MANAGER_ROLE) setShowMultiSelect(true)
        else setShowMultiSelect(false)
      } else {
        setShowMultiSelect(true)
      }

      setLoading(false)
    }
    if (props.value) {
      setValues(props.value)
    } else if (props.getUrl) {
      loadData()
    }
  }, [props.getUrl, props.value])

  useEffect(() => {
    const loadData = async () => {
      setSelectedValues([])
      setLoading(true)
      let filterByFirst = ''
      if (useLast) {
        filterByFirst = `&filterByFirst=false`
      }
      // eslint-disable-next-line max-len
      const url = `${SERVER_URL}/${props.getRelationUrl}?id=${props.mainId}${filterByFirst}&startRowIndex=0&pageSize=10000&lefttoright=${props.lefttoright}`
      const response = await RequestService.Get(url, history)
      if (response?.hasError) {
        console.error(response.errorMessage)
      } else {
        const selected = values.filter((item) =>
          response.data.some((el) => (useLast ? el.first.id === item.id : el.second.id === item.id))
        )
        const strName = selected.map((a) => `${a.description}`)
        setSelectedValues(selected)
        settextFieldVal(strName)
      }
      setLoading(false)
    }
    if (props.mainId && props.open) loadData()
  }, [props.mainId, props.getRelationUrl, props.lefttoright, values, props.open])

  const handleSubmit = async (event) => {
    event.preventDefault()

    const payload = buildPayload(
      props.mainId,
      selectedValues,
      props.additionalData,
      useLast,
      props.deleteRelationKey,
      props.getAdditionalData
    )
    setLoading(true)
    let filterByFirst = ''
    if (useLast) {
      filterByFirst = `?filterByFirst=false`
    }
    const url = `${SERVER_URL}/${props.getRelationUrl}${filterByFirst}`
    try {
      const response = await RequestService.Put(url, history, payload)
      trackEvent(LOG_MULTISELECT_UPDATE.UPDATED_VIA_MULTISELECT, { email: props.userEmail, url, payload })
    } catch (error) {
      console.error(error.errorMessage)
    }
    setLoading(false)
    props.onClose()
  }

  const handleChange = (event) => {
    const { value } = event.target
    if (value[value.length - 1] === 'all') {
      setSelectedValues(selectedValues.length === values.length ? [] : values)
      return
    }
    setSelectedValues(value)
  }

  return showMultiSelect ? (
    <div>
      <FormDialog
        open={props.open}
        onClose={props.onClose}
        loading={loading}
        title={props.title}
        handleSubmit={handleSubmit}
      >
        <div>
          <FormControl className={classes.formControl} variant="outlined" fullWidth>
            <Select
              id={`${props.title}-multiple-checkbox`}
              multiple
              disabled={loading || (props.role !== SUPER_ADMIN_ROLE && props.role !== QUALITY_CONTROL_MANAGER_ROLE)}
              value={selectedValues}
              onChange={handleChange}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((item) => (
                    <Chip
                      key={`chip-${props.title}-${item.id}`}
                      label={item.description ? item.description : item.name ? item.name : ''}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={{
                getContentAnchorEl: () => null,
              }}
            >
              <MenuItem key={`check-${props.title}-0`} value="all">
                <Checkbox checked={isAllSelected} />
                <ListItemText primary="Select all" />
              </MenuItem>
              {values.map((item) => (
                <MenuItem key={`check-${props.title}-${item.id}`} value={item}>
                  <Checkbox checked={selectedValues.some((el) => el.id === item.id)} />
                  <ListItemText primary={item.description ? item.description : item.name ? item.name : ''} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </FormDialog>
    </div>
  ) : (
    <div>
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
        open={props.open}
        aria-labelledby="form-dialog-title"
        onClose={props.onClose}
      >
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <TextField fullWidth value={textFieldVal} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
  role: state.user.role,
})

export default connect(mapStateToProps, null)(MultipleSelect)
