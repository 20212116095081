export const BASE_TABLE_COLUMNS = [
  { field: 'name', headerName: 'name', minWidth: 115, flex: 1 },
  { field: 'description', headerName: 'description', minWidth: 150, flex: 1 },
  {
    field: 'active',
    headerName: 'status',
    minWidth: 120,
    renderCell: (params) => (params.value ? 'Active' : 'Inactive'),
    flex: 1,
  },
]
export const ACTIONS_COLUMN = {
  field: 'id',
  headerName: 'actions',
  minWidth: 130,
  flex: 1,
}
export const DEFAULT_TABLE_CONFIGURATION = { page: 1, pageSize: 100 }
export const DEFAULT_ROW_DATA = { name: '', description: '', template: '' }
export const ROW_PER_PAGE_OPTIONS = [5, 20, 50, 100]
