import { useQuery } from 'react-query'
import RequestService from '../../services/request/request-service'
import { INCLUDE_INACTIVE_FLAG } from '../constants/general'
import { CATEGORY_QUERY } from '../constants/queries'

const useCategoryQuery = ({ showActive = true, page = 0, pageSize = 10000, filter = { id_category_type: 0 } } = {}) => {
  return useQuery({
    queryKey: [CATEGORY_QUERY, showActive, page, pageSize],
    queryFn: async ({ queryKey }) => {
      let url = ``
      if (filter.id_category_type !== 0) {
        url = `${queryKey[0]}?id_category_type=${filter.id_category_type}${
          !queryKey[1] ? `&${INCLUDE_INACTIVE_FLAG}` : '&'
        }startRowIndex=${queryKey[2]}&pageSize=${queryKey[3]}`
      } else {
        url = `${queryKey[0]}?${!queryKey[1] ? INCLUDE_INACTIVE_FLAG : ''}&startRowIndex=${queryKey[2]}&pageSize=${
          queryKey[3]
        }`
      }
      const { data } = await RequestService.Get(url)
      return data
    },
    initialData: [],
  })
}
export default useCategoryQuery
