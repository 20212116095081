import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { useTranslation } from 'react-i18next'

const ReportsRowSizeHeaderRow = (props) => {
  const { classes } = props
  const { t } = useTranslation()

  return (
    <TableRow key="header">
      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
        {t('row_size')}
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel} ${classes.tableCellUnders}`} align="center">
        {t('unders')}
      </TableCell>
      <TableCell
        className={`${classes.tableCell} ${classes.tableCellLabel} ${classes.tableCellCorrect}`}
        align="center"
      >
        {t('correct')}
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel} ${classes.tableCellOvers}`} align="center">
        {t('overs')}
      </TableCell>
    </TableRow>
  )
}

export default ReportsRowSizeHeaderRow
