import React from 'react'
import Button from '@material-ui/core/Button'

const PrimaryButton = React.memo(({ children, ...props }) => {
  return (
    <div>
      <Button
        style={{ textTransform: 'none', padding: '6px 20px', letterSpacing: '1.5px' }}
        variant="contained"
        color="primary"
        {...props}
      >
        {children}
      </Button>
    </div>
  )
})

export default PrimaryButton
