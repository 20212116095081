import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    minHeight: 126,
  },
  container: {
    display: 'flex',
  },
  valuesContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    textAlign: 'center',
  },
  icon: {
    color: '#c2c7d6',
    marginTop: 0,
    marginRight: 12,
  },
  value: {
    fontSize: 22,
  },
  doubleValueContainer: {
    display: 'flex',
    marginBottom: -3,
  },
  value1: {
    marginRight: 20,
  },
  defectsContainer: {
    marginBottom: -10,
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '3px',
  },
})

export default useStyles
