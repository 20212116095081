import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  colorPickerLabel: {
    marginLeft: 0,
    marginTop: '10px',
  },
  previewBox: { width: '25px', height: '25px', marginRight: '10px' },
})

export default useStyles
