import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import HelpIcon from '@material-ui/icons/Help'
import { FormControlLabel, Switch } from '@material-ui/core'

import FormDialog from '../../components/form-dialog/form-dialog.component'
import TableContainer from '../../components/table-container/table-container'
import MultipleSelect from '../../components/multi-select/multi-select'
import ManyToManyInput from '../../components/many-many-input/many-many-input.component'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import { DEFAULT_ROW_DATA, DEFAULT_TABLE_CONFIGURATION } from '../../shared/constants/table'
import {
  FACILITY_QUERY,
  ROLE_QUERY,
  USER_QUERY,
  USERS_FACILITIES_QUERY,
  UPDATE_ROLES_USER,
  SHIFT_QUERY,
  USERS_SHIFT_QUERY,
  LOCK_USER,
  UNLOCK_USER,
} from '../../shared/constants/queries'
import { QUALITY_CONTROL_ROLE } from '../../shared/constants/roles'
import { validateEmail } from '../../shared/utils/utils'
import useStyles from './users.styles'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import useUserQuery from '../../shared/hooks/useUserQuery'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'

const Users = ({ token, userEmail, role }) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialogNew, setOpenNew] = useState(false)
  const [facilitiesOpen, setFacilitiesOpen] = useState(false)
  const [rolesOpen, setRolesOpen] = useState(false)
  const [shiftOpen, setShiftOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [newData, setNewData] = useState({ role: '' })
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [rows, setRows] = useState([])
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [userError, setUserError] = useState('')
  const [showPass, setShowPass] = useState(false)
  const [roles, setRoles] = useState([])
  const [shifts, setShifts] = useState([])
  const [userShifts, setUserShifts] = useState([])
  const [selectedFacilities, setSelectedFacilities] = useState([])
  const [selectedRoleId, setSelectedRoleId] = useState('')
  const [qCRoleId, setQCRoleId] = useState('notQc')
  const [selectedShift, setSelectedShift] = useState(0)
  const [facilities, setFacilities] = useState([])
  const [activeOpen, setActiveOpen] = useState(false)
  const [showActive, setShowActive] = useState(true)
  const userQuery = useUserQuery({ showActive, startRowIndex: tableConf.page - 1, pageSize: 10000 })

  // columns definition for table
  const columns = [
    { field: 'name', headerName: t('name'), flex: 1, minWidth: 115 },
    { field: 'email', headerName: t('email'), flex: 2 },
    {
      field: 'roles',
      headerName: t('role'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            console.log(params.row.roles)
            setRowData({ id: params.row.id, roles: params.row.roles })
            setRolesOpen(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_role')}
        </Button>
      ),
    },
    {
      field: 'shift',
      headerName: t('shift'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => {
        const isQc = params.row.roles.some((role) => role === QUALITY_CONTROL_ROLE)

        return isQc ? (
          <Button
            color="secondary"
            onClick={() => {
              setRowData({ id: params.row.id, roles: params.row.roles })
              getUserShift(params.row.id)
              setShiftOpen(true)
            }}
            style={{ textTransform: 'none' }}
          >
            {t('see_shift')}
          </Button>
        ) : null
      },
    },
    {
      field: 'facilities',
      headerName: t('facilities'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id, roles: params.row.roles })
            setSelectedFacilities([])
            setFacilitiesOpen(true)
          }}
          style={{ textTransform: 'none' }}
        >
          {t('see_facilities')}
        </Button>
      ),
    },
    {
      field: 'active',
      headerName: t('status'),
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <FormControlLabel
          control={
            <Switch
              checked={params.row.active}
              onChange={() => {
                setRowData({ id: params.row.id, active: params.row.active, name: params.row.name })
                setActiveOpen(true)
              }}
              name="status"
            />
          }
          label={params.row.active ? 'Active' : 'Inactive'}
        />
      ),
    },
  ]

  // load roles
  useEffect(async () => {
    const loadData = async () => {
      try {
        let url = `${ROLE_QUERY}`
        let response = await RequestService.Get(url, history)
        setRoles(response[0])
        response[0].forEach((item) => {
          if (item.value === QUALITY_CONTROL_ROLE) {
            setQCRoleId(item.id)
          }
        })
        url = `${SHIFT_QUERY}`
        response = await RequestService.Get(url, history)
        setShifts(response.data)
        url = `${FACILITY_QUERY}?startRowIndex=0&pageSize=10000`
        response = await RequestService.Get(url, history)
        setFacilities(response.data)
      } catch (error) {
        console.error(error)
      }
    }
    await loadData()
    trackEvent(LOG_PAGE_LOADS.LOAD_USERS_PAGE, { email: userEmail })
  }, [])

  useEffect(async () => {
    await loadData()
  }, [tableConf, showActive, userQuery.data])

  // Active change
  const handleChangeUserStatus = async () => {
    setLoading(true)
    try {
      const userData = {
        user_id: rowData.id,
      }
      if (rowData.active) {
        await RequestService.Post(`${LOCK_USER}`, history, userData)
      } else {
        await RequestService.Post(`${UNLOCK_USER}`, history, userData)
      }
      const indexRowSelected = rows.findIndex((x) => x.id === rowData.id)
      rows[indexRowSelected].active = !rowData.active
      setRows(rows)
      setActiveOpen(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const loadData = async () => {
    try {
      setRows([])
      setLoading(true)
      setRows(userQuery?.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  const getUserShift = async (id) => {
    try {
      setLoading(true)
      const userURL = `${USERS_SHIFT_QUERY}?id=${id}&startRowIndex=${tableConf.page - 1}&pageSize=10000`
      const responseShift = await RequestService.Get(userURL, history)
      setUserShifts(responseShift.data)
      if (responseShift.data.length > 0) {
        setSelectedShift(responseShift.data[0].second.id)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const fetchData = async () => {
      setLoading(true)
      try {
        const _roleIds = [newData.roleId]

        const userData = {
          ...newData,
          userId: `${newData.userId}@buenafrutasolutions.com`,
          password: newData.password,
          roleIds: _roleIds,
        }

        let response = await RequestService.Post(`${USER_QUERY}`, history, userData)

        const data = selectedFacilities.map((facility) => {
          return {
            first: { id: response.data.id, roles: _roleIds },
            second: { id: facility.id, id_region: facility.id_region },
          }
        })
        const dataShift = [
          {
            first: { id: response.data.id },
            second: { id: selectedShift },
          },
        ]

        response = await RequestService.Put(`${USERS_FACILITIES_QUERY}`, history, data)

        response = await RequestService.Put(`${USERS_SHIFT_QUERY}`, history, dataShift)

        setOpenNew(false)
        setLoading(false)
        setSelectedFacilities([])
        setTableConf({ ...tableConf })
        setRows(userQuery.data)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    if (!validateEmail(newData.userId)) {
      fetchData()
    } else {
      setUserError('Invalid user ID')
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setNewData({
      ...newData,
      [name]: value,
    })
    setUserError('')
    if (name === 'roleId') {
      setSelectedRoleId(value)
    }
  }

  // Form Dialog handler
  const handleClickOpen = () => () => {
    setNewData({ role: '' })
    setOpenNew(true)
  }

  const handleSubmitRoles = async (event) => {
    event.preventDefault()
    try {
      setLoading(true)
      const dataBody = {
        userId: rowData.id,
        roleIds: roles.filter((x) => rowData.roles.some((z) => z === x.value)).map((x) => x.id),
      }
      await RequestService.Put(`${UPDATE_ROLES_USER}`, history, dataBody)

      const indexRowSelected = rows.findIndex((x) => x.id === rowData.id)
      rows[indexRowSelected].roles = rowData.roles
      setRows(rows)

      setRolesOpen(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitShift = async (event) => {
    event.preventDefault()
    try {
      setLoading(true)
      const dataBody = [
        {
          first: { id: rowData.id },
          second: { id: selectedShift },
        },
      ]
      await RequestService.Put(`${USERS_SHIFT_QUERY}`, history, dataBody)

      setShiftOpen(false)
      setSelectedShift(0)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  // const handleChangeSelectedRoles = (event) => {
  //   const { value } = event.target
  //   let _roles = []
  //   if (value[value.length - 1] === 'all') {
  //     _roles = roles.map((x) => x.value)
  //     if (rowData.roles.length === _roles.length) {
  //       setIsAllSelected(false)
  //       setRowData({ id: rowData.id, roles: [] })
  //     } else {
  //       setIsAllSelected(true)
  //       setRowData({ id: rowData.id, roles: _roles })
  //     }
  //     return
  //   }
  //   setIsAllSelected(false)
  //   setRowData({ id: rowData.id, roles: value })
  // }

  const handleChangeSelectedRoles = (event) => {
    const { value } = event.target
    setRowData({ id: rowData.id, roles: [value] })
  }

  const handleChangeSelectedShift = (event) => {
    const { value } = event.target
    setSelectedShift(value)
    setRowData({ id: rowData.id, roles: rowData.roles })
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          {t('users')}
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={rows}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={loading}
      />
      <MultipleSelect
        open={facilitiesOpen}
        onClose={() => {
          setFacilitiesOpen(false)
        }}
        title={t('facilities')}
        getUrl="facility"
        getRelationUrl="usersfacilities"
        getAdditionalData="id_region"
        deleteRelationKey="id_region"
        mainId={rowData.id}
        lefttoright
        additionalData={{
          roles: rowData.roles,
        }}
      />
      <FormDialog
        open={rolesOpen}
        onClose={() => {
          setRolesOpen(false)
        }}
        title={t('role')}
        loading={loading}
        handleSubmit={handleSubmitRoles}
      >
        <div>
          <FormControl className={classes.formControl} variant="outlined" fullWidth>
            <InputLabel id="select-facility-label">Role *</InputLabel>
            <Select
              required
              labelId="select-facility-label"
              label={t('role')}
              onChange={handleChangeSelectedRoles}
              defaultValue={rowData?.roles && rowData.roles.length > 0 ? rowData?.roles[0] : ''}
            >
              {roles.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </FormDialog>
      <FormDialog
        open={shiftOpen}
        onClose={() => {
          setShiftOpen(false)
          setSelectedShift(0)
        }}
        title={t('shift')}
        loading={loading}
        handleSubmit={handleSubmitShift}
        defaultValue={userShifts.length > 0 ? userShifts[0].second.id : 0}
      >
        <div>
          <FormControl className={classes.formControl} variant="outlined" fullWidth>
            <InputLabel id="select-facility-label">Role *</InputLabel>
            <Select
              required
              labelId="select-facility-label"
              label={t('role')}
              onChange={handleChangeSelectedRoles}
              defaultValue={rowData?.roles && rowData.roles.length > 0 ? rowData?.roles[0] : ''}
            >
              {roles.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </FormDialog>
      <FormDialog
        open={shiftOpen}
        onClose={() => {
          setShiftOpen(false)
          setSelectedShift(0)
        }}
        title="Shift"
        loading={loading}
        handleSubmit={handleSubmitShift}
        defaultValue={userShifts.length > 0 ? userShifts[0].second.id : 0}
      >
        <div>
          <FormControl className={classes.formControl} variant="outlined" fullWidth>
            <InputLabel id="select-facility-label">{`${t('shift')} *`}</InputLabel>
            <Select
              required
              labelId="select-facility-label"
              label={t('shift')}
              value={selectedShift}
              onChange={handleChangeSelectedShift}
            >
              {shifts.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </FormDialog>
      <FormDialog
        title={t('new_user')}
        open={openDialogNew}
        loading={loading}
        onClose={() => {
          setOpenNew(false)
          setSelectedFacilities([])
        }}
        handleSubmit={handleSubmit}
      >
        <TextField
          autoFocus
          error={userError !== ''}
          helperText={userError}
          variant="outlined"
          margin="normal"
          name="userId"
          label={t('user_id')}
          InputProps={{
            endAdornment: <InputAdornment position="end">@buenafrutasolutions.com</InputAdornment>,
            inputProps: {
              title: t('must_not_contain_special_char_and_at_least_4_char'),
              pattern: '^[a-zA-Z0-9.]{4,}$',
            },
          }}
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          variant="outlined"
          margin="normal"
          name="displayName"
          label={t('name')}
          inputProps={{
            pattern: '^[a-zA-Z0-9. ]{1,}$',
            title: t('must_not_contain_special_char'),
          }}
          type="text"
          fullWidth
          required
          onChange={handleChange}
        />
        <FormControl margin="normal" variant="outlined" fullWidth>
          <InputLabel id="select-facility-label">Role *</InputLabel>
          <Select
            required
            labelId="select-facility-label"
            label={t('role')}
            name="roleId"
            onChange={handleChange}
            value={newData.roleId}
          >
            {roles.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedRoleId === qCRoleId ? (
          <FormControl margin="normal" variant="outlined" fullWidth>
            {/* <ManyToManyInput
            inputLabel="Roles"
            name="roleIds"
            selectedValues={selectedRoles}
            allValues={roles.map((x) => {
              return { id: x.id, name: x.value }
            })}
            required
            setSelectedValues={setSelectedRoles}
          /> */}
            <InputLabel id="select-facility-label">Shift *</InputLabel>
            <Select
              required
              labelId="select-facility-label"
              label={t('shift')}
              name="shiftId"
              onChange={(event) => setSelectedShift(event.target.value)}
              value={selectedShift}
            >
              {shifts.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <div />
        )}
        <Grid container>
          <Grid item xs={11}>
            <TextField
              variant="outlined"
              margin="normal"
              name="password"
              label={t('password')}
              type={!showPass ? 'password' : 'text'}
              required
              fullWidth
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPass(!showPass)}>
                      {showPass ? (
                        <Icon color="secondary">visibility</Icon>
                      ) : (
                        <Icon color="secondary">visibility_off</Icon>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                inputProps: {
                  pattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}',
                },
              }}
            />
          </Grid>
          <Grid item xs={1} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              /* eslint-disable-next-line max-len */
              title="Password must be at least 8 characters, and require 3 of the following: include lowercase characters, uppercase characters, numbers and symbols."
            >
              <IconButton style={{ marginTop: '10px', color: '#5a7a98' }} aria-label="tooltip">
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <ManyToManyInput
          inputLabel="Facilities"
          name="id_facility"
          selectedValues={selectedFacilities}
          allValues={facilities}
          required
          setSelectedValues={setSelectedFacilities}
        />
      </FormDialog>
      <ConfirmDialog
        title={t('status')}
        okButton={rowData?.active ? 'InActive' : 'Active'}
        open={activeOpen}
        loading={loading}
        onClose={() => {
          setActiveOpen(false)
        }}
        onOk={handleChangeUserStatus}
      >
        {t('do_you_really_want_to')} {rowData?.active ? t('inActive') : t('active')} {rowData?.name}?
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  token: state.user.token,
  userEmail: state.user.email,
  role: state.user.role,
})

export default connect(mapStateToProps)(Users)
