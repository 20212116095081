import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import SecondaryLayout from '../../components/secondary-layout/secondary-layout.component'
import PrimaryButton from '../../components/button/button.component'

import { validateEmail } from '../../shared/utils/utils'

import makeStyles from './forgot_password.styles'
import './forgot_password.styles.scss'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'

const ForgotPassword = () => {
  const classes = makeStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [email, setEmail] = useState('')
  const [hasError, setHasError] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_FORGOT_PASSWORD_PAGE)
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (email === '') {
      setHasError(true)
      setErrorMessage(t('email_is_required'))
      return
    }

    if (!validateEmail(email)) {
      setHasError(true)
      setErrorMessage(t('email_is_invalid'))
    }

    setDisableSubmit(true)
    // Do call to forgot password endpoint.
  }

  const handleChange = (event) => {
    const { value } = event.target
    setHasError(false)
    setErrorMessage('')
    setEmail(value)
  }

  return (
    <SecondaryLayout>
      <Grid
        className={`${classes.rightGrid} ${classes.headerShadow}`}
        item
        xs={12}
        sm={8}
        md={7}
        component={Paper}
        elevation={0}
        square
      >
        <div className={classes.paper}>
          <Typography className="welcome-title" component="h1" variant="h4" color="primary">
            {t('to_recover_your_password')}
          </Typography>
          <form className={`${classes.form} login-form`} onSubmit={handleSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('email')}
              name="email"
              autoFocus
              onChange={handleChange}
              error={hasError}
              helperText={errorMessage}
            />
            <Grid container>
              <Grid item xs />
              <Grid item>
                <PrimaryButton disabled={disableSubmit} type="submit" className={classes.submit}>
                  {t('send')}
                </PrimaryButton>
              </Grid>
            </Grid>
          </form>
          <Grid className="go-back-button-container">
            <Link
              component="button"
              align="right"
              variant="body2"
              className={classes.goBackButton}
              onClick={() => {
                history.push('/')
              }}
            >
              <div>{t('go_back')}</div>
            </Link>
          </Grid>
        </div>
      </Grid>
    </SecondaryLayout>
  )
}

export default ForgotPassword
