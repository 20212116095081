import { useQuery } from 'react-query'
import RequestService from '../../services/request/request-service'
import { LOCATION_QUERY } from '../constants/queries'

const useLocationFacilityQuery = ({ filter = { id_facility: 0 } } = {}) => {
  return useQuery({
    queryKey: [LOCATION_QUERY, filter],
    queryFn: async ({ queryKey }) => {
      const url = `${queryKey[0]}${filter?.id_facility !== 0 ? `?id_facility=${filter?.id_facility}` : ''}`
      const { data } = await RequestService.Get(url)
      return data
    },
    initialData: [],
  })
}
export default useLocationFacilityQuery
