export const TEMPLATES_IMG = [
  {
    templateName: 'Template Receiving',
    src: ['./img/template/receiving-grade.png', './img/template/receiving-size.png'],
    value: 1,
  },
  {
    templateName: 'Template Upstream',
    src: ['./img/template/upstream-grade.png', './img/template/upstream-size.png'],
    value: 2,
  },
  {
    templateName: 'Template InLine',
    src: ['./img/template/inline-grade.png', './img/template/inline-size.png'],
    value: 3,
  },
  {
    templateName: 'Template FinishBox',
    src: ['./img/template/finishbox-grade.png', './img/template/finishbox-size.png'],
    value: 4,
  },
  {
    templateName: 'Template Culls',
    src: ['./img/template/culls-grade.png'],
    value: 5,
  },
  {
    templateName: 'Template Peddler',
    src: ['./img/template/peddler-grade.png'],
    value: 6,
  },
]
