import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { DataGrid } from '@material-ui/data-grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Drawer from '@material-ui/core/Drawer'
import { Button, Chip, Switch } from '@material-ui/core'
import { DateTime } from 'luxon'

import Alert from '../../components/alert/alert.component'
import SingleDatePicker from '../../components/single-datepicker/simple-datepicker.component'
import PrimaryButton from '../../components/button/button.component'
import SearchBar from '../../components/SearchBar/SearchBar.component'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'

import useStyles from './log-samples.style'

import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
  DEFAULT_COMFIRM_INACTIVE_MESSAGE,
  DEFAULT_COMFIRM_ACTIVE_MESSAGE,
} from '../../shared/constants/messages'
import { ALERT_TYPES } from '../../shared/constants/general'
import { DEFAULT_TABLE_CONFIGURATION, ROW_PER_PAGE_OPTIONS } from '../../shared/constants/table'
import { searchFullColumns, convertFormatDateTime } from '../../shared/utils/utils'

import { LOGS_QUERY, INACTIVE_LOG_QUERY, ACTIVE_LOG_QUERY } from '../../shared/constants/queries'
import useMachineQuery from '../../shared/hooks/useMachineQuery'
import useSampleTypeQuery from '../../shared/hooks/useSampleTypeQuery'
import useLotsQuery from '../../shared/hooks/useLotsQuery'
import useLocationQuery from '../../shared/hooks/useLocationQuery'
import useUserQuery from '../../shared/hooks/useUserQuery'
import useVerietyQuery from '../../shared/hooks/useVerietyQuery'

import { LOG_PAGE_LOADS } from '../../shared/constants/logging'

const LogSamples = ({ userEmail, role, currentFacility }) => {
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const history = useHistory()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [rowsResponse, setRowsResponse] = useState([])
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [openAlert, setOpenAlert] = useState(false)
  const [alertSeverity, setAlertSeverity] = useState(ALERT_TYPES.success)
  const [rowData, setRowData] = useState({})
  const [openDialog, setOpenDialog] = useState(false)

  const [sampleTypes, setSampleTypes] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [sortModel, setSortModel] = useState([{ field: 'dateLog', sort: 'desc' }])
  const [endDate, setEndDate] = useState(null)
  const [page, setPage] = useState(DEFAULT_TABLE_CONFIGURATION.page - 1)
  const [isDrawerOpen, setDrawer] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState('')
  const machineQuery = useMachineQuery({
    byFacility: true,
    filter: { id_facility: currentFacility.id },
  })

  const classes = useStyles()
  const sampleTypeQuery = useSampleTypeQuery()
  const lotsQuery = useLotsQuery()
  const locationQuery = useLocationQuery()
  const userQuery = useUserQuery()
  const verietyQuery = useVerietyQuery()
  const isLoadingQuery =
    verietyQuery.isFetching ||
    userQuery.isFetching ||
    locationQuery.isFetching ||
    lotsQuery.isFetching ||
    sampleTypeQuery.isFetching ||
    machineQuery.isFetching

  let onFilter = false

  const handleClickOpen = (params) => {
    setRowData({
      id: params.target.id,
      checked: params.target.checked,
    })
    setOpenDialog(true)
  }

  const onToggleDelete = async () => {
    try {
      setLoading(true)
      if (!rowData.checked) {
        const url = `${INACTIVE_LOG_QUERY}`
        await RequestService.Post(url, history, rowData.id)
      } else {
        const url = `${ACTIVE_LOG_QUERY}`
        await RequestService.Post(url, history, rowData.id)
      }
      const index = rows.findIndex((x) => x.id === rowData.id)
      if (index !== -1) {
        rows[index].is_deleted = !rowData.checked
        setRows(rows)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setOpenDialog(false)
      setLoading(false)
    }
  }
  // columns definition for table
  const columns = [
    {
      field: 'created_date',
      headerName: t('date_and_time'),
      filterable: false,
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return convertFormatDateTime(params.value)
      },
    },
    { field: 'userFullname', headerName: t('name_of_user'), flex: 1, minWidth: 150, sortable: false },
    { field: 'machineName', headerName: t('machine'), flex: 1, minWidth: 120, sortable: false },
    { field: 'lotName', headerName: `${t('lot')} #`, flex: 1, minWidth: 120, sortable: false },
    { field: 'varietyName', headerName: t('variety'), flex: 1, minWidth: 120, sortable: false },
    { field: 'locationName', headerName: t('location'), flex: 1, minWidth: 120, sortable: false },
    { field: 'sampleTypeName', headerName: t('sample_type'), flex: 1, minWidth: 120, sortable: false },
    { field: 'sample_size', headerName: t('sample_size'), flex: 1, minWidth: 120, sortable: false },
    {
      field: 'is_deleted',
      headerName: t('active'),
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: (params) => <Switch checked={!params.value} id={params.id} onChange={handleClickOpen} />,
    },
    {
      field: 'details',
      headerName: t('details'),
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Chip
          icon={<Icon>visibility</Icon>}
          label={t('view_details')}
          variant="outlined"
          onClick={() => {
            setSelectedRowData(JSON.stringify(params.row, undefined, 2))
            setDrawer(true)
          }}
        />
      ),
    },
  ]

  // load data
  const loadData = async () => {
    try {
      setRows([])
      let dateQuery = ''
      if (startDate) {
        dateQuery += `&start_date=${startDate}`
      }
      if (endDate) {
        dateQuery += `&end_date=${endDate}`
      }

      let _page = tableConf.page - 1
      if (onFilter) {
        _page = 0
        onFilter = false
      }
      /* eslint-disable-next-line max-len */
      const url = `${LOGS_QUERY}?startRowIndex=${_page}&id_facility=${currentFacility.id}&pageSize=${tableConf.pageSize}${dateQuery}`
      setLoading(true)
      const response = await RequestService.Get(url, history)
      setRowCount(response.data.totalCount)

      const resultData = response.data.result.map((x) => renderRowLog(x))
      setRowsResponse(resultData)
      setRows(resultData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderRowLog = (item) => {
    const user = userQuery.data?.find((u) => u.id === item.id_user)
    const lot = lotsQuery.data?.find((l) => l.id === item.id_lot)
    const variety = verietyQuery.data?.find((v) => v.id === item.id_variety)
    const location = locationQuery.data?.find((l) => l.id === item.id_location)
    const sampletype = sampleTypeQuery.data?.find((s) => s.id === item.id_sample_type)
    const machine = machineQuery.data?.find((m) => m.id === item.id_machine)
    const result = {
      ...item,
      userFullname: user?.name,
      lotName: lot?.name,
      varietyName: variety?.description,
      locationName: location?.description,
      sampleTypeName: sampletype?.description,
      machineName: machine?.name,
    }
    return result
  }

  useEffect(async () => {
    const loadDataInIt = async () => {
      setLoading(true)
      try {
        sampleTypeQuery.refetch()
        lotsQuery.refetch()
        locationQuery.refetch()
        userQuery.refetch()
        verietyQuery.refetch()
        machineQuery.refetch()
      } catch (error) {
        onError(error)
      } finally {
        setLoading(false)
      }
    }
    await loadDataInIt()
    trackEvent(LOG_PAGE_LOADS.LOAD_LOT_INFORMATION_PAGE, { email: userEmail })
  }, [])

  useEffect(async () => {
    if (
      userQuery.data.length > 0 &&
      lotsQuery.data.length > 0 &&
      locationQuery.data.length > 0 &&
      verietyQuery.data.length > 0 &&
      sampleTypeQuery.data.length > 0 &&
      machineQuery.data.length > 0
    ) {
      await loadData()
    }
  }, [userQuery.data, lotsQuery.data, locationQuery.data, verietyQuery.data, sampleTypeQuery.data, machineQuery.data])

  useEffect(async () => {
    if (
      userQuery.data.length > 0 &&
      lotsQuery.data.length > 0 &&
      locationQuery.data.length > 0 &&
      verietyQuery.data.length > 0 &&
      sampleTypeQuery.data.length > 0 &&
      machineQuery.data.length > 0
    )
      await loadData()
  }, [tableConf])

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setOpenAlert(true)
    setAlertSeverity(ALERT_TYPES.error)
    setLoading(false)
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpenAlert(false)
  }

  const onPageSizeChange = (pageSize) => {
    setPage(DEFAULT_TABLE_CONFIGURATION.page - 1)
    setTableConf({ page: DEFAULT_TABLE_CONFIGURATION.page, pageSize })
  }

  const onPageChange = (page) => {
    setPage(page)
    setTableConf({ page: page + 1, pageSize: tableConf.pageSize })
  }

  const handleChangeSearch = (event) => {
    const text = event.target.value
    searchFullColumns(rowsResponse, text, setRows, columns)
  }

  const handleSortModelChange = (vmsort) => {}

  const onFilterChange = (e) => {}

  return (
    <>
      <Drawer variant="temporary" anchor="right" open={isDrawerOpen}>
        <div className={classes.root}>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button className={classes.closeBtn} onClick={() => setDrawer(false)} variant="contained" color="secondary">
              <Icon>clear</Icon>
            </Button>
          </div>
          <Typography className={classes.currentLabel} component="h1" variant="h6" color="primary">
            View details:
          </Typography>
          <div style={{ height: '60vh', width: '100%' }}>
            <pre>
              <code>{selectedRowData}</code>
            </pre>
          </div>
        </div>
      </Drawer>
      <Grid container>
        <Grid item xs={12}>
          <Box pt={5} pb={3}>
            <Typography
              component="h1"
              variant="h5"
              color="primary"
              style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
            >
              {t('logs')} <Icon color="secondary">notes</Icon>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card raised>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <SingleDatePicker
                    disabled={isLoadingQuery || loading}
                    style={{ width: '100%' }}
                    label={t('start_date')}
                    name="startDate"
                    value={startDate}
                    disableFuture
                    onChange={(date) => {
                      const startDate = DateTime.fromJSDate(date)
                      setStartDate(startDate.startOf('day').toISO({ includeOffset: false }))
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <SingleDatePicker
                    disabled={isLoadingQuery || loading}
                    style={{ width: '100%' }}
                    label={t('end_date')}
                    name="endDate"
                    value={endDate}
                    disableFuture
                    onChange={(date) => {
                      const endDate = DateTime.fromJSDate(date)
                      setEndDate(endDate.endOf('day').toISO({ includeOffset: false }))
                    }}
                  />
                </Grid>
                <Grid item md={1} style={{ display: 'flex', alignItems: 'end' }}>
                  <PrimaryButton
                    variant="contained"
                    disabled={isLoadingQuery || loading}
                    onClick={() => {
                      setPage(DEFAULT_TABLE_CONFIGURATION.page - 1)
                      onFilter = true
                      loadData()
                    }}
                  >
                    {t('filter')}
                  </PrimaryButton>
                </Grid>
                <Grid item md style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end' }}>
                  <div className={classes.searchBox}>
                    <SearchBar onChange={handleChangeSearch} />
                  </div>
                </Grid>
              </Grid>
              <div style={{ display: 'flex', height: '100%', flexGrow: 1 }}>
                <DataGrid
                  className={classes.dataGrid}
                  rows={rows}
                  rowCount={rowCount}
                  columns={columns}
                  rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                  page={page}
                  pageSize={tableConf.pageSize}
                  loading={isLoadingQuery || loading}
                  onPageSizeChange={onPageSizeChange}
                  onPageChange={onPageChange}
                  onSortModelChange={handleSortModelChange}
                  onFilterModelChange={onFilterChange}
                  paginationMode="server"
                  sortingMode="server"
                  filterMode="server"
                  sortModel={sortModel}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Alert onClose={handleErrorClose} open={openAlert} severity={alertSeverity}>
          {alertSeverity === ALERT_TYPES.error ? t(DEFAULT_ERROR_MESSAGE) : t(DEFAULT_SUCCESS_MESSAGE)}
        </Alert>
      </Grid>
      <ConfirmDialog
        title={!rowData.checked ? 'Deactivate item' : 'Activate item'}
        okButton={!rowData.checked ? 'Deactivate' : 'Activate'}
        open={openDialog}
        loading={loading}
        onClose={() => {
          setOpenDialog(false)
        }}
        onOk={onToggleDelete}
      >
        {!rowData.checked ? `${t(DEFAULT_COMFIRM_INACTIVE_MESSAGE)} ?` : `${t(DEFAULT_COMFIRM_ACTIVE_MESSAGE)} ?`}
      </ConfirmDialog>
    </>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
  role: state.user.role,
  currentFacility: state.facility.currentFacility,
})

export default connect(mapStateToProps, null)(LogSamples)
